import { useContext } from 'react';
import { EstablishmentContext } from '../../../../../context/EstablishmentContext';

const useClearValues = ({
  permissao,
  setValue,
  handleSetFilter,
  watch,
  loadReportsHistopatologia,

  setEstadosSelected,
  setMunicipiosSelected,
  setEstabelecimentosSelected,
  showTableData,
  setShowTableData,
  setTableData,
  setErrorsFields,
  setListFilterShow,
  setDataColetaIni,
  setDataColetaFinal,
  setDataResultadoFinal,
  setDataResultadoIni,
  setSelectedResultado,
  setSelectedOptionsOrigem,
  setSelectedOptionsCarater,
  setSelectedOptionsEscamosa,
  setSelectedOptionsGlandular,
  setPayload,
  setSelectedOrigem,
  setSelectedCarater,
  setSelectedEscamosa,
  setSelectedGlandular,
  setSelectedLaboratorio,
  setSelectedOptionsLaboratorio,
}) => {
  const handleSetFilterToShow = (newValue: any, inputName: string) => {
    setListFilterShow((prev) =>
      prev.map((item) =>
        item.inputName === inputName ? { ...item, value: newValue } : item
      )
    );
  };

  const clearValues = async () => {
    setErrorsFields([]);
    setTableData([]);

    setDataColetaIni(null);
    setDataResultadoFinal(null);
    setDataColetaFinal(null);
    setDataResultadoIni(null);
    setSelectedResultado();
    setValue('profissionalLaudoId', null);
    setSelectedOptionsOrigem([]);
    setSelectedOptionsCarater([]);
    setSelectedOptionsEscamosa([]);
    setSelectedOptionsGlandular([]);

    setSelectedOrigem([]);
    setSelectedCarater([]);
    setSelectedEscamosa([]);
    setSelectedGlandular([]);

    setSelectedLaboratorio([]);
    setSelectedOptionsLaboratorio([]);

    const fieldsToClearWhenIsPadrao: any[] = [
      'resultado',
      'profissionalLaudoId',
      'data_ini_coleta',
      'data_fin_coleta',
      'data_ini_resultado',
      'data_fin_resultado',
      'search',
    ];

    const fieldsToClearWhenSuperAdmin: any[] = [
      ...fieldsToClearWhenIsPadrao,
      'estado',
      'municipio',
      'estabelecimento_saude',
    ];

    const fieldsToClearWhenIsMunicipal: any[] = [
      ...fieldsToClearWhenIsPadrao,
      'estabelecimento_saude',
    ];

    const fieldsToClearWhenEstadual = [
      'municipio',
      ...fieldsToClearWhenIsMunicipal,
    ];

    if (permissao === 'isPadrao') {
      fieldsToClearWhenIsPadrao.forEach((field) => {
        setValue(field, '');
        handleSetFilter('', field);
        handleSetFilterToShow('', field);
      });
      setShowTableData(true);
      await setPayload().then((payload) => {
        try {
          loadReportsHistopatologia({
            estado: payload.estado,
            municipioId: payload.municipioId,
            estabelecimentoSaudeId: payload.estabelecimentoSaudeId,
            laboratorioLaudoId: payload.laboratorioLaudoId,
            size: payload.size,
            page: payload.page,
          });
        } catch (error) {}
      });
    }

    if (permissao === 'isMunicipal') {
      fieldsToClearWhenIsMunicipal.forEach((field) => {
        setValue(field, '');
        handleSetFilter('', field);
        handleSetFilterToShow('', field);
      });
      setShowTableData(true);
      await setPayload().then((payload) => {
        try {
          loadReportsHistopatologia({
            estado: payload.estado,
            municipioId: payload.municipioId,
            size: payload.size,
            page: payload.page,
          });
        } catch (error) {}
      });
    }

    if (permissao === 'isEstadual') {
      fieldsToClearWhenEstadual.forEach((field) => {
        setValue(field, '');
        handleSetFilter('', field);
        handleSetFilterToShow('', field);
      });
      setShowTableData(false);
      setEstabelecimentosSelected([]);
      setMunicipiosSelected([]);

      // const payload = {
      //   size: watch().size,
      //   page: watch().page,
      // };

      // if (showTableData) {
      //   loadReportsHpv(payload);
      // }
    }

    if (permissao === 'isSuperAdmin') {
      fieldsToClearWhenSuperAdmin.forEach((field) => {
        setValue(field, '');
        handleSetFilter('', field);
        handleSetFilterToShow('', field);
      });
      setShowTableData(false);
      setEstabelecimentosSelected([]);
      setMunicipiosSelected([]);
      setEstadosSelected([]);

      // const payload = {
      //   size: watch().size,
      //   page: watch().page,
      // };

      // if (showTableData) {
      //   loadReportsHpv(payload);
      // }
    }
  };

  return { clearValues };
};

export default useClearValues;
