import { Box, Button } from '@mui/material';

import ClearIcon from '@mui/icons-material/Clear';
import CheckIcon from '@mui/icons-material/Check';

import { useEffect, useState } from 'react';
import { useRequests } from '../hooks/useRequests';
import { useHandlers } from '../hooks/useHandlers';
import PessoalInformation from './PessoalInformation';
import ResidentialInformation from './ResidentialInformation';
import ContactInformation from './ContactInformation';
import BindInformation from './BindInformation';
import AdditionalInformation from './AdditionalInformation';

const FormPatients = ({
  handleSubmit,
  onSubmit,
  control,
  errors,
  setValue,
  ufValue,
  setUfValue,
  watch,
  canUpdateVinculo,
  clearErrors,
  id,
  addContato,
  contatos,
  setIndexRemoveContato,
  setEstabelecimentoVinculo,
  disableInputsVinculo,
  setDisableInputsVinculo,
  haventVinculo,
  sethaventVinculo,
  setOpenDialogCancel,
  setOpenDialogRemoveContato,
  setProfissionalNotHasEquipe,
}) => {
  const [estados, setEstados] = useState<any>([]);

  const [estabelecimentosSelect, setEstabelecimentosSelect] = useState([]);

  const { getEstados } = useRequests({
    setValue,
  });

  const { handleClickOpenDialogCancel } = useHandlers();

  useEffect(() => {
    const loadEstados = async () => {
      const response = await getEstados();
      setEstados(response);
    };

    loadEstados();
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <PessoalInformation control={control} errors={errors} watch={watch} />

      <ResidentialInformation
        control={control}
        errors={errors}
        estados={estados}
        id={id}
        setUfValue={setUfValue}
        ufValue={ufValue}
        setValue={setValue}
      />

      <ContactInformation
        addContato={addContato}
        contatos={contatos}
        control={control}
        errors={errors}
        setIndexRemoveContato={setIndexRemoveContato}
        setOpenDialogRemoveContato={setOpenDialogRemoveContato}
        setValue={setValue}
        watch={watch}
      />

      {canUpdateVinculo && (
        <BindInformation
          clearErrors={clearErrors}
          control={control}
          disableInputsVinculo={disableInputsVinculo}
          errors={errors}
          estabelecimentosSelect={estabelecimentosSelect}
          estados={estados}
          haventVinculo={haventVinculo}
          setDisableInputsVinculo={setDisableInputsVinculo}
          setEstabelecimentoVinculo={setEstabelecimentoVinculo}
          setEstabelecimentosSelect={setEstabelecimentosSelect}
          setValue={setValue}
          sethaventVinculo={sethaventVinculo}
          setProfissionalNotHasEquipe={setProfissionalNotHasEquipe}
        />
      )}

      <AdditionalInformation control={control} errors={errors} />

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          borderRadius: 1,
        }}
        mb={3}
        mt={1}
      >
        <Button
          sx={{
            marginTop: '10px',
            backgroundColor: '#17bebb',
          }}
          variant="contained"
          type="submit"
          // onClick={() => scrollToErrors(errors)}
          startIcon={<CheckIcon />}
        >
          {id ? 'Salvar' : 'Cadastrar'}
        </Button>

        <Button
          sx={{ marginTop: '10px' }}
          variant="outlined"
          color="error"
          onClick={() => handleClickOpenDialogCancel(setOpenDialogCancel)}
          startIcon={<ClearIcon />}
        >
          Cancelar
        </Button>
      </Box>
    </form>
  );
};

export default FormPatients;
