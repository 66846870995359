/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect } from 'react';
import * as echarts from 'echarts';
import { useNavigate } from 'react-router-dom';

const GraphicExames = ({ data }) => {
  const formatedData = [
    { typeExam: 'Citologia', value: data?.citologia_count },
    { typeExam: 'Colposcopia', value: data?.colposcopia_count },
    { typeExam: 'Histopatologia', value: data?.histopatologia_count },
    { typeExam: 'HPV', value: data?.hpv_count },
  ];

  const navigate = useNavigate();
  useEffect(() => {
    const chart = echarts.init(document.getElementById('echarts-chart-exames'));

    const option = {
      tooltip: {
        trigger: 'item',
      },
      grid: {
        left: '5px',
        right: '0',
        bottom: '0',
        top: '40px',
        containLabel: true,
      },
      xAxis: {
        type: 'category',
        data: formatedData.map((item) => item.typeExam),
      },
      yAxis: {
        type: 'value',
      },
      series: [
        {
          data: formatedData.map((item) => item.value),
          type: 'bar',
          itemStyle: {
            color: '#2D9CED',
            emphasis: {
              barBorderRadius: 5,
              color: '#5CACEE',
              label: {
                show: true,
                position: 'top',
                rich: {
                  value: {
                    width: '200px',
                    height: '200px',
                    padding: '5px 0 5px 0',
                    color: '#5CACEE',
                    backgroundColor: '#fff',
                    borderRadius: 5,
                    border: '1px solid #2D9CED',
                  },
                },

                formatter: (params) => {
                  if (
                    params.dataIndex !== -1 &&
                    formatedData[params.dataIndex].typeExam === 'HPV'
                  ) {
                    return `{value|${params.value}}`;
                  }
                  return '';
                },
              },
            },
          },
          barWidth: 40,
        },
      ],
    };

    chart.setOption(option);

    chart.on('click', (params) => {
      const clickedTypeExam = formatedData[params.dataIndex]?.typeExam;
      if (clickedTypeExam === 'HPV') {
        navigate('/relatorios/hpv');
      }
    });

    return () => {
      chart.dispose();
    };
  }, [data]);

  return (
    <div
      id="echarts-chart-exames"
      style={{
        width: '100%',
        height: '400px',
      }}
    />
  );
};

export default GraphicExames;
