export function validarData(data) {
  // Verificar se a string tem o formato XX/XX/XXXX
  const regex = /^\d{2}\/\d{2}\/\d{4}$/;
  if (!regex.test(data)) {
    return false; // Formato inválido
  }

  const partes = data.split('/');
  const dia = parseInt(partes[0]);
  const mes = parseInt(partes[1]);
  const ano = parseInt(partes[2]);

  // Verificar se dia e mês têm dois dígitos cada
  if (isNaN(dia) || isNaN(mes) || isNaN(ano)) {
    return false; // Dia, mês ou ano não são números
  }

  // Verificar se o ano tem mais de 3 dígitos e não é menor que 1800
  if (partes[2].length < 3 || ano < 1800) {
    return false; // Ano inválido
  }

  // Verificar se o mês está entre 01 e 12
  if (mes < 1 || mes > 12) {
    return false; // Mês inválido
  }

  // Verificar se o dia está entre 01 e 31 (alguns meses têm menos de 31 dias, mas vamos manter essa validação genérica)
  if (dia < 1 || dia > 31) {
    return false; // Dia inválido
  }

  return true; // A data é válida
}
