/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import MenuItem from '@mui/material/MenuItem';
import Menu, { MenuProps } from '@mui/material/Menu';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { styled } from '@mui/material/styles';
import { SampleContext } from '../../context/SampleContext';
import CustomizedButton from '../CustomizedButton';
interface Column {
  id:
    | 'distritoSanitario'
    | 'cnes'
    | 'nomeUbs'
    | 'ine'
    | 'nomeEquipe'
    | 'nome'
    | 'cns'
    | 'cpf'
    | 'dataNascimento'
    | 'nomeDaMae'
    | 'idade'
    | 'citologia';
  label: string;
  minWidth?: string | number;
  align?: 'right';
  format?: (value: number) => string;
}

const columns: readonly Column[] = [
  { id: 'distritoSanitario', label: 'Distrito Sanitário' },
  { id: 'cnes', label: 'CNES' },
  { id: 'nomeUbs', label: 'UBS' },
  { id: 'ine', label: 'INE' },
  { id: 'nomeEquipe', label: 'Nome da Equipe' },
  { id: 'nome', label: 'Nome' },
  { id: 'cns', label: 'CNS' },
  { id: 'cpf', label: 'CPF' },
  { id: 'dataNascimento', label: 'Data de Nascimento' },
  { id: 'nomeDaMae', label: 'Nome da Mãe' },
  { id: 'idade', label: 'Idade' },
  { id: 'citologia', label: 'Citologia' },
];

type DataType = Record<
  | 'distritoSanitario'
  | 'cnes'
  | 'nomeUbs'
  | 'ine'
  | 'nomeEquipe'
  | 'nome'
  | 'cns'
  | 'cpf'
  | 'dataNascimento'
  | 'nomeDaMae'
  | 'idade'
  | 'citologia',
  any
>;

export type ResponseData = {
  count: number;
  data: DataType[];
  page: number;
  totalItems: number;
  totalPages: number;
};

export default function TablePatients() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const { samples, getSample, setFilters } = React.useContext(SampleContext);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleChangePage = React.useCallback(
    (_: unknown, newPage: number) => {
      setPage(newPage);
      setFilters((filter) => ({
        ...filter,
        page: newPage + 1,
      }));
      getSample(newPage + 1, rowsPerPage);
    },
    [setFilters]
  );
  const handleChangeRowsPerPage = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
      setFilters((filter) => ({
        ...filter,
        size: Number(event.target.value),
        page: 1,
      }));
      getSample(1, Number(event.target.value));
    },
    [setFilters]
  );

  const StyledMenu = styled((props: MenuProps) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...props}
    />
  ))(({ theme }) => ({
    '& .MuiPaper-root': {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      minWidth: 180,
      color:
        theme.palette.mode === 'light'
          ? 'rgb(55, 65, 81)'
          : theme.palette.grey[300],
      boxShadow:
        'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
      '& .MuiMenu-list': {
        padding: '4px 0',
      },
      '& .MuiMenuItem-root': {
        '& .MuiSvgIcon-root': {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        '&:active': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  }));

  return (
    <Box sx={{ marginTop: '15px', marginBottom: '50px' }}>
      <Paper sx={{ mb: 2, pt: 2 }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            borderRadius: 1,
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <CustomizedButton
              aria-controls={open ? 'demo-customized-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
              endIcon={<KeyboardArrowDownIcon />}
              variant="outlined"
            >
              Exportar
            </CustomizedButton>
            <StyledMenu
              id="demo-customized-menu"
              MenuListProps={{
                'aria-labelledby': 'demo-customized-button',
              }}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
            >
              <MenuItem disableRipple>CSV</MenuItem>
              <MenuItem disableRipple>PDF</MenuItem>
            </StyledMenu>
          </Box>
        </Box>
        <TableContainer>
          <Table aria-labelledby="tableTitle" style={{ whiteSpace: 'nowrap' }}>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth, fontWeight: 'bold' }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {samples.data?.map((row: DataType, index: number) => {
                return (
                  <TableRow hover key={index}>
                    {columns.map((column: Column) => (
                      <TableCell>{row[column.id]}</TableCell>
                    ))}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={samples.totalItems || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={'Itens por página'}
          labelDisplayedRows={({ from, to, count }) => {
            return `${from}–${to} de ${
              count !== -1 ? count : `mais do que ${to}`
            }`;
          }}
        />
      </Paper>
    </Box>
  );
}
