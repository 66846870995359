import { Close, Search } from '@mui/icons-material';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import {
  Box,
  Button,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  TextField,
} from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { ptBR } from 'date-fns/locale';
import { SetStateAction, useState } from 'react';
import { useParams } from 'react-router-dom';
import api from '../../api';
import { formatDate } from '../../utils/formatters';
import { Procedimentos } from '../Timeline';
import {
  FiltersSelectedWrapper,
  FiltersWrapper,
  InputWrapper,
} from './TimelineFilter.style';

const status = [
  {
    value: 'RESULTADO_DISPONIVEL',
    label: 'Resultado Dispónivel',
  },
  {
    value: 'RESULTADO_NAO_ENTREGUE',
    label: 'Resultado não entregue',
  },
  {
    value: 'ENTREGUE',
    label: 'Entregue',
  },
  {
    value: 'RECOMENDADO',
    label: 'Recomendado',
  },
  {
    value: 'COLETADO',
    label: 'Coletado',
  },
  {
    value: 'ENVIADO_PARA_ANALISE',
    label: 'Enviado para Análise',
  },
  {
    value: 'EM_ANALISE',
    label: 'Em Análise',
  },
  {
    value: 'NAO_ENTREGUE',
    label: 'Não entregue',
  },
  {
    value: 'FALHA',
    label: 'Falha',
  },
];

const types = [
  {
    value: '1',
    label: 'Teste de HPV',
  },
  // {
  //   value: '2',
  //   label: 'Citologia como rastreio',
  // },
  {
    value: '3',
    label: 'Citologia reflexo (após teste de HPV positivo)',
  },
  {
    value: '11',
    label: 'Colposcopia',
  },
  {
    value: '10',
    label: 'Histopatologia',
  },
];

interface ITimelineFilterProps {
  setExams: React.Dispatch<SetStateAction<Procedimentos[]>>;
}

export function TimelineFilter({ setExams }: ITimelineFilterProps) {
  const [statusSelected, setStatusSelected] = useState<string[]>([]);
  const [typesSelected, setTypesSelected] = useState<string[]>([]);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [finalDate, setFinalDate] = useState<Date | null>(null);
  const { id } = useParams();

  const getSelectValue = ({ target }) => {
    return typeof target.value === 'string'
      ? target.value.split(',')
      : target.value;
  };

  const handleStatusChange = (e: SelectChangeEvent<typeof statusSelected>) => {
    setStatusSelected(getSelectValue(e));
  };

  const handleTypeChange = (e: SelectChangeEvent<typeof statusSelected>) => {
    setTypesSelected(getSelectValue(e));
  };

  const loadExams = async (query?: string) => {
    if (query) {
      return await api.get(`pacientes/${id}/exames?${query}`);
    }
    return await api.get(`pacientes/${id}/exames`);
  };

  const handleFilter = async () => {
    const _startDate = startDate ? startDate.toISOString() : '';
    const _finalDate = finalDate ? finalDate.toISOString() : '';
    const query = `dt_final=${_startDate}&dt_inicio=${_finalDate}&categoria=${typesSelected}&status=${statusSelected}`;

    const res = await loadExams(query);

    if (res.status === 200) {
      setExams(res.data);
    }
  };

  const handleClear = async () => {
    setStatusSelected([]);
    setTypesSelected([]);
    setStartDate(null);
    setFinalDate(null);

    const res = await loadExams();

    if (res.status === 200) {
      setExams(res.data);
    }
  };

  const handleSetChipLabel = (search, array) => {
    const result = array.filter((item) => item.value === search);
    if (result) {
      return result[0].label;
    }
    return '';
  };

  return (
    <>
      <InputWrapper>
        <Search color="inherit" onClick={handleFilter} data-testid='search-bar-icon'/>
        <FiltersSelectedWrapper>
          {statusSelected.map((_status) => (
            <Chip label={handleSetChipLabel(_status, status)} key={_status} />
          ))}
          {typesSelected.map((type) => (
            <Chip label={handleSetChipLabel(type, types)} key={type} />
          ))}
          {startDate && <Chip label={`Início: ${formatDate(startDate)}`} />}
          {finalDate && <Chip label={`Fim: ${formatDate(finalDate)}`} />}
        </FiltersSelectedWrapper>
        <Close color="inherit" onClick={handleClear} data-testid='clearSearchButton'/>
      </InputWrapper>

      <FiltersWrapper>
        <FormControl sx={{ mt: 3, width: 300 }} size="small">
          <InputLabel id="status-label">Status</InputLabel>
          <Select
            labelId="status-label"
            id="status"
            value={statusSelected}
            onChange={handleStatusChange}
            input={<OutlinedInput id="status-chip" label="Chip" />}
            data-testid='statusField'
            renderValue={(selected) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map((value) => (
                  <Chip
                    key={value}
                    label={handleSetChipLabel(value, status)}
                    onDelete={() => {}}
                    color="primary"
                    variant="outlined"
                    size="small"
                  />
                ))}
              </Box>
            )}
          >
            {status.map((_status, idx) => (
              <MenuItem key={_status.value} value={_status.value} data-testid={`statusField-${idx}`}>
                {_status.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl sx={{ mt: 3, width: 300 }} size="small">
          <InputLabel id="type-label">Tipo de Procedimento</InputLabel>
          <Select
            labelId="type-label"
            data-testid={`procedimentoField`}
            id="type"
            value={typesSelected}
            onChange={handleTypeChange}
            input={<OutlinedInput id="type-chip" label="Chip" />}
            renderValue={(selected) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map((value) => (
                  <Chip
                    key={value}
                    label={handleSetChipLabel(value, types)}
                    onDelete={() => {}}
                    color="primary"
                    variant="outlined"
                    size="small"
                  />
                ))}
              </Box>
            )}
          >
            {types.map((type, idx) => (
              <MenuItem key={type.value} value={type.value} data-testid={`procedimentoField-${idx}`}>
                {type.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl sx={{ mt: 3, width: 300 }} size="small">
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
            <DatePicker
              onChange={(value) => setStartDate(value)}
              data-testid={`dataInicioField`}
              mask="__/__/____"
              value={startDate}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Data de Início"
                  size="small"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </LocalizationProvider>
        </FormControl>

        <FormControl sx={{ mt: 3, width: 300 }} size="small">
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
            <DatePicker
              onChange={(value) => setFinalDate(value)}
              data-testid={`dataFinalField`}
              mask="__/__/____"
              value={finalDate}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Data Final"
                  size="small"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </LocalizationProvider>
        </FormControl>
      </FiltersWrapper>
      <Box mt={4} display="flex" justifyContent="flex-end" gap={3}>
        <Button
          sx={{ fontWeight: 'bold' }}
          variant="contained"
          onClick={handleFilter}
          data-testid={`filterButton`}
        >
          Filtrar
        </Button>

        <Button
          sx={{ fontWeight: 'bold' }}
          variant="outlined"
          color="error"
          onClick={handleClear}
          data-testid={`cleanButton`}
        >
          Limpar
        </Button>
      </Box>
    </>
  );
}
