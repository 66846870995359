import React from 'react';
import { Grid, Modal, Paper, Typography } from '@mui/material';
import { Box } from '@mui/system';
import CalendarCheck from '../../../../../assets/imgs/Calendar-Check.svg';
import { PatientScheduleProps } from '../../PatientSchedule/types';

interface ModalProps {
  open: boolean;
  handleClose: () => void;
  footer?: React.ReactNode;
  children?: React.ReactNode;
  patientSchedule?: PatientScheduleProps;
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  pt: 2,
  px: 4,
  pb: 3,
  borderRadius: '8px',
};

const Size16 = {
  fontSize: '16px',
  fontWeight: '400',
  color: 'rgba(0, 0, 0, 0.87)',
};

const Size12 = {
  fontSize: '12px',
  fontWeight: '400',
  color: 'rgba(0, 0, 0, 0.87)',
};

export const ModalSchedulingPatient = ({
  open,
  handleClose,
  patientSchedule,
}: ModalProps) => {
  const isRealizado =
    patientSchedule?.status === 'Realizado' ||
    patientSchedule?.status === 'Agendado';

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box sx={{ ...style, maxWidth: '567px', width: '100%' }}>
        <Grid container>
          <Grid
            item
            xs={12}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <span
              style={{
                fontSize: '20px',
                fontWeight: '700',
                color: 'rgba(0, 0, 0, 0.87)',
              }}
            >
              {isRealizado
                ? 'Procedimento Realizado'
                : 'Procedimento Cancelado'}
            </span>

            <span onClick={handleClose}>x</span>
          </Grid>
          <Paper
            style={{
              border: '1px solid #bdbdbd',
              borderRadius: '12px',
              width: '100%',
              padding: '16px',
              marginTop: '26px',
            }}
          >
            <Grid container>
              <Grid item xs={12}>
                <Typography
                  style={{
                    fontSize: '20px',
                    fontWeight: '700',
                    color: 'rgba(0, 0, 0, 0.87)',
                  }}
                >
                  Dados da Convocação
                </Typography>
              </Grid>
              <Grid
                item
                xs={7}
                style={{ display: 'flex', alignItems: 'center', gap: '6px' }}
                marginTop={'8px'}
              >
                <img src={CalendarCheck} alt="check circle" />
                <Typography style={Size16}>Sexta, 11/11/2022</Typography>
              </Grid>
              <Grid item xs={12} style={{ marginTop: '16px' }}>
                <Typography style={Size12}>Local de procedimento</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography style={Size16}>Upinha Dom Elder Câmara</Typography>
              </Grid>
              <Grid item xs={12} style={{ marginTop: '16px' }}>
                <Typography style={Size12}>Profissional</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography style={Size16}>
                  {patientSchedule?.nome_profissional}
                </Typography>
              </Grid>
              <Grid item xs={12} style={{ marginTop: '16px' }}>
                <Typography style={Size12}>Procedimento</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography style={Size16}>
                  {patientSchedule?.procedimento}
                </Typography>
              </Grid>
              <Grid item xs={12} style={{ marginTop: '16px' }}>
                <Typography style={Size12}>Paciente</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography style={Size16}>Maria das Dores Assunção</Typography>
              </Grid>
              <Grid item xs={12} style={{ marginTop: '16px' }}>
                <Typography style={Size12}>Horário</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography style={Size16}>
                  {patientSchedule?.data} - {patientSchedule?.hora}
                </Typography>
              </Grid>

              <Grid item xs={12} style={{ marginTop: '16px' }}>
                <Typography style={Size12}>Motivo</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography style={Size16}>Solicitação do Paciente</Typography>
              </Grid>
              <Grid item xs={12} style={{ marginTop: '16px' }}>
                <Typography style={Size12}>
                  Usuário resposável pelo cancelamento
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography style={Size16}>
                  {patientSchedule?.nome_profissional}- {patientSchedule?.cbo}
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Box>
    </Modal>
  );
};
