import { Divider, Paper, TablePagination } from '@mui/material';
import CardCentralNotification from '../CardCentralNotificiation';
import { makeStyles } from '@material-ui/styles';
import useSetPayload from '../../pages/CentralNotification/hooks/useSetPayload';
import useRequestFilter from '../../pages/CentralNotification/hooks/useRequestFilter';
import { useContext } from 'react';
import { LoadingContext } from '../../context/LoadingContext';

const TableCentralNotification = ({
  data,
  watch,
  setValue,
  setData,
  handleSizeChange,
  handlePageChange,
  setAlertId,
  setModalIsOpen,
}) => {
  const useStyles = makeStyles({
    root: {
      overflowX: 'auto',
    },
    pagination: {
      position: 'sticky',
      bottom: 0,
      backgroundColor: '#fff',
      zIndex: 999,
    },
  });

  const classes = useStyles();

  return (
    <Paper
      variant="outlined"
      sx={{
        marginTop: '25px',
        padding: '32px',
        border: '1px solid rgba(0, 0, 0, 0.23)',
        borderRadius: '10px',
        gap: '40px',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {data?.data?.map((notification, index) => {
        return (
          <>
            <CardCentralNotification
              setModalIsOpen={setModalIsOpen}
              setAlertId={setAlertId}
              status={notification?.type?.name}
              data={notification?.user_notify.created_at}
              namePatient={notification?.notify_data?.pessoa?.nome}
              id={notification.user_notify.id}
              alertId={notification.id}
              viewed_at={notification.user_notify?.viewed_at}
              notificationResolved={notification.is_resolved}
              isResolved={notification.resolved_by}
              resolved_at={notification.resolved_at}
              idPessoa={notification?.notify_data?.pessoa?.id}
              idExam={notification?.notify_data?.hpv_id ?? null}
            />

            {index + 1 !== data?.data?.length && <Divider />}
          </>
        );
      })}

      <TablePagination
        className={classes.pagination}
        rowsPerPageOptions={[25, 50, 100]}
        component="div"
        count={data?.totalItems || 0}
        rowsPerPage={watch().size || 25}
        page={data?.page - 1 || 0}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleSizeChange}
        labelRowsPerPage={'Itens por página'}
        labelDisplayedRows={({ from, to, count }) => {
          return `${from}–${to} de ${
            count !== -1 ? count : `mais do que ${to}`
          }`;
        }}
      />
    </Paper>
  );
};

export default TableCentralNotification;
