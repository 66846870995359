import { useStyles } from './styles';
export interface Props {
  active?: boolean;
  title: string;
  onClick?: () => void;
}

export const ScheduleListItem = ({ active, title, onClick }: Props) => {
  const styles = useStyles({ active });

  return (
    <div className={styles.container} onClick={onClick}>
      {title}
    </div>
  );
};
