import {
  Divider,
  Table,
  TableContainer,
  TablePagination,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { useStyles } from '.';
import ButtonWithMenu from '../../ButtonWithMenu';
import TableHeaders from './Components/ColumnsHeaders';
import Rows from './Components/Rows';
import useSetPayload from '../../../pages/Reports/ReportsColposcopia/FiltersReportsColposcopia/hooks/useSetPayload';
import useRequestFilter from '../../../pages/Reports/ReportsColposcopia/FiltersReportsColposcopia/hooks/useRequestFilter';
import { DownloadFilesReportsColposcopia } from '../../../lib/Reports/downloadFilesReportsColposcopia';

export const TableReportsColposcopia = ({
  dtResultadoIni,
  dtResultadoFinal,

  showTableData,
  tableData,
  setValue,
  watch,
  setTableData,
  estadosSelected,
  municipiosSelected,
  estabelecimentosSelected,
  equipesSelected,
  dtColposcopiaIni,
  dtColposcopiaFinal,

  selectedOptionsAvaliacao,
  selectedOptionsVisibilidade,
  selectedOptionsZt,
  selectedOptionsTiposAchados,
  selectedOptionsLugol,
  selectedOptionsSchiller,
  selectedOptionsBiopsia,

  selectedOptionsAlteracoes,
}) => {
  const [openModalAmostragem, setOpeModalAmostragem] = useState(false);

  const firstRowDisplayed = (tableData?.page - 1) * tableData?.count + 1;
  const lastRowDisplayed = Math.min(
    tableData?.page * tableData?.count,
    tableData?.totalItems
  );
  const totalItems = tableData?.totalItems;

  const stringToModalExportActualList = `${firstRowDisplayed}–${lastRowDisplayed} de ${totalItems}`;

  const { setPayload } = useSetPayload({
    watch,
    estadosSelected,
    municipiosSelected,
    estabelecimentosSelected,
    equipesSelected,

    dtResultadoIni,
    dtResultadoFinal,
    selectedOptionsAlteracoes,
    selectedOptionsAvaliacao,
    selectedOptionsVisibilidade,

    selectedOptionsZt,

    selectedOptionsTiposAchados,
    selectedOptionsLugol,
    selectedOptionsSchiller,
    selectedOptionsBiopsia,
  });

  const { loadReportsColposcopia } = useRequestFilter({ setTableData });
  const classes = useStyles();

  const handleSizeChange = (e) => {
    setTableData([]);

    setValue('size', e.target.value);
    setPayload().then((payload) => loadReportsColposcopia(payload));
  };

  const handlePageChange = (e, newPage) => {
    setTableData([]);
    setValue('page', newPage + 1);
    setPayload().then((payload) => loadReportsColposcopia(payload));
  };

  const prepareDownload = async ({ selectedOption, sizeFile }) => {
    setPayload().then(async (payload: any) => {
      if (sizeFile === 'all_list') {
        delete payload.size;
        delete payload?.page;
      }

      const file = await DownloadFilesReportsColposcopia({
        fileType: selectedOption,
        payload,
      });

      const url = window.URL.createObjectURL(new Blob([file]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `relatorio-colposcopia.${String(selectedOption).toLowerCase()}`
      );
      document.body.appendChild(link);
      link.click();

      setOpeModalAmostragem(false);
    });
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <p style={{ marginTop: '35px' }}>
          Exibindo {tableData?.data ? tableData?.data?.length : 0} pacientes
        </p>
        <ButtonWithMenu
          disabled={!showTableData}
          prepareDownload={prepareDownload}
          openModalAmostragem={openModalAmostragem}
          setOpeModalAmostragem={setOpeModalAmostragem}
          stringToModalExportActualList={stringToModalExportActualList}
          totalItems={totalItems}
          title="Exportar Relatório Geral Colposcopia"
        />
      </div>
      <Typography
        variant="h4"
        fontWeight="bold"
        style={{ marginBottom: '30px', fontSize: '24px', marginTop: '35px' }}
      >
        Listagem
      </Typography>
      <Divider />
      <TableContainer>
        {showTableData ? (
          <>
            {tableData?.length === 0 ? (
              <div style={{ marginBottom: '20px', marginTop: '20px' }}>
                Carregando Dados...
              </div>
            ) : (
              <>
                <TableHeaders />
                <Rows tableData={tableData} />
              </>
            )}
          </>
        ) : (
          <>
            <div style={{ marginBottom: '20px', marginTop: '20px' }}>
              Selecione um filtro.
            </div>
          </>
        )}
      </TableContainer>
      <TablePagination
        className={classes.pagination}
        rowsPerPageOptions={[25, 50, 100]}
        component="div"
        count={tableData?.totalItems || 0}
        rowsPerPage={watch().size || 0}
        page={tableData?.page - 1 || 0}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleSizeChange}
        labelRowsPerPage={'Itens por página'}
        labelDisplayedRows={({ from, to, count }) => {
          return `${from}–${to} de ${
            count !== -1 ? count : `mais do que ${to}`
          }`;
        }}
      />
    </>
  );
};
