export function getAtendimentoStatusName(status: string) {
  return (
    {
      REALIZADO: 'Realizado',
      NAO_REALIZOU: 'Não Realizou',
      MENSTRUADA: 'Menstruada',
      NAO_FAIXA_ETARIA: 'Não está na faixa etária',
      HISTERECTOMIZADA: 'Histerectomizada',
      OUTRO: 'Outro',
    }[status] || 'Desconhecido'
  );
}
