import {
  formatedMultiples,
  formatDate,
} from '../../../utils/requestUtils/formatMultiples';

const useSetPayload = ({
  watch,
  dataNotificacaoFinal,
  dataNotificacaoIni,
  selectedOptionsStatus,
  selectedOptionsNotificationType,
  selectedOptionsStatusLidas,
}) => {
  const setPayload = () => {
    let payload: any;
    return new Promise<void>((resolve, reject) => {
      payload = {
        status: formatedMultiples(selectedOptionsStatus),
        type_ids: formatedMultiples(selectedOptionsNotificationType),
        date_start: formatDate(dataNotificacaoIni),
        date_end: formatDate(dataNotificacaoFinal),
        size: watch().size,
        page: watch().page,
      };

      resolve(payload);
    });
  };

  return {
    setPayload,
  };
};

export default useSetPayload;
