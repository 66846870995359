import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@mui/material';
import { useContext, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import logoJordana from '../../assets/imgs/logo.png';
import { FormContainer } from './ValidateAccount.style';
import { ButtonNew } from '../SeePatient/SeePatient.style';
import api from '../../api';
import { BackgroundContext } from '../../context/BackgroundContext';

export default function ValidateAccount() {
  const [openDialogSearch, setOpenDialogSearch] = useState(false);
  const [requestError, setRequestError] = useState<any>();

  const { backgroundImage } = useContext(BackgroundContext);

  let navigate = useNavigate();
  const { token } = useParams();

  const handleClickDialogSearch = () => {
    setOpenDialogSearch(true);
  };

  const handleCloseDialogsearch = () => {
    setOpenDialogSearch(false);
  };

  return (
    <Box
      bgcolor="lightblue"
      width="100vw"
      height="100vh"
      sx={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0 16px',
      }}
    >
      <FormContainer variant="outlined">
        <Box py={7.5} px={3} textAlign="center">
          <img src={logoJordana} alt="logo jornada" width="181" />

          <Box my={4}>
            <Typography variant="h5" fontWeight="bold">
              Boas Vindas!
            </Typography>
            <Typography variant="body2">
              "Para aceitar o convite clique no botão abaixo."
            </Typography>
          </Box>

          <Box>
            <ButtonNew
              variant="contained"
              startIcon={<AddIcon />}
              onClick={async () => {
                await api
                  .get(`/usuarios/token/${token}`)
                  .then((response) => {
                    setRequestError(response);
                    handleClickDialogSearch();
                    navigate('/');
                  })
                  .catch((err) => {
                    setRequestError(err.response);
                    handleClickDialogSearch();
                    navigate('/');
                  });
              }}
            >
              Aceitar e continuar
            </ButtonNew>
          </Box>
        </Box>
      </FormContainer>

      <Dialog open={openDialogSearch}>
        <DialogTitle>Atenção!</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {requestError?.data.message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialogsearch}>OK</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
