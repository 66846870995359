export enum CategoriaProcedimentoEnum {
  Teste_de_HPV = 1,
  Citologia_Como_Rastreio = 2,
  Citologia_Reflexo = 3,
  ExereseTipo1 = 4,
  ExereseTipo2 = 5,
  ExereseTipo3 = 6,
  Conizacao = 7,
  Histerectomia = 8,
  Quimioterapia = 9,
  Histopatologia = 10,
  Colposcopia = 11,
}
