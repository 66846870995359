import api from '../../../../../api';

const useGetDetailsFilters = () => {
  const getAcsDetails = async (acsNome) => {
    const response = await api.get('/profissionais', {
      params: {
        nome: acsNome,
      },
    });

    return response.data;
  };
  const getEstabelecimentoDetails = async (estabelecimentoNome) => {
    const response = await api.get('/estabelecimentos-saude', {
      params: {
        nome: estabelecimentoNome,
      },
    });

    return response.data;
  };

  const getMunicipioDetails = async (municipioNome) => {
    const response = await api.get('/municipios', {
      params: {
        nome: municipioNome,
      },
    });

    return response.data;
  };

  return {
    getMunicipioDetails,
    getEstabelecimentoDetails,
    getAcsDetails,
  };
};

export default useGetDetailsFilters;
