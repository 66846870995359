import { Alert, Box, Grid, Typography } from '@mui/material';

const ComplementInformation = ({ complementInformation }) => {
  var orientacao = '';

  var identidade_genero = '';

  switch (complementInformation.data?.orientacao) {
    case 1:
      orientacao = 'Heterossexual';
      break;
    case 2:
      orientacao = 'Homossexual (gay/lésbica)';
      break;
    case 3:
      orientacao = 'Bissexual';
      break;
    case 4:
      orientacao = 'Outro';
      break;
    default:
      orientacao = '';
  }
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
      data-testid='complementary-box'
    >
      <Grid container mb={3}>
        <Grid item xs={12} md={2.4}>
          <Typography sx={{ fontSize: '10px', color: '#555555' }}>
            Escolaridade
          </Typography>
          <Typography sx={{ fontSize: '14px', fontWeight: 600 }}  data-testid='complementary-escolaridade'>
            {complementInformation.data?.escolaridade}
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} md={4}>
          <Typography sx={{ fontSize: '10px', color: '#555555' }}>
            Orientação Sexual
          </Typography>
          <Typography sx={{ fontSize: '14px', fontWeight: 600 }} data-testid='complementary-orientacao'>
            {orientacao}
          </Typography>
        </Grid>
        <Grid item xs={12} md={3}>
          <Typography sx={{ fontSize: '10px', color: '#555555' }}>
            Identidade de Genêro
          </Typography>
          <Typography sx={{ fontSize: '14px', fontWeight: 600 }} data-testid='complementary-genero'>
            {identidade_genero}
          </Typography>
        </Grid>
        <Grid item xs={12} md={5}>
          <Alert severity="info" data-testid='complementary-alert_info'>
            Informações importadas da <b data-testid='complementary-platform'>plataforma e-SUS AB</b>
          </Alert>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ComplementInformation;
