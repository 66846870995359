import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles({
  root: {
    overflowX: 'auto',
  },
  pagination: {
    position: 'sticky',
    bottom: 0,
    backgroundColor: '#fff',
    zIndex: 999,
  },
});
