import { makeStyles } from '@material-ui/styles';

interface Props {
  active?: boolean;
}

export const useStyles = makeStyles({
  container: ({ active }: Props) => ({
    display: 'flex',
    padding: '16px',
    alignItems: 'center',
    border: active
      ? '1px solid var(--action-active, rgba(0, 0, 0, 0.54))'
      : '1px dashed gray ',
    boxShadow: active ? '0px -1px 0px 0px rgba(0, 0, 0, 0.12) inset' : 'unset',
    background: active ? '#DBEBFF' : 'unset',
    cursor: 'pointer',
  }),
  footer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '8px 8px 0',
  },
  newPatientLink: {
    textDecoration: 'none',
    color: '#2196F3',
  },
});
