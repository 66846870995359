import { TableCell, Typography } from '@mui/material';

const RowsHealthcareFacility = ({ row }) => {
  return (
    <>
      {/* <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          INE
        </Typography>
      </TableCell>
      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {row?.procedimento?.estabelecimento_saude?.nome_fantasia}
        </Typography>
      </TableCell> */}
    </>
  );
};

export default RowsHealthcareFacility;
