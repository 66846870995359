/* eslint-disable react-hooks/exhaustive-deps */

import { useState } from 'react';
import {
  Autocomplete,
  Box,
  Breadcrumbs,
  Button,
  Container,
  Divider,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import * as React from 'react';
import { SideMenu } from '../../components/SideMenu';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Link } from 'react-router-dom';
import ReturnButton from '../../components/ReturnButton';
import api from '../../api';
import TotalCardsPanels from '../../components/PanelsComponents/TotalCards';
import GraphicTotalCards from '../../components/PanelsComponents/Graphics/GraphicTotalCards';
import GraphicTotalConvocation from '../../components/PanelsComponents/Graphics/GraphicTotalConvocation';
import GraphicReasonNaoAtendida from '../../components/PanelsComponents/Graphics/GraphicReasonNaoAtendida';
import GraphicReasonNaoElegivel from '../../components/PanelsComponents/Graphics/GraphicReasonNaoElegiveis';
import GraphicExames from '../../components/PanelsComponents/Graphics/GraphicsExams';
import GraphicReasonExamsNaoColetados from '../../components/PanelsComponents/Graphics/GraphicReasonExamsNaoColetados';

const Panels = () => {
  const [loading] = React.useState(false);
  const [statesOptions, setStatesOptions] = React.useState<any>([]);
  const [municipiosOptions, setMunicipiosOptions] = React.useState([]);
  const [estabelecimentosOptions, setEstabelecimentosOptions] = useState<any>(
    []
  );

  const [payloadFilter, setPayloadFilter] = React.useState({});
  const [convocadosMensal, setConvocadosMensal] = React.useState<any>();
  const [contadoresStatus, setContadoresStatus] = React.useState<any>();
  const [motivosNaoAtendidas, setMotivosNaoAtendidas] = useState<any>();
  const [motivoNaoElegiveis, setMotivoNaoElegiveis] = React.useState<any>();
  const [examsCounts, setExamsCounts] = useState<any>();

  const [examsNaoColetados, setExamsNaoColetados] = useState<any>();
  const handleChangeState = async (newValue) => {
    if (newValue) {
      const responseMunicipios = await api.get('/municipios', {
        params: { sigla: newValue.sigla },
      });
      setPayloadFilter((prev) => {
        return {
          ...prev,
          estadoSigla: newValue.sigla,
        };
      });
      setMunicipiosOptions(responseMunicipios.data);
    } else {
      setPayloadFilter((prev) => {
        return {
          ...prev,
          estadoSigla: null,
        };
      });
    }
  };

  const handleChangeMunicipio = async (newValue) => {
    if (newValue) {
      const response = await api.get('/estabelecimentos-saude/unpaged', {
        params: {
          municipio: newValue.nome,
        },
      });
      setEstabelecimentosOptions(response.data.data);

      setPayloadFilter((prev) => {
        return {
          ...prev,
          municipioId: newValue.id,
        };
      });
    } else {
      setPayloadFilter((prev) => {
        return {
          ...prev,
          municipioId: null,
        };
      });
    }
  };

  const handleChangeUbs = (newValue) => {
    if (newValue) {
      setPayloadFilter((prev) => {
        return {
          ...prev,
          unidadeSaudeId: newValue.id,
        };
      });
    } else {
      setPayloadFilter((prev) => {
        return {
          ...prev,
          unidadeSaudeId: null,
        };
      });
    }
  };

  React.useEffect(() => {
    const getStates = async () => {
      const response = await api.get('/estados');
      if (response.status === 200) {
        setStatesOptions(response.data);
      }
    };
    getStates();
  }, []);

  let resultsWhatsappSended;

  if (convocadosMensal) {
    resultsWhatsappSended =
      convocadosMensal?.length > 1
        ? convocadosMensal?.map((item) => Number(item?.numero_de_convocados))
        : Number(convocadosMensal[0]?.numero_de_convocados);
  }

  const getAllGraphics = async () => {
    const getConvocadosMensal = async () => {
      try {
        const response = await api.get('/dashboard/convocados-mensal', {
          params: {
            ...payloadFilter,
          },
        });
        return response.data;
      } catch (error) {}
    };

    const getContadoresStatus = async () => {
      try {
        const response = await api.get('/dashboard/status-counts', {
          params: {
            ...payloadFilter,
          },
        });
        return response.data;
      } catch (error) {}
    };

    const getMotivosNaoAtendida = async () => {
      try {
        const response = await api.get('/dashboard/motivo-nao-atendimento', {
          params: {
            ...payloadFilter,
          },
        });
        return response.data;
      } catch (error) {}
    };
    const getMotivosNaoElegiveis = async () => {
      try {
        const response = await api.get('/dashboard/motivo-nao-elegivel', {
          params: {
            ...payloadFilter,
          },
        });
        return response.data;
      } catch (error) {}
    };

    const getExamsCounts = async () => {
      try {
        const response = await api.get('es', {
          params: {
            ...payloadFilter,
          },
        });
        return response.data;
      } catch (error) {}
    };

    const getExamsNaoColetados = async () => {
      try {
        const response = await api.get('/dashboard/exames/hpv/naocoletado', {
          params: {
            ...payloadFilter,
          },
        });
        return response.data;
      } catch (error) {}
    };

    const getExamsPendentes = async () => {
      try {
        const response = await api.get('/dashboard/exames-pendentes', {
          params: {
            ...payloadFilter,
          },
        });
        return response.data;
      } catch (error) {}
    };
    await Promise.all([
      getConvocadosMensal(),
      getContadoresStatus(),
      getMotivosNaoAtendida(),
      getMotivosNaoElegiveis(),
      getExamsCounts(),
      getExamsNaoColetados(),
    ]).then((result) => {
      setConvocadosMensal(result[0]);
      setContadoresStatus(result[1]);
      setMotivosNaoAtendidas(result[2]);
      setMotivoNaoElegiveis(result[3]);
      setExamsCounts(result[4]);
      setExamsNaoColetados(result[5]);
    });
  };

  const dataReasonFailure = [
    {
      value: Number(motivosNaoAtendidas?.mudanca_endereco?.percentual),
      name: 'Mudança de Endereço',
      itemStyle: { color: '#C11C2F' },
    },
    {
      value: Number(motivosNaoAtendidas?.nao_atendida?.percentual),
      name: 'Não Atendidas:',
      itemStyle: { color: '#F4284E' },
    },
    {
      value: Number(motivosNaoAtendidas?.nao_encontada_residencia?.percentual),
      name: 'Não encontrada na residência:',
      itemStyle: { color: '#F56580' },
    },
  ];

  const dataReasonNaoElegiveis = [
    {
      value: Number(motivoNaoElegiveis?.fora_da_faixa_etaria?.percentual),
      name: 'Fora da Faixa Etária',
      itemStyle: { color: '#223C89' },
    },
    {
      value: Number(
        motivoNaoElegiveis?.nao_iniciou_atividade_sexual?.percentual
      ),
      name: 'Não Iniciou Atividade Sexual',
      itemStyle: { color: '#1251B3' },
    },
    {
      value: Number(motivoNaoElegiveis?.histerectomizada?.percentual),
      name: 'Histerectomizada:',
      itemStyle: { color: '#0E6ECE' },
    },
    {
      value: Number(motivoNaoElegiveis?.outros?.percentual),
      name: 'Outros',
      itemStyle: { color: '#2D9CED' },
    },
  ];

  const dataReasonNaoColetados = [
    {
      value: examsNaoColetados?.menstruada ?? 0,
      name: 'Estava menstruada:',
      itemStyle: { color: '#C11C2F' },
    },
    {
      value: examsNaoColetados?.nao_realizou ?? 0,
      name: 'Compareceu e não realizou:',
      itemStyle: { color: '#F4284E' },
    },
    {
      value: examsNaoColetados?.histerectomizada ?? 0,
      name: 'Histerectomizada:',
      itemStyle: { color: '#F56580' },
    },
    {
      value: examsNaoColetados?.nao_faixa_etaria ?? 0,
      name: 'Não está na faixa etária:',
      itemStyle: { color: '#F598A7' },
    },
  ];

  React.useEffect(() => {
    getAllGraphics();
  }, []);

  if (loading) {
    return <h1>Carregando...</h1>;
  } else {
    return (
      <SideMenu>
        <Container>
          <Box mt={12}>
            <ReturnButton backPage="/home" />

            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div>
                <Typography variant="h4" fontWeight="bold">
                  Painéis
                </Typography>
                <Breadcrumbs
                  separator={<NavigateNextIcon fontSize="small" />}
                  aria-label="breadcrumb"
                  style={{ marginBottom: '16px', marginTop: '16px' }}
                  sx={{ marginBottom: '24px' }}
                >
                  <Link
                    style={{
                      textDecoration: 'none',
                      color: '#646464',
                    }}
                    to="/home"
                  >
                    Home
                  </Link>
                  <Typography key="3" color="text.primary">
                    Painéis
                  </Typography>
                </Breadcrumbs>
              </div>
            </div>

            <Divider />
            <Box mt={4} mb={4}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={3}>
                  <Autocomplete
                    options={statesOptions}
                    renderOption={(props, option: any) => (
                      <li {...props}>{option.nome}</li>
                    )}
                    onChange={(e, newValue) => {
                      handleChangeState(newValue);
                    }}
                    getOptionLabel={(option) => option.nome}
                    isOptionEqualToValue={(option) => option.nome}
                    renderInput={(params) => (
                      <TextField {...params} label="Estado" size="small" />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Autocomplete
                    options={municipiosOptions}
                    getOptionLabel={(option: any) => option.nome}
                    onChange={(e, newValue) => {
                      handleChangeMunicipio(newValue);
                    }}
                    renderOption={(props, option: any) => (
                      <li {...props}>{option.nome}</li>
                    )}
                    renderInput={(params) => (
                      <TextField {...params} label="Município" size="small" />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Autocomplete
                    options={estabelecimentosOptions}
                    noOptionsText="Selecione um município"
                    getOptionLabel={(option: any) => option?.nome_fantasia}
                    onChange={(e, newValue) => handleChangeUbs(newValue)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Estabelecimento de saúde"
                        size="small"
                      />
                    )}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={1.5}
                  sx={{ width: '100%', display: 'flex', justifyContent: 'end' }}
                >
                  <Button
                    variant="contained"
                    sx={{ width: '100%' }}
                    onClick={() => getAllGraphics()}
                  >
                    Filtrar
                  </Button>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={1.5}
                  sx={{ width: '100%', display: 'flex', justifyContent: 'end' }}
                >
                  <Button
                    variant="outlined"
                    color="error"
                    sx={{ width: '100%' }}
                  >
                    Limpar
                  </Button>
                </Grid>
              </Grid>
              <Grid container spacing={1} mt={3} sx={{ marginLeft: '0px' }}>
                <h1 style={{ fontWeight: 700, fontSize: '24px', margin: 0 }}>
                  Convocatória
                </h1>
              </Grid>
            </Box>
            <Box
              sx={{
                border: '1px solid rgba(0, 0, 0, 0.23)',
                borderRadius: '12px',
                padding: '16px',
              }}
            >
              <Typography
                variant="h1"
                style={{ fontSize: '20px', fontWeight: 'bold' }}
              >
                Total de convocações por mês
              </Typography>
              <div
                style={{
                  width: '100%',
                  overflowX: 'auto',
                  overflowY: 'hidden',
                }}
              >
                {convocadosMensal && (
                  <GraphicTotalConvocation data={convocadosMensal} />
                )}
              </div>
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} lg={3}>
                <TotalCardsPanels
                  title="Agendados"
                  value={contadoresStatus?.agendado}
                  color="#54A77B"
                  graphicComponent={
                    <div
                      style={{
                        width: '50px',
                        height: '50px',
                      }}
                    >
                      <GraphicTotalCards
                        color="rgb(84, 167, 123)"
                        index={0}
                        valueGraphic={contadoresStatus?.agendado?.percentual}
                      />
                    </div>
                  }
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <TotalCardsPanels
                  title="Pendentes"
                  value={contadoresStatus?.pendente}
                  color="#F5C062"
                  graphicComponent={
                    <div
                      style={{
                        width: '50px',
                        height: '50px',
                      }}
                    >
                      <GraphicTotalCards
                        color="rgb(245, 192, 98)"
                        index={1}
                        valueGraphic={contadoresStatus?.pendente?.percentual}
                      />
                    </div>
                  }
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <TotalCardsPanels
                  title="Não Atendidas"
                  value={contadoresStatus?.nao_atendida}
                  color="#F56580"
                  graphicComponent={
                    <div
                      style={{
                        width: '50px',
                        height: '50px',
                      }}
                    >
                      <GraphicTotalCards
                        color="rgb(245, 101, 128)"
                        index={2}
                        valueGraphic={
                          contadoresStatus?.nao_atendida?.percentual
                        }
                      />
                    </div>
                  }
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <TotalCardsPanels
                  title="Não Elegíveis"
                  value={contadoresStatus?.nao_elegivel}
                  color="#9D53F2"
                  graphicComponent={
                    <div
                      style={{
                        width: '50px',
                        height: '50px',
                      }}
                    >
                      <GraphicTotalCards
                        color="rgb(157, 83, 242)"
                        index={3}
                        valueGraphic={
                          contadoresStatus?.nao_elegivel?.percentual
                        }
                      />
                    </div>
                  }
                />
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Box
                  sx={{
                    border: '1px solid rgba(0, 0, 0, 0.23)',
                    borderRadius: '12px',
                    padding: '16px',
                    marginTop: '32px',
                  }}
                >
                  <Typography
                    variant="h1"
                    style={{ fontSize: '20px', fontWeight: 'bold' }}
                  >
                    Motivos de Mulheres Não Atendidas
                  </Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      flexDirection: 'column',
                      paddingBottom: '24px',
                    }}
                  >
                    {motivosNaoAtendidas && (
                      <GraphicReasonNaoAtendida
                        data={dataReasonFailure}
                        total={motivosNaoAtendidas?.total}
                      />
                    )}
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                      }}
                    >
                      {dataReasonFailure?.map((item) => {
                        return (
                          <div
                            style={{ display: 'flex', alignItems: 'center' }}
                          >
                            <div
                              style={{
                                width: '30px',
                                height: '15px',
                                backgroundColor: item.itemStyle.color,
                                marginRight: '15px',
                              }}
                            />
                            <p style={{ margin: 0 }}>
                              {item.name} - {item.value}%
                            </p>
                          </div>
                        );
                      })}
                    </div>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box
                  sx={{
                    border: '1px solid rgba(0, 0, 0, 0.23)',
                    borderRadius: '12px',
                    padding: '16px',
                    marginTop: '32px',
                  }}
                >
                  <Typography
                    variant="h1"
                    style={{ fontSize: '20px', fontWeight: 'bold' }}
                  >
                    Motivos de Mulheres não Elegíveis
                  </Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      flexDirection: 'column',
                    }}
                  >
                    {motivosNaoAtendidas && (
                      <GraphicReasonNaoElegivel
                        data={dataReasonNaoElegiveis}
                        total={motivoNaoElegiveis?.total}
                      />
                    )}
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                      }}
                    >
                      {dataReasonNaoElegiveis?.map((item) => {
                        return (
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <div
                              style={{
                                width: '30px',
                                height: '15px',
                                backgroundColor: item.itemStyle.color,
                                marginRight: '15px',
                              }}
                            />
                            <p style={{ margin: 0 }}>
                              {item.name} - {item.value}%
                            </p>
                          </div>
                        );
                      })}
                    </div>
                  </Box>
                </Box>
              </Grid>
            </Grid>

            <Grid container spacing={1} mt={3} sx={{ marginLeft: '0px' }}>
              <h1
                style={{ fontWeight: 700, fontSize: '24px', margin: '15px 0' }}
              >
                Exames
              </h1>
            </Grid>

            <Box
              sx={{
                border: '1px solid rgba(0, 0, 0, 0.23)',
                borderRadius: '12px',
                padding: '16px',
              }}
            >
              <Typography
                variant="h1"
                style={{ fontSize: '20px', fontWeight: 'bold' }}
              >
                Tipos de exames realizados por mês
              </Typography>
              <div
                style={{
                  width: '100%',
                  overflowX: 'auto',
                  overflowY: 'hidden',
                }}
              >
                {examsCounts && <GraphicExames data={examsCounts} />}
              </div>
            </Box>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <Box
                  sx={{
                    border: '1px solid rgba(0, 0, 0, 0.23)',
                    borderRadius: '12px',
                    padding: '16px',
                    marginTop: '32px',
                  }}
                >
                  <Typography
                    variant="h1"
                    style={{ fontSize: '20px', fontWeight: 'bold' }}
                  >
                    Motivos de exames de HPV não coletados
                  </Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      flexDirection: 'column',
                    }}
                  >
                    {examsNaoColetados && (
                      <GraphicReasonExamsNaoColetados
                        data={dataReasonNaoColetados}
                        total={examsNaoColetados?.total}
                      />
                    )}

                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                      }}
                    >
                      {dataReasonNaoColetados?.map((item) => {
                        return (
                          <div
                            style={{ display: 'flex', alignItems: 'center' }}
                          >
                            <div
                              style={{
                                width: '30px',
                                height: '15px',
                                backgroundColor: item.itemStyle.color,
                                marginRight: '15px',
                              }}
                            />
                            <p style={{ margin: 0 }}>
                              {item.name} - {item.value ?? 0}%
                            </p>
                          </div>
                        );
                      })}
                    </div>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </SideMenu>
    );
  }
};

export default Panels;
