import { TableCell, Typography } from '@mui/material';
import { useState } from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import CollapseColumn from '../../../../../CollapseColumn';

const RowsResultadoData = ({ row }) => {
  const [openedRow, setOpenedRow] = useState<any>();
  const boolValues = (value) => {
    if (value) {
      return 'Sim';
    } else {
      return 'Não';
    }
  };

  const useStyles = makeStyles({
    table: {
      minWidth: 750,
      border: 'none',
      '& td': {
        borderBottom: 'none',
      },
    },
    icon: {
      fontSize: 20,
    },
  });

  const classes = useStyles();

  const objLesaoBenigno = {
    metaplasia_escamosa: {
      value: row?.metaplasia_escamosa,
      label: 'Metaplasia Escamosa',
    },
    polipo_endocervical: {
      value: row?.polipo_endocervical,
      label: 'Pólipo Endocervical',
    },
    cervicite_cronica: {
      value: row?.cervicite_cronica,
      label: 'Cervicite Crônica Inespecífica',
    },
    hpv: {
      value: row?.hpv,
      label: 'Alterações Citoarquiteturais compatíveis com Ação Vital (HPV)',
    },
  };

  const lesaoBenignoList = Object.keys(objLesaoBenigno);

  const objNeoplasicoEscamosa = {
    1: 'NIC I (Displasia leve)',
    2: 'NIC II (Displasia moderada)',
    3: 'NIC III (Displasia acentuada / Carcinoma in situ)',
    4: 'Carcinoma Epidermóide Microinvasivo',
    5: 'Carcinoma Epidermóide Invasivo',
    6: 'Carcinoma Epidermóide impossível avaliar invasão',
  };

  const objNeoplasicoGlandular = {
    adenocarcionoma_in_situ: {
      value: row?.adenocarcionoma_in_situ,
      label: 'Adenocarcinoma in Situ',
    },
    adenocarcionoma_invasor: {
      value: row?.adenocarcionoma_invasor,
      label: 'Adenocarcinoma Invasor',
    },
    outras_neoplasias: {
      value: row?.outras_neoplasias,
      label: 'Outras Neoplasias',
    },
  };

  const neoplasiaGlandularList = Object.keys(objNeoplasicoGlandular);

  return (
    <>
      <TableCell align="left" sx={{ width: '162px', height: 'auto' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          <CollapseColumn
            arrayItens={lesaoBenignoList}
            classes={classes}
            objItens={objLesaoBenigno}
            setStateOpenedRow={setOpenedRow}
            stateOpenRow={openedRow}
            valueToOpen="carater_benigno"
          />
        </Typography>
      </TableCell>

      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {objNeoplasicoEscamosa[row?.neoplastia_escamosa]}
        </Typography>
      </TableCell>

      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          <CollapseColumn
            arrayItens={neoplasiaGlandularList}
            classes={classes}
            objItens={objNeoplasicoGlandular}
            setStateOpenedRow={setOpenedRow}
            stateOpenRow={openedRow}
            valueToOpen="neoplasia_glandular"
          />
        </Typography>
      </TableCell>

      {/* 
      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {boolValues(row?.neoplastia_escamosa)}
        </Typography>
      </TableCell> */}

      {/* <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {boolValues(row?.adenocarcionoma_in_situ)}
        </Typography>
      </TableCell> */}

      {/* <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {boolValues(row?.adenocarcionoma_invasor)}
        </Typography>
      </TableCell> */}

      {/* <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {boolValues(row?.outras_neoplasias)}
        </Typography>
      </TableCell> */}

      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {row?.diagnostico_descritivo}
        </Typography>
      </TableCell>

      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {row?.profissional?.pessoa?.nome}
        </Typography>
      </TableCell>

      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {row?.profissional?.cbo}
        </Typography>
      </TableCell>

      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {row?.profissional?.profissional_equipe?.ine}
        </Typography>
      </TableCell>
    </>
  );
};

export default RowsResultadoData;
