import * as Yup from 'yup';
import { Escolha } from './interface';

export const schema = Yup.object({
  motivo: Yup.string().required('Campo Obrigatório'),
  papanicolaou: Yup.string().required('Campo Obrigatório'),
  ano_papanicolaou: Yup.number().when('papanicolaou', {
    is: (papanicolaou: any) => {
      return papanicolaou === Escolha.Sim;
    },
    then: Yup.number().required('Campo Obrigatório'),
  }),
  diu: Yup.string().required('Campo Obrigatório'),
  gravida: Yup.string().required('Campo Obrigatório'),
  anticoncepcional: Yup.string().required().required('Campo Obrigatório'),
  hormonio: Yup.string().required('Campo Obrigatório'),
  radioterapia: Yup.string().required('Campo Obrigatório'),
  dt_menstruacao_desconhecida: Yup.boolean().default(false),
  dt_menstruacao: Yup.string().when('dt_menstruacao_desconhecida', {
    is: (dt_menstruacao_desconhecida: boolean) => {
      return dt_menstruacao_desconhecida === false;
    },
    then: Yup.string().required('Campo Obrigatório'),
    otherwise: Yup.string(),
  }),
  sangramento_relacao: Yup.string().required('Campo Obrigatório'),
  sangramento_menopausa: Yup.string().required('Campo Obrigatório'),
});
