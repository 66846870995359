/* eslint-disable react-hooks/exhaustive-deps */

import { Autocomplete, Button, Grid, MenuItem, TextField } from '@mui/material';

import { Controller } from 'react-hook-form';
import { StyledAsteriskTextField } from '../../../../components/StyledAsteriskTextField/styles';
import { DisplayFilter, ItemsDisplayFilter } from '../../../Convocation/styles';
import SearchIcon from '@mui/icons-material/Search';
import DatePicker from '@mui/lab/DatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import ptBR from 'date-fns/locale/pt-BR';
import { useContext, useEffect, useState } from 'react';
import { getEstados } from '../../../../lib/getEstados';
import useHandleChangesFilters from './hooks/useHandleChangesFilters';
import { InspecaoColo } from '../../../Anamnese/interface';
import { getProfissionais } from '../../../../lib/profissionais';
import useRequestFilter from './hooks/useRequestFilter';
import useVerifyAccessLevel from './hooks/useVerifyAccessLevel';
import useSetPayload from './hooks/useSetPayload';
import { EstablishmentContext } from '../../../../context/EstablishmentContext';
import useClearValues from './hooks/useClearValues';

const FiltersReportsConvocatoria = ({
  setTableData,
  control,
  handleSubmit,
  setValue,
  watch,
  setError,
}) => {
  const initialStateListFilter = [
    { inputName: 'estado', value: '' },
    { inputName: 'municipio', value: '' },
    { inputName: 'estabelecimento_saude', value: '' },
    { inputName: 'motivo', value: '' },
    { inputName: 'idade_ini', value: '' },
    { inputName: 'idade_fin', value: '' },
    { inputName: 'atendimento_status', value: '' },
    { inputName: 'n_convocacao', value: '' },
    { inputName: 'responsavel_coleta', value: '' },
    { inputName: 'dt_coleta', value: '' },
    { inputName: 'resultado', value: '' },
    { inputName: 'responsavel_laudo', value: '' },
    { inputName: 'profissionalAgendadoId', value: '' },
    { inputName: 'dt_resultado', value: '' },
    { inputName: 'search', value: '' },
    { inputName: 'status', value: '' },
  ];

  const [estados, setEstados] = useState<any[]>([]);
  const [cidades, setCidades] = useState<any[]>([]);
  const [estabelecimentos, setEstabelecimentos] = useState<any[]>([]);
  const [professionalList, setProfessionalList] = useState<any[]>([]);
  const [listFilter, setListFilter] = useState<any[]>(initialStateListFilter);

  const [disableFilters, setDisableFilters] = useState({
    estado: false,
    municipio: false,
    estabelecimento: false,
  });

  const { currentEstablishment } = useContext(EstablishmentContext);

  const {
    handleChangeUf,
    handleChangeCidade,
    handleChangeEstabelecimento,
    handleSetFilter,
    handleChangeDatePicker,
    getCidadesEstabelecimentos,
    getEstabelecimento,
  } = useHandleChangesFilters({
    setCidades,
    setEstabelecimentos,
    setValue,
    setListFilter,
    estados,
  });

  const { loadReportsConvocatoria } = useRequestFilter({
    setTableData,
  });

  const { setPayload } = useSetPayload({ watch });

  const { setDefaultValues, permissao } = useVerifyAccessLevel({
    setValue,
    getCidadesEstabelecimentos,
    getEstabelecimento,
    currentEstablishment,
    setDisableFilters,
    handleSetFilter,
    setTableData,
    watch,
  });

  const { clearValues } = useClearValues({
    permissao,
    setValue,
    handleSetFilter,
    loadReportsConvocatoria,
    watch,
  });

  const currentEstablishmentId = localStorage.getItem('id_last_establishment');

  const loadEstados = async () => {
    const response = await getEstados();
    setEstados(response);
  };

  const getProfissionaisList = async () => {
    const response = await getProfissionais({
      estabelecimentoId: String(currentEstablishmentId),
    });
    setProfessionalList(response.data);
  };

  const onSubmit = async () => {
    setTableData([]);

    const inputRealizouAtendimento = watch('realizou_atendimento');
    const inputAtendimentoStatus = watch('atendimento_status');
    if (inputRealizouAtendimento === 'Não' && !inputAtendimentoStatus) {
      setError('atendimento_status', {
        type: 'manual',
        message: 'Campo Obrigatório',
      });
    } else {
      await setPayload().then((payload) => loadReportsConvocatoria(payload));
    }
  };

  useEffect(() => {
    loadEstados();
    getProfissionaisList();
  }, []);

  useEffect(() => {
    setDefaultValues().then((payload) => {
      if (payload === 'waiting') {
        return;
      } else {
        loadReportsConvocatoria(payload);
      }
    });
  }, [estados, currentEstablishment]);

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <DisplayFilter style={{ marginTop: '24px' }}>
            <p style={{ margin: 0, width: '104px' }}>Filtro ativos:</p>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                width: '100%',
                gap: '10px',
                flexWrap: 'wrap',
              }}
            >
              {listFilter.map((item) => (
                <>
                  {item.value ? (
                    <ItemsDisplayFilter>{item.value}</ItemsDisplayFilter>
                  ) : undefined}
                </>
              ))}
            </div>
          </DisplayFilter>
        </Grid>
        <Grid item xs={4}>
          <Controller
            name="estado"
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <StyledAsteriskTextField
                  fullWidth
                  select
                  disabled={disableFilters.estado}
                  label="UF"
                  size="small"
                  variant="outlined"
                  onChange={(e) => {
                    handleChangeUf({ newValue: e.target.value, onChange });
                  }}
                  value={value ? value : ''}
                >
                  {estados.map((estado) => {
                    return (
                      <MenuItem key={estado.id} value={estado.nome}>
                        {estado.sigla}
                      </MenuItem>
                    );
                  })}
                </StyledAsteriskTextField>
              );
            }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Controller
            name="municipio"
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <StyledAsteriskTextField
                  fullWidth
                  disabled={disableFilters.municipio}
                  select
                  label="Município"
                  size="small"
                  variant="outlined"
                  onChange={(e) => {
                    handleChangeCidade({ newValue: e.target.value, onChange });
                  }}
                  value={value ? value : ''}
                >
                  {cidades.map((cidade) => {
                    return (
                      <MenuItem key={cidade.id} value={cidade.nome}>
                        {cidade.nome}
                      </MenuItem>
                    );
                  })}
                </StyledAsteriskTextField>
              );
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <Controller
            name="estabelecimento_saude"
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <Autocomplete
                  placeholder="Estabelecimento de Saúde"
                  value={value ? value : null}
                  disabled={disableFilters.estabelecimento}
                  onChange={(_, newValue) => {
                    handleChangeEstabelecimento({ newValue, onChange });
                  }}
                  options={estabelecimentos}
                  getOptionLabel={(option) => option.nome_fantasia ?? ''}
                  renderInput={(params) => (
                    <StyledAsteriskTextField
                      {...params}
                      fullWidth
                      size="small"
                      label="Estabelecimento de Saúde"
                      variant="outlined"
                    />
                  )}
                />
              );
            }}
          />
        </Grid>

        <Grid item xs={4}>
          <Controller
            name="idade_ini"
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <TextField
                  size="small"
                  label="Idade Início"
                  onChange={(e) => {
                    onChange(e.target.value);
                    handleSetFilter(e.target.value, 'idade_ini');
                  }}
                  value={value ? value : ''}
                  fullWidth
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <Controller
            name="idade_fin"
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <TextField
                  size="small"
                  label="Idade Fim"
                  onChange={(e) => {
                    onChange(e.target.value);
                    handleSetFilter(e.target.value, 'idade_fin');
                  }}
                  value={value ? value : ''}
                  fullWidth
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <Controller
            name="responsavel_coleta"
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <Autocomplete
                  options={professionalList}
                  onChange={(_, newValue) => {
                    onChange(newValue);
                    handleSetFilter(
                      newValue?.nomeprofissional,
                      'responsavel_coleta'
                    );
                  }}
                  value={value ? value : ''}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      label="Profissional Vinculado"
                    />
                  )}
                />
              );
            }}
          />
        </Grid>

        <Grid item xs={12} md={watch('status') === 0 ? 3 : 6}>
          <Controller
            name="n_convocacao"
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <TextField
                  label="Convocação"
                  onChange={(e) => {
                    onChange(e.target.value);
                    handleSetFilter(e.target.value, 'n_convocacao');
                  }}
                  value={value ? value : ''}
                  select
                  fullWidth
                  size="small"
                  variant="outlined"
                >
                  <MenuItem value={1}>1ª Convocação - Mensagem</MenuItem>
                  <MenuItem value={2}>
                    2ª Convocação - Agente Comunitário de Saúde
                  </MenuItem>
                  <MenuItem value={3}>
                    3ª Convocação - Agente Comunitário de Saúde
                  </MenuItem>
                </TextField>
              );
            }}
          />
        </Grid>

        <Grid item xs={12} md={watch('status') === 0 ? 3 : 6}>
          <Controller
            name="status"
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <TextField
                  label="Status"
                  onChange={(e) => {
                    onChange(e.target.value);
                    setValue('responsavel_coleta', null);
                    setValue('dt_coleta', null);
                    handleSetFilter('', 'responsavel_coleta');
                    handleSetFilter('', 'dt_coleta');
                    handleSetFilter(() => {
                      if (Number(e.target.value) === 0) {
                        return 'Agendadas';
                      } else if (Number(e.target.value) === 1) {
                        return 'Pendentes';
                      } else if (Number(e.target.value) === 2) {
                        return 'Não atendidas';
                      } else if (Number(e.target.value) === 3) {
                        return 'Não elegíveis';
                      }
                    }, 'status');
                  }}
                  value={value !== null ? value : ''}
                  select
                  fullWidth
                  size="small"
                  variant="outlined"
                >
                  <MenuItem value={0}>Agendadas</MenuItem>
                  <MenuItem value={1}>Pendentes</MenuItem>
                  <MenuItem value={2}>Não atendidas</MenuItem>
                  <MenuItem value={3}>Não elegíveis</MenuItem>
                </TextField>
              );
            }}
          />
        </Grid>

        {watch('status') === 0 && (
          <>
            <Grid item xs={3}>
              <Controller
                name="profissionalAgendadoId"
                control={control}
                render={({ field: { onChange, value } }) => {
                  return (
                    <Autocomplete
                      options={professionalList}
                      onChange={(_, newValue) => {
                        onChange(newValue);
                        handleSetFilter(
                          newValue?.nomeprofissional,
                          'profissionalAgendadoId'
                        );
                      }}
                      value={value ? value : ''}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          label="Profissional Agendado"
                        />
                      )}
                    />
                  );
                }}
              />
            </Grid>

            <Grid item xs={3}>
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                <div>
                  <Controller
                    name="dt_coleta"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <DatePicker
                        onChange={(date) => {
                          handleChangeDatePicker({
                            date,
                            onChange,
                            datePickerName: 'dt_coleta',
                          });
                        }}
                        maxDate={new Date()}
                        value={value ? value : null}
                        renderInput={(params) => (
                          <StyledAsteriskTextField
                            {...params}
                            label="Data do Agendamento"
                            size="small"
                            variant="outlined"
                            fullWidth
                          />
                        )}
                      />
                    )}
                  />
                </div>
              </LocalizationProvider>
            </Grid>
          </>
        )}

        <Grid item xs={12} md={9}>
          <Controller
            name="search"
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <TextField
                  label="Pesquisar"
                  placeholder="Pesquisar por Nome ou CPF da paciente"
                  size="small"
                  value={value ? value : ''}
                  onChange={(e) => {
                    onChange(e.target.value);
                    handleSetFilter(e.target.value, 'search');
                  }}
                  sx={{
                    width: '100%',
                    '.MuiOutlinedInput-root': {
                      paddingRight: '0',
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <Button type="submit">
                        <SearchIcon sx={{ cursor: 'pointer', color: 'gray' }} />
                      </Button>
                    ),
                  }}
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={12} md={1.5}>
          <Button variant="contained" fullWidth type="submit">
            FILTRAR
          </Button>
        </Grid>
        <Grid item xs={12} md={1.5}>
          <Button
            variant="outlined"
            color="error"
            fullWidth
            onClick={clearValues}
          >
            LIMPAR
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default FiltersReportsConvocatoria;
