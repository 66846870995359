import moment from 'moment';
import { getCidades } from '../../../../../lib/getCidadesEstabelecimentos';
import { getEstabelecimentos } from '../../../../../lib/getEstabelecimentos';
import api from '../../../../../api';

const useHandleChangesFilters = ({
  setOptionsMunicipios,
  setEstabelecimentos,
  setValue,
  setListFilter,
  estados,
  watch,
}: any) => {
  const handleSetFilter = (newValue: any, inputName: string) => {
    setListFilter((prev) =>
      prev.map((item) =>
        item.inputName === inputName ? { ...item, value: newValue ?? '' } : item
      )
    );
  };
  const getCidadesEstabelecimentos = async (sigla) => {
    const response = await getCidades(sigla);
    setOptionsMunicipios(response);
  };

  const getEstabelecimento = async ({ municipio }) => {
    const response = await api.get(
      '/dashboard/rastreamento/hpv/estabelecimentos',
      {
        params: {
          estados: watch('estado'),
          municipios: municipio,
        },
      }
    );

    let arrFormated: any = [];

    response.data?.forEach((estabelecimento) => {
      const objFormated = {
        id: estabelecimento.id,
        nome_fantasia: estabelecimento.nome,
      };

      arrFormated.push(objFormated);
    });

    setEstabelecimentos(arrFormated);
  };

  const handleChangeUf = ({ newValue, onChange }) => {
    onChange(newValue);
    setValue('municipioId', null);
    setValue('estabelecimentoSaudeId', null);
    setValue('profissionalAcsId', null);

    handleSetFilter('', 'municipioId');
    handleSetFilter('', 'estabelecimentoSaudeId');
    handleSetFilter('', 'profissionalAcsId');
    getCidadesEstabelecimentos(
      estados.find((item) => item.sigla === newValue).sigla
    );
    handleSetFilter(newValue, 'estado');
  };

  const handleChangeCidade = ({ newValue, onChange, cidades }) => {
    onChange(newValue);
    setValue('estabelecimentoSaudeId', null);
    setValue('profissionalAcsId', null);

    handleSetFilter('', 'estabelecimentoSaudeId');
    handleSetFilter('', 'profissionalAcsId');
    if (newValue) {
      getEstabelecimento({
        municipio: newValue?.nome,
      });
    }
    handleSetFilter(newValue?.nome, 'municipioId');
  };

  const handleChangeEstabelecimento = async ({
    newValue,
    onChange,
    setAcsList,
    setLoadingAcsList,
  }) => {
    onChange(newValue);
    handleSetFilter(newValue?.nome_fantasia, 'estabelecimentoSaudeId');
    setValue('profissionalAcsId', null);

    handleSetFilter('', 'profissionalAcsId');

    if (newValue) {
      const pathname = window.location.pathname;

      const isResults =
        pathname === '/relatorios/citologia-reflexo' ||
        pathname === '/relatorios/histopatologia' ||
        pathname === '/relatorios/colposcopia';

      if (!isResults) {
        const listAcs = await api.get('/dashboard/rastreamento/hpv/acs', {
          params: {
            estabelecimento_id: newValue?.id,
          },
        });

        setAcsList(listAcs.data);
        setLoadingAcsList(false);
      }
    } else {
      setValue('estabelecimentoSaudeId', null);
    }
  };

  const handleChangeDatePicker = ({ date, onChange, datePickerName }) => {
    const dataOriginal = moment(date);
    const dataFormatada = dataOriginal.format('DD/MM/YYYY');
    // const dateToPayload = dataOriginal.format('YYYY-MM-DD');

    onChange(date);
    handleSetFilter(dataFormatada, datePickerName);
  };

  const handleChangeRealizouAtendimento = (
    newValue,
    setShowMotivoNaoAtendimento,
    onChange
  ) => {
    if (newValue === 'Sim' || !newValue) {
      setShowMotivoNaoAtendimento(false);
      setValue('atendimento_status', 'REALIZADO');
      handleSetFilter('', 'atendimento_status');
      handleSetFilter('Sim', 'realizou_atendimento');
    } else if (newValue === 'Não') {
      setShowMotivoNaoAtendimento(true);
      setValue('atendimento_status', '');
      handleSetFilter('Não', 'realizou_atendimento');
    }
    if (!newValue) {
      setShowMotivoNaoAtendimento(false);
      setValue('atendimento_status', null);
      handleSetFilter('', 'atendimento_status');
      handleSetFilter('', 'realizou_atendimento');
    }
    onChange(newValue);
  };

  return {
    handleChangeUf,
    handleChangeCidade,
    handleChangeEstabelecimento,
    handleSetFilter,
    handleChangeDatePicker,
    handleChangeRealizouAtendimento,
    getCidadesEstabelecimentos,
    getEstabelecimento,
  };
};

export default useHandleChangesFilters;
