/* eslint-disable react-hooks/exhaustive-deps */

import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { toast } from 'react-toastify';

import { useEffect, useState } from 'react';
import { AlertInfo } from '../../../../components/AlertInfo';
import { Label } from '../../../../components/Typo';
import { PeriodList, Periods } from '../periodList';
import { ProfessionalSchedule } from '../..';
import { Controller, useForm } from 'react-hook-form';
import {
  editProfessionalSchedule,
  getTimesToEdit,
} from '../../../../lib/professionalSchedule';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { ptBR } from 'date-fns/locale';

interface CreateNewAgendaProps {
  handleClickCancel: () => void;
  selectedProfessional: any;
  handleSetProfessionalSchedule: (professionalSchedule) => void;
  savedProfessionalSchedule: ProfessionalSchedule[];
  professionalSchedule: any;
  schedule: any;
}

const interval = [
  '20 min',
  '30 min',
  '40 min',
  '60 min',
  '80 min',
  '100 min',
  '120 min',
];

const EditAgenda = ({
  handleClickCancel,
  selectedProfessional,
  schedule,
}: CreateNewAgendaProps) => {
  const [stateForm, setStateForm] = useState({
    intervalo: `${schedule.intervalo} min`,
    n_dias: schedule.diasUteis,
    sexo: schedule.restricaoSexo,
    idade_min: schedule.idade[0],
    idade_max: schedule.idade[1],
    start_at: schedule.iniciaEm || new Date(),
  });
  const [periods, setPeriods] = useState<Periods>({
    seg_hours: [],
    ter_hours: [],
    qua_hours: [],
    qui_hours: [],
    sex_hours: [],
  });

  const { control } = useForm();

  const handleSetValueState = (value, fieldName) => {
    setStateForm((prev) => {
      return {
        ...prev,
        [fieldName]: value,
      };
    });
  };

  const currentEstablishmentId = localStorage.getItem('id_last_establishment');

  const handleSaveEdition = async () => {
    const intervalFormated = stateForm.intervalo?.replace(/[a-zA-Z\s]/g, '');

    const response = await editProfessionalSchedule({
      scheduleId: schedule.id,
      profissionalId: selectedProfessional.id,
      estabelecimentoSaudeId: currentEstablishmentId,
      procedimento: 'Teste de HPV',
      n_dias: stateForm.n_dias,
      intervalo: intervalFormated,
      idade_min: stateForm.idade_min,
      idade_max: stateForm.idade_max,
      sexo: stateForm.sexo,
      periods,
      ativo: true,
      start_at: stateForm.start_at || new Date(),
    });

    if (response.status !== 200) {
      toast.error(response.data.message, {
        position: 'bottom-right',
      });
    } else {
      toast.success('Agenda Editada com sucesso!', {
        position: 'bottom-right',
      });
      handleClickCancel();
    }
    // const response = await editProfessionalSchedule();
  };

  // const postData = async () => {
  // };

  // const handleSubmit = async (data) => {
  //   const intervalFormated = data.intervalo?.replace(/[a-zA-Z\s]/g, '');
  //   const response = await postProfessionalSchedule({
  //     profissionalId: selectedProfessional.id,
  //     estabelecimentoSaudeId: currentEstablishmentId,
  //     procedimento: 'Teste de HPV',
  //     n_dias: data.n_dias,
  //     intervalo: intervalFormated,
  //     idade_min: data.idade_min,
  //     idade_max: data.idade_max,
  //     sexo: data.sexo,
  //     periods,
  //     ativo: true,
  //     start_at: stateForm.start_at || new Date(),
  //   });

  //   if (response.status !== 201) {
  //     toast.error(response.data.message, {
  //       position: 'bottom-right',
  //     });
  //   } else {
  //     toast.success('Agenda cadastrada!', {
  //       position: 'bottom-right',
  //     });
  //     handleClickCancel();
  //   }

  //   handleSetProfessionalSchedule({
  //     profissionalId: selectedProfessional.id,
  //     estabelecimentoSaudeId: currentEstablishmentId,
  //     procedimento: 'Teste de HPV',
  //     n_dias: data.n_dias,
  //     intervalo: data.intervalo,
  //     idade_min: data.idade_min,
  //     idade_max: data.idade_max,
  //     sexo: data.sexo,
  //     periods,
  //     ativo: true,
  //     start_at: stateForm.start_at || new Date(),
  //   });

  // setTimeout(() => {
  //   postData();
  // }, 100);
  // };

  // useEffect(() => {
  //   if (savedProfessionalSchedule.length) {
  //     setPeriods(savedProfessionalSchedule[0].periods);
  //   }
  // }, [savedProfessionalSchedule]);

  // useEffect(() => {
  //   setValue('intervalo', schedule.intervalo);
  //   setValue('n_dias', schedule.diasUteis);
  //   setValue('sexo', schedule.restricaoSexo);
  // }, []);

  useEffect(() => {
    const timesSchedule = async () => {
      const response = await getTimesToEdit(schedule.id);

      const objectResponse = {
        seg_hours: response.data.seg_hours,
        ter_hours: response.data.ter_hours,
        qua_hours: response.data.qua_hours,
        qui_hours: response.data.qui_hours,
        sex_hours: response.data.sex_hours,
      };

      const convertedObject = {};

      for (const day in objectResponse) {
        if (objectResponse.hasOwnProperty(day)) {
          const hours = objectResponse[day];
          if (hours.length > 0) {
            const formattedHours = hours.map((timeRange) => {
              const [beginningPeriod, endPeriod] = timeRange.split(', ');
              return {
                beginningPeriod: beginningPeriod,
                endPeriod: endPeriod || '',
              };
            });
            convertedObject[day] = formattedHours;
          } else {
            convertedObject[day] = [];
          }
        }
      }

      setPeriods(convertedObject as Periods);
    };

    timesSchedule();
  }, []);

  return (
    <>
      <Grid container rowSpacing={3} columnSpacing={2}>
        <Grid item xs={12} md={6}>
          <Label labelcolor="rgba(0, 0, 0, 0.87)">Profissional</Label>
          <Typography variant="body1" mt={1}>
            {selectedProfessional?.pessoa?.nome}
          </Typography>
        </Grid>

        <Grid item xs={12} md={6}>
          <Label labelcolor="rgba(0, 0, 0, 0.87)">Procedimento</Label>
          <Typography variant="body1" mt={1}>
            Teste de HPV
          </Typography>
        </Grid>

        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <InputLabel id="interval-between-calls-label">
              Tempo de atendimento para cada paciente
            </InputLabel>

            <Select
              id="intervalo"
              size="small"
              labelId="interval-between-calls-label"
              value={stateForm.intervalo}
              label="Tempo de atendimento para cada paciente"
              onChange={(e) => handleSetValueState(e.target.value, 'intervalo')}
            >
              {interval.map((item) => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid
          item
          xs={12}
          md={6}
          display={'flex'}
          alignItems={'center'}
          gap={2}
        >
          <div>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
              <Controller
                control={control}
                name="start_at"
                render={({ field: { onChange, onBlur, value, ref } }) => {
                  return (
                    <DatePicker
                      mask="__/__/____"
                      onChange={(e, v) => handleSetValueState(e, 'start_at')}
                      value={stateForm.start_at}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={false}
                          style={{ minWidth: 220 }}
                          label="Data de início dos agendamentos"
                          size="small"
                          variant="outlined"
                          fullWidth
                        />
                      )}
                    />
                  );
                }}
              />
            </LocalizationProvider>
          </div>
          <TextField
            id="n_dias"
            value={stateForm.n_dias}
            label="Quantidade de dias futuros pela liberação da agenda"
            size="small"
            variant="outlined"
            fullWidth
            InputLabelProps={{ shrink: true }}
            placeholder="ex: 15"
            onChange={(e) => handleSetValueState(e.target.value, 'n_dias')}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <FormControl
            id="sexo"
            onChange={(e: any) => {
              handleSetValueState(e.target.value, 'sexo');
            }}
          >
            <Label mb={1} labelcolor="rgba(0, 0, 0, 0.87)">
              Restrição por sexo
            </Label>
            <RadioGroup
              row
              aria-labelledby="restriction-by-gender-label"
              name="restriction-by-gender"
            >
              <FormControlLabel
                value="N"
                control={<Radio />}
                label="Não restringir"
                checked={stateForm.sexo === 'N'}
              />
              <FormControlLabel
                value="F"
                control={<Radio />}
                label="Apenas feminino"
                checked={stateForm.sexo === 'F'}
              />
              <FormControlLabel
                value="M"
                control={<Radio />}
                label="Apenas Masculino"
                checked={stateForm.sexo === 'M'}
              />
            </RadioGroup>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={6}>
          <Label mb={1} labelcolor="rgba(0, 0, 0, 0.87)">
            Restrição por idade
          </Label>

          <Box
            display="flex"
            justifyContent="space-evenly"
            width="220px"
            gap={2}
          >
            <TextField
              label="Entre:"
              size="small"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              placeholder="ex: 12"
              value={stateForm.idade_min}
              onChange={(e) => handleSetValueState(e.target.value, 'idade_min')}
            />

            <TextField
              label="E:"
              size="small"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              placeholder="ex: 60"
              value={stateForm.idade_max}
              onChange={(e) => handleSetValueState(e.target.value, 'idade_max')}
            />
          </Box>
        </Grid>
      </Grid>

      <Box mt={3} gap={3} display="flex" alignItems="center" flexWrap="wrap">
        <Typography variant="h6" component="h2" fontWeight="bold">
          Configuração de Horários
        </Typography>

        <AlertInfo text="Recomendamos que os períodos inseridos sejam múltiplos do tempo de atendimento para cada paciente" />
      </Box>

      <PeriodList
        periods={periods}
        setPeriods={setPeriods}
        interval={Number(stateForm.intervalo?.replace(/[a-zA-Z\s]/g, ''))}
      />

      <Box mt={3} display="flex" justifyContent="space-between">
        <Button type="button" onClick={handleSaveEdition} variant="contained">
          Salvar agenda
        </Button>
        <Button onClick={handleClickCancel} variant="outlined" color="error">
          Cancelar
        </Button>
      </Box>
    </>
  );
};

export default EditAgenda;
