import { TableBody, TableCell, TableRow, Typography } from '@mui/material';

import { formatCpf } from '../../../../../utils/formatters';
import moment from 'moment';
import calculaIdade from '../../../../../utils/calculaIdade';

const enumStatusConvocatoria = {
  0: 'Agendado',
  1: 'Pendente',
  2: 'Não atendida',
  3: 'Não elegível',
};

const enumConvocacaoOrder = {
  1: '1ª Convocação',
  2: '2ª Convocação',
  3: '3ª Convocação',
};
const Rows = ({ tableData }) => {
  const calculateAge = (dt_nascimento) => {
    let nascimento = moment(dt_nascimento).format('DD/MM/YYYY');
    let newIdade = calculaIdade(nascimento);
    return isNaN(newIdade) ? '00 anos' : newIdade.toString() + ' anos';
  };
  return (
    <TableBody>
      {tableData?.data?.map((row) => (
        <TableRow key={row?.pessoa?.id}>
          <TableCell align="left" sx={{ width: '162px' }}>
            <Typography color="#000001de" variant="body2" fontSize="14px">
              {row?.pessoa?.nome}
            </Typography>
          </TableCell>
          <TableCell align="left" sx={{ width: '162px' }}>
            <Typography color="#000001de" variant="body2" fontSize="14px">
              {row?.pessoa?.cpf ? formatCpf(row?.pessoa?.cpf) : ''}
            </Typography>
          </TableCell>
          <TableCell align="left" sx={{ width: '162px' }}>
            <Typography color="#000001de" variant="body2" fontSize="14px">
              {calculateAge(row?.pessoa?.dt_nascimento)}
            </Typography>
          </TableCell>
          <TableCell align="left" sx={{ width: '162px' }}>
            <Typography color="#000001de" variant="body2" fontSize="14px">
              {`${row?.pessoa?.endereco?.logradouro}, ${row?.pessoa?.endereco?.numero} - ${row?.pessoa?.endereco?.bairro}`}
            </Typography>
          </TableCell>
          <TableCell align="left" sx={{ width: '162px' }}>
            <Typography color="#000001de" variant="body2" fontSize="14px">
              {row?.pessoa?.endereco?.municipio?.nome}
            </Typography>
          </TableCell>
          <TableCell align="left" sx={{ width: '162px' }}>
            <Typography color="#000001de" variant="body2" fontSize="14px">
              {row?.pessoa?.endereco?.municipio?.estado?.nome}
            </Typography>
          </TableCell>
          <TableCell align="left" sx={{ width: '162px' }}>
            <Typography color="#000001de" variant="body2" fontSize="14px">
              {row?.pessoa?.estabelecimento_saude?.nome_fantasia}
            </Typography>
          </TableCell>
          <TableCell align="left" sx={{ width: '162px' }}>
            <Typography color="#000001de" variant="body2" fontSize="14px">
              {row?.profissional?.pessoa?.nome}
            </Typography>
          </TableCell>
          <TableCell align="left" sx={{ width: '162px' }}>
            <Typography color="#000001de" variant="body2" fontSize="14px">
              {row?.status ? enumStatusConvocatoria[row?.status] : ''}
            </Typography>
          </TableCell>
          <TableCell align="left" sx={{ width: '162px' }}>
            <Typography color="#000001de" variant="body2" fontSize="14px">
              {row?.procedimento
                ? moment(row?.procedimento?.dt_coleta).format('DD/MM/YYYY')
                : ''}
            </Typography>
          </TableCell>
          <TableCell align="left" sx={{ width: '162px' }}>
            <Typography color="#000001de" variant="body2" fontSize="14px">
              {row?.procedimento
                ? moment(row?.procedimento?.dt_coleta).format('HH:mm:ss')
                : ''}
            </Typography>
          </TableCell>
          <TableCell align="left" sx={{ width: '162px' }}>
            <Typography
              color="#000001de"
              variant="body2"
              fontSize="14px"
            ></Typography>
          </TableCell>
          <TableCell align="left" sx={{ width: '162px' }}>
            <Typography color="#000001de" variant="body2" fontSize="14px">
              {row?.n_convocacao ? enumConvocacaoOrder[row?.n_convocacao] : ''}
            </Typography>
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  );
};

export default Rows;
