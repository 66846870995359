import {
  Box,
  Divider,
  Grid,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import { Controller } from 'react-hook-form';
import { StyledAsteriskTextField } from '../../../../components/StyledAsteriskTextField/styles';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import MaskedInput, { MaskedInputProps } from 'react-text-mask';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import ptBR from 'date-fns/locale/pt-BR';
import DatePicker from '@mui/lab/DatePicker';
import { useStyles } from '../../RegisterPatient.style';
import { useEffect, useState } from 'react';
import moment from 'moment';
import calculaIdade from '../../../../utils/calculaIdade';

const PessoalInformation = ({ errors, control, watch }) => {
  const [idade, setIdade] = useState<any>();

  const styles = useStyles();

  const nascimento = watch().dt_nascimento;

  useEffect(() => {
    let nascimento = moment(watch().dt_nascimento).format('DD/MM/YYYY');
    let newIdade = calculaIdade(nascimento);
    setIdade(isNaN(newIdade) ? '00 anos' : newIdade.toString() + ' anos');
  }, [nascimento, watch]);

  return (
    <Box sx={{ width: '100%', marginTop: '24px' }}>
      <Paper
        elevation={0}
        sx={{
          width: '100%',
          border: `1px solid RGBA(0, 0, 0, 0.23)`,
          borderRadius: '10px',
        }}
      >
        <Typography
          variant="h6"
          fontWeight="bold"
          style={{ margin: '25px', paddingTop: '10px' }}
        >
          Informações Pessoais
        </Typography>
        <Divider variant="middle" sx={{ width: '96%', margin: '0 auto' }} />
        <Box m={3} pb={3} sx={{ flexDirection: 'column' }}>
          <Grid
            container
            rowSpacing={3}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={12} md={12}>
              <Controller
                render={({ field: { onChange, value } }) => (
                  <StyledAsteriskTextField
                    id="cns"
                    onChange={onChange}
                    value={value ? value : ''}
                    label="Nº do Cartão SUS"
                    size="small"
                    type="number"
                    variant="outlined"
                    className={styles.textField}
                    error={!!errors.cns}
                    helperText={errors.cns?.message}
                    required
                  />
                )}
                name="cns"
                control={control}
              />
            </Grid>

            <Grid item xs={12} md={8}>
              <Controller
                render={({ field: { onChange, value } }) => (
                  <StyledAsteriskTextField
                    fullWidth
                    id="nome"
                    label="Nome completo da mulher"
                    size="small"
                    variant="outlined"
                    className={styles.textField}
                    onChange={onChange}
                    value={value ? value : ''}
                    error={!!errors.nome}
                    helperText={errors.nome?.message}
                    required
                  />
                )}
                name="nome"
                control={control}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    label="Nome social"
                    size="small"
                    variant="outlined"
                    className={styles.textField}
                    onChange={onChange}
                    value={value ? value : ''}
                    error={!!errors.apelido}
                    helperText={errors.apelido?.message}
                  />
                )}
                name="apelido"
                control={control}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                render={({ field: { onChange, value } }) => (
                  <StyledAsteriskTextField
                    fullWidth
                    label="Nome completo da mãe"
                    size="small"
                    variant="outlined"
                    className={styles.textField}
                    onChange={onChange}
                    value={value ? value : ''}
                    error={!!errors.nome_mae}
                    helperText={errors.nome_mae?.message}
                    required
                  />
                )}
                name="nome_mae"
                control={control}
              />
            </Grid>
            <Grid item xs={12} md={2.5}>
              <Controller
                render={({ field: { onChange, value } }) => {
                  return (
                    <StyledAsteriskTextField
                      required
                      onChange={(e) => {
                        const cpf = e.target.value.replace(/_/g, ''); // Remover os "_"
                        onChange(cpf);
                      }}
                      InputLabelProps={{
                        shrink: value ? true : false,
                      }}
                      value={value}
                      label="CPF"
                      size="small"
                      variant="outlined"
                      className={styles.textField}
                      error={!!errors.cpf}
                      helperText={errors.cpf?.message}
                      sx={{ width: '100%' }}
                      InputProps={{
                        autoComplete: 'off',
                        inputComponent: MaskedInput as any,
                        inputProps: {
                          mask: [
                            /\d/,
                            /\d/,
                            /\d/,
                            '.',
                            /\d/,
                            /\d/,
                            /\d/,
                            '.',
                            /\d/,
                            /\d/,
                            /\d/,
                            '-',
                            /\d/,
                            /\d/,
                          ],
                        } as MaskedInputProps,
                      }}
                    />
                  );
                }}
                name="cpf"
                control={control}
              />
            </Grid>
            <Grid item xs={12} md={2.5}>
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                <div>
                  <Controller
                    control={control}
                    name="dt_nascimento"
                    render={({ field: { onChange, value } }) => {
                      return (
                        <DatePicker
                          onChange={onChange}
                          maxDate={new Date()}
                          value={value ? value : null}
                          renderInput={(params) => (
                            <StyledAsteriskTextField
                              {...params}
                              label="Data de Nascimento"
                              size="small"
                              variant="outlined"
                              className={styles.textField}
                              fullWidth
                              error={!!errors.dt_nascimento}
                              helperText={errors.dt_nascimento?.message}
                              required
                            />
                          )}
                        />
                      );
                    }}
                  />
                </div>
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} md={2.5}>
              <TextField
                fullWidth
                label="Idade"
                size="small"
                variant="outlined"
                className={styles.textField}
                disabled={true}
                value={idade}
              />
            </Grid>

            <Grid item xs={12} md={2.5}>
              <Controller
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    label="Nacionalidade"
                    size="small"
                    variant="outlined"
                    className={styles.textField}
                    onChange={onChange}
                    value={value ? value : ''}
                    error={!!errors.nacionalidade}
                    helperText={errors.nacionalidade?.message}
                  />
                )}
                name="nacionalidade"
                control={control}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <Controller
                render={({ field: { onChange, value } }) => (
                  <TextField
                    defaultValue=""
                    label="Raça/Cor"
                    select
                    fullWidth
                    size="small"
                    variant="outlined"
                    className={styles.textField}
                    onChange={onChange}
                    value={value ? value : ''}
                    error={!!errors.raca}
                    helperText={errors.raca?.message}
                  >
                    <MenuItem value="BRANCA">Branca</MenuItem>
                    <MenuItem value="PRETA">Preta</MenuItem>
                    <MenuItem value="PARDA">Parda</MenuItem>
                    <MenuItem value="AMARELA">Amarela</MenuItem>
                    <MenuItem value="INDÍGENA">Indígena</MenuItem>
                  </TextField>
                )}
                name="raca"
                control={control}
              />
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Box>
  );
};

export default PessoalInformation;
