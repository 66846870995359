/* eslint-disable react-hooks/exhaustive-deps */
import {
  Autocomplete,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Menu,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';

import { Controller } from 'react-hook-form';
import { StyledAsteriskTextField } from '../StyledAsteriskTextField/styles';
import { useEffect, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import moment from 'moment';
import { getEstados } from '../../lib/getEstados';
import { getCidades } from '../../lib/getCidadesEstabelecimentos';
import api from '../../api';

const FiltersVisaoGeral = ({
  control,
  setPayload,
  getAllCharts,
  setListFilter,
  initialStateListFilter,
  setShowListFilter,
  listFilter,
}) => {
  const [estadosSelected, setEstadosSelected] = useState<any[]>([]);
  const [anchorElEstados, setAnchorElEstados] = useState<any>(null);
  const [searchEstado, setSearchEstado] = useState('');
  const [estadosOptions, setEstadosOptions] = useState<any>([]);
  const [allEstadosOptions, setAllEstadosOptionss] = useState<any>([]);

  const [municipiosSelected, setMunicipiosSelected] = useState<any>([]);
  const [anchorElMunicipios, setAnchorElMunicipios] = useState<any>();
  const [searchMunicipio, setSearchMunicipio] = useState<any>();
  const [optionsMunicipios, setOptionsMunicipios] = useState<any>([]);
  const [allMunicipiosOptions, setAllMunicipiosOptionss] = useState<any>([]);
  const [disableMunicipioInput, setDisabledMunicipioInput] = useState(true);

  const [searchEstabelecimentos, setSearchEstabelecimentos] = useState<any>();
  const [optionsEstabelecimentos, setOptionsEstabelecimentos] = useState<any>(
    []
  );
  const [estabelecimentosSelected, setEstabelecimentosSelected] = useState<any>(
    []
  );
  const [anchorElEstabelecimentos, setAnchorElEstabelecimentos] =
    useState<any>();
  const [allEstabelecimentosOptions, setAllEstabelecimentosOptions] =
    useState<any>([]);
  const [disableEstabelecimentoInput, setDisabledEstabelecimentoInput] =
    useState(true);

  const [arrayEquipes, setArrayEquipes] = useState<any>([]);
  const [disableEquipeInput, setDisableEquipeInput] = useState(true);
  const [allEquipesOptions, setAllEquipesOptions] = useState<any>([]);
  const [searchEquipes, setSearchEquipes] = useState('');
  const [equipesSelected, setEquipesSelected] = useState<any>([]);
  const [anchorElEquipe, setAnchorElEquipe] = useState<any>();

  const [openModalPeriodo, setOpenModalPeriodo] = useState(false);
  const [period, setPeriod] = useState<any>();
  const [year, setYear] = useState<any>('');
  const [valueInputPeriod, setValueInputPeriod] = useState<any>();
  const [endDayAndMonth, setEndDayAndMonth] = useState<any>();
  const [initialDayAndMonth, setInitialDayAndMonth] = useState<any>();
  const [valuePeriodoModal, setValuePeriodoModal] = useState<any>();
  const [valueInputQuadrimestreSemestre, setValueInputQuadrimestreSemestre] =
    useState<any>();

  const [dataFaixa, setDataFaixa] = useState<any>([]);

  const optionsPeriodo = [
    {
      label: 'Últimos 12 meses',
      value: 'twelve_months',
    },
    {
      label: 'Quadrimestral',
      value: 'four_months',
    },
    { label: 'Semestral', value: 'six_months' },
    { label: 'Anual', value: 'yearly' },
  ];

  const optionsQuadrimestre = [
    '1º Quadrimestre',
    '2º Quadrimestre',
    '3º Quadrimestre',
  ];
  const optionsSemestre = ['1º Semestre', '2º Semestre'];

  const setValuesInLastTwelveMonths = () => {
    setValueInputPeriod('Últimos 12 meses');
    setOpenModalPeriodo(false);
    const today = moment().format('YYYY-MM-DD');
    const twelveMonths = moment().subtract(12, 'months').format('YYYY-MM-DD');

    setPeriod({
      periodo_fim: today,
      periodo_inicio: twelveMonths,
    });
  };
  const setValuesQuadrimestreSelected = () => {
    setValueInputPeriod('Quadrimestral');
    setInitialDayAndMonth('-01-01');
    setEndDayAndMonth('-04-01');
    setValueInputQuadrimestreSemestre('1º Quadrimestre');
    setPeriod(undefined);
  };

  const setValuesSemestreSelected = () => {
    setValueInputPeriod('Semestral');
    setValueInputQuadrimestreSemestre('1º Semestre');
    setInitialDayAndMonth('-01-01');
    setEndDayAndMonth('-06-01');
    setPeriod(undefined);
  };

  const setValuesAnualSelected = () => {
    setValueInputPeriod('Anual');
    setInitialDayAndMonth('-01-01');
    setEndDayAndMonth('-12-01');

    setPeriod(undefined);
  };

  const handleSelectPeriodModal = (newValue) => {
    setValuePeriodoModal(newValue);
    if (!newValue) {
      setPeriod(undefined);
      setYear(undefined);
      setValueInputPeriod(null);
    }
    switch (newValue?.value) {
      case 'twelve_months':
        setValuesInLastTwelveMonths();
        break;
      case 'four_months':
        setValuesQuadrimestreSelected();
        break;
      case 'six_months':
        setValuesSemestreSelected();

        break;
      case 'yearly':
        setValuesAnualSelected();
        break;
      default:
        break;
    }
  };

  const handleClearFilter = () => {
    setPeriod(null);
    setValueInputPeriod(null);
    setValuePeriodoModal(null);
    setValueInputQuadrimestreSemestre(null);
    setYear(null);
    setEstabelecimentosSelected([]);
    setEstabelecimentosSelected([]);
    setEstadosSelected([]);
    setMunicipiosSelected([]);
    setSearchEquipes('');
    setSearchEstabelecimentos('');
    setSearchEstado('');
    setSearchMunicipio('');
    setEstadosOptions(allEstadosOptions);

    initialStateListFilter.forEach((input) => {
      handleSetFilter(null, input.inputName);
    });

    setShowListFilter([]);

    getAllCharts({ cleared: true });
  };

  const handleChangeWhenYearSelected = (newValue) => {
    setYear(newValue);
    if (valueInputPeriod === 'Quadrimestral') {
      setValueInputQuadrimestreSemestre('1º Quadrimestre');
    }

    if (valueInputPeriod === 'Semestral') {
      setValueInputQuadrimestreSemestre('1º Semestre');
    }
    if (valueInputPeriod === 'Anual') {
      setPeriod({
        periodo_fim: `${newValue}-12-01`,
        periodo_inicio: `${newValue}-01-01`,
      });
    }
  };

  const handleChangeSemestreOrQuadrimestre = (newValue) => {
    setValueInputQuadrimestreSemestre(newValue);
    switch (newValue) {
      case '1º Semestre':
        setPeriod({
          periodo_fim: `${year}-06-01`,
          periodo_inicio: `${year}-01-01`,
        });
        break;
      case '2º Semestre':
        setPeriod({
          periodo_fim: `${year}-12-01`,
          periodo_inicio: `${year}-06-01`,
        });
        break;
      case '1º Quadrimestre':
        setPeriod({
          periodo_fim: `${year}-04-01`,
          periodo_inicio: `${year}-01-01`,
        });
        break;
      case '2º Quadrimestre':
        setPeriod({
          periodo_fim: `${year}-08-01`,
          periodo_inicio: `${year}-04-01`,
        });
        break;
      case '3º Quadrimestre':
        setPeriod({
          periodo_fim: `${year}-12-01`,
          periodo_inicio: `${year}-08-01`,
        });
        break;
      default:
        break;
    }
  };

  const handleCloseModal = () => {
    setPeriod(null);
    // handleSetFilter(null, 'periodo');
    setOpenModalPeriodo(false);
    setPeriod(null);
    setYear(null);
    setValueInputPeriod(null);
    setEndDayAndMonth(null);
    setInitialDayAndMonth(null);
    setValuePeriodoModal(null);
    setValueInputQuadrimestreSemestre(null);
  };

  const handleSelectMultiplesFields = ({
    value,
    setState,
    state,
    paramToSave,
  }) => {
    const isSelected = state?.find(
      (itemSaved) => itemSaved === value[paramToSave]
    );

    if (isSelected) {
      setState((prev) => {
        return prev.filter((itemSaved) => itemSaved !== value[paramToSave]);
      });
    } else {
      setState((prev) => {
        return [...prev, value[paramToSave]];
      });
    }
  };

  const handleSetFilter = (newValue: any, inputName: string) => {
    setListFilter((prev) =>
      prev.map((item) =>
        item.inputName === inputName ? { ...item, value: newValue } : item
      )
    );
  };

  const handleSetFiltersInMultiplesInputs = ({
    value,
    fieldName,
    arrayValues,
  }) => {
    const convertedFieldNames = {
      estado: 'Estados',
      municipio: 'Municípios',
      estabelecimento: 'Estabelecimentos',
      equipe: 'Equipes',
    };
    if (arrayValues.length > 1) {
      handleSetFilter(`${convertedFieldNames[fieldName]}: Vários`, fieldName);
    } else {
      handleSetFilter(`${convertedFieldNames[fieldName]}: ${value}`, fieldName);
    }
  };

  useEffect(() => {
    if (estadosSelected.length > 0) {
      handleSetFiltersInMultiplesInputs({
        value: estadosSelected[0] ?? '',
        fieldName: 'estado',
        arrayValues: estadosSelected,
      });
      setDisabledMunicipioInput(false);
    } else {
      setDisabledMunicipioInput(true);
      setDisabledEstabelecimentoInput(true);
      setDisableEquipeInput(true);
      handleSetFilter(null, 'estado');
    }

    if (estadosSelected.length > 1 || estadosSelected.length === 0) {
      setEstabelecimentosSelected([]);
      setMunicipiosSelected([]);
      setEquipesSelected([]);
      setDisabledMunicipioInput(true);
      setDisabledEstabelecimentoInput(true);
      setDisableEquipeInput(true);
    } else {
      setDisabledMunicipioInput(false);
    }
  }, [estadosSelected]);

  useEffect(() => {
    setEstabelecimentosSelected([]);
    if (municipiosSelected.length > 0) {
      handleSetFiltersInMultiplesInputs({
        value: municipiosSelected[0] ?? '',
        fieldName: 'municipio',
        arrayValues: municipiosSelected,
      });
      setDisabledEstabelecimentoInput(false);
    } else {
      setDisabledEstabelecimentoInput(true);
      setDisableEquipeInput(true);
      handleSetFilter(null, 'municipio');
    }

    if (municipiosSelected.length > 1 || municipiosSelected.length === 0) {
      setDisabledEstabelecimentoInput(true);
    } else {
      setDisabledEstabelecimentoInput(false);
    }
  }, [municipiosSelected]);
  useEffect(() => {
    const estabelecimentoNome =
      optionsEstabelecimentos?.find(
        (item) => estabelecimentosSelected[0] === item.id
      )?.nome ?? '';

    if (estabelecimentosSelected.length > 0) {
      handleSetFiltersInMultiplesInputs({
        value: estabelecimentoNome,
        fieldName: 'estabelecimento',
        arrayValues: estabelecimentosSelected,
      });
      setDisableEquipeInput(false);
    } else {
      setDisableEquipeInput(true);
      handleSetFilter(null, 'estabelecimento');
    }

    const hasSelectedNoBind = !!estabelecimentosSelected.find(
      (item) => item === 'null'
    );

    if (
      estabelecimentosSelected.length > 1 ||
      estabelecimentosSelected.length === 0
    ) {
      setDisableEquipeInput(true);
    } else {
      setDisableEquipeInput(false);
    }
    if (hasSelectedNoBind) {
      setDisableEquipeInput(true);
    }
  }, [estabelecimentosSelected]);

  useEffect(() => {
    if (equipesSelected.length > 0) {
      handleSetFiltersInMultiplesInputs({
        value: equipesSelected[0] ?? '',
        fieldName: 'equipe',
        arrayValues: equipesSelected,
      });
    } else {
      handleSetFilter(null, 'equipe');
    }
  }, [equipesSelected]);

  useEffect(() => {
    const getEquipes = async () => {
      const hasSelectedNoBind = !!estabelecimentosSelected.find(
        (item) => item === 'null'
      );
      if (estabelecimentosSelected.length === 1) {
        if (!hasSelectedNoBind) {
          const response = await api.get('/equipe/estabelecimento/get', {
            params: {
              estabelecimentoId: estabelecimentosSelected[0],
            },
          });
          setArrayEquipes([
            {
              id: 'null',
              municipio: '',
              nome: 'SEM VINCULAÇÃO',
              quantidade: '',
              label: '',
            },
            ...response.data,
          ]);
          setAllEquipesOptions([
            {
              id: 'null',
              municipio: '',
              nome: 'SEM VINCULAÇÃO',
              quantidade: '',
              label: '',
            },
            ...response.data,
          ]);
        }
      }

      if (estabelecimentosSelected.length === 0) {
        setArrayEquipes([]);
      }
    };

    getEquipes();
  }, [estabelecimentosSelected]);

  useEffect(() => {
    const loadEstados = async () => {
      // const response = await getEstados();
      setEstadosOptions([
        {
          id: 13,
          created_at: '2023-03-21T00:36:08.490Z',
          updated_at: '2023-03-21T00:36:08.490Z',
          nome: 'Pernambuco',
          sigla: 'PE',
          codigo: '26',
        },
      ]);

      setAllEstadosOptionss([
        {
          id: 13,
          created_at: '2023-03-21T00:36:08.490Z',
          updated_at: '2023-03-21T00:36:08.490Z',
          nome: 'Pernambuco',
          sigla: 'PE',
          codigo: '26',
        },
      ]);
    };

    loadEstados();
  }, []);

  useEffect(() => {
    setPayload({
      params: {
        estados:
          estadosSelected.length > 0
            ? JSON.stringify(estadosSelected)
            : undefined,
        municipios:
          municipiosSelected.length > 0
            ? municipiosSelected.join(', ')
            : undefined,
        estabelecimentos:
          estabelecimentosSelected.length > 0
            ? estabelecimentosSelected.join(', ')
            : undefined,
        equipe:
          equipesSelected.length > 0 ? equipesSelected.join(', ') : undefined,

        periodo_inicio: period?.periodo_inicio
          ? period.periodo_inicio
          : undefined,
        periodo_fim: period?.periodo_fim ? period.periodo_fim : undefined,
      },
    });
  }, [
    estadosSelected,
    municipiosSelected,
    estabelecimentosSelected,
    equipesSelected,
    equipesSelected,
    period,
  ]);

  useEffect(() => {
    const getOptionsCidades = async () => {
      if (estadosSelected.length === 1) {
        const optionsCidades = await getCidades(estadosSelected[0]);
        setOptionsMunicipios(optionsCidades);
        setAllMunicipiosOptionss(optionsCidades);
      }

      if (estadosSelected.length === 0) {
        setOptionsMunicipios([]);
      }
    };

    getOptionsCidades();
  }, [estadosSelected]);

  useEffect(() => {
    const getOptionsEstabelecimentos = async () => {
      if (municipiosSelected.length === 1) {
        const optionsEstabelecimentos = await api.get(
          '/dashboard/exames/hpv/panel/estabelecimentos',
          {
            params: {
              estados:
                estadosSelected.length > 0
                  ? JSON.stringify(estadosSelected)
                  : undefined,
              municipios:
                municipiosSelected.length > 0
                  ? JSON.stringify(municipiosSelected)
                  : undefined,
            },
          }
        );

        setOptionsEstabelecimentos([
          {
            id: 'null',
            municipio: '',
            nome: 'SEM VINCULAÇÃO',
            quantidade: '',
            label: '',
          },
          ...optionsEstabelecimentos.data,
        ]);

        setAllEstabelecimentosOptions([
          {
            id: 'null',
            municipio: '',
            nome: 'SEM VINCULAÇÃO',
            quantidade: '',
            label: '',
          },
          ...optionsEstabelecimentos.data,
        ]);
      }

      if (municipiosSelected.length === 0) {
        setOptionsEstabelecimentos([]);
      }
    };

    getOptionsEstabelecimentos();
  }, [municipiosSelected]);

  return (
    <>
      <Dialog
        open={openModalPeriodo}
        onClose={() => setOpenModalPeriodo(false)}
      >
        <DialogTitle>
          <Typography variant="h6" fontWeight="bold">
            Tipo de Período
          </Typography>
          <DialogContent sx={{ padding: '0', marginTop: '30px' }}>
            <Paper
              variant="outlined"
              sx={{
                padding: '16px',
                display: 'flex',
                flexDirection: 'column',
                gap: '16px',
              }}
            >
              <Typography variant="h6" fontWeight="bold">
                Selecione o Período para Visualização dos Dados
              </Typography>
              <Autocomplete
                placeholder="Tipo de Período"
                value={valuePeriodoModal}
                onChange={(_, newValue: any) => {
                  handleSelectPeriodModal(newValue);
                }}
                options={optionsPeriodo}
                getOptionLabel={(option) => option.label ?? ''}
                renderInput={(params) => (
                  <StyledAsteriskTextField
                    {...params}
                    fullWidth
                    label="Tipo de Período"
                    variant="outlined"
                  />
                )}
              />
              {valueInputPeriod === 'Últimos 12 meses' ||
              !valueInputPeriod ? undefined : (
                <Autocomplete
                  options={['2024', '2023']}
                  onChange={(_, newValue) => {
                    handleChangeWhenYearSelected(newValue);
                  }}
                  value={year ? year : ''}
                  renderInput={(params) => (
                    <TextField {...params} label="Selecione o Ano" />
                  )}
                />
              )}
              {}

              {(valueInputPeriod === 'Quadrimestral' ||
                valueInputPeriod === 'Semestral') && (
                <Autocomplete
                  options={
                    valueInputPeriod === 'Quadrimestral'
                      ? optionsQuadrimestre
                      : optionsSemestre
                  }
                  value={
                    valueInputQuadrimestreSemestre
                      ? valueInputQuadrimestreSemestre
                      : ''
                  }
                  onChange={(_, newValue) => {
                    handleChangeSemestreOrQuadrimestre(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={
                        valueInputPeriod === 'Quadrimestral'
                          ? 'Selecione o Quadrimestre'
                          : 'Selecione o Semestre'
                      }
                    />
                  )}
                />
              )}
            </Paper>
          </DialogContent>
        </DialogTitle>

        <DialogActions>
          <Button
            variant="outlined"
            color="error"
            onClick={() => {
              handleCloseModal();
            }}
            startIcon={<ClearIcon />}
          >
            CANCELAR
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setOpenModalPeriodo(false)}
            endIcon={<CheckIcon />}
          >
            CONFIRMAR
          </Button>
        </DialogActions>
      </Dialog>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={2}>
          <TextField
            size="small"
            // disabled={disableOtherInputs}
            label="Estado"
            value={
              estadosSelected.length > 1 ? 'Vários' : estadosSelected[0] ?? ''
            }
            sx={{
              padding: 0,
              width: '100%',
              '.MuiOutlinedInput-root': {
                paddingRight: '9px',
              },
            }}
            InputProps={{
              endAdornment: (
                <Button
                  sx={{
                    padding: 0,
                    minWidth: '24px !important',
                  }}
                  // disabled={disableOtherInputs}
                  onClick={(event) => {
                    window.scrollTo(0, 0);
                    setAnchorElEstados(event.currentTarget);
                  }}
                >
                  <ArrowDropDownIcon
                    sx={{
                      cursor: 'pointer',
                      color: 'gray',
                      // color: disableOtherInputs
                      //   ? '#BDBDBD'
                      //   : 'gray',
                    }}
                  />
                </Button>
              ),
            }}
          />
          <Menu
            anchorEl={anchorElEstados}
            open={!!anchorElEstados}
            onClose={() => setAnchorElEstados(null)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            sx={{
              position: 'absolute',
              width: '100%',
              marginTop: '10px',
              left: '10px',

              '.MuiList-root': {
                paddingTop: 0,
                paddingBottom: 0,
              },
            }}
          >
            <div
              style={{
                width: '100%',
                padding: '24px 32px',
                flexDirection: 'column',
              }}
            >
              <TextField
                size="small"
                placeholder="Pesquise por Sigla do Estado"
                onChange={(e) => {
                  setSearchEstado(e.target.value);
                }}
                value={searchEstado}
                sx={{
                  width: '289px',
                  '.MuiOutlinedInput-root': {
                    paddingRight: '9px',
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <Button
                      sx={{
                        padding: 0,
                        minWidth: '24px !important',
                      }}
                      onClick={() => {
                        if (!searchEstado) {
                          setEstadosOptions(allEstadosOptions);
                        } else {
                          const newArrEstados = allEstadosOptions.filter(
                            (estado) =>
                              estado.sigla
                                .toLowerCase()
                                .includes(searchEstado.toLowerCase())
                          );
                          setEstadosOptions(newArrEstados);
                        }
                      }}
                    >
                      <SearchIcon
                        sx={{
                          cursor: 'pointer',
                          color: 'gray',
                        }}
                      />
                    </Button>
                  ),
                }}
              />
              <div>
                <Button
                  variant="text"
                  onClick={() => {
                    const allItemsArray = estadosOptions.map(
                      (estado: any) => estado.sigla
                    );

                    setEstadosSelected(allItemsArray);
                  }}
                >
                  SELECIONAR TODOS -{' '}
                </Button>
                <Button variant="text" onClick={() => setEstadosSelected([])}>
                  LIMPAR
                </Button>
              </div>
              {estadosOptions?.map((estado: any) => {
                return (
                  <div style={{ display: 'flex' }}>
                    <Checkbox
                      onChange={() => {
                        handleSelectMultiplesFields({
                          paramToSave: 'sigla',
                          setState: setEstadosSelected,
                          state: estadosSelected,
                          value: estado,
                        });
                      }}
                      checked={
                        estadosSelected.find((item) => item === estado.sigla)
                          ? true
                          : false
                      }
                    />
                    <MenuItem
                      sx={{ padding: 0 }}
                      onClick={() => {
                        handleSelectMultiplesFields({
                          paramToSave: 'sigla',
                          setState: setEstadosSelected,
                          state: estadosSelected,
                          value: estado,
                        });
                      }}
                    >
                      {estado?.sigla}
                    </MenuItem>
                  </div>
                );
              })}
            </div>
          </Menu>
          {/* <Controller
            name="estado"
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <Autocomplete
                  options={[]}
                  renderInput={(params) => (
                    <TextField {...params} size="small" label="Estado" />
                  )}
                />
              );
            }}
          /> */}
        </Grid>

        <Grid item xs={12} lg={2}>
          <TextField
            size="small"
            disabled={disableMunicipioInput}
            label="Municipio"
            value={
              municipiosSelected.length > 1
                ? 'Vários'
                : municipiosSelected[0] ?? ''
            }
            sx={{
              padding: 0,
              width: '100%',
              '.MuiOutlinedInput-root': {
                paddingRight: '9px',
              },
            }}
            InputProps={{
              endAdornment: (
                <Button
                  sx={{
                    padding: 0,
                    minWidth: '24px !important',
                  }}
                  disabled={disableMunicipioInput}
                  onClick={(event) => {
                    window.scrollTo(0, 0);
                    setAnchorElMunicipios(event.currentTarget);
                  }}
                >
                  <ArrowDropDownIcon
                    sx={{
                      cursor: 'pointer',
                      color: disableMunicipioInput ? '#BDBDBD' : 'gray',
                    }}
                  />
                </Button>
              ),
            }}
          />
          <Menu
            anchorEl={anchorElMunicipios}
            open={!!anchorElMunicipios}
            onClose={() => setAnchorElMunicipios(null)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            sx={{
              position: 'absolute',
              width: '100%',
              marginTop: '10px',
              left: '10px',

              '.MuiList-root': {
                paddingTop: 0,
                paddingBottom: 0,
              },
            }}
          >
            <div
              style={{
                width: '100%',
                padding: '24px 32px',
                flexDirection: 'column',
              }}
            >
              <TextField
                size="small"
                placeholder="Pesquise por Nome do Município"
                onChange={(e) => setSearchMunicipio(e.target.value)}
                value={searchMunicipio}
                sx={{
                  width: '289px',
                  '.MuiOutlinedInput-root': {
                    paddingRight: '9px',
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <Button
                      sx={{
                        padding: 0,
                        minWidth: '24px !important',
                      }}
                      onClick={() => {
                        if (!searchMunicipio) {
                          setOptionsMunicipios(allMunicipiosOptions);
                        } else {
                          const newArrMunicipios = allMunicipiosOptions.filter(
                            (municipio) =>
                              municipio.nome
                                .toLowerCase()
                                .includes(searchMunicipio.toLowerCase())
                          );
                          setOptionsMunicipios(newArrMunicipios);
                        }
                      }}
                    >
                      <SearchIcon
                        sx={{
                          cursor: 'pointer',
                          color: 'gray',
                        }}
                      />
                    </Button>
                  ),
                }}
              />
              <div>
                <Button
                  variant="text"
                  onClick={() => {
                    const allItemsArray = optionsMunicipios.map(
                      (municipio: any) => municipio.nome
                    );

                    setMunicipiosSelected(allItemsArray);
                  }}
                >
                  SELECIONAR TODOS -{' '}
                </Button>
                <Button
                  variant="text"
                  onClick={() => setMunicipiosSelected([])}
                >
                  LIMPAR
                </Button>
              </div>
              {optionsMunicipios?.map((municicpio: any) => {
                return (
                  <div style={{ display: 'flex' }}>
                    <Checkbox
                      onChange={() => {
                        handleSelectMultiplesFields({
                          paramToSave: 'nome',
                          setState: setMunicipiosSelected,
                          state: municipiosSelected,
                          value: municicpio,
                        });
                      }}
                      checked={
                        municipiosSelected.find(
                          (item) => item === municicpio.nome
                        )
                          ? true
                          : false
                      }
                    />
                    <MenuItem
                      sx={{ padding: 0 }}
                      onClick={() => {
                        handleSelectMultiplesFields({
                          paramToSave: 'nome',
                          setState: setMunicipiosSelected,
                          state: municipiosSelected,
                          value: municicpio,
                        });
                      }}
                    >
                      {municicpio?.nome}
                    </MenuItem>
                  </div>
                );
              })}
            </div>
          </Menu>
        </Grid>

        <Grid item xs={12} lg={2}>
          <TextField
            size="small"
            disabled={disableEstabelecimentoInput}
            label="Estabelecimento"
            value={
              estabelecimentosSelected.length > 1
                ? 'Vários'
                : optionsEstabelecimentos.find(
                    (item) => item.id === estabelecimentosSelected[0]
                  )?.nome ?? ''
            }
            sx={{
              padding: 0,
              width: '100%',
              '.MuiOutlinedInput-root': {
                paddingRight: '9px',
              },
            }}
            InputProps={{
              endAdornment: (
                <Button
                  sx={{
                    padding: 0,
                    minWidth: '24px !important',
                  }}
                  disabled={disableEstabelecimentoInput}
                  onClick={(event) => {
                    window.scrollTo(0, 0);
                    setAnchorElEstabelecimentos(event.currentTarget);
                  }}
                >
                  <ArrowDropDownIcon
                    sx={{
                      cursor: 'pointer',
                      color: disableEstabelecimentoInput ? '#BDBDBD' : 'gray',
                    }}
                  />
                </Button>
              ),
            }}
          />
          <Menu
            anchorEl={anchorElEstabelecimentos}
            open={!!anchorElEstabelecimentos}
            onClose={() => setAnchorElEstabelecimentos(null)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            sx={{
              position: 'absolute',
              width: '100%',
              marginTop: '10px',
              left: '10px',

              '.MuiList-root': {
                paddingTop: 0,
                paddingBottom: 0,
              },
            }}
          >
            <div
              style={{
                width: '100%',
                padding: '24px 32px',
                flexDirection: 'column',
              }}
            >
              <TextField
                size="small"
                placeholder="Pesquise por Nome do Estabelecimento"
                onChange={(e) => setSearchEstabelecimentos(e.target.value)}
                value={searchEstabelecimentos}
                sx={{
                  width: '289px',
                  '.MuiOutlinedInput-root': {
                    paddingRight: '9px',
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <Button
                      sx={{
                        padding: 0,
                        minWidth: '24px !important',
                      }}
                      onClick={() => {
                        if (!searchEstabelecimentos) {
                          setOptionsEstabelecimentos(
                            allEstabelecimentosOptions
                          );
                        } else {
                          const newArrEstabelecimentos =
                            allEstabelecimentosOptions.filter(
                              (estabelecimento) =>
                                estabelecimento.nome
                                  .toLowerCase()
                                  .includes(
                                    searchEstabelecimentos.toLowerCase()
                                  )
                            );

                          setOptionsEstabelecimentos(newArrEstabelecimentos);
                        }
                      }}
                    >
                      <SearchIcon
                        sx={{
                          cursor: 'pointer',
                          color: 'gray',
                        }}
                      />
                    </Button>
                  ),
                }}
              />
              <div>
                <Button
                  variant="text"
                  onClick={() => {
                    const allItemsArray = optionsEstabelecimentos.map(
                      (estabelecimento: any) => estabelecimento.id
                    );

                    setEstabelecimentosSelected(allItemsArray);
                  }}
                >
                  SELECIONAR TODOS -{' '}
                </Button>
                <Button
                  variant="text"
                  onClick={() => setEstabelecimentosSelected([])}
                >
                  LIMPAR
                </Button>
              </div>
              {optionsEstabelecimentos?.map((estabelecimento: any) => {
                return (
                  <div style={{ display: 'flex' }}>
                    <Checkbox
                      onChange={() => {
                        handleSelectMultiplesFields({
                          paramToSave: 'id',
                          setState: setEstabelecimentosSelected,
                          state: estabelecimentosSelected,
                          value: estabelecimento,
                        });
                      }}
                      checked={
                        estabelecimentosSelected.find(
                          (item) => item === estabelecimento.id
                        )
                          ? true
                          : false
                      }
                    />
                    <MenuItem
                      sx={{ padding: 0 }}
                      onClick={() => {
                        handleSelectMultiplesFields({
                          paramToSave: 'id',
                          setState: setEstabelecimentosSelected,
                          state: estabelecimentosSelected,
                          value: estabelecimento,
                        });
                      }}
                    >
                      {estabelecimento?.nome}
                    </MenuItem>
                  </div>
                );
              })}
            </div>
          </Menu>
        </Grid>
        <Grid item xs={12} lg={2}>
          <TextField
            size="small"
            disabled={disableEquipeInput}
            label="Ine da Equipe"
            value={
              equipesSelected.length > 1
                ? 'Vários'
                : arrayEquipes.find(
                    (item: any) => item.ine === equipesSelected[0]
                  )?.ine ?? ''
            }
            sx={{
              padding: 0,
              width: '100%',
              '.MuiOutlinedInput-root': {
                paddingRight: '9px',
              },
            }}
            InputProps={{
              endAdornment: (
                <Button
                  sx={{
                    padding: 0,
                    minWidth: '24px !important',
                  }}
                  disabled={disableEquipeInput}
                  onClick={(event) => {
                    setAnchorElEquipe(event.currentTarget);
                  }}
                >
                  <ArrowDropDownIcon
                    sx={{
                      cursor: 'pointer',
                      color: disableEquipeInput ? '#BDBDBD' : 'gray',
                    }}
                  />
                </Button>
              ),
            }}
          />

          <Menu
            anchorEl={anchorElEquipe}
            open={!!anchorElEquipe}
            onClose={() => setAnchorElEquipe(null)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            sx={{
              position: 'absolute',
              width: '100%',
              marginTop: '10px',
              left: '10px',

              '.MuiList-root': {
                paddingTop: 0,
                paddingBottom: 0,
              },
            }}
          >
            <div
              style={{
                width: '100%',

                padding: '24px 32px',
                flexDirection: 'column',
              }}
            >
              <TextField
                size="small"
                placeholder="Pesquise por Ine da Equipe"
                onChange={(e) => setSearchEquipes(e.target.value)}
                value={searchEquipes}
                sx={{
                  width: '289px',
                  '.MuiOutlinedInput-root': {
                    paddingRight: '9px',
                  },
                }}
                onClick={() => {
                  if (!searchEquipes) {
                    setArrayEquipes(allEquipesOptions);
                  } else {
                    const newArrEquipes = allEquipesOptions.filter((equipe) =>
                      equipe.ine
                        .toLowerCase()
                        .includes(searchEquipes.toLowerCase())
                    );

                    setArrayEquipes(newArrEquipes);
                  }
                }}
                InputProps={{
                  endAdornment: (
                    <Button
                      sx={{
                        padding: 0,
                        minWidth: '24px !important',
                      }}
                    >
                      <SearchIcon
                        sx={{
                          cursor: 'pointer',
                          color: 'gray',
                        }}
                      />
                    </Button>
                  ),
                }}
              />
              <div>
                <Button
                  variant="text"
                  onClick={() => {
                    const allItemsArray = arrayEquipes.map(
                      (equipe: any) => equipe.id
                    );

                    setEquipesSelected(allItemsArray);
                  }}
                >
                  SELECIONAR TODOS -{' '}
                </Button>
                <Button variant="text" onClick={() => setEquipesSelected([])}>
                  LIMPAR
                </Button>
              </div>
              {arrayEquipes?.map((equipe: any) => {
                return (
                  <div style={{ display: 'flex' }}>
                    <Checkbox
                      onChange={() => {
                        handleSelectMultiplesFields({
                          paramToSave: 'ine',
                          setState: setEquipesSelected,
                          state: equipesSelected,
                          value: equipe,
                        });
                      }}
                      checked={
                        equipesSelected.find((item) => item === equipe.ine)
                          ? true
                          : false
                      }
                    />
                    <MenuItem
                      sx={{ padding: 0 }}
                      onClick={() => {
                        handleSelectMultiplesFields({
                          paramToSave: 'ine',
                          setState: setEquipesSelected,
                          state: equipesSelected,
                          value: equipe,
                        });
                      }}
                    >
                      {equipe?.ine}
                    </MenuItem>
                  </div>
                );
              })}
            </div>
          </Menu>
        </Grid>
        <Grid item xs={12} lg={2}>
          <Controller
            name="periodo"
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <StyledAsteriskTextField
                  fullWidth
                  size="small"
                  label="Período"
                  value={valueInputPeriod ? valueInputPeriod : ''}
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        sx={{ padding: 0 }}
                        onClick={() => setOpenModalPeriodo(true)}
                      >
                        <ArrowDropDownIcon />
                      </IconButton>
                    ),
                  }}
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={12} lg={1}>
          <Button
            variant="contained"
            onClick={() => {
              getAllCharts({ cleared: false });
              setShowListFilter(listFilter);
            }}
          >
            Filtrar
          </Button>
        </Grid>
        <Grid item xs={12} lg={1}>
          <Button
            variant="outlined"
            color="error"
            onClick={() => {
              handleClearFilter();
            }}
          >
            Limpar
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default FiltersVisaoGeral;
