import * as Yup from 'yup';

export const schemaChangePassword = Yup.object().shape({
  nova_senha: Yup.string()
    .test('valid', '', (value) => {
      if (!value) {
        return false;
      }

      const hasUpperCase = /[A-Z]/.test(value);
      const hasLowerCase = /[a-z]/.test(value);
      const hasNumber = /[0-9]/.test(value);
      const hasSpecialChar = /[^A-Za-z0-9]/.test(value);

      return hasUpperCase && hasLowerCase && hasNumber && hasSpecialChar;
    })
    .min(8, '')
    .matches(/[A-Z]/, 'Caracteres Maiúsculos.')
    .matches(/[a-z]/, 'Caracteres Minúsculos.')
    .matches(/[0-9]/, 'Números.')
    .matches(/[^A-Za-z0-9]/, 'Símbolos Especiais ("@#).'),

  confirme_nova_senha: Yup.string()
    .required('Campo Obrigatorio')
    .oneOf([Yup.ref('nova_senha')], 'As senhas não coincidem.'),
});
