import laboratory from './laboratoryIcon.svg';
export const LaboratoryRounded = () => {
  return (
    <>
      <div
        style={{
          backgroundColor: '#238884',
          width: '64px',
          height: '64px',
          borderRadius: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <img src={laboratory} />
      </div>
    </>
  );
};
