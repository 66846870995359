import styled from '@emotion/styled';
import { Box, Button, Paper } from '@mui/material';

export const FormContainer = styled(Paper)`
  width: 464px;
  border: 12px;

  @media (max-width: 768px) {
    max-width: 464px;
  }
`;

export const ContainerOffButtons = styled(Button)({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
});

export const SendEmailButton = styled(Button)({
  backgroundColor: '#238884',
  color: '#FFFFFF',
  width: '108px',
  marginTop: '32px',
});

export const CancelButton = styled(Button)({
  color: 'red',
  fontSize: '14px',
  fontWeight: '500',
  marginTop: '16px',
});

export const BoxInputButton = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});
