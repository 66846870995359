import { TableCell, TableHead, TableRow, Typography } from '@mui/material';

const TableHeaders = () => {
  return (
    <TableHead>
      <TableRow>
        <TableCell sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Nome Completo <br /> da Mulher
          </Typography>
        </TableCell>

        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            {' '}
            Nº do Cartão SUS
          </Typography>
        </TableCell>

        <TableCell align="left" sx={{ borderBottom: 'none', width: '160px' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Nome Completo <br />
            da Mãe
          </Typography>
        </TableCell>

        <TableCell align="left" sx={{ borderBottom: 'none', width: '160px' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            CPF
          </Typography>
        </TableCell>

        <TableCell align="left" sx={{ borderBottom: 'none', width: '160px' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Data de <br /> Nascimento
          </Typography>
        </TableCell>

        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Nacionalidade
          </Typography>
        </TableCell>

        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Raça / Cor
          </Typography>
        </TableCell>

        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            CEP
          </Typography>
        </TableCell>

        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Logradouro
          </Typography>
        </TableCell>

        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Número
          </Typography>
        </TableCell>

        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Complemento
          </Typography>
        </TableCell>

        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Bairro
          </Typography>
        </TableCell>

        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Zona
          </Typography>
        </TableCell>

        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Município
          </Typography>
        </TableCell>

        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            UF
          </Typography>
        </TableCell>

        {/* <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Estabelecimento
            <br />
            Vinculado
          </Typography>
        </TableCell> */}

        {/* <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            INE da Equipe Vinculada
          </Typography>
        </TableCell> */}

        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Atendimento Status
          </Typography>
        </TableCell>

        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Motivo do Não <br />
            Atendimento
          </Typography>
        </TableCell>

        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Motivo do exame
          </Typography>
        </TableCell>

        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Fez o exame <br />
            preventivo <br />
            (Papaonicolaou)
            <br />
            alguma vez?
          </Typography>
        </TableCell>

        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Ano de realização <br />
            do exame <br />
            preventivo
            <br />
            (Papaonicolaou)
          </Typography>
        </TableCell>

        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Usa DIU?
          </Typography>
        </TableCell>

        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Está Grávida?
          </Typography>
        </TableCell>

        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Usa pílula
            <br />
            anticoncepcional?
          </Typography>
        </TableCell>

        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Já fez tratamento
            <br />
            por radioterapia?
          </Typography>
        </TableCell>

        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Data da última <br />
            menstraução /<br />
            regra?
          </Typography>
        </TableCell>

        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Tem ou teve algum <br />
            sangramento após
            <br />
            relações sexuais?
          </Typography>
        </TableCell>

        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Está na <br />
            menopausa? (Não
            <br />
            apresenta ciclos <br />
            menstruais há <br />
            12 meses ou <br />
            mais)
            <br />
          </Typography>
        </TableCell>

        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Após a menopausa
            <br /> tem ou teve algum
            <br />
            sangramento vaginal
          </Typography>
        </TableCell>

        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Usa hormônio / <br />
            remédio para
            <br />
            tratar a <br />
            menopausa?
          </Typography>
        </TableCell>

        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Inspeção do colo
          </Typography>
        </TableCell>

        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Sinais sugestivos <br />
            de doenças <br />
            sexualmente <br />
            transimissíveis?
          </Typography>
        </TableCell>

        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Método de Coleta <br />
            de HPV
          </Typography>
        </TableCell>

        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Data de Coleta
          </Typography>
        </TableCell>

        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Profissional <br />
            Responsável pela
            <br />
            Coleta
          </Typography>
        </TableCell>

        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Local da coleta
          </Typography>
        </TableCell>

        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Estabelecimento <br />
            do Procedimento
          </Typography>
        </TableCell>

        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Atendimento <br />
            Observação
          </Typography>
        </TableCell>

        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Status do Exame
          </Typography>
        </TableCell>

        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Data do Resultado
          </Typography>
        </TableCell>

        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Laboratório <br />
            Responsável pelo <br />
            Laudo
          </Typography>
        </TableCell>

        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Resultado
          </Typography>
        </TableCell>

        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            HPV 16
          </Typography>
        </TableCell>

        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            HPV18
          </Typography>
        </TableCell>

        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Outros
          </Typography>
        </TableCell>

        {/* <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          ></Typography>
        </TableCell> */}
      </TableRow>
    </TableHead>
  );
};

export default TableHeaders;
