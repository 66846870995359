import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles({
  scheduleContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    gap: '24px',
  },
  datePickerWrapper: {
    boxShadow:
      '0px 1px 5px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.20);',
    borderRadius: '4px',
    overflow: 'hidden',
    maxHeight: '340px',
  },
  schedules: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
  emptySchedules: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '32px',
  },
  configScheduleInfo: {
    maxWidth: '353px',
    textAlign: 'center',

    '& a': {
      color: '#2196F3',
      textDecoration: 'none',
    },
  },
  footer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '8px 8px 0',
  },
  newPatientLink: {
    textDecoration: 'none',
    color: '#2196F3',
  },
});
