/* eslint-disable react-hooks/exhaustive-deps */
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import * as React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ClearIcon from '@mui/icons-material/Clear';
import CheckIcon from '@mui/icons-material/Check';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DatePicker from '@mui/lab/DatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import ptBR from 'date-fns/locale/pt-BR';
import { useStyles } from './RegisterExam.style';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import * as Yup from 'yup';
import api from '../../api';
import BoxInfoPatient from '../../components/BoxInfoPatient/BoxInfoPatient';
import { SideMenu } from '../../components/SideMenu';

enum MtdColeta {
  Convencional = 'CONVENCIONAL',
  MeioLiquido = 'MEIO_LIQUIDO',
}

enum LocalColeta {
  Residencia = 'RESIDENCIA',
  ServicoSaude = 'SERVICO_SAUDE',
  UnidadeMovel = 'UNIDADE_MOVEL',
  Outro = 'OUTRO',
}

enum InspecaoColo {
  Normal = 'NORMAL',
  Ausente = 'AUSENTE',
  Alterado = 'ALTERADO',
  NaoVisualizado = 'NAO_VISUALIZADO',
}

enum ExameStatus {
  ResultadoDisponivel = 'RESULTADO_DISPONIVEL',
  ResultadoNaoEntregue = 'RESULTADO_NAO_ENTREGUE',
  Entregue = 'ENTREGUE',
}

export enum Escolha {
  Sim = 'SIM',
  Nao = 'NAO',
  NaoSabe = 'NAO_SABE',
  Default = '',
}

export enum Motivo {
  Rastreamento = 'RASTREAMENTO',
  Repeticao = 'REPETICAO',
  Seguimento = 'SEGUIMENTO',
  PosTratamento = 'POS_TRATAMENTO',
}

interface Anamnese {
  motivo: Motivo;
  papanicolaou: Escolha;
  ano_papanicolaou: number;
  diu: Escolha;
  gravida: Escolha;
  anticoncepcional: Escolha;
  hormonio: Escolha;
  radioterapia: Escolha;
  menstruacao: Escolha;
  checkedMenstruacao: boolean;
  dt_menstruacao: string;
  sangramento_relacao: Escolha;
  sangramento_menopausa: Escolha;
}

interface Clinico {
  inspecao: InspecaoColo;
  dst: boolean;
  dt_coleta: Date;
  responsavelId: number;
  coleta_consultorio: boolean;
  autocoleta: boolean;
  responsavelColetaId: number;
  local_coleta: LocalColeta;
  outro_local_coleta: string;
  mtd_coleta: MtdColeta;
}

interface Inputs {
  tipo: string;
  status: ExameStatus;
  anamnese: Anamnese;
  clinico: Clinico;
  pessoaId: number;
  profissionalId: number;
  estabelecimentoSaudeId: number;
}

const schema = Yup.object({
  tipo: Yup.string(),
  status: Yup.string(),
  clinico: Yup.object({
    inspecao: Yup.string().required('Campo Obrigatório'),
    dst: Yup.boolean().required('Campo Obrigatório'),
    dt_coleta: Yup.string().required('Campo Obrigatório'),
    responsavelId: Yup.number().required('Campo Obrigatório'),
    coleta_consultorio: Yup.boolean().required('Campo Obrigatório'),
    autocoleta: Yup.boolean().required('Campo Obrigatório'),
    responsavelColetaId: Yup.number(),
    local_coleta: Yup.string().required('Campo Obrigatório'),
    outro_local_coleta: Yup.string(),
    mtd_coleta: Yup.string().required('Campo Obrigatório'),
  }),
  anamnese: Yup.object({
    motivo: Yup.string().required('Campo Obrigatório'),
    papanicolaou: Yup.string().required('Campo Obrigatório'),
    ano_papanicolaou: Yup.number().when('papanicolaou', {
      is: (papanicolaou: any) => {
        return papanicolaou === Escolha.Sim;
      },
      then: Yup.number().required('Campo Obrigatório'),
    }),
    diu: Yup.string().required('Campo Obrigatório'),
    gravida: Yup.string().required('Campo Obrigatório'),
    anticoncepcional: Yup.string().required().required('Campo Obrigatório'),
    hormonio: Yup.string().required('Campo Obrigatório'),
    radioterapia: Yup.string().required('Campo Obrigatório'),
    checkedMenstruacao: Yup.boolean().default(false),
    dt_menstruacao: Yup.string().when('checkedMenstruacao', {
      is: (checkedMenstruacao: boolean) => {
        return checkedMenstruacao === true;
      },
      then: Yup.string().required('Campo Obrigatório'),
    }),
    sangramento_relacao: Yup.string().required('Campo Obrigatório'),
    sangramento_menopausa: Yup.string(),
  }),
  pessoaId: Yup.number(),
  profissionalId: Yup.number(),
  estabelecimentoSaudeId: Yup.number(),
});

export default function RegisterExam() {
  const styles = useStyles();
  const location = useLocation();
  const [pacienteData, setPacienteData] = React.useState<any>();
  const [responsaveis, setResponsaveis] = React.useState<any>([]);
  const [checkedMenstruacao, setCheckedMenstruacao] =
    React.useState<boolean>(false);
  const [openDialogCancel, setOpenDialogCancel] =
    React.useState<boolean>(false);
  const {
    handleSubmit,
    setValue,
    formState: { errors },
    control,
    watch,
  } = useForm<Inputs>({
    resolver: yupResolver(schema),
    defaultValues: {
      tipo: 'Teste de HPV',
      status: ExameStatus.ResultadoNaoEntregue,
    },
  });

  const onSubmit = async (data: Inputs) => {
    data.pessoaId = pacienteData.id;
    data.clinico.coleta_consultorio = data.clinico.coleta_consultorio.valueOf();
    try {
      await api.post('/hpv', { ...data });
      navigate(`/pacientes/${pacienteData.id}`);
    } catch (err) {
      console.error(err);
    }
  };

  const styleRadio = {
    '&.Mui-checked': {
      color: '#238884',
    },
  };

  const styleFormLabel = {
    '&.Mui-focused': {
      color: '#238884',
    },
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setValue('anamnese.dt_menstruacao', '');
    } else {
      setValue('anamnese.dt_menstruacao', new Date().toString());
    }
    setCheckedMenstruacao(event.target.checked);
  };

  let navigate = useNavigate();

  React.useEffect(() => {
    setPacienteData(location.state);
    async function loadResponsaveis() {
      const estabelecimentos = await api.get('/estabelecimentos-saude');
      await api
        .get('/profissionais')
        .then((response) => {
          setResponsaveis(response.data.data);
          setValue('estabelecimentoSaudeId', estabelecimentos.data.data[3].id);
          setValue('clinico.responsavelId', response.data.data[0].id);
          setValue('profissionalId', response.data.data[0].id);
        })
        .catch((err) => {
          console.error('ops! ocorreu um erro' + err);
        });
    }
    loadResponsaveis();
  }, []);

  const handleClickOpenDialogCancel = () => {
    setOpenDialogCancel(true);
  };

  const handleCloseDialogCancel = () => {
    setOpenDialogCancel(false);
  };

  const handleCloseConfirmCancel = () => {
    navigate(`/pacientes/${pacienteData.id}`);
  };

  return (
    <SideMenu>
      <Container>
        <Box sx={{ width: '100%' }} mt={6}>
          <Dialog open={openDialogCancel} onClose={handleCloseDialogCancel}>
            <DialogTitle>Cancelar Cadastro de exame</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Deseja realmente cancelar o cadastro de exame ?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDialogCancel}>Não</Button>
              <Button onClick={handleCloseConfirmCancel} autoFocus>
                Sim
              </Button>
            </DialogActions>
          </Dialog>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <div>
                <Typography
                  variant="h4"
                  fontWeight="bold"
                  style={{ marginBottom: '5px' }}
                >
                  Cadastro de Exame
                </Typography>
                <Breadcrumbs
                  separator={<NavigateNextIcon fontSize="small" />}
                  aria-label="breadcrumb"
                  style={{ marginBottom: '16px', marginTop: '16px' }}
                >
                  <Link
                    style={{
                      textDecoration: 'none',
                      color: '#646464',
                    }}
                    to="/pacientes"
                  >
                    Listagem de Pacientes
                  </Link>
                  <Link
                    style={{
                      textDecoration: 'none',
                      color: '#646464',
                    }}
                    to={`/pacientes/${pacienteData.id}`}
                  >
                    Ficha da Paciente
                  </Link>
                  <Typography key="3" color="text.primary">
                    Cadastro de Exame
                  </Typography>
                </Breadcrumbs>
              </div>
              <div>
                <Typography variant="body2" fontWeight="bold">
                  Código do sistema
                </Typography>
                <Typography variant="body1" fontWeight="bold">
                  Nº 0000000
                </Typography>
              </div>
            </Box>
            <BoxInfoPatient
              data={pacienteData}
              viewButton={false}
            ></BoxInfoPatient>
            <Box sx={{ width: '100%' }}>
              <Paper sx={{ width: '100%' }}>
                <Typography
                  variant="h5"
                  fontWeight="bold"
                  style={{ margin: '25px', paddingTop: '25px' }}
                >
                  Dados da Anamnese
                </Typography>
                <Divider />
                <Box m={3} pb={3} sx={{ flexDirection: 'column' }}>
                  <Grid
                    container
                    rowSpacing={3}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  >
                    <Grid item xs={12}>
                      <Controller
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            select
                            onChange={onChange}
                            value={value ? value : ''}
                            fullWidth
                            label="Motivo do exame"
                            size="small"
                            variant="outlined"
                            error={!!errors.anamnese?.motivo}
                            helperText={errors.anamnese?.motivo?.message}
                            className={styles.textField}
                          >
                            <MenuItem value={Motivo.Rastreamento}>
                              Rastreamento
                            </MenuItem>
                            <MenuItem value={Motivo.Repeticao}>
                              Repetição (exame alterado ASCUS/Baixo grau)
                            </MenuItem>
                            <MenuItem value={Motivo.Seguimento}>
                              Seguimento (pós diagnóstico colposcópico /
                              tratamento)
                            </MenuItem>
                          </TextField>
                        )}
                        name="anamnese.motivo"
                        control={control}
                      />
                    </Grid>
                    <Grid item>
                      <FormControl
                        error={errors.anamnese?.papanicolaou && true}
                      >
                        <FormLabel sx={styleFormLabel}>
                          Fez o exame preventivo (Papanicolaou) alguma vez? *
                        </FormLabel>
                        <Controller
                          render={({ field: { onChange, value } }) => (
                            <RadioGroup
                              onChange={onChange}
                              value={value ? value : ''}
                              row
                            >
                              <FormControlLabel
                                value={Escolha.Sim}
                                control={<Radio sx={styleRadio} />}
                                label="Sim"
                              />
                              <FormControlLabel
                                value={Escolha.Nao}
                                control={<Radio sx={styleRadio} />}
                                label="Não"
                              />
                              <FormControlLabel
                                value={Escolha.NaoSabe}
                                control={<Radio sx={styleRadio} />}
                                label="Não Sabe"
                              />
                            </RadioGroup>
                          )}
                          name="anamnese.papanicolaou"
                          control={control}
                        />
                        <FormHelperText>
                          {errors.anamnese?.papanicolaou?.message}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item sx={{ mt: 3, ml: 8 }}>
                      <Controller
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            fullWidth
                            onChange={(e) => onChange(parseInt(e.target.value))}
                            value={value ? value : ''}
                            label="Ano *"
                            size="small"
                            variant="outlined"
                            error={!!errors.anamnese?.ano_papanicolaou}
                            helperText={
                              errors.anamnese?.ano_papanicolaou?.message
                            }
                            inputProps={{
                              inputMode: 'numeric',
                              pattern: '[0-9]*',
                            }}
                            className={styles.textField}
                          />
                        )}
                        name="anamnese.ano_papanicolaou"
                        control={control}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl error={errors.anamnese?.diu && true}>
                        <FormLabel
                          sx={{ '&.Mui-focused': { color: '#238884' } }}
                        >
                          Usa DIU? *
                        </FormLabel>
                        <Controller
                          render={({ field: { onChange, value } }) => (
                            <RadioGroup
                              onChange={onChange}
                              value={value ? value : ''}
                              row
                            >
                              <FormControlLabel
                                value={Escolha.Sim}
                                control={<Radio sx={styleRadio} />}
                                label="Sim"
                              />
                              <FormControlLabel
                                value={Escolha.Nao}
                                control={<Radio sx={styleRadio} />}
                                label="Não"
                              />
                              <FormControlLabel
                                value={Escolha.NaoSabe}
                                control={<Radio sx={styleRadio} />}
                                label="Não Sabe"
                              />
                            </RadioGroup>
                          )}
                          name="anamnese.diu"
                          control={control}
                        />
                        <FormHelperText>
                          {errors.anamnese?.diu?.message}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl error={errors.anamnese?.gravida && true}>
                        <FormLabel
                          sx={styleFormLabel}
                          id="demo-row-radio-buttons-group-label"
                        >
                          Está Grávida? *
                        </FormLabel>
                        <Controller
                          render={({ field: { onChange, value } }) => (
                            <RadioGroup
                              onChange={onChange}
                              value={value ? value : ''}
                              row
                            >
                              <FormControlLabel
                                value={Escolha.Sim}
                                control={<Radio sx={styleRadio} />}
                                label="Sim"
                              />
                              <FormControlLabel
                                value={Escolha.Nao}
                                control={<Radio sx={styleRadio} />}
                                label="Não"
                              />
                              <FormControlLabel
                                value={Escolha.NaoSabe}
                                control={<Radio sx={styleRadio} />}
                                label="Não Sabe"
                              />
                            </RadioGroup>
                          )}
                          name="anamnese.gravida"
                          control={control}
                        />
                        <FormHelperText>
                          {errors.anamnese?.gravida?.message}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl
                        error={errors.anamnese?.anticoncepcional && true}
                      >
                        <FormLabel
                          sx={styleFormLabel}
                          id="demo-row-radio-buttons-group-label"
                        >
                          Usa pílula anticoncepcional? *
                        </FormLabel>
                        <Controller
                          render={({ field: { onChange, value } }) => (
                            <RadioGroup
                              onChange={onChange}
                              value={value ? value : ''}
                              row
                            >
                              <FormControlLabel
                                value={Escolha.Sim}
                                control={<Radio sx={styleRadio} />}
                                label="Sim"
                              />
                              <FormControlLabel
                                value={Escolha.Nao}
                                control={<Radio sx={styleRadio} />}
                                label="Não"
                              />
                              <FormControlLabel
                                value={Escolha.NaoSabe}
                                control={<Radio sx={styleRadio} />}
                                label="Não Sabe"
                              />
                            </RadioGroup>
                          )}
                          name="anamnese.anticoncepcional"
                          control={control}
                        />
                        <FormHelperText>
                          {errors.anamnese?.anticoncepcional?.message}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl error={errors.anamnese?.hormonio && true}>
                        <FormLabel
                          sx={styleFormLabel}
                          id="demo-row-radio-buttons-group-label"
                        >
                          Usa hormônio / remédio para tratar a menopausa? *
                        </FormLabel>
                        <Controller
                          render={({ field: { onChange, value } }) => (
                            <RadioGroup
                              onChange={onChange}
                              value={value ? value : ''}
                              row
                            >
                              <FormControlLabel
                                value={Escolha.Sim}
                                control={<Radio sx={styleRadio} />}
                                label="Sim"
                              />
                              <FormControlLabel
                                value={Escolha.Nao}
                                control={<Radio sx={styleRadio} />}
                                label="Não"
                              />
                              <FormControlLabel
                                value={Escolha.NaoSabe}
                                control={<Radio sx={styleRadio} />}
                                label="Não Sabe"
                              />
                            </RadioGroup>
                          )}
                          name="anamnese.hormonio"
                          control={control}
                        />
                        <FormHelperText>
                          {errors.anamnese?.hormonio?.message}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl
                        error={errors.anamnese?.radioterapia && true}
                      >
                        <FormLabel
                          sx={styleFormLabel}
                          id="demo-row-radio-buttons-group-label"
                        >
                          Já fez tratamento por radioterapia? *
                        </FormLabel>
                        <Controller
                          render={({ field: { onChange, value } }) => (
                            <RadioGroup
                              onChange={onChange}
                              value={value ? value : ''}
                              row
                            >
                              <FormControlLabel
                                value={Escolha.Sim}
                                control={<Radio sx={styleRadio} />}
                                label="Sim"
                              />
                              <FormControlLabel
                                value={Escolha.Nao}
                                control={<Radio sx={styleRadio} />}
                                label="Não"
                              />
                              <FormControlLabel
                                value={Escolha.NaoSabe}
                                control={<Radio sx={styleRadio} />}
                                label="Não Sabe"
                              />
                            </RadioGroup>
                          )}
                          name="anamnese.radioterapia"
                          control={control}
                        />
                        <FormHelperText>
                          {errors.anamnese?.radioterapia?.message}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item container xs={12}>
                      <FormControl
                        style={{ width: '100%', display: 'flex' }}
                        error={errors.anamnese?.dt_menstruacao && true}
                      >
                        <Grid item xs={12}>
                          <FormLabel
                            error={!!errors.anamnese?.dt_menstruacao?.message}
                            sx={styleFormLabel}
                            id="demo-row-radio-buttons-group-label"
                          >
                            Data da última menstruação / regra *
                          </FormLabel>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          style={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'row',
                          }}
                        >
                          <LocalizationProvider
                            dateAdapter={AdapterDateFns}
                            locale={ptBR}
                          >
                            <div>
                              <Controller
                                control={control}
                                name="anamnese.dt_menstruacao"
                                render={({
                                  field: { onChange, onBlur, value, ref },
                                }) => (
                                  <DatePicker
                                    disabled={checkedMenstruacao}
                                    onChange={onChange}
                                    value={value}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        style={{ marginTop: '20px' }}
                                        label="Data"
                                        size="small"
                                        variant="outlined"
                                        className={styles.textField}
                                        fullWidth
                                      />
                                    )}
                                  />
                                )}
                              />
                              <FormHelperText>
                                {errors.anamnese?.dt_menstruacao?.message}
                              </FormHelperText>
                            </div>
                          </LocalizationProvider>
                          <FormControlLabel
                            style={{ marginTop: '15px', marginLeft: '15px' }}
                            control={
                              <Checkbox
                                checked={checkedMenstruacao}
                                onChange={handleChange}
                                sx={{
                                  color: '#238884',
                                  '&.Mui-checked': {
                                    color: '#238884',
                                  },
                                }}
                              />
                            }
                            label="Não sabe / Não lembra"
                          />
                        </Grid>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl
                        error={errors.anamnese?.sangramento_relacao && true}
                      >
                        <FormLabel
                          sx={styleFormLabel}
                          id="demo-row-radio-buttons-group-label"
                        >
                          Tem ou teve algum sangramento após relações sexuais? *
                        </FormLabel>
                        <Controller
                          render={({ field: { onChange, value } }) => (
                            <RadioGroup
                              onChange={onChange}
                              value={value ? value : ''}
                              row
                            >
                              <FormControlLabel
                                value={Escolha.Sim}
                                control={<Radio sx={styleRadio} />}
                                label="Sim"
                              />
                              <FormControlLabel
                                value={Escolha.NaoSabe}
                                control={<Radio sx={styleRadio} />}
                                label="Não / Não Sabe / Não Lembra"
                              />
                            </RadioGroup>
                          )}
                          name="anamnese.sangramento_relacao"
                          control={control}
                        />
                        <FormHelperText style={{ fontStyle: 'italic' }}>
                          Não considerar a primeira relação sexual na vida
                        </FormHelperText>
                        <FormHelperText>
                          {errors.anamnese?.sangramento_relacao?.message}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl
                        error={errors.anamnese?.sangramento_menopausa && true}
                      >
                        <FormLabel
                          sx={styleFormLabel}
                          id="demo-row-radio-buttons-group-label"
                        >
                          Tem ou teve algum sangramento após a menopausa?
                        </FormLabel>
                        <Controller
                          render={({ field: { onChange, value } }) => (
                            <RadioGroup
                              onChange={onChange}
                              value={value ? value : ''}
                              row
                            >
                              <FormControlLabel
                                value={Escolha.Sim}
                                control={<Radio sx={styleRadio} />}
                                label="Sim"
                              />
                              <FormControlLabel
                                value={Escolha.NaoSabe}
                                control={<Radio sx={styleRadio} />}
                                label="Não / Não Sabe / Não Lembra"
                              />
                            </RadioGroup>
                          )}
                          name="anamnese.sangramento_menopausa"
                          control={control}
                        />
                        <FormHelperText style={{ fontStyle: 'italic' }}>
                          Não considerar o(s) sangramento(s)na vigência de
                          reposição hormonal
                        </FormHelperText>
                        <FormHelperText>
                          {errors.anamnese?.sangramento_menopausa?.message}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Box>
              </Paper>
            </Box>
            <Box sx={{ width: '100%' }}>
              <Paper sx={{ width: '100%' }}>
                <Typography
                  variant="h5"
                  fontWeight="bold"
                  style={{ margin: '25px', paddingTop: '25px' }}
                >
                  Exame Clínico
                </Typography>
                <Divider />
                <Box m={3} pb={3}>
                  <Grid
                    container
                    rowSpacing={3}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  >
                    <Grid item xs={5.8}>
                      <Controller
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            label="Inspeção do Colo *"
                            onChange={onChange}
                            value={value ? value : ''}
                            select
                            fullWidth
                            size="small"
                            variant="outlined"
                            className={styles.textField}
                            error={!!errors.clinico?.inspecao}
                            helperText={errors.clinico?.inspecao?.message}
                          >
                            <MenuItem value={InspecaoColo.Normal}>
                              Normal
                            </MenuItem>
                            <MenuItem value={InspecaoColo.Ausente}>
                              Ausente (anomalias congênitas ou retirado
                              cirurgicamente)
                            </MenuItem>
                            <MenuItem value={InspecaoColo.Alterado}>
                              Alterado
                            </MenuItem>
                            <MenuItem value={InspecaoColo.NaoVisualizado}>
                              Colo Não Visualizado
                            </MenuItem>
                          </TextField>
                        )}
                        name="clinico.inspecao"
                        control={control}
                      />
                    </Grid>
                    <Grid item xs={6.2}>
                      <Alert severity="warning">
                        Na presença de colo alterado, com{' '}
                        <b>lesão sugestiva de câncer</b>, não aguardar o
                        resultado do TESTE HPV para <b>encaminhar</b> a mulher
                        para <b>colposcopia.</b>
                      </Alert>
                    </Grid>
                    <Grid container item xs={12}>
                      <Grid item xs={12}>
                        <FormControl error={errors.clinico?.dst && true}>
                          <FormLabel
                            required
                            sx={styleFormLabel}
                            id="demo-row-radio-buttons-group-label"
                          >
                            Sinais sugestivos de doenças sexualmente
                            transmisssíveis?{' '}
                          </FormLabel>
                          <Controller
                            render={({ field: { onChange, value } }) => (
                              <RadioGroup
                                onChange={onChange}
                                value={value ? value : ''}
                                row
                              >
                                <FormControlLabel
                                  value={true}
                                  control={<Radio sx={styleRadio} />}
                                  label="Sim"
                                />
                                <FormControlLabel
                                  value={false}
                                  control={<Radio sx={styleRadio} />}
                                  label="Não"
                                />
                              </RadioGroup>
                            )}
                            name="clinico.dst"
                            control={control}
                          />
                          <FormHelperText>
                            {errors.clinico?.dst?.message}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sx={{ flexDirection: 'column', display: 'flex' }}
                      >
                        <FormControl
                          error={!!errors.clinico?.coleta_consultorio && true}
                        >
                          <FormLabel sx={styleFormLabel}>
                            Método de Coleta de HPV *
                          </FormLabel>
                          <Controller
                            render={({ field: { onChange, value } }) => (
                              <RadioGroup
                                onChange={(e) => {
                                  if (e.target.value === 'true') {
                                    setValue('clinico.autocoleta', false);
                                  } else {
                                    setValue('clinico.autocoleta', true);
                                  }
                                  onChange(e.target.value);
                                }}
                                value={value ? value : ''}
                                row
                              >
                                <FormControlLabel
                                  value={true}
                                  control={<Radio sx={styleRadio} />}
                                  label="Coleta por Profissional de Saúde"
                                />
                                <FormControlLabel
                                  value={false}
                                  control={<Radio sx={styleRadio} />}
                                  label="Autocoleta"
                                />
                              </RadioGroup>
                            )}
                            name="clinico.coleta_consultorio"
                            control={control}
                          />
                          <FormHelperText>
                            {errors.clinico?.coleta_consultorio?.message}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid
                        item
                        sx={{ display: 'flex', flexDirection: 'row' }}
                        xs={12}
                      >
                        <FormControl
                          error={!!errors.clinico?.dt_coleta && true}
                        >
                          <LocalizationProvider
                            dateAdapter={AdapterDateFns}
                            locale={ptBR}
                          >
                            <div>
                              <Controller
                                control={control}
                                name="clinico.dt_coleta"
                                render={({
                                  field: { onChange, onBlur, value, ref },
                                }) => (
                                  <DatePicker
                                    //disabled={checkedMenstruacao}
                                    onChange={onChange}
                                    value={value}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        style={{ marginTop: '20px' }}
                                        label="Data da coleta *"
                                        size="small"
                                        variant="outlined"
                                        className={styles.textField}
                                        fullWidth
                                      />
                                    )}
                                  />
                                )}
                              />
                              <FormHelperText>
                                {errors.clinico?.dt_coleta?.message}
                              </FormHelperText>
                            </div>
                          </LocalizationProvider>
                        </FormControl>
                        <Controller
                          render={({ field: { onChange, value } }) => (
                            <TextField
                              select
                              fullWidth
                              label="Profissional Responsável pela Coleta *"
                              size="small"
                              variant="outlined"
                              className={styles.textField}
                              error={!!errors.clinico?.responsavelColetaId}
                              helperText={
                                errors.clinico?.responsavelColetaId?.message
                              }
                              value={value ? value : ''}
                              style={{
                                width: '100%',
                                marginTop: '20px',
                                marginLeft: '25px',
                              }}
                              onChange={onChange}
                            >
                              {responsaveis.map(
                                (option: any, index: number) => {
                                  return (
                                    <MenuItem key={index} value={option.id}>
                                      {option.pessoa.nome}
                                    </MenuItem>
                                  );
                                }
                              )}
                            </TextField>
                          )}
                          name="clinico.responsavelColetaId"
                          control={control}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sx={{ flexDirection: 'column', display: 'flex' }}
                    >
                      <Controller
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            select
                            label="Local da Coleta*"
                            size="small"
                            variant="outlined"
                            className={styles.textField}
                            onChange={onChange}
                            error={!!errors.clinico?.local_coleta}
                            helperText={errors.clinico?.local_coleta?.message}
                            value={value ? value : ''}
                          >
                            <MenuItem value={LocalColeta.Residencia}>
                              Residência da mulher
                            </MenuItem>
                            <MenuItem value={LocalColeta.ServicoSaude}>
                              Serviço de Saúde
                            </MenuItem>
                            <MenuItem value={LocalColeta.UnidadeMovel}>
                              Unidade Móvel
                            </MenuItem>
                            <MenuItem value={LocalColeta.Outro}>
                              Outro Local
                            </MenuItem>
                          </TextField>
                        )}
                        name="clinico.local_coleta"
                        control={control}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sx={{ flexDirection: 'column', display: 'flex' }}
                    >
                      {watch().clinico?.local_coleta === LocalColeta.Outro && (
                        <Controller
                          render={({ field: { onChange, value } }) => (
                            <TextField
                              label="Outro Local"
                              size="small"
                              variant="outlined"
                              className={styles.textField}
                              value={value ? value : ''}
                              onChange={onChange}
                            />
                          )}
                          name="clinico.outro_local_coleta"
                          control={control}
                        />
                      )}
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sx={{ flexDirection: 'column', display: 'flex' }}
                    >
                      <Controller
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            fullWidth
                            select
                            label="Método de Coleta de Citologia"
                            size="small"
                            variant="outlined"
                            className={styles.textField}
                            onChange={onChange}
                            value={value ? value : ''}
                            error={!!errors.clinico?.mtd_coleta}
                            helperText={errors.clinico?.mtd_coleta?.message}
                          >
                            <MenuItem value={MtdColeta.Convencional}>
                              Convencional
                            </MenuItem>
                            <MenuItem value={MtdColeta.MeioLiquido}>
                              Meio Líquido
                            </MenuItem>
                          </TextField>
                        )}
                        name="clinico.mtd_coleta"
                        control={control}
                      />
                    </Grid>

                    {/*<Grid item xs={12} sx={{ flexDirection: 'column', display: 'flex' }}>
                      <Grid sx={{ flexDirection: 'row', display: 'flex' }}>



                      </Grid>

                    </Grid>*/}
                  </Grid>
                </Box>
              </Paper>
            </Box>

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                borderRadius: 1,
              }}
              mb={3}
            >
              <Button
                sx={{ marginTop: '10px' }}
                variant="outlined"
                color="error"
                onClick={handleClickOpenDialogCancel}
                startIcon={<ClearIcon />}
              >
                Cancelar
              </Button>

              <Button
                sx={{
                  marginTop: '10px',
                  backgroundColor: '#17bebb',
                }}
                variant="contained"
                type="submit"
                startIcon={<CheckIcon />}
              >
                Cadastrar
              </Button>
            </Box>
          </form>
        </Box>
      </Container>
    </SideMenu>
  );
}
