import { Box, Grid, Paper, Typography } from '@mui/material';
import { AreaChart } from '../../../../components/Charts/areaChart';
import { ChartCard } from '../../../../components/Charts/chartCard';
import { SampleStatus } from '../../../../components/Charts/sampleStatus';
import { TableWithCharts } from '../../../../components/Charts/tableWithCharts';
import { TypesOfViruses } from '../../../../components/Charts/typesOfViruses';
import { WomenOutsideGroup } from '../../../../components/Charts/womenOutsideGroup';
import styles from './styles.module.css';
import { getWindowDimensions } from '../../../../utils/getWindowDimensions';
import { useEffect, useState } from 'react';
import { TableMobile } from '../../../../components/Charts/tableWithCharts/tableMobile';
import FilterIndicadores from '../../../../components/Filters/FilterIndicadores';
import { useForm } from 'react-hook-form';
import { DisplayFilter, ItemsDisplayFilter } from '../../../Convocation/styles';
import api from '../../../../api';
import { DataSource } from '../../../../components/Charts/dataSource';

const totalTestsPerMonth = [500, 490, 470, 420, 519, 602];
const total = totalTestsPerMonth.reduce((prev, curr) => prev + curr, 0);
const categories = ['JAN', 'FEV', 'MAR', 'ABR', 'MAI', 'JUN'];

const sampleStatusData = [450, 1051, 1500];
const typesOfVirusesData = [26, 13, 177, 12, 9, 2186];
// const womenOutsideGroupData = [35, 2916];

export function Indicadores() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  const [listFilter] = useState<any>([]);
  const [isMobile, setIsMobile] = useState(false);

  const { control } = useForm();
  // const [totalTestsPerMonth, setTotalTestsPerMonth] = useState<any>([]);
  // const [categories, setCategories] = useState<any>([]);
  const [womenOutsideGroupData, setwomenOutsideGroupData] = useState<any>([]);
  const [examResultsChartData, setExamResultsChartData] = useState<any>([]);
  const [examCollectionOriginData, setExamCollectionOriginData] = useState<any>(
    []
  );
  const [payload, setPayload] = useState<any>();

  const [detectableAndVirusTypeData, setdetectableAndVirusTypeData] =
    useState<any>([]);
  const [reasonsNotProcedureChartData, setreasonsNotProcedureChartData] =
    useState<any>([]);

  const getTestesPerMonth = async () => {
    const response = await api.get(
      '/dashboard/exames/hpv/panel/quantity-month',
      payload
    );

    // setTotalTestsPerMonth(
    //   response.data?.data.map((item) => Number(item?.count))
    // );
    // setCategories(
    //   response.data?.data.map((item) => String(item?.month)?.toUpperCase())
    // );
  };

  const getAgeRange = async () => {
    const response = await api.get(
      '/dashboard/exames/hpv/panel/faixa',
      payload
    );

    setwomenOutsideGroupData([
      Number(response.data.data[2].count),
      Number(response.data.data[1].count) +
        Number(response.data.data[0].count) +
        Number(response.data.data[3].count),
    ]);
  };

  const getexamCollectionOrigin = async () => {
    const response = await api.get(
      '/dashboard/exames/hpv/panel/natureza',
      payload
    );

    setExamCollectionOriginData([
      Number(response.data.data.autocoleta),
      Number(response.data.data.profissional),
    ]);
  };

  const getExamResultsChart = async () => {
    const response = await api.get(
      '/dashboard/exames/hpv/panel/resultados',
      payload
    );

    setExamResultsChartData([
      Number(response.data.data.NEGATIVO_INDETECTADO),
      Number(response.data.data.POSITIVO),
      Number(response.data.data.INVALIDO),
    ]);
  };

  const getdetectableAndVirusTypeData = async () => {
    const response = await api.get(
      '/dashboard/exames/hpv/panel/resultados-tipos',
      payload
    );

    setdetectableAndVirusTypeData([
      Number(response.data.data.hpv_16),
      Number(response.data.data.hpv_18),
      Number(response.data.data.hpv_outros),
      Number(response.data.data['hpv_16-18-outros']),
      Number(response.data.data.invalido),
      Number(response.data.data.negativo),
    ]);
  };
  const getreasonsNotProcedure = async () => {
    const response = await api.get(
      '/dashboard/exames/hpv/panel/nao-realizados',
      payload
    );

    setreasonsNotProcedureChartData([
      Number(response.data.data.NAO_REALIZOU),
      Number(response.data.data.MENSTRUADA),
      Number(response.data.data.NAO_FAIXA_ETARIA),
      Number(response.data.data.HISTERECTOMIZADA),
      Number(response.data.data.OUTRO),
    ]);
  };

  const getAllCharts = () => {
    // setTotalTestsPerMonth([]);
    // setCategories([]);
    setwomenOutsideGroupData([]);
    setExamResultsChartData([]);
    setExamCollectionOriginData([]);
    // setTotal('');
    setdetectableAndVirusTypeData([]);
    setreasonsNotProcedureChartData([]);

    getTestesPerMonth();
    getAgeRange();
    getexamCollectionOrigin();
    getExamResultsChart();
    getdetectableAndVirusTypeData();
    getreasonsNotProcedure();
  };

  useEffect(() => {
    getAllCharts();
  }, []);

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);

    if (windowDimensions.width < 768) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }

    return () => window.removeEventListener('resize', handleResize);
  }, [windowDimensions]);

  return (
    <Box mt="31px">
      <Box sx={{ marginBottom: '42px' }}>
        <FilterIndicadores control={control} />
      </Box>
      <Box display="flex" flexDirection="column" gap="2px">
        <Typography variant="h5" fontWeight="bold" fontSize="24px">
          Testes de HPV - Indicadores
        </Typography>
        <Typography variant="h6" fontWeight="bold" fontSize="20px">
          Período: 01/09/2023 a 26/01/2024
        </Typography>
        <Typography variant="body1">
          Data de Atualização: 29/01/2024 16:15
        </Typography>

        <DisplayFilter style={{ marginTop: '17px', marginBottom: '37px' }}>
          <p style={{ margin: 0, width: '138px' }}>Filtros aplicados:</p>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              width: '100%',
              gap: '10px',
              flexWrap: 'wrap',
            }}
          >
            {listFilter.map((item) => (
              <>
                {item.value ? (
                  <ItemsDisplayFilter>{item.value}</ItemsDisplayFilter>
                ) : undefined}
              </>
            ))}
          </div>
        </DisplayFilter>
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h5" fontSize="48px" mb={2}>
            Região I
          </Typography>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Paper
            variant="outlined"
            sx={{
              paddingTop: '16px',
              borderRadius: '10px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              borderColor: 'rgba(0, 0, 0, 0.23)',
              height: '461px',
            }}
          >
            <Typography variant="h5" fontWeight="bold" pl="15px">
              Região I<br />
              Total de Amostras Coletadas
            </Typography>
            <Typography
              variant="h5"
              fontWeight="bold"
              pl="15px"
              fontSize="76px"
            >
              {total?.toLocaleString('pt-BR')}
            </Typography>
            <AreaChart
              payload={null}
              data={totalTestsPerMonth}
              categories={categories}
              height={240}
            />
            <span
              style={{
                fontSize: '10px',
                textAlign: 'end',
                // marginTop: '14px',
                marginRight: '16px',
                color: 'rgba(0, 0, 0, 0.6)',
              }}
            >
              Fonte de Dados: JORDANA
            </span>
          </Paper>
        </Grid>
        <Grid item xs={12} lg={6}>
          <ChartCard title="Região I" subtitle="Situação das Amostras">
            <SampleStatus data={sampleStatusData} />
          </ChartCard>
        </Grid>
        <Grid item xs={12} lg={6}>
          <ChartCard title="Região I" subtitle="Tipos de Vírus">
            <TypesOfViruses data={detectableAndVirusTypeData} />
          </ChartCard>
        </Grid>
        <Grid item xs={12} lg={6}>
          <ChartCard title="Região I" subtitle="Mulheres Fora da Faixa Etária">
            <WomenOutsideGroup data={womenOutsideGroupData} />
          </ChartCard>
        </Grid>
      </Grid>

      {/* <Typography variant="h5" fontSize="48px" mb={2}>
        Região I
      </Typography> */}

      {/* <div className={styles.chartsList}>
        <div className={styles.testsDoneCard}>
          <div className={styles.testsDoneCardTitle}>
            <Typography variant="h5" fontWeight="bold">
              Região I<br />
              Total de Amostras Coletadas
            </Typography>

            <Typography variant="h5" fontWeight="bold">
              {total?.toLocaleString('pt-BR')}
            </Typography>
          </div>

          <AreaChart
            data={totalTestsPerMonth}
            categories={categories}
            height={240}
          />
        </div>
      </div> */}

      <Box mt="28px">{isMobile ? <TableMobile /> : <TableWithCharts />}</Box>
    </Box>
  );
}
