export interface StateProps {
  id: number;
}

export interface MtdColeta {
  id: number;
  nome: string;
}

export enum LocalColeta {
  Residencia = 'RESIDENCIA',
  ServicoSaude = 'SERVICO_SAUDE',
  UnidadeMovel = 'UNIDADE_MOVEL',
  Outro = 'OUTRO',
}

export enum InspecaoColo {
  Normal = 'NORMAL',
  Ausente = 'AUSENTE',
  Alterado = 'ALTERADO',
  NaoVisualizado = 'NAO_VISUALIZADO',
}

export enum ExameStatus {
  ResultadoDisponivel = 'RESULTADO_DISPONIVEL',
  ResultadoNaoEntregue = 'RESULTADO_NAO_ENTREGUE',
  Entregue = 'ENTREGUE',
  Recomendado = 'RECOMENDADO',
  Coletado = 'COLETADO',
  EnviadoParaAnalise = 'ENVIADO_PARA_ANALISE',
  EmAnalise = 'EM_ANALISE',
  Disponivel = 'DISPONIVEL',
  NaoEntregue = 'NAO_ENTREGUE',
  Falha = 'FALHA',
}

export enum Escolha {
  Sim = 'SIM',
  Nao = 'NAO',
  NaoSabe = 'NAO_SABE',
  Default = '',
}

export enum Motivo {
  Rastreamento = 'RASTREAMENTO',
  Repeticao = 'REPETICAO',
  Seguimento = 'SEGUIMENTO',
}

export interface AnamneseProps {
  motivo: Motivo;
  papanicolaou: Escolha;
  ano_papanicolaou: number;
  diu: Escolha;
  gravida: Escolha;
  anticoncepcional: Escolha;
  hormonio: Escolha;
  radioterapia: Escolha;
  menstruacao: Escolha;
  dt_menstruacao_desconhecida: boolean;
  dt_menstruacao: string;
  sangramento_relacao: Escolha;
  sangramento_menopausa: Escolha;
}

export interface Clinico {
  inspecao: InspecaoColo;
  dst: boolean;
  dt_coleta: Date;
  responsavelId: number;
  coleta_consultorio: boolean;
  autocoleta: boolean;
  responsavelColetaId: number;
  local_coleta: LocalColeta;
  outro_local_coleta: string;
  mtd_coleta: MtdColeta;
  n_kit: string;
}

export interface Inputs {
  motivo: Motivo;
  papanicolaou: Escolha;
  ano_papanicolaou: number;
  diu: Escolha;
  gravida: Escolha;
  anticoncepcional: Escolha;
  hormonio: Escolha;
  radioterapia: Escolha;
  menstruacao: Escolha;
  dt_menstruacao_desconhecida: boolean;
  dt_menstruacao: string;
  sangramento_relacao: Escolha;
  sangramento_menopausa: Escolha;
}