import { Option } from '../types/Option';

export const caraterBenigno = [
  {
    label: 'Alterações Citoarquiteturais compatíveis com Ação Vital (HPV)',
    value: 4,
  },
  {
    label: 'Cervicite Crônica Inespecífica',
    value: 3,
  },
  {
    label: 'Metaplasia Escamosa',
    value: 1,
  },
  {
    label: 'Pólipo Endocervical',
    value: 2,
  },
];

export const escamosa = [
  { label: 'NIC I (Displasia leve)', value: 1 },
  {
    label: 'NIC II (Displasia moderada)',
    value: 2,
  },
  {
    label: 'NIC III (Displasia acentuada / Carcinoma in situ)',
    value: 3,
  },
  {
    label: 'Carcinoma Epidermóide Microinvasivo',
    value: 4,
  },
  {
    label: 'Carcinoma Epidermóide Invasivo',
    value: 5,
  },
  {
    label: 'Carcinoma Epidermóide impossível avaliar invasão',
    value: 6,
  },
];

export const glandular = [
  { label: 'Adenocarcinoma in situ', value: 1 },
  { label: 'Adenocarcinoma invasor', value: 2 },
];
