/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from 'react';
import { Container, Field } from './styles';

type enviroments = 'LocalHost' | 'Desenvolvimento' | 'Homologação' | 'Produção';

export function EnviromentPopup() {
  const [serverVersion, setServerVersion] = useState('unknow');
  const [env, setEnv] = useState<enviroments>('Produção');
  useEffect(() => {
    const envDiction = process.env.REACT_APP_API_URL as string;
    const showEnv = process.env.REACT_APP_SHOW_ENV;
    if (showEnv === 'false') {
      return;
    }

    if (!envDiction?.includes('digitalcare2u')) {
      setEnv('LocalHost');
    } else if (envDiction?.includes('api.dev.jordana.')) {
      setEnv('Desenvolvimento');
    } else if (envDiction?.includes('api.hom.jordana.')) {
      setEnv('Homologação');
    }
    if (envDiction?.includes('prod')) {
      setEnv('Produção');
    }
  }, [process.env]);

  async function init() {
    const serverRes = await (
      await fetch(process.env.REACT_APP_API_URL + '/')
    ).json();
    setServerVersion(serverRes.version);
  }

  useEffect(() => {
    init();
  }, []);

  if (env === 'Produção') {
    return null;
  }

  return (
    <Container>
      <Field color="#17bebb" title={`Versão: ${serverVersion}`}>
        {env}
      </Field>
    </Container>
  );
}
