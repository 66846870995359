import { useContext } from 'react';
import api from '../../../api';
import { LoadingContext } from '../../../context/LoadingContext';

const useRequestFilter = ({ setDataCentral }) => {
  const { setLoading } = useContext(LoadingContext);
  const loadDataCentralNotification = async (payload) => {
    try {
      const response = await api.get('/notificacao', {
        params: {
          ...payload,
        },
      });
      setDataCentral(response.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  return { loadDataCentralNotification };
};

export default useRequestFilter;
