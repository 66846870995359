import styled from '@emotion/styled';
import { Button, Box } from '@mui/material';
import { responsiveBreakpoints } from '../../styles/breakpoinst';

export const FormContainer = styled(Box)`
  background: #ffff;
  overflow: hidden;
  width: 464px;
  border: 12px;
  margin-top: 60px;
  border-radius: 4px;
  min-height: 500px;
  height: auto;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);

  @media (max-width: ${responsiveBreakpoints.small}) {
    width: 100%;
    margin-top: 60px;
  }

  text-align: center;
  padding: 15px;

  @media (min-width: ${responsiveBreakpoints.small}) {
    padding: 40px;
  }
`;

export const ButtonSelectUnit = styled(Button)({
  height: '62px',
  width: '100%',
  margin: '16px 24px',
  border: '1px solid #238884',
});

export const ContainerOffButtons = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  @media (min-width: ${responsiveBreakpoints.small}) {
    flex-direction: row;
  }
`;

export const RecoverPasswordButton = styled(Button)({
  textTransform: 'none',
  fontSize: '1rem',
});

export const LoginButton = styled(Button)`
  background-color: #238884;
  color: #ffffff;
  width: 100%;
  @media (min-width: ${responsiveBreakpoints.small}) {
    width: 30%;
  }
`;

export const ScreenBackgroundLogin = styled(Box)`
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
  overflow: auto;
  min-height: 950px;
  height: 100%;

  justify-content: flex-start;

  @media (min-width: ${responsiveBreakpoints.small}) {
    min-height: 800px;
    justify-content: center;
  }
`;

export const StateCityContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 24px;
  margin-bottom: 24px;

  @media (min-width: ${responsiveBreakpoints.small}) {
    flex-direction: row;
  }
`;

export const ClearButton = styled(Button)`
  margin-right: 24px;
  font-weight: bold;
  width: 100%;
  margin-bottom: 15px;
  @media only screen and (min-width: ${responsiveBreakpoints.small}) {
    width: 30%;
  }
`;
