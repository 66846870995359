import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

interface ICardProps {
  borderLeftColor: string;
}

export const Card = styled(Box)`
  width: 100%;
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 10px;
  display: flex;
  align-items: center;
  gap: 16px;
  flex-wrap: wrap;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: -1px;
    width: 16px;
    height: 100%;
    border-radius: 10px;
    border-left: ${({ borderLeftColor }: ICardProps) => borderLeftColor ? `8px solid ${borderLeftColor}` : '8px solid rgba(0, 0, 0, 0.38)'};
  }

  .card-date {
    flex: 1;
  }

  .card-type {
    flex: 2;
  }

  .card-status {
    flex: 1;
  }
`;

export const ExpandIconWrapper = styled(Box)`
  cursor: pointer;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  background-color: #238884;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2);
`;