import { PieChart } from '../pieChart';
import { PieChart2 } from '../pieChart2';

interface HistopathologyOriginProps {
  data: number[];
  categories?: String[];
  height?: number;
}

export function HistopathologyOrigin({ data }: HistopathologyOriginProps) {
  return (
    <PieChart2
      data={data}
      height={284}
      labels={[`Aguardando Triagem (${data[0]})`, `Em Análise (${data[1]})`]}
      colors={['#01AEFA', '#0079AD']}
    />
  );
}
