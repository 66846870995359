import AddIcon from '@mui/icons-material/AddOutlined';
import { Box, Button, TextField, Typography } from '@mui/material';
import { useStyles } from './styles';

export interface Period {
  beginningPeriod: string;
  endPeriod: string;
}

export interface Periods {
  seg_hours: Period[];
  ter_hours: Period[];
  qua_hours: Period[];
  qui_hours: Period[];
  sex_hours: Period[];
}

interface PeriodInputProps {
  beginningPeriod: string;
  endPeriod: string;
  onChangeBeginning: (value: string) => void;
  onChangeEnd: (value: string) => void;
  beginningPeriodValidationError?: boolean;
  endPeriodValidationError?: boolean;
}

interface PeriodListProps {
  periods: Periods;
  interval?: number;
  setPeriods: React.Dispatch<React.SetStateAction<Periods>>;
}

type Weekday = 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday';

const PeriodInput = ({
  beginningPeriod,
  endPeriod,
  onChangeBeginning,
  onChangeEnd,
  beginningPeriodValidationError,
  endPeriodValidationError,
}: PeriodInputProps) => {
  return (
    <Box
      width="216px"
      display="flex"
      gap={2}
      sx={{
        '& p': {
          margin: '0',
        },
      }}
    >
      <TextField
        label="Início"
        placeholder="hh:mm"
        value={beginningPeriod}
        onChange={(e) => onChangeBeginning(e.target.value)}
        size="small"
        error={beginningPeriodValidationError}
        helperText={beginningPeriodValidationError ? 'Horario invalido' : ''}
      />

      <TextField
        label="Fim"
        placeholder="hh:mm"
        value={endPeriod}
        onChange={(e) => onChangeEnd(e.target.value)}
        size="small"
        error={endPeriodValidationError}
        helperText={endPeriodValidationError ? 'Horario invalido' : ''}
      />
    </Box>
  );
};

const isAMultipleOf = (value1: string, value2: string, mult) => {
  const [hour1, minutes1] = value1.split(':');
  const [hour2, minutes2] = value2.split(':');

  const totalMinutes1 = Number(hour1) * 60 + Number(minutes1);
  const totalMinutes2 = Number(hour2) * 60 + Number(minutes2);

  if(totalMinutes1 > totalMinutes2)
    return false

  return (totalMinutes2 - totalMinutes1) % Number(mult) === 0;
};

const validateInputValue = (begin: string, end: string, mult=20) => {
  return begin.length > 0 && end?.length > 0 && !isAMultipleOf(begin, end, mult);
};

const handlePeriodHeader = (day: string) => {
  let dayName = 'Segunda';

  switch (day) {
    case 'ter_hours':
      dayName = 'Terça';
      break;
    case 'qua_hours':
      dayName = 'Quarta';
      break;
    case 'qui_hours':
      dayName = 'Quinta';
      break;
    case 'sex_hours':
      dayName = 'Sexta';
      break;
    default:
      dayName = 'Segunda';
      break;
  }

  return dayName;
};

export const PeriodList = ({ periods, interval=20, setPeriods }: PeriodListProps) => {
  const styles = useStyles();

  const handleAddPeriod = (
    dayOfWeek: Weekday,
    beginningPeriod: string,
    endPeriod: string
  ) => {
    setPeriods((prevPeriods) => {
      const lastPeriod = prevPeriods[dayOfWeek];

      if (
        !lastPeriod.length ||
        (
          lastPeriod.length &&
          isAMultipleOf(lastPeriod[lastPeriod.length - 1].beginningPeriod, lastPeriod[lastPeriod.length - 1].endPeriod, interval)
        )
      ) {
        return {
          ...prevPeriods,
          [dayOfWeek]: [
            ...prevPeriods[dayOfWeek],
            {
              beginningPeriod,
              endPeriod,
            },
          ],
        };
      }

      return prevPeriods;
    });
  };

  const handleRemoveLastPeriod = (dayOfWeek: Weekday) => {
    setPeriods((prevPeriods) => {
      const updatedPeriods = { ...prevPeriods };
      updatedPeriods[dayOfWeek].pop();
      return updatedPeriods;
    });
  };

  const formatTimeInput = (value: string) => {
    const formattedValue = value.replace(/[^0-9]/g, ''); // Remove todos os caracteres não numéricos

    if (formattedValue.length === 4 || formattedValue.length === 5) {
      const hour = formattedValue.slice(0, 2);
      const minutes = formattedValue.slice(2, 4);

      return `${hour}:${minutes}`;
    }

    return formattedValue.slice(0, 4);
  };

  return (
    <>
      {Object.keys(periods).map((day, index) => {
        const weekday = day as Weekday;

        return (
          <div key={index}>
            <Box
              key={weekday}
              mt={3}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <p className={styles.label}>{handlePeriodHeader(day)}</p>
              <Box display="flex" gap={2}>
                <Button onClick={() => handleRemoveLastPeriod(weekday)}>
                  Limpar
                </Button>
                <Button
                  onClick={() => {
                    handleAddPeriod(weekday, '', '');
                  }}
                  variant="outlined"
                  startIcon={<AddIcon />}
                >
                  Adicionar período
                </Button>
              </Box>
            </Box>

            <Box display="flex" flexWrap="wrap" gap={2} mt={2}>
              {periods[weekday].map((period, index) => {
                const currentPeriod = periods[weekday][index];
                return (
                  <PeriodInput
                    key={index}
                    beginningPeriod={period.beginningPeriod}
                    endPeriod={period.endPeriod}
                    onChangeBeginning={(value) => {
                      setPeriods((prevPeriods) => {
                        const updatedPeriods = { ...prevPeriods };
                        updatedPeriods[weekday][index].beginningPeriod =
                          formatTimeInput(value);
                        return updatedPeriods;
                      });
                    }}
                    onChangeEnd={(value) =>
                      setPeriods((prevPeriods) => {
                        const updatedPeriods = { ...prevPeriods };
                        updatedPeriods[weekday][index].endPeriod =
                          formatTimeInput(value);
                        return updatedPeriods;
                      })
                    }
                    beginningPeriodValidationError={false}
                    endPeriodValidationError={validateInputValue(
                      currentPeriod.beginningPeriod,
                      currentPeriod.endPeriod,
                      interval
                    )}
                  />
                );
              })}

              {!periods[day].length && (
                <Typography variant="body1">Sem atendimento</Typography>
              )}
            </Box>
          </div>
        );
      })}
    </>
  );
};
