import { Box, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import logoJordana from '../../../../assets/imgs/logo.png';
import { ArrowForward } from '@mui/icons-material';
import { FormContainer } from '../TypeNewPassword/styles';

export default function PasswordSuccessChange() {
  let navigate = useNavigate();

  const returnToSigninPage = () => {
    navigate('/');
  };

  return (
    <FormContainer variant="outlined">
      <Box textAlign="center" sx={{ padding: '60px 24px' }}>
        <img src={logoJordana} alt="logo jornada" width="181" />

        <Box my={4}>
          <Typography
            variant="h5"
            fontWeight="bold"
            sx={{ padding: '0 80px', marginBottom: '32px' }}
          >
            Redefinição de senha bem-sucedida
          </Typography>
          <Typography variant="body2" sx={{ color: '#00000099' }}>
            {'Faça login com a sua nova senha para acessar o Jordana'}
          </Typography>
        </Box>

        <Box>
          <Button
            onClick={returnToSigninPage}
            variant="contained"
            endIcon={<ArrowForward />}
          >
            Entrar
          </Button>
        </Box>
      </Box>
    </FormContainer>
  );
}
