import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles({
  header: {
    marginBottom: '24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  link: {
    textDecoration: 'none',
    color: 'rgba(0,0,0,0.6)',
  },
  btnConfig: {
    color: '#ffffff',
    backgroundColor: '#238884',
    borderColor: '#238884',
    '&:hover': {
      backgroundColor: '#238884',
      color: '#ffffff',
      borderColor: '#238884',
    },
  },
  title: {
    display: 'flex',
    flexDirection: 'column',
    gap: '5px',
  },
  tabContainer: {
    backgroundColor: '#ffffff',
    color: '#ffffff',

    '&& .Mui-selected': {
      backgroundColor: '#238884',
      color: '#FFFFFF',
    },
    '& .MuiTabs-indicator': {
      backgroundColor: '#238884',
      color: '#FFFFFF',
    },
    '& button': {
      borderBottom: '2px solid #238884',
      color: '#238884',
      fontWeight: '500',
    },
  },
});
