import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import * as React from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { useStyles } from './ListRequisitions.style';
import TablePatients from '../../components/TablePatients/TablePatients';
import { PeopleContext } from '../../context/PeopleContext';
import { SideMenu } from '../../components/SideMenu';

export default function ListRequisitions() {
  const styles = useStyles();
  const { people } = React.useContext(PeopleContext);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    setLoading(true);
    if (people) {
      setLoading(false);
    }
  }, [people]);

  if (loading) {
    return <h1>Carregando...</h1>;
  } else {
    return (
      <SideMenu>
        <Container>
          <Box mt={12}>
            <Typography
              variant="h4"
              fontWeight="bold"
              style={{ marginBottom: '30px' }}
            >
              Listagem de Requisições
            </Typography>

            <Divider />
            <Box mt={4} mb={4}>
              <Grid container spacing={2}>
                <Grid item>
                  <TextField
                    label="Código do Sistema"
                    size="small"
                    variant="outlined"
                    className={styles.textField}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    label="Nome da mulher"
                    size="small"
                    variant="outlined"
                    className={styles.textField}
                  />
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    startIcon={<SearchIcon />}
                    style={{
                      backgroundColor: '#238884',
                      color: '#FFFFFF',
                    }}
                  >
                    Pesquisar
                  </Button>
                </Grid>
              </Grid>
            </Box>
            <TablePatients {...people} />
          </Box>
        </Container>
      </SideMenu>
    );
  }
}
