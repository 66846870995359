import {
  Box,
  Breadcrumbs,
  Container,
  Divider,
  Typography,
} from '@mui/material';
import { SideMenu } from '../../components/SideMenu';
import ChangePassword from './ChangePassword';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Link } from 'react-router-dom';
import ReturnButton from '../../components/ReturnButton';

const ManageAccount = () => {
  return (
    <SideMenu>
      <Container>
        <Box mt={12}>
          <ReturnButton backPage="/home" />
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
              <Typography variant="h4" fontWeight="bold">
                Gerenciar Conta
              </Typography>
              <Breadcrumbs
                separator={<NavigateNextIcon fontSize="small" />}
                aria-label="breadcrumb"
    style={{ marginBottom: '16px', marginTop: '16px' }}
                sx={{ marginBottom: '24px' }}
              >
                <Link
                  style={{
                    textDecoration: 'none',
                    color: '#646464',
                  }}
                  to="/home"
                >
                  Home
                </Link>
                <Typography key="3" color="text.primary">
                  Gerenciar Conta
                </Typography>
              </Breadcrumbs>
            </div>
          </div>
          <Divider />
          <p
            style={{
              margin: '24px 0',
              fontSize: '12px',
              textAlign: 'right',
              color: '#d32f2f',
            }}
          >
            <i>* Resposta obrigatória</i>
          </p>
          <ChangePassword />
          <Divider />
        </Box>
      </Container>
    </SideMenu>
  );
};

export default ManageAccount;
