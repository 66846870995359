import api from '../api';
import qs from 'qs';

export const createUser = async ({
  cpf,
  email,
  nome_de_usuario,
  perfis
}) => {
  return await api
    .post(
      `/usuarios`,
      {
        cpf,
        email,
        nome_de_usuario,
        perfis
    })
    .then((res) => {
      if (res.status === 201 || res.status === 200) {
        return { ...res, success: true, message: 'Sucesso' };
      }
    })
    .catch((e: any) => {
      return { success: false, message: e?.response?.data.message, ...e };
    });
}

export const inviteUser = async ({
  cpf,
  email,
  nome_de_usuario,
  cargo,
  estabelecimentoSaudeId,
}) => {
  return await api
    .post(
      `/usuarios/invite`,
      qs.stringify({
        cpf,
        email,
        nome_de_usuario,
        cargo,
        estabelecimentoSaudeId,
      })
    )
    .then((res) => {
      if (res.status === 201) {
        return { ...res, success: false, message: 'Sucesso' };
      }
    })
    .catch((e: any) => {
      return { success: false, message: e?.response?.data.message };
    });
};
