import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { useCallback } from 'react';

const ModalRemoveContato = ({
  remove,
  indexRemoveContato,
  setOpenDialogRemoveContato,
  openDialogRemoveContato,
}) => {
  const handleCloseDialogRemoveContato = () => {
    setOpenDialogRemoveContato(false);
  };

  const removeContato = useCallback((index) => {
    remove(index);
  }, []);

  const handleCloseConfirmRemoveContato = () => {
    removeContato(indexRemoveContato);
    setOpenDialogRemoveContato(false);
  };
  return (
    <Dialog
      open={openDialogRemoveContato}
      onClose={handleCloseDialogRemoveContato}
    >
      <DialogTitle>Remover Contato</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Deseja realmente remover este contato ?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDialogRemoveContato}>Não</Button>
        <Button onClick={handleCloseConfirmRemoveContato} autoFocus>
          Sim
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalRemoveContato;
