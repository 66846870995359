import * as React from 'react';
import {
  Box,
  Container,
  Grid,
  Paper,
  TextField,
  Typography,
  Autocomplete,
  Checkbox,
  MenuItem,
  InputProps,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import CheckIcon from '@mui/icons-material/Check';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useParams, Link, useNavigate, useLocation } from 'react-router-dom';
import api from '../../api.js';
import { SideMenu } from '../../components/SideMenu';
import { Label } from '../../components/Typo';
import { StyledAsteriskTextField } from '../../components/StyledAsteriskTextField/styles';
import moment from 'moment';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { ptBR } from 'date-fns/locale';
import FormControl from '@mui/material/FormControl';
import { SelectChangeEvent } from '@mui/material/Select';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { TokenData, User } from '../../components/ModalStatus/index.js';
import jwtDecode from 'jwt-decode';
import { getAccessToken, getUserToken } from '../../lib/auth';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import { schema } from './schema.js';
import DownloadIcon from '@mui/icons-material/Download';
import { toast } from 'react-toastify';

export default function ExamResult() {
  const [patientData, setPatientData] = React.useState<any>([]);
  const [examResultData, setExamResultData] = React.useState<any>();
  const [dataRecebimento, setDataRecebimento] = React.useState<Date | null>(
    null
  );

  const [view, setView] = React.useState(false);

  const [hpv16, setHpv16] = React.useState(false);

  const [hpv18, setHpv18] = React.useState(false);
  const [hpvOutros, setHpvOutros] = React.useState(false);
  const [idPatient, setIdPatient] = React.useState('');
  const [observacao, setObservacao] = React.useState('');
  const [galId, setGalId] = React.useState('');
  const [subHpvResult, setSubHpvResult] = React.useState<any>({});
  const [idResultado, setIdResultado] = React.useState('' as string);
  const [nomeLaboratorio, setNomeLaboratorio] = React.useState('' as string);
  const [user, setUser] = React.useState({} as User);
  const navigate = useNavigate();
  const { id } = useParams();
  const { examId } = useParams();
  const location = useLocation();
  const params = useParams();
  const formatCpf = (cpf: string) =>
    cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1$2$3-$4');
  const {
    control,
    setValue,
    formState: { errors },
    watch,
    clearErrors,
    handleSubmit,
  } = useForm({ resolver: yupResolver(schema) });

  React.useEffect(() => {
    async function loadData(id: any) {
      await api
        .get('/pessoas/' + id)
        .then((response) => {
          setPatientData(response.data);
          setIdPatient(response.data.id);
        })
        .catch((err) => {
          console.error('ops! ocorreu um erro' + err);
        });
    }
    loadData(id);
  }, [id]);
  React.useEffect(() => {
    if (!idPatient) {
      return;
    } else {
      loadHpv(idPatient);
    }

    async function loadHpv(id: any) {
      await api
        .get(`/exames/hpv/${id}`)
        .then((response) => {
          if (response.data) {
            const currentHpv = response.data.find(
              (examHpv) => String(examHpv.id) === params?.examId
            );
            setObservacao(handleObservacao(currentHpv));
            setGalId(currentHpv.resultado?.request_id || 'N/C');
            setIdResultado(currentHpv?.id);
            setSubHpvResult(currentHpv.sub_resultado || {});
          }
        })
        .catch((err) => {
          console.error('ops! ocorreu um erro' + err);
        });
    }
  }, [idPatient]);

  React.useEffect(() => {
    async function loadHpv(id: any) {
      await api
        .get(`/hpv/${id}/resultado`)
        .then((response) => {
          if (response.data) {
            setNomeLaboratorio(
              response.data.estabelecimentoSaude.nome_fantasia
            );
          }
        })
        .catch((err) => {
          console.error('ops! ocorreu um erro' + err);
        });
    }

    if (!idResultado) {
      return;
    } else {
      loadHpv(idResultado);
    }
  }, [idResultado]);

  const optionsResultado = [
    { value: 'INVALIDO', label: 'Inconclusivo' },
    {
      value: 'NEGATIVO_INDETECTADO',
      label: 'Não Detectável',
    },
    {
      value: 'POSITIVO',
      label: 'Detectável',
    },
  ];

  React.useEffect(() => {
    async function loadExamResultData(examId: any) {
      await api
        .get(`/hpv/${examId}/resultado`)
        .then((response) => {
          if (response.data) {
            const valueResultado = optionsResultado.find(
              (item) => item.value === response.data.resultado
            )?.value;

            if (response.data.id) {
              setView(true);
            }

            setValue('resultado', valueResultado);

            setValue('data_recebimento', response.data.data_recebimento);

            setExamResultData(response.data);
            setDataRecebimento(response.data.data_recebimento);

            setHpv16(response.data.hpv_16);
            setHpv18(response.data.hpv_18);
            setHpvOutros(response.data.hpv_outros);
          } else {
            setExamResultData([]);
          }
        })
        .catch((err) => {
          console.error('ops! ocorreu um erro' + err);
        });
    }
    loadExamResultData(examId);
  }, [examId]);

  React.useEffect(() => {
    if (dataRecebimento) {
      clearErrors('data_recebimento');
    }
    setValue('data_recebimento', dataRecebimento);
  }, [dataRecebimento]);

  React.useEffect(() => {
    const token: TokenData = jwtDecode(getAccessToken());
    if (token) {
      setUser(token.user);
    }
  }, [examId]);

  const generatePDF = async () => {
    try {
      const response = await api.get(`/reports/${patientData.id}/${examId}`, {
        responseType: 'arraybuffer',
        headers: {
          Accept: 'application/pdf',
        },
      });

      const blob = new Blob([response.data], { type: 'application/pdf' });
      const link = document.createElement('a');

      link.href = window.URL.createObjectURL(blob);
      link.target = '_blank';

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Erro ao gerar PDF:', error);
    }
  };

  const onSubmit = async (data) => {
    const info = {
      data_recebimento: data.data_recebimento,
      resultado: data.resultado,
      hpv_16: Boolean(hpv16),
      hpv_18: Boolean(hpv18),
      hpv_outros: Boolean(hpvOutros),
    };

    let res = null;
    const response = await api.post(`/hpv/${examId}/resultado`, info);

    if (response.status === 201) {
      navigate(`/pacientes/${id}`);
    } else {
      toast.error('Ocorreu um problema, tente novamente', {
        position: 'bottom-right',
      });
    }
  };

  const styleSelect = {
    '& .MuiFormLabel-asterisk': {
      color: '#d32f2f',
    },
  };

  const handleObservacao = (resultadoHpv) => {
    if (
      !resultadoHpv.atendimento_observacao &&
      !resultadoHpv.resultado?.observacao
    ) {
      return null;
    }
    if (resultadoHpv.resultado?.observacao) {
      return resultadoHpv.resultado?.observacao;
    }
    return resultadoHpv.atendimento_observacao;
  };

  return (
    <SideMenu>
      <Container>
        <Box mt={5} mb={3}>
          <Typography
            variant="h4"
            fontWeight="bold"
            style={{ marginBottom: '5px' }}
            data-testid="rhpv-title"
          >
            Resultado do Teste de HPV
          </Typography>
          <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
            <Link
              style={{
                textDecoration: 'none',
                color: '#646464',
              }}
              to="/pacientes"
            >
              Listagem de Pacientes
            </Link>
            <Link
              style={{
                textDecoration: 'none',
                color: '#646464',
              }}
              to={`/pacientes/${patientData.id}`}
            >
              Ficha Individual da Paciente
            </Link>
            <Typography color="text.primary">Resultado</Typography>
          </Breadcrumbs>
        </Box>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          {location.state.view === 'visualizar' && (
            <Button
              variant="outlined"
              onClick={() => generatePDF()}
              startIcon={<DownloadIcon />}
              data-testid="button-export"
            >
              EXPORTAR
            </Button>
          )}
        </div>

        <p style={{ fontSize: '12px', textAlign: 'right', color: '#d32f2f' }}>
          <i>* Resposta obrigatória</i>
        </p>
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
          <Paper variant="outlined" sx={{ borderRadius: '10px' }}>
            <Box p={4}>
              <Box
                display="flex"
                justifyContent="space-between"
                flexWrap="wrap"
                alignItems="center"
                mb={4}
              >
                <Typography variant="h6" fontWeight="bold">
                  Informações de Identificação
                </Typography>

                <Box>
                  <Typography variant="body2">
                    Número de Requisição do GAL
                  </Typography>
                  <Typography
                    variant="h5"
                    fontWeight="bold"
                    align="center"
                    data-testid="rhpv-gal_number"
                  >
                    {galId}
                  </Typography>
                </Box>
              </Box>
              <Grid container spacing={2}>
                <Grid item md={3}>
                  <Label style={{ marginBottom: '4px' }}>Nome</Label>
                  <Typography variant="body1" data-testid="rhpv-paciente_nome">
                    {patientData.nome}
                  </Typography>
                </Grid>
                <Grid item md={2}>
                  <Label style={{ marginBottom: '4px' }}>CPF</Label>
                  <Typography variant="body1" data-testid="rhpv-paciente_cpf">
                    {patientData.cpf ? formatCpf(patientData.cpf) : ''}
                  </Typography>
                </Grid>

                <Grid item md={3}>
                  <Label style={{ marginBottom: '4px' }}>
                    Nº Cartão do SUS
                  </Label>
                  <Typography variant="body1" data-testid="rhpv-paciente_sus">
                    {patientData.cns}
                  </Typography>
                </Grid>

                <Grid item md={2}>
                  <Label style={{ marginBottom: '4px' }}>
                    Data de Nascimento
                  </Label>
                  <Typography variant="body1" data-testid="rhpv-paciente_nasc">
                    {patientData.dt_nascimento
                      ? moment(patientData.dt_nascimento).format('DD/MM/YYYY')
                      : 'Data não informada'}
                  </Typography>
                </Grid>

                <Grid item md={2}>
                  <Label style={{ marginBottom: '4px' }}>
                    Método de Coleta:
                  </Label>
                  <Typography variant="body1" data-testid="rhpv-mtd_coleta">
                    {examResultData?.autocoleta
                      ? 'Autocoleta'
                      : 'Coleta por Profissional de Saúde'}
                  </Typography>
                </Grid>
              </Grid>

              <Box my={3}>
                <Divider />
              </Box>

              <Grid container spacing={3}>
                <Grid item md={3}>
                  <Controller
                    control={control}
                    name="data_recebimento"
                    render={({ field: { onChange, value } }) => {
                      return (
                        <LocalizationProvider
                          dateAdapter={AdapterDateFns}
                          locale={ptBR}
                        >
                          <DatePicker
                            label="Data do Resultado"
                            value={dataRecebimento ? dataRecebimento : null}
                            onChange={(newValue) => {
                              onChange(newValue);
                              setDataRecebimento(newValue);
                            }}
                            disabled
                            renderInput={(params) => (
                              <StyledAsteriskTextField
                                {...params}
                                disabled={location.state.view === 'visualizar'}
                                error={!!errors.data_recebimento}
                                value={
                                  dataRecebimento
                                    ? moment(dataRecebimento).format(
                                        'DD/MM/YYYY'
                                      )
                                    : ''
                                }
                                helperText={errors.data_recebimento?.message}
                                inputProps={
                                  {
                                    'data-testid': 'rhpv-data',
                                  } as React.InputHTMLAttributes<HTMLInputElement>
                                }
                                required
                                size="small"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      );
                    }}
                  />
                </Grid>

                <Grid item md={9}>
                  <StyledAsteriskTextField
                    fullWidth
                    required
                    label="Laboratório responsável"
                    inputProps={
                      {
                        'data-testid': 'rhpv-laboratorio',
                      } as React.InputHTMLAttributes<HTMLInputElement>
                    }
                    variant="outlined"
                    value={
                      'LAB CENTRAL DE SAUDE PUB DR MILTON BEZERRA SOBRAL LACEN'
                    }
                    disabled
                    size="small"
                  />
                </Grid>

                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name="resultado"
                    render={({ field: { onChange, value } }) => {
                      return (
                        <>
                          <StyledAsteriskTextField
                            required
                            select
                            error={!!errors.resultado}
                            helperText={errors.resultado?.message}
                            size="small"
                            fullWidth
                            onChange={(e) => {
                              onChange(e.target.value ? e.target.value : '');
                            }}
                            inputProps={
                              {
                                'data-testid': 'rhpv-resultado',
                              } as React.InputHTMLAttributes<HTMLInputElement>
                            }
                            label="Resultado"
                            value={value ? value : ''}
                            disabled={location.state.view === 'visualizar'}
                          >
                            {optionsResultado.map((option) => {
                              return (
                                <MenuItem
                                  key={option.value}
                                  value={option.value}
                                >
                                  {option.label}
                                </MenuItem>
                              );
                            })}
                          </StyledAsteriskTextField>
                        </>
                      );
                    }}
                  />
                </Grid>
              </Grid>

              {(watch('resultado') === 'POSITIVO' ||
                watch('resultado') === 'INCONCLUSIVO') &&
                getUserToken().permissoes?.includes(
                  'VIEW_EXAME_HPV_RESULTADO_POSITIVO'
                ) && (
                  <Box mt={3}>
                    <Typography variant="body1">Detectável para:</Typography>

                    <Grid container spacing={2} mt={3}>
                      <Grid item md={1.5}>
                        <FormControl>
                          <RadioGroup
                            row
                            aria-labelledby="hpv-16"
                            name="row-radio-buttons-group"
                          >
                            <FormControlLabel
                              value="true"
                              control={
                                <Checkbox
                                  checked={hpv16}
                                  onChange={() => setHpv16(!hpv16)}
                                  inputProps={
                                    {
                                      'data-testid': 'rhpv-16',
                                    } as React.InputHTMLAttributes<HTMLInputElement>
                                  }
                                  disabled={
                                    location.state.view === 'visualizar'
                                  }
                                />
                              }
                              label="HPV 16"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>

                      <Grid item md={1.5}>
                        <FormControl>
                          <RadioGroup
                            row
                            aria-labelledby="hpv-18"
                            name="row-radio-buttons-group"
                          >
                            <FormControlLabel
                              value="true"
                              control={
                                <Checkbox
                                  checked={hpv18}
                                  onChange={() => setHpv18(!hpv18)}
                                  inputProps={
                                    {
                                      'data-testid': 'rhpv-18',
                                    } as React.InputHTMLAttributes<HTMLInputElement>
                                  }
                                  disabled={
                                    location.state.view === 'visualizar'
                                  }
                                />
                              }
                              label="HPV 18"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>

                      <Grid item md={6}>
                        <FormControl>
                          <RadioGroup
                            row
                            aria-labelledby="outros"
                            name="row-radio-buttons-group"
                          >
                            <FormControlLabel
                              value="true"
                              control={
                                <Checkbox
                                  onChange={() => setHpvOutros(!hpvOutros)}
                                  inputProps={
                                    {
                                      'data-testid': 'rhpv-outros',
                                    } as React.InputHTMLAttributes<HTMLInputElement>
                                  }
                                  disabled={
                                    location.state.view === 'visualizar'
                                  }
                                  checked={hpvOutros}
                                />
                              }
                              label="Outros (31, 33, 35, 39, 45, 51, 52, 56, 58, 59, 66, e
                          68)"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          multiline
                          rows={4}
                          style={{ marginTop: '28px' }}
                          sx={{ width: '100%' }}
                          value={
                            observacao
                              ? observacao
                                  .replace(/<br>/g, '\n')
                                  .replace(/<\/?b>/g, '')
                              : ''
                          }
                          disabled={location.state.view === 'visualizar'}
                          inputProps={
                            {
                              'data-testid': 'rhpv-observacoes',
                            } as React.InputHTMLAttributes<HTMLInputElement>
                          }
                          label="Observação adicional"
                        />
                      </Grid>
                    </Grid>
                  </Box>
                )}
            </Box>
          </Paper>
          {subHpvResult?.id !== undefined && (
            <Paper
              variant="outlined"
              sx={{ borderRadius: '10px', marginTop: '24px' }}
            >
              <Box p={4}>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  flexWrap="wrap"
                  alignItems="center"
                  mb={4}
                >
                  <Typography variant="h6" fontWeight="bold">
                    Resultado Anterior
                  </Typography>

                  <Box>
                    <Typography variant="body2">
                      Número de Requisição do GAL
                    </Typography>
                    <Typography
                      variant="h5"
                      fontWeight="bold"
                      align="center"
                      data-testid="sub-rhpv-gal_number"
                    >
                      {subHpvResult.request_id}
                    </Typography>
                  </Box>
                </Box>

                <Box my={3}>
                  <Divider />
                </Box>

                <Grid container spacing={3}>
                  <Grid item md={3}>
                    <Controller
                      control={control}
                      name="sub_data_recebimento"
                      render={({ field: { onChange, value } }) => {
                        return (
                          <LocalizationProvider
                            dateAdapter={AdapterDateFns}
                            locale={ptBR}
                          >
                            <DatePicker
                              label="Data do Resultado"
                              value={
                                subHpvResult.data_recebimento
                                  ? subHpvResult.data_recebimento
                                  : null
                              }
                              onChange={(newValue) => {
                                onChange(newValue);
                                setDataRecebimento(newValue);
                              }}
                              disabled
                              renderInput={(params) => (
                                <StyledAsteriskTextField
                                  {...params}
                                  disabled={true}
                                  error={!!errors.sub_data_recebimento}
                                  value={
                                    subHpvResult.data_recebimento
                                      ? moment(
                                          subHpvResult.data_recebimento
                                        ).format('DD/MM/YYYY')
                                      : ''
                                  }
                                  helperText={
                                    errors.sub_data_recebimento?.message
                                  }
                                  inputProps={
                                    {
                                      'data-testid': 'sub-rhpv-data',
                                    } as React.InputHTMLAttributes<HTMLInputElement>
                                  }
                                  required
                                  size="small"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                />
                              )}
                            />
                          </LocalizationProvider>
                        );
                      }}
                    />
                  </Grid>

                  <Grid item md={9}>
                    <StyledAsteriskTextField
                      fullWidth
                      required
                      label="Laboratório responsável"
                      inputProps={
                        {
                          'data-testid': 'sub-rhpv-laboratorio',
                        } as React.InputHTMLAttributes<HTMLInputElement>
                      }
                      variant="outlined"
                      value={
                        'LAB CENTRAL DE SAUDE PUB DR MILTON BEZERRA SOBRAL LACEN'
                      }
                      disabled
                      size="small"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Controller
                      control={control}
                      name="resultado"
                      render={({ field: { onChange, value } }) => {
                        return (
                          <>
                            <StyledAsteriskTextField
                              required
                              select
                              error={!!errors.resultado}
                              helperText={errors.resultado?.message}
                              size="small"
                              fullWidth
                              onChange={(e) => {
                                onChange(e.target.value ? e.target.value : '');
                              }}
                              inputProps={
                                {
                                  'data-testid': 'sub-rhpv-resultado',
                                } as React.InputHTMLAttributes<HTMLInputElement>
                              }
                              label="Resultado"
                              value={
                                subHpvResult.resultado
                                  ? subHpvResult.resultado
                                  : ''
                              }
                              disabled
                            >
                              {optionsResultado.map((option) => {
                                return (
                                  <MenuItem
                                    key={option.value}
                                    value={option.value}
                                  >
                                    {option.label}
                                  </MenuItem>
                                );
                              })}
                            </StyledAsteriskTextField>
                          </>
                        );
                      }}
                    />
                  </Grid>
                </Grid>

                {(watch('resultado') === 'POSITIVO' || observacao) &&
                  getUserToken().permissoes?.includes(
                    'VIEW_EXAME_HPV_RESULTADO_POSITIVO'
                  ) && (
                    <Box mt={3}>
                      <Grid container spacing={1} mt={1}>
                        <Grid item xs={12}>
                          <TextField
                            multiline
                            rows={4}
                            style={{ marginTop: '28px' }}
                            sx={{ width: '100%' }}
                            value={
                              subHpvResult.observacao
                                ? subHpvResult.observacao
                                    .replace(/<br>/g, '\n')
                                    .replace(/<\/?b>/g, '')
                                : ''
                            }
                            disabled
                            inputProps={
                              {
                                'data-testid': 'sub-rhpv-observacoes',
                              } as React.InputHTMLAttributes<HTMLInputElement>
                            }
                            label="Observação adicional"
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  )}
              </Box>
            </Paper>
          )}

          <Box display="flex" justifyContent="space-between" mt={3}>
            {view ? (
              <Button
                sx={{
                  backgroundColor: '#17BEBB',
                }}
                variant="contained"
                type="submit"
                onClick={() => navigate(`/pacientes/${id}`)}
                data-testid="button-return"
              >
                Voltar
              </Button>
            ) : (
              <Button
                sx={{
                  backgroundColor: '#17BEBB',
                }}
                variant="contained"
                type="submit"
                startIcon={<CheckIcon />}
              >
                Cadastrar
              </Button>
            )}

            {view ? undefined : (
              <Button
                variant="outlined"
                color="error"
                onClick={() => navigate(`/pacientes/${id}`)}
                startIcon={<ClearIcon />}
              >
                Cancelar
              </Button>
            )}
          </Box>
        </form>
      </Container>
    </SideMenu>
  );
}
