import { useContext } from 'react';
import { EstablishmentContext } from '../../../../../context/EstablishmentContext';

const useClearValues = ({
  permissao,
  setValue,
  handleSetFilter,
  watch,
  loadReportsCitologiaReflexo,

  setEstadosSelected,
  setMunicipiosSelected,
  setEstabelecimentosSelected,
  showTableData,
  setShowTableData,
  setTableData,
  setErrorsFields,
  setListFilterShow,
  setDataColetaIni,
  setDataColetaFinal,
  setSelectedResultado,
  setSelectedOptions,
  setPayload,
  setSelectedLaboratorio,
  setSelectedOptionsLaboratorio,
}) => {
  const handleSetFilterToShow = (newValue: any, inputName: string) => {
    setListFilterShow((prev) =>
      prev.map((item) =>
        item.inputName === inputName ? { ...item, value: newValue } : item
      )
    );
  };

  const clearValues = async () => {
    setErrorsFields([]);
    setTableData([]);

    setDataColetaIni(null);
    setDataColetaFinal(null);
    setDataColetaFinal(null);
    setDataColetaIni(null);
    setSelectedResultado([]);
    setValue('profissionalResultadoId', null);
    setSelectedOptions([]);

    const fieldsToClearWhenIsPadrao: any[] = [
      'resultado',
      'profissionalLaudo',
      'data_ini',
      'data_fin',
      'search',
    ];

    const fieldsToClearWhenSuperAdmin: any[] = [
      ...fieldsToClearWhenIsPadrao,
      'estado',
      'municipio',
      'estabelecimento_saude',
    ];

    const fieldsToClearWhenIsMunicipal: any[] = [
      ...fieldsToClearWhenIsPadrao,

      'estabelecimento_saude',
    ];

    const fieldsToClearWhenEstadual = [
      'municipio',
      ...fieldsToClearWhenIsMunicipal,
    ];

    if (permissao === 'isMunicipal') {
      fieldsToClearWhenIsMunicipal.forEach((field) => {
        setValue(field, '');
        handleSetFilter('', field);
        handleSetFilterToShow('', field);
      });

      setEstabelecimentosSelected([]);
      setSelectedLaboratorio([]);
      setSelectedOptionsLaboratorio([]);
      await setPayload().then((payload) => {
        try {
          loadReportsCitologiaReflexo({
            estado: payload.estado,
            municipioId: payload.municipioId,

            size: payload.size,
            page: payload.page,
          });
        } catch (error) {}
      });
    }

    if (permissao === 'isPadrao') {
      fieldsToClearWhenIsPadrao.forEach((field) => {
        setValue(field, '');
        handleSetFilter('', field);
        handleSetFilterToShow('', field);
      });
      setShowTableData(true);
      await setPayload().then((payload) => {
        try {
          loadReportsCitologiaReflexo({
            estado: payload.estado,
            municipioId: payload.municipioId,
            estabelecimentoSaudeId: payload.estabelecimentoSaudeId,
            laboratorioLaudoId: payload.laboratorioLaudoId,
            size: payload.size,
            page: payload.page,
          });
        } catch (error) {}
      });
    }

    if (permissao === 'isSuperAdmin') {
      fieldsToClearWhenSuperAdmin.forEach((field) => {
        setValue(field, '');
        handleSetFilter('', field);
        handleSetFilterToShow('', field);
      });
      setSelectedLaboratorio([]);
      setSelectedOptionsLaboratorio([]);
      setShowTableData(false);
      setEstabelecimentosSelected([]);
      setMunicipiosSelected([]);
      setEstadosSelected([]);

      // const payload = {
      //   size: watch().size,
      //   page: watch().page,
      // };

      // if (showTableData) {
      //   loadReportsHpv(payload);
      // }
    }

    if (permissao === 'isEstadual') {
      fieldsToClearWhenEstadual.forEach((field) => {
        setValue(field, '');
        handleSetFilter('', field);
        handleSetFilterToShow('', field);
      });
      setShowTableData(false);
      setSelectedLaboratorio([]);
      setSelectedOptionsLaboratorio([]);
      setEstabelecimentosSelected([]);
      setMunicipiosSelected([]);

      // const payload = {
      //   size: watch().size,
      //   page: watch().page,
      // };

      // if (showTableData) {
      //   loadReportsHpv(payload);
      // }
    }
  };

  return { clearValues };
};

export default useClearValues;
