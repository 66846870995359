import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { PerfilContext } from '../../context/PerfilContext';
import { useContext } from 'react';
import DescriptionIcon from '@mui/icons-material/Description';
import { getUserToken } from '../../lib/auth';
import { formatTestId } from '../../utils/formatters';

const ButtonResults = ({
  data,
  hasResult,
  disableButtonResultado,
  index = -1,
  examResult,
  showButtonResultadoCitologia,
  loadingButtonResultadoHpv,
}) => {
  const { hasPermission } = useContext(PerfilContext);
  const navigate = useNavigate();

  return (
    <>
      {!!data?.citologia && (
        <>
          {showButtonResultadoCitologia &&
            ((hasResult && hasPermission('VIEW_EXAME_CITOLOGIA_RESULTADO')) ||
              (!hasResult &&
                hasPermission('CREATE_EXAME_CITOLOGIA_RESULTADO'))) && (
              <>
                <Button
                  size="small"
                  variant="outlined"
                  data-testid={formatTestId(
                    `button-${index}-`,
                    hasResult ? 'VER RESULTADO' : 'CADASTRAR RESULTADO'
                  )}
                  disabled={
                    disableButtonResultado ||
                    !hasPermission('VIEW_EXAME_CITOLOGIA_RESULTADO')
                  }
                  onClick={() => {
                    navigate(`/cadastrar-resultado`, {
                      state: {
                        resultData: hasResult ? hasResult : undefined,
                        examData: data,
                        url: window.location.pathname,
                      },
                    });
                  }}
                  startIcon={<DescriptionIcon />}
                >
                  {hasResult ? 'VER RESULTADO' : 'CADASTRAR RESULTADO'}
                </Button>
              </>
            )}

          {!showButtonResultadoCitologia && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: '-7px',
              }}
            >
              <span
                className="loader"
                style={{ width: '30px', height: '30px' }}
              ></span>
            </div>
          )}
        </>
      )}

      {data?.hpv && (
        <>
          {loadingButtonResultadoHpv ? (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: '-7px',
              }}
            >
              <span
                className="loader"
                style={{ width: '30px', height: '30px' }}
              ></span>
            </div>
          ) : (
            <>
              {examResult && (
                <>
                  {(data?.hpv?.status === 'RESULTADO_DISPONIVEL' ||
                    data?.hpv?.clinico ||
                    data?.status === 'DISPONIVEL') && (
                    <Button
                      size="small"
                      variant="outlined"
                      sx={{
                        display: examResult
                          ? hasPermission('VIEW_EXAME_HPV') ||
                            hasPermission(
                              'VIEW_EXAME_HPV_RESULTADO_POSITIVO'
                            ) ||
                            hasPermission('VIEW_EXAME_HPV_RESULTADO_NEGATIVO')
                            ? 'flex'
                            : 'none'
                          : hasPermission(
                              'CREATE_EXAME_HPV_RESULTADO_POSITIVO'
                            ) ||
                            hasPermission('CREATE_EXAME_HPV_RESULTADO_NEGATIVO')
                          ? 'flex'
                          : 'none',
                      }}
                      data-testid={formatTestId(
                        `button-${index}-`,
                        examResult ? 'Ver Resultado' : 'Cadastrar Resultado'
                      )}
                      disabled={
                        examResult
                          ? !hasPermission('VIEW_EXAME_HPV') ||
                            !hasPermission(
                              'VIEW_EXAME_HPV_RESULTADO_POSITIVO'
                            ) ||
                            !hasPermission('VIEW_EXAME_HPV_RESULTADO_NEGATIVO')
                          : !hasPermission(
                              'CREATE_EXAME_HPV_RESULTADO_POSITIVO'
                            ) ||
                            !hasPermission(
                              'CREATE_EXAME_HPV_RESULTADO_NEGATIVO'
                            )
                      }
                      onClick={() =>
                        navigate(`result/${data?.hpv?.id}`, {
                          state: {
                            view: examResult ? 'visualizar' : 'cadastrar',
                          },
                        })
                      }
                      startIcon={<DescriptionIcon />}
                    >
                      {examResult ? 'Ver Resultado' : 'Cadastrar Resultado'}
                    </Button>
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default ButtonResults;
