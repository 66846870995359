import { styled } from '@mui/material/styles';
import { Box, BoxProps } from '@mui/material';

interface ListProps extends BoxProps {
  borderLeftColor?: string;
  group?: boolean;
}

export const List = styled(Box)<ListProps>`
  padding-left: 40px;
  position: relative;

  ::before {
    content: '';
    position: absolute;
    width: 16px;
    left: 0px;
    top: ${({ group }) => (group ? '0' : '0px')};
    bottom: 0%;
    background: ${({ borderLeftColor }) =>
      borderLeftColor ? borderLeftColor : '#299b9b'};
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  .card-wrap::before {
    content: '';
    position: absolute;
    width: 16px;
    left: 0px;
    top: ${({ group }) => (group ? '0' : '0px')};
    bottom: -8px;
    background: ${({ borderLeftColor }) =>
      borderLeftColor ? borderLeftColor : '#299b9b'};
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
`;

export const BorderLeft = styled(Box)`
  width: 100%;
  background-color: transparent;
  position: absolute;
  width: 16px;
  left: 0px;
  top: 0;
  bottom: 0;
  z-index: 3;
`;
