//import { Filter } from './../../context/SampleContext';
import * as Yup from 'yup';

export const schema = Yup.object({
  cpf: Yup.string().required('Campo Obrigatório'),
  // cpf: Yup.string(),
  email: Yup.string().email('E-mail inválido').required('Campo Obrigatório'),
  // email: Yup.string().email('E-mail inválido'),
  nome_de_usuario: Yup.string().required('Campo Obrigatório'),
  // estabelecimento_funcao: Yup.object().required('Campo Obrigatório'),
  nome_completo: Yup.string().required('Campo Obrigatório'),

  perfis: Yup.array().of(
    Yup.object().shape({
      id: Yup.number(),
      descricao: Yup.string(),
      estabelecimento_saude: Yup.object().shape({ 
        id: Yup.number(),
      }),
      status: Yup.number(),
      permissao: Yup.object().shape({ 
        id: Yup.number(),
      }),
    })
  ),
});
