import logoJordana from '../../../../assets/imgs/logo.png';

import { Box, Typography } from '@mui/material';

import {
  FormContainer,
  SendEmailButton,
  CancelButton,
  BoxInputButton,
} from './style';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { schema } from './schema';
import { ArrowForward } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import api from '../../../../api.js';
import { toast } from 'react-toastify';
import { useState } from 'react';
import MaskedInput, { MaskedInputProps } from 'react-text-mask';
import { StyledAsteriskTextField } from '../../../../components/StyledAsteriskTextField/styles';

const TypeEmail = ({ setPageCurrent, setMyEmail }) => {
  const {
    handleSubmit,
    formState: { errors },
    setError,
    control,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [loading, setLoading] = useState(false);

  const onSubmit = async (data) => {
    if (!data.cpf || data.cpf === '') {
      setError('cpf', { type: 'manual', message: 'Insira um CPF!' });
      return;
    }
    setLoading(true);

    data.cpf = data.cpf.replace(/[.-]/g, '');

    await api
      .post('/usuarios/forgotPass', {
        cpf: data.cpf,
      })
      .then((res) => {
        if (!res.data.success)
          return toast.error('Erro no servidor de Email, contacte o suporte!');
        setPageCurrent('sended_email');
        setMyEmail(res.data.email || '');
      })
      .catch((err) => {
        if (err?.request?.status === 400)
          return toast.error('Erro: Usuário com este CPF não encontrado!');
        toast.error('Erro no servidor de Email, contacte o suporte!');
      });
    setLoading(false);
  };

  let navigate = useNavigate();

  const returnToSigninPage = () => {
    navigate('/');
  };

  return (
    <FormContainer variant="outlined">
      <Box py={7.5} px={3} textAlign="center">
        <img src={logoJordana} alt="logo jornada" width="181" />

        <Box my={4}>
          <Typography variant="h5" fontWeight="bold">
            Esqueceu sua senha?
          </Typography>
          <Typography variant="body2" sx={{ color: '#00000099' }}>
            {
              'Insira o seu CPF usado no cadastro para receber um link de redefinição de senha.'
            }
          </Typography>
        </Box>

        <Box>
          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <BoxInputButton>
              <Controller
                control={control}
                name="cpf"
                render={({ field: { onChange, value } }) => (
                  <StyledAsteriskTextField
                    onChange={(e) => {
                      const cpf = e.target.value.replace(/_/g, ''); // Remover os "_"
                      onChange(cpf);
                    }}
                    value={value ? value : ''}
                    label="Digite seu CPF"
                    variant="outlined"
                    // className={styles.textField}
                    error={!!errors.cpf}
                    helperText={errors.cpf?.message}
                    sx={{ width: '100%' }}
                    inputMode="numeric"
                    InputProps={{
                      autoComplete: 'off',
                      inputComponent: MaskedInput as any,
                      inputProps: {
                        mask: [
                          /\d/,
                          /\d/,
                          /\d/,
                          '.',
                          /\d/,
                          /\d/,
                          /\d/,
                          '.',
                          /\d/,
                          /\d/,
                          /\d/,
                          '-',
                          /\d/,
                          /\d/,
                        ],
                      } as MaskedInputProps,
                    }}
                  />
                )}
              />
              <SendEmailButton
                type="submit"
                variant="contained"
                disabled={loading}
                endIcon={<ArrowForward />}
                fullWidth
              >
                Enviar
              </SendEmailButton>
              <CancelButton onClick={returnToSigninPage}>CANCELAR</CancelButton>
            </BoxInputButton>
          </form>
        </Box>
        <p style={{ color: '#00000099', fontWeight: '500' }}>
          Se ainda precisar de ajuda, contate o suporte por{' '}
          <p style={{ color: '#238884', margin: '0' }}>
            suporte@digitalcare2u.com.br
          </p>
        </p>
      </Box>
    </FormContainer>
  );
};

export default TypeEmail;
