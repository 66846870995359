import {
  Box,
  Breadcrumbs,
  Container,
  Divider,
  Pagination,
  Paper,
  TablePagination,
  Typography,
} from '@mui/material';
import { SideMenu } from '../../components/SideMenu';
import ReturnButton from '../../components/ReturnButton';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Link } from 'react-router-dom';
import FiltersCentralNotification from './FiltersCentralNotification';
import { useContext, useEffect, useState } from 'react';
import CardCentralNotification from '../../components/CardCentralNotificiation';
import api from '../../api';
import { makeStyles } from '@material-ui/styles';
import { useForm } from 'react-hook-form';
import TableCentralNotification from '../../components/TableCentralNotification';
import { LoadingContext } from '../../context/LoadingContext';
import useSetPayload from './hooks/useSetPayload';
import useRequestFilter from './hooks/useRequestFilter';
import NotificationModal from '../../components/NotificationModal';
import { AlertListContext } from '../../context/AlertListContext';
import { FiltersCentralNotificationContext } from '../../context/FiltersCentralNotificationContext';

const CentralNotification = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const {
    initialStateListFilter,
    dataNotificacaoFinal,
    dataNotificacaoIni,
    periodDtNotificacao,
    selectedOptionsStatus,
    alertId,
    listFilter,
    listFilterShow,
    selectedOptionsNotificationType,
    selectedOptionsStatusLidas,
    setDataNotificacaoFinal,
    setDataNotificacaoIni,
    setPeriodDtNotificacao,
    setSelectedOptionsStatus,
    setAlertId,
    setListFilter,
    setListFilterShow,
    setSelectedOptionsNotificationType,
    setSelectedOptionsStatusLidas,
  } = useContext(FiltersCentralNotificationContext);

  const { dataCentral, setDataCentral } = useContext(AlertListContext);

  const { control, watch, setValue, handleSubmit } = useForm({
    defaultValues: {
      page: 1,
      size: 25,
    },
  });

  const useStyles = makeStyles({
    root: {
      overflowX: 'auto',
    },
    pagination: {
      position: 'sticky',
      bottom: 0,
      backgroundColor: '#fff',
      zIndex: 999,
    },
  });

  const classes = useStyles();

  const { setLoading } = useContext(LoadingContext);
  const { reloadAlertList } = useContext(AlertListContext);

  const { setPayload } = useSetPayload({
    watch,
    dataNotificacaoFinal,
    dataNotificacaoIni,
    selectedOptionsStatus,
    selectedOptionsNotificationType,
    selectedOptionsStatusLidas,
  });
  const { loadDataCentralNotification } = useRequestFilter({
    setDataCentral,
  });

  const handleSizeChange = (e) => {
    setLoading(true);
    setValue('size', e.target.value);
    setPayload().then((payload) => loadDataCentralNotification(payload));
  };

  const handlePageChange = (e, newPage) => {
    setLoading(true);
    setDataCentral({});
    setValue('page', newPage + 1);
    setPayload().then((payload) => loadDataCentralNotification(payload));
  };

  useEffect(() => {
    if (!modalIsOpen) {
      setPayload().then((payload) => loadDataCentralNotification(payload));
      reloadAlertList();
    }
  }, [modalIsOpen]);

  return (
    <SideMenu>
      <Container style={{ minHeight: '100vh' }}>
        <Box mt={12}>
          <NotificationModal
            isOpen={modalIsOpen}
            closeModal={setModalIsOpen}
            alertId={alertId}
            data={dataCentral}
            fromCentral
          />
          <ReturnButton backPage="/home" />

          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
              <Typography variant="h4" fontWeight="bold">
                Central de Notificação
              </Typography>
              <Breadcrumbs
                separator={<NavigateNextIcon fontSize="small" />}
                aria-label="breadcrumb"
                style={{ marginBottom: '16px', marginTop: '16px' }}
                sx={{ marginBottom: '24px' }}
              >
                <Link
                  style={{
                    textDecoration: 'none',
                    color: '#646464',
                  }}
                  to="/home"
                >
                  Home
                </Link>

                <Typography key="3" color="text.primary">
                  Central de Notificação
                </Typography>
              </Breadcrumbs>
            </div>
          </div>
        </Box>

        <FiltersCentralNotification
          setListFilterShow={setListFilterShow}
          listFilter={listFilter}
          setListFilter={setListFilter}
          listFilterShow={listFilterShow}
          initialStateListFilter={initialStateListFilter}
          handleSubmit={handleSubmit}
          setDataCentral={setDataCentral}
          control={control}
          watch={watch}
          dataNotificacaoFinal={dataNotificacaoFinal}
          setDataNotificacaoFinal={setDataNotificacaoFinal}
          dataNotificacaoIni={dataNotificacaoIni}
          setDataNotificacaoIni={setDataNotificacaoIni}
          periodDtNotificacao={periodDtNotificacao}
          setPeriodDtNotificacao={setPeriodDtNotificacao}
          selectedOptionsStatus={selectedOptionsStatus}
          setSelectedOptionsStatus={setSelectedOptionsStatus}
          selectedOptionsNotificationType={selectedOptionsNotificationType}
          setSelectedOptionsNotificationType={
            setSelectedOptionsNotificationType
          }
          selectedOptionsStatusLidas={selectedOptionsStatusLidas}
          setSelectedOptionsStatusLidas={setSelectedOptionsStatusLidas}
        />

        <TableCentralNotification
          setModalIsOpen={setModalIsOpen}
          setAlertId={setAlertId}
          data={dataCentral}
          setData={setDataCentral}
          setValue={setValue}
          watch={watch}
          handlePageChange={handlePageChange}
          handleSizeChange={handleSizeChange}
        />
      </Container>
    </SideMenu>
  );
};

export default CentralNotification;
