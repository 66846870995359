import { Box, Divider, Grid, Typography } from '@mui/material';
import { textCapitalize } from '../../../utils/formatters';
import { BootstrapTooltip } from '../styles';
import { Star } from '@mui/icons-material';
import WhatsIcon from '../../../assets/imgs/whatsSvg.svg';

const ContactData = ({ contactData }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
       data-testid={`contact-box`}
    >
      <Grid container>
        <Grid item xs={12} sm={3} className="table-header">
          <Typography sx={{ fontSize: '14px', fontWeight: 700 }} data-testid={`contact-tipo`}>
            Tipo
          </Typography>
        </Grid>

        <Grid item xs={12} sm={4} className="table-header">
          <Typography sx={{ fontSize: '14px', fontWeight: 700 }} data-testid={`contact-contato`}>
            Contato
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4} className="table-header">
          <Typography sx={{ fontSize: '14px', fontWeight: 700 }} data-testid={`contact-observacoes`}>
            Observações Adicionais
          </Typography>
        </Grid>
        <Grid item xs={12} sm={1} className="table-header"></Grid>
      </Grid>

      <Grid container>
        {contactData.data?.contatos?.map((contato: any, idx) => {
          return (
            <div style={{ width: '100%' }} key={contato.id}>
              <Grid
                container
                my={1}
                sx={{ display: 'flex', alignItems: 'center' }}
              >
                <Grid item xs={12} sm={3} className="contact-type">
                  <Typography
                    sx={{ fontSize: '14px', fontWeight: 700 }}
                    className="header-xs"
                  >
                    Tipo
                  </Typography>
                  <Typography sx={{ fontSize: '14px', fontWeight: 400 }} data-testid={`contact-${idx}-tipo`}>
                    {textCapitalize(contato.forma_contato)}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4} className="contact-description">
                  <Typography
                    sx={{ fontSize: '14px', fontWeight: 700 }}
                    className="header-xs contato-xs"
                  >
                    Contato
                    {contato.is_favorito && (
                      <BootstrapTooltip
                        title="Contato de preferência"
                        className="favorite-icon-sm"
                      >
                        <span className="favorite-icon ">
                          <Star style={{ color: '#238884' }} fontSize="small" />
                        </span>
                      </BootstrapTooltip>
                    )}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: '14px',
                      fontWeight: 400,
                      wordBreak: 'break-word',
                    }}
                    data-testid={`contact-${idx}-contato`}
                  >
                    {contato.descricao}
                    {contato.isWhatsapp &&
                      contato.forma_contato === 'TELEFONE' && (
                        <span
                          style={{
                            fontSize: '12px',
                            color: 'rgba(0,0,0,0.5)',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          Número de Whatsapp{' '}
                          <img
                            src={WhatsIcon}
                            alt="Whats app icon"
                            style={{ marginLeft: '5px' }}
                          />
                        </span>
                      )}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4} className="additional-remarks">
                  <Typography
                    sx={{ fontSize: '14px', fontWeight: 700 }}
                    className="header-xs"
                  >
                    Observações Adicionais
                  </Typography>
                  <Typography sx={{ fontSize: '14px', fontWeight: 400 }}  data-testid={`contact-${idx}-observacoes`}>
                    {contato.observacao}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={1}>
                  {contato.is_favorito && (
                    <BootstrapTooltip
                      title="Contato de preferência"
                      className="favorite-icon-sm"
                    >
                      <span className="favorite-icon " data-testid={`contact-${idx}-favorite`}>
                        <Star style={{ color: '#238884' }} fontSize="small" />
                      </span>
                    </BootstrapTooltip>
                  )}
                </Grid>
              </Grid>
              <Divider sx={{ width: '100%', margin: '16px 0' }} />
            </div>
          );
        })}
      </Grid>
    </Box>
  );
};

export default ContactData;
