import { ChartProps } from '../../../types/Chart';
import { PieChart } from '../pieChart';
import { PieChart2 } from '../pieChart2';

export function WomenOutsideGroup({ data }: ChartProps) {
  return (
    <PieChart2
      data={data}
      height={284}
      labels={[
        `Fora da faixa etária (${data[0]})`,
        `Dentro da faixa etária (${data[1]})`,
      ]}
      colors={['#F4284E', '#D9E4E5']}
    />
  );
}
