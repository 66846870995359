import { makeStyles, styled } from '@material-ui/styles';

export const useStyles = makeStyles({
  container: {
    minHeight: '60px',
    border: '1px solid var(--action-disabled, rgba(0, 0, 0, 0.26))',
    background: '#fff',
    padding: '20px 16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
});

export const ContainerButtonsModal = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
  '@media (min-width: 449px)': {
    flexDirection: 'row',
  },
});
