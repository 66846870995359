import { TableCell, Typography } from '@mui/material';

const RowsAddressData = ({ row }) => {
  return (
    <>
      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {row?.paciente_endereco_cep}
        </Typography>
      </TableCell>
      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {row?.paciente_endereco_numero}
        </Typography>
      </TableCell>
      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {row?.paciente_endereco_logradouro}
        </Typography>
      </TableCell>
      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {row?.paciente_endereco_complemento}
        </Typography>
      </TableCell>
      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {row?.paciente_endereco_bairro}
        </Typography>
      </TableCell>
      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {row?.paciente_endereco_ponto_de_referencia}
        </Typography>
      </TableCell>
      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {row?.paciente_endereco_municipio_nome}
        </Typography>
      </TableCell>
      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {row?.paciente_endereco_estado_sigla}
        </Typography>
      </TableCell>
      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {row?.paciente_estabelecimento_vinculado}
        </Typography>
      </TableCell>
    </>
  );
};

export default RowsAddressData;
