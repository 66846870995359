import api from '../api';

interface getConvocationsParams {
  getParams: {
    profissionalId?: { id: string | number };
    nasc_min?: string;
    nasc_max?: string;
    ultimo_Procedimento?: string;
    n_convocacao?: string;
    status?: { value: number };
    nome?: string;
    page?: string;
    size?: string;
  };
}
export async function getConvocations({ getParams }: getConvocationsParams) {
  return await api
    .get('/convocacao', {
      params: {
        profissionalId: getParams.profissionalId?.id,
        nasc_min: getParams.nasc_min,
        nasc_max: getParams.nasc_max,
        ultimo_Procedimento: getParams.ultimo_Procedimento,
        n_convocacao: getParams.n_convocacao,
        status: getParams.status?.value,
        nome: getParams.nome,
        page: getParams.page,
        size: getParams.size,
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((e: any) => {
      return { success: false, error: e.response.data };
    });
}
