import { createContext, useState } from 'react';

interface EstablishmentContextValue {
  establishments: any[];
  setEstablishments: (data: any) => void;
  currentEstablishment: any;
  setCurrentEstablishment: (data) => void;
}

export const EstablishmentContext = createContext<EstablishmentContextValue>({
  establishments: [],
  setEstablishments: () => {
    return;
  },
  currentEstablishment: {
    name: '',
    cnes: '',
    city: '',
    uf: '',
    estabelecimentoId: '',
  },
  setCurrentEstablishment: (data) => {
    return;
  },
});

function EstablishmentProvider({ children }) {
  const [establishments, setEstablishments] = useState<any[]>([]);
  const [currentEstablishment, setCurrentEstablishment] = useState<any>();

  return (
    <EstablishmentContext.Provider
      value={{
        currentEstablishment,
        setEstablishments,
        setCurrentEstablishment,
        establishments,
      }}
    >
      {children}
    </EstablishmentContext.Provider>
  );
}

export default EstablishmentProvider;
