import { toast } from 'react-toastify';
import api from '../api';

export const getEstados = async () => {
  try {
    const response = await api.get('/estados');

    if (response.status === 200) {
      return response.data;
    }
  } catch (error: any) {
    toast.error(error.message, { position: 'bottom-right' });
  }
};
