import { ApexOptions } from 'apexcharts';
import ReactApexChart from 'react-apexcharts';

export interface ChartProps {
  chartData: ApexOptions;
  width?: number;
  height?: number;
  type?:
    | 'line'
    | 'area'
    | 'bar'
    | 'pie'
    | 'donut'
    | 'radialBar'
    | 'scatter'
    | 'bubble'
    | 'heatmap'
    | 'candlestick'
    | 'boxPlot'
    | 'radar'
    | 'polarArea'
    | 'rangeBar'
    | 'rangeArea'
    | 'treemap';
}

export function PieChart({ chartData, height, type = 'pie' }: ChartProps) {
  return (
    <ReactApexChart
      options={chartData}
      series={chartData.series}
      type={type}
      height={height ?? 350}
    />
  );
}
