import { TableCell, Typography } from '@mui/material';
import moment from 'moment';
import { valuesLocalColeta } from '../../../variablesRowsValues';
import CollapseColumn from '../../../../../CollapseColumn';
import { useState } from 'react';
import { makeStyles } from '@material-ui/styles';

const RowsClinico = ({ row }) => {
  const [openedRow, setOpenedRow] = useState<any>();

  const useStyles = makeStyles({
    table: {
      minWidth: 750,
      border: 'none',
      '& td': {
        borderBottom: 'none',
      },
    },
    icon: {
      fontSize: 20,
    },
  });

  const classes = useStyles();

  const rowAnestesia = [
    {
      value: !!row.anestesia_utilizada.find((anst) => anst === 'Local'),
      anestesia: {
        id: 4,
        nome: 'Local',
      },
    },
    {
      value: !!row.anestesia_utilizada.find((anst) => anst === 'Raqui'),
      anestesia: {
        id: 1,
        nome: 'Raqui',
      },
    },
    {
      value: !!row.anestesia_utilizada.find((anst) => anst === 'Sedação'),
      anestesia: {
        id: 2,
        nome: 'Sedação',
      },
    },
    {
      value: !!row.anestesia_utilizada.find((anst) => anst === 'Outras'),
      anestesia: {
        id: 3,
        nome: 'Outras',
      },
    },
  ];
  const objAnestesia = {
    Local: {
      value: rowAnestesia?.[0]?.value,
      label: 'Local',
    },
    Raqui: {
      value: rowAnestesia?.[1]?.value,
      label: 'Raqui',
    },
    Sedação: {
      value: rowAnestesia?.[2]?.value,
      label: 'Sedação',
    },
    Outras: {
      value: rowAnestesia?.[3]?.value,
      label: 'Outras',
    },
  };

  const anestesiaList = Object.keys(objAnestesia);

  return (
    <>
      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {row?.data_realizado}
        </Typography>
      </TableCell>

      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {row?.servico_de_saude_ezt}
        </Typography>
      </TableCell>

      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {row?.indicacao_ezt}
        </Typography>
      </TableCell>

      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {row?.local_realizacao_procedimento}
        </Typography>
      </TableCell>

      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {row?.tipo_exerese_da_ezt}
        </Typography>
      </TableCell>
      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {
            <CollapseColumn
              arrayItens={anestesiaList}
              classes={classes}
              objItens={objAnestesia}
              setStateOpenedRow={setOpenedRow}
              stateOpenRow={openedRow}
              valueToOpen="anestesia"
            />
          }
        </Typography>
      </TableCell>
      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {row?.clinico?.dt_coleta
            ? moment(row.clinico.dt_coleta).format('DD/MM/YYYY')
            : ''}
        </Typography>
      </TableCell>
      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {row?.tipo_de_procedimento_realizado}
        </Typography>
      </TableCell>
      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {row?.profissional_responsavel_resultado}
        </Typography>
      </TableCell>
      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {row?.profissional_responsavel_resultado_cbo}
        </Typography>
      </TableCell>
      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {row?.profissional_responsavel_resultado_cns}
        </Typography>
      </TableCell>
      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {row?.profissional_responsavel_resultado_ine}
        </Typography>
      </TableCell>
    </>
  );
};

export default RowsClinico;
