import './ItemGraphicStyles.scss';

const ItemGraphic = ({ label, value1, value2, disableGraphicsOnHover }) => {
  const total = value1 + value2;
  const percent1 = (value1 / total) * 100;
  const percent2 = (value2 / total) * 100;

  return (
    <div className="itemGraphic">
      <p>{label}</p>
      <div className="itemGraphic__boxGraphic" style={{ width: 354 }}>
        <div
          className={`itemGraphic__boxGraphic__chartColorized `}
          style={{
            backgroundColor: '#7b61ff',
            width: `${Math.round(percent1)}%`,
            opacity: disableGraphicsOnHover === 2 ? '0.5' : '',
            transition: '0.1s',
          }}
        >
          {value1}
        </div>

        <div
          className={`itemGraphic__boxGraphic__chartColorized `}
          style={{
            backgroundColor: '#ed6c02',
            width: `${Math.round(percent2)}%`,
            opacity: disableGraphicsOnHover === 1 ? '0.5' : '',
            transition: '0.1s',
          }}
        >
          {value2}
        </div>
      </div>
    </div>
  );
};

export default ItemGraphic;
