import {
  Autocomplete,
  Box,
  Divider,
  Grid,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import { Controller } from 'react-hook-form';
import MaskedInput, { MaskedInputProps } from 'react-text-mask';
import { StyledAsteriskTextField } from '../../../../components/StyledAsteriskTextField/styles';
import { useStyles } from '../../RegisterPatient.style';
import { useRequests } from '../../hooks/useRequests';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';

const ResidentialInformation = ({
  errors,
  control,
  setValue,
  id,
  estados,
  ufValue,
  setUfValue,
}) => {
  const [cidades, setCidades] = useState<Array<any>>([]);

  const styles = useStyles();
  const { getCidades } = useRequests({ setValue });

  const location = useLocation();
  useEffect(() => {
    if (id) {
      getCidades(location.state.endereco?.municipio?.estado?.sigla, setCidades);
    }
  }, []);
  return (
    <Box sx={{ width: '100%', marginTop: '24px' }}>
      <Paper
        elevation={0}
        sx={{
          width: '100%',
          border: `1px solid RGBA(0, 0, 0, 0.23)`,
          borderRadius: '10px',
        }}
      >
        <Typography
          variant="h6"
          fontWeight="bold"
          style={{ margin: '25px', paddingTop: '25px' }}
        >
          Dados Residenciais
        </Typography>
        <Divider variant="middle" sx={{ width: '96%', margin: '0 auto' }} />
        <Box m={3} pb={3} sx={{ flexDirection: 'column' }}>
          <Grid
            container
            rowSpacing={3}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={12} md={3}>
              <Controller
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    label="CEP"
                    size="small"
                    onChange={onChange}
                    value={value}
                    variant="outlined"
                    InputLabelProps={{
                      shrink: value ? true : false,
                    }}
                    className={styles.textField}
                    error={!!errors.endereco?.cep}
                    helperText={errors.endereco?.cep?.message}
                    InputProps={{
                      autoComplete: 'off',
                      inputComponent: MaskedInput as any,
                      inputProps: {
                        mask: [
                          /\d/,
                          /\d/,
                          /\d/,
                          /\d/,
                          /\d/,
                          '-',
                          /\d/,
                          /\d/,
                          /\d/,
                        ],
                      } as MaskedInputProps,
                    }}
                  />
                )}
                name="endereco.cep"
                control={control}
              />
            </Grid>
            <Grid item xs={12} md={9}>
              <Controller
                render={({ field: { onChange, value } }) => (
                  <TextField
                    label="Logradouro"
                    fullWidth
                    size="small"
                    variant="outlined"
                    onChange={onChange}
                    value={value ? value : ''}
                    error={!!errors.endereco?.logradouro}
                    helperText={errors.endereco?.logradouro?.message}
                    className={styles.textField}
                  />
                )}
                name="endereco.logradouro"
                control={control}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Controller
                render={({ field: { onChange, value } }) => (
                  <TextField
                    label="Número"
                    fullWidth
                    size="small"
                    variant="outlined"
                    className={styles.textField}
                    onChange={onChange}
                    value={value ? value : ''}
                    error={!!errors.endereco?.numero}
                    helperText={errors.endereco?.numero?.message}
                  />
                )}
                name="endereco.numero"
                control={control}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    label="Complemento"
                    size="small"
                    variant="outlined"
                    className={styles.textField}
                    onChange={onChange}
                    value={value ? value : ''}
                    error={!!errors.endereco?.complemento}
                    helperText={errors.endereco?.complemento?.message}
                  />
                )}
                name="endereco.complemento"
                control={control}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Controller
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    label="Bairro"
                    size="small"
                    variant="outlined"
                    className={styles.textField}
                    onChange={onChange}
                    value={value ? value : ''}
                  />
                )}
                name="endereco.bairro"
                control={control}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    label="Ponto de Referência"
                    size="small"
                    variant="outlined"
                    className={styles.textField}
                    onChange={onChange}
                    value={value ? value : ''}
                    error={!!errors.endereco?.referencia}
                    helperText={errors.endereco?.referencia?.message}
                  />
                )}
                name="endereco.referencia"
                control={control}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                render={({ field: { onChange, value } }) => {
                  return (
                    <Autocomplete
                      options={estados}
                      value={value}
                      getOptionLabel={(option) => option?.sigla}
                      onChange={(e, newValue) => {
                        setValue('endereco.municipio.estado', newValue);
                        onChange(newValue);
                        getCidades(newValue?.sigla, setCidades);
                        setUfValue(newValue);
                      }}
                      defaultValue={
                        location?.state?.endereco?.municipio?.estado
                      }
                      renderInput={(params) => {
                        return (
                          <StyledAsteriskTextField
                            {...params}
                            required
                            label="UF"
                            fullWidth
                            size="small"
                            variant="outlined"
                            className={styles.textField}
                            error={!!errors.endereco?.municipio?.estado?.id}
                            helperText={
                              errors.endereco?.municipio?.estado?.id?.message
                            }
                          />
                        );
                      }}
                    />
                  );
                }}
                name="endereco.municipio.estado"
                control={control}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    options={cidades}
                    onChange={(e, newValue) => {
                      onChange({ ...newValue, estado: ufValue });
                    }}
                    defaultValue={location?.state?.endereco?.municipio}
                    getOptionLabel={(option) => {
                      return option?.nome;
                    }}
                    renderInput={(params) => {
                      return (
                        <StyledAsteriskTextField
                          {...params}
                          fullWidth
                          label="Município"
                          size="small"
                          variant="outlined"
                          required
                          className={styles.textField}
                          value={value}
                          error={!!errors.endereco?.municipio?.id}
                          helperText={errors.endereco?.municipio?.id?.message}
                        />
                      );
                    }}
                  />
                )}
                name="endereco.municipio"
                control={control}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                render={({ field: { onChange, value } }) => (
                  <TextField
                    defaultValue=""
                    label="Zona"
                    select
                    fullWidth
                    size="small"
                    variant="outlined"
                    className={styles.textField}
                    onChange={onChange}
                    value={value ? value : ''}
                    error={!!errors.endereco?.zona}
                    helperText={errors.endereco?.zona?.message}
                  >
                    <MenuItem value="Zona Rural">Zona Rural</MenuItem>
                    <MenuItem value="Zona Urbana">Zona Urbana</MenuItem>
                  </TextField>
                )}
                name="endereco.zona"
                control={control}
              />
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Box>
  );
};

export default ResidentialInformation;
