const PluginPolicyPrivacy = () => {
  return (
    <>
      <h2 style={{ textAlign: 'center' }}>Pol&iacute;tica de Privacidade</h2>
      <p>&nbsp;</p>
      <p style={{ textAlign: 'center' }}>
        A extens&atilde;o do Jordana n&atilde;o faz nenhuma coleta de
        informa&ccedil;&atilde;o do usu&aacute;rio, pois a &uacute;nica
        fun&ccedil;&atilde;o &eacute; inserir um &iacute;cone para facilitar o
        acesso ao{' '}
        <a
          href="https://jordana.digitalcare2u.com.br/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Sistema Jordana
        </a>
        .
      </p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>
        <a
          href="https://www.digitalcare2u.com.br/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            style={{
              display: 'block',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
            src="https://jordana.digitalcare2u.com.br/dc2u.png"
            alt=""
            width="266"
            height="110"
          />
        </a>
      </p>
      <p>&nbsp;</p>
    </>
  );
};

export default PluginPolicyPrivacy;
