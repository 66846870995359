import styled from '@emotion/styled';

export const Container = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 1500;
`;

export const Field = styled.div`
  padding: 3px 6px;
  border-radius: 0 12px 0 0;
  font-size: 12px;
  font-weight: bold;
  user-select: none;

  background-color: ${({color}) => color};
  color: white;
  transform: translateY(0);
  transition: all .8s;
  opacity: .9;

  &:hover {
    opacity: .5;
    cursor: pointer;
  }
  &.hidden {
    transform: translateY(28px);
  }
`

