import {
  Autocomplete,
  Box,
  Button,
  Modal,
  TextField,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/CloseRounded';
import EventAvailableOutlinedIcon from '@mui/icons-material/EventAvailableOutlined';
import CheckIcon from '@mui/icons-material/Check';

import { Label } from '../../../../../components/Typo';
import { Link } from 'react-router-dom';
import { useContext, useState } from 'react';
import { useStyles } from '../styles';
import api from '../../../../../api';
import { EstablishmentContext } from '../../../../../context/EstablishmentContext';
import { toast } from 'react-toastify';

const RealizeSchedule = ({
  open,
  appointmentTime,
  appointmentDate,
  patientName,
  profissionalName,
  handleClose,
  profissionalId,
  examName,
  optionsPatients,
  listTimes,
}) => {
  const [patient, setPatient] = useState<any>(patientName);
  const [time, setTime] = useState(appointmentTime);

  const styles = useStyles();
  const handleSetPatient = (patient) => setPatient(patient);
  const handleSetTime = (time: any) => {
    setTime(time.hour);
  };

  const { currentEstablishment } = useContext(EstablishmentContext);

  const numeroFormatado =
    currentEstablishment.endereco.numero === 'S/N'
      ? 'S/N'
      : currentEstablishment.endereco.numero.padStart(2, '0');
  const cepFormatado =
    currentEstablishment.endereco.cep.slice(0, 5) +
    '-' +
    currentEstablishment.endereco.cep.slice(5);

  const enderecoCompleto =
    currentEstablishment.endereco.logradouro +
    ', ' +
    numeroFormatado +
    ' - ' +
    currentEstablishment.endereco.bairro +
    ', ' +
    currentEstablishment.endereco.municipio.nome +
    ' - ' +
    currentEstablishment.endereco.municipio.estado.sigla +
    ', ' +
    cepFormatado;

  const handleSubmitSchedule = async () => {
    await api
      .get(`/pacientes/${patient?.id}`)
      .then(async (res) => {
        const response = await api.post('/cidadao_agenda/agendamento', {
          id_paciente: patient.id,
          nome: patient.nome,
          cns: res.data.cns,
          cpf: res.data.cpf,
          dt_nascimento: res.data.dt_nascimento,
          nacionalidade: res.data.nacionalidade ?? '',
          estabelecimentoId: currentEstablishment.id,
          nome_fantasia: currentEstablishment.nome_fantasia,
          endereco_local: enderecoCompleto,
          procedimento: 'Teste de HPV',
          isagendamento: true,
          nomeProfissional: profissionalName,
          dt_agendamento: appointmentDate,
          idProfissional: profissionalId,
          horario_atendimento: time,
          status: 'Agendado',
          motivo_cancelamento: '',
          motivo_outro: '',
        });
        if (response.status === 201) {
          handleClose();

          setTimeout(() => {
            window.location.reload();
          }, 2000);

          toast.success('Agendamento realizado com Sucesso.', {
            position: 'bottom-right',
          });
        } else {
          toast.success(
            'Ocorreu um erro. Tente novamente ou contate o suporte.',
            {
              position: 'bottom-right',
            }
          );
        }
      })
      .catch((error) => {
        toast.success(
          'Ocorreu um erro. Tente novamente ou contate o suporte.',
          {
            position: 'bottom-right',
          }
        );
      });
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className={styles.container}>
          <div className={styles.content}>
            <div className={styles.header}>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                className={styles.h6}
                fontWeight="700"
              >
                Agendamento
              </Typography>

              <button className={styles.closeIcon} onClick={handleClose}>
                <CloseIcon fontSize="small" />
              </button>
            </div>

            <div className={styles.body}>
              <Typography
                variant="h6"
                component="h2"
                className={styles.h6}
                fontWeight="700"
              >
                Dados da convocação
              </Typography>

              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '8px',
                }}
                sx={{ mt: 2 }}
              >
                <EventAvailableOutlinedIcon sx={{ color: 'rgba(0,0,0,0.8)' }} />
                <Typography variant="body1">{appointmentDate || ''}</Typography>
              </Box>

              <Label labelcolor="rgba(0,0,0,0.87)" sx={{ mt: 2, mb: 0.5 }}>
                Local do Procedimento
              </Label>
              <Typography variant="body1">
                {currentEstablishment?.nome_fantasia || ''}
              </Typography>

              <Label labelcolor="rgba(0,0,0,0.87)" sx={{ mt: 2, mb: 0.5 }}>
                Endereço
              </Label>
              <Typography variant="body1">{enderecoCompleto || ''}</Typography>

              <Label labelcolor="rgba(0,0,0,0.87)" sx={{ mt: 2, mb: 0.5 }}>
                Profissional
              </Label>
              <Typography variant="body1">{profissionalName || ''}</Typography>

              <Label labelcolor="rgba(0,0,0,0.87)" sx={{ mt: 2, mb: 0.5 }}>
                Procedimento
              </Label>
              <Typography variant="body1">{examName || ''}</Typography>

              <Box mt={2}>
                <Autocomplete
                  options={optionsPatients}
                  onChange={(_, value) => handleSetPatient(value)}
                  getOptionLabel={(option: any) => {
                    return option?.nome;
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Paciente"
                      size="small"
                      placeholder="Busque nome ou cpf do paciente"
                    />
                  )}
                />
              </Box>

              <Label variant="body1" mt={2} labelcolor="rgba(0, 0, 0, 0.87)">
                Paciente ainda não cadastrada?{' '}
                <Link
                  to="/cadastrar-paciente"
                  className={styles.newPatientLink}
                >
                  Cadastrar Novo Paciente
                </Link>
              </Label>

              <Box mt={2}>
                <Autocomplete
                  options={listTimes}
                  onChange={(_, value) => handleSetTime(value ?? '')}
                  value={time}
                  renderOption={(props, option) => (
                    <li {...props}>{option.hour}</li>
                  )}
                  isOptionEqualToValue={(option, value) => option === value}
                  renderInput={(params) => (
                    <TextField {...params} label="Horário" size="small" />
                  )}
                />
              </Box>
            </div>

            <div className={styles.footer}>
              <Button
                variant="contained"
                endIcon={<CheckIcon />}
                onClick={() => {
                  handleSubmitSchedule();
                }}
              >
                Agendar
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default RealizeSchedule;
