import { AgendaHeader, ContentContainer, Label, Value } from './styles';

import { Typography, Grid } from '@mui/material';

const UnfulfilledSchedule = () => {
  return (
    <>
      <AgendaHeader>
        <Typography
          sx={{ fontSize: '20px', color: 'white', fontWeight: '700' }}
        >
          Agendamento
        </Typography>
      </AgendaHeader>
      <ContentContainer>
        <Grid
          container
          spacing={1}
          sx={{
            '@media (min-width: 965px)': {
              boxShadow: '0px 0px 14px -8px black',
              width: '350px',
              padding: '25px',
            },
          }}
        >
          <Grid
            item
            xs={12}
            style={{ display: 'flex', alignItems: 'center', gap: '6px' }}
          >
            <Typography fontWeight="bold" fontSize="20px" color={'#3b873e'}>
              Agenda Não Realizada
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Label>Nome</Label>
            <Value>Valdirene da Silva Nascimento</Value>
          </Grid>
          <Grid item xs={6}>
            <Label>Nº do Cartão do SUS</Label>
            <Value>...4567891234...</Value>
          </Grid>
          <Grid item xs={6}>
            <Label>CPF</Label>
            <Value>....519.916-..</Value>
          </Grid>
          <Grid item xs={12}>
            <Label>Ação</Label>
            <Value style={{ fontWeight: 'bold' }}>NÃO COMPARECIMENTO</Value>
          </Grid>
          <Grid item xs={12}>
            <Label>Motivo</Label>
            <Value style={{ fontWeight: 'bold' }}>Outro</Value>
          </Grid>
          <Grid item xs={12}>
            <Label>Local do Procedimento</Label>
            <Value style={{ fontWeight: '400' }}>
              Upinha Dom Helder Câmara
            </Value>
          </Grid>
          <Grid item xs={12}>
            <Label>Endereço</Label>
            <Value>
              R. Grijalva Costa, 09 - Vasco da Gama, Recife - PE, 52081-265
            </Value>
          </Grid>
          <Grid item xs={12}>
            <Label>Procedimento</Label>
            <Value>Exame Citopatológico</Value>
          </Grid>
          <Grid item xs={12}>
            <Label>Profissional</Label>
            <Value>Marcia Cavalcante Batista</Value>
          </Grid>
        </Grid>
      </ContentContainer>
    </>
  );
};

export default UnfulfilledSchedule;
