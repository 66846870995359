import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { getUserToken } from '../../../lib/auth';
import { useNavigate } from 'react-router-dom';
const ChartTotalTests = ({
  categoriesTesteChart,
  dataEstados,
  subtitleGraphic,
  loadingChart,
  payload,
}) => {
  const [seriesState, setSeriesState] = useState<any>([
    {
      name: 'Mulheres elegíveis identificadas no banco de dados que não realizaram Teste de HPV',
      data: [123, 123, 123, 123],
      field: 'need_hpv',
    },
    {
      name: 'Mulheres elegíveis identificadas no banco de dados que já realizaram Teste de HPV',
      data: [123, 123, 123, 123],
      field: 'has_hpv',
    },
    {
      name: 'Mulheres rastreadas por outros meios que já realizarem o Teste de HPV ',
      data: [123, 123, 123, 123],
      field: 'other_hpv',
    },
  ]);

  const series = [
    {
      name: 'Mulheres elegíveis identificadas no banco de dados que não realizaram Teste de HPV ',
      data: [],
      field: 'need_hpv',
    },
    {
      name: 'Mulheres elegíveis identificadas no banco de dados que já realizaram Teste de HPV',
      data: [],
      field: 'has_hpv',
    },
    {
      name: 'Mulheres elegíveis não identificadas no banco de dados que já realizaram Teste de HPV   ',
      data: [],
      field: 'other_hpv',
    },
  ];

  const navigate = useNavigate();

  const handleBarClick = (event, chartContext, config) => {
    const clickedSeriesIndex = config.seriesIndex;
    const clickedCategoryIndex = config.dataPointIndex;
    const clickedCategory = categoriesTesteChart[clickedCategoryIndex];

    if (clickedCategory) {
      navigate('/relatorios/rastreamento-hpv', {
        state: {
          origin: 'tables',
          payload: payload,
          clickedCategory: clickedCategory,
          clickedSeries: series[clickedSeriesIndex]?.field,
        },
      });
    }
  };

  const options = {
    colors: ['#2DC4CC', '#2196F3', '#FFB547'],
    markers: {
      colors: ['#2DC4CC', '#2196F3', '#FFB547'],
    },
    responsive: [
      {
        breakpoint: 900,
        options: {
          legend: {
            offsetY: 50,
            // height: 6,
            position: 'bottom',
            formatter: function (seriesName, opts) {
              return seriesName;
            },
          },
        },
      },
    ],
    chart: {
      autoSelected: 'zoom',
      type: 'bar',
      events: {
        click: handleBarClick,
      },

      zoom: {
        enabled: true,
        type: 'xy',
      },

      stacked: true,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: '20px',
        borderRadius: 5,

        dataLabels: {
          total: {
            enabled: true,
            style: {
              fontSize: '13px',
              fontWeight: 900,
            },
          },
        },
      },
    },
    xaxis: {
      type: 'category',
      categories: categoriesTesteChart,
      tickPlacement: 'on',
      // labels: {
      //   formatter: function (value) {
      //     return '';
      //   },
      // },
    },
    noData: {
      text: loadingChart ? 'Carregando informações...' : 'Não há resultados',
    },
    legend: {
      show: true,
      position: 'top',
      offsetY: 10,
      height: 100,

      formatter: function (seriesName, opts) {
        // Quebra a string na posição do terceiro espaço
        const breakIndex = seriesName.indexOf(
          ' ',
          seriesName.indexOf(' ', seriesName.indexOf(' ') + 1) + 1
        );

        // Se encontrar o terceiro espaço, quebra a linha
        if (breakIndex !== -1) {
          return (
            seriesName.substring(0, breakIndex) +
            '<br/>' +
            seriesName.substring(breakIndex + 1)
          );
        }

        return seriesName;
      },

      floating: false,
    },
    fill: {
      opacity: 1,
    },
  } as ApexOptions;

  useEffect(() => {
    function setDataValues(field) {
      const values: any = [];

      for (const state in dataEstados) {
        values.push(dataEstados[state][field]);
      }

      return values;
    }

    // Set values for each series data
    series.forEach((serie) => {
      serie.data = setDataValues(serie.field);
    });
    setSeriesState(series);
  }, [dataEstados]);

  useEffect(() => {
    if (dataEstados) {
      const element = document.getElementsByTagName('path');
    }
  }, [dataEstados]);

  return (
    <Box
      sx={{
        border: '1px solid rgba(0, 0, 0, 0.23)',
        borderRadius: '12px',
        padding: '16px',
        marginTop: '24px',
        marginBottom: '24px',
      }}
    >
      <Typography variant="h1" style={{ fontSize: '20px', fontWeight: 'bold' }}>
        Total de Mulheres Elegíveis para o Teste de HPV
      </Typography>
      <p style={{ margin: 0, color: 'rgba(0, 0, 0, 0.6)' }}>
        {subtitleGraphic}
      </p>

      <div
        style={{
          width: '100%',
          overflowX: 'auto',
          overflowY: 'hidden',
        }}
      >
        {!!seriesState === true && (
          <Chart
            options={options}
            series={seriesState}
            type="bar"
            // height={600}
          />
        )}
      </div>
    </Box>
  );
};

export default ChartTotalTests;
