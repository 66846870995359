import { Autocomplete, Button, Grid, TextField } from '@mui/material';
import { Controller } from 'react-hook-form';

const FilterIndicadores = ({ control }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={2}>
        <Controller
          name="estado"
          control={control}
          render={({ field: { onChange, value } }) => {
            return (
              <Autocomplete
                options={[]}
                renderInput={(params) => (
                  <TextField {...params} size="small" label="Estado" />
                )}
              />
            );
          }}
        />
      </Grid>
      <Grid item xs={12} lg={2}>
        <Controller
          name="estado"
          control={control}
          render={({ field: { onChange, value } }) => {
            return (
              <Autocomplete
                options={[]}
                renderInput={(params) => (
                  <TextField {...params} size="small" label="Região de Saúde" />
                )}
              />
            );
          }}
        />
      </Grid>
      <Grid item xs={12} lg={3}>
        <Controller
          name="estado"
          control={control}
          render={({ field: { onChange, value } }) => {
            return <TextField size="small" fullWidth label="Período Inicial" />;
          }}
        />
      </Grid>
      <Grid item xs={12} lg={3}>
        <Controller
          name="estado"
          control={control}
          render={({ field: { onChange, value } }) => {
            return <TextField size="small" fullWidth label="Período Final" />;
          }}
        />
      </Grid>{' '}
      <Grid item xs={12} lg={1}>
        <Button variant="contained">Filtrar</Button>
      </Grid>
      <Grid item xs={12} lg={1}>
        <Button variant="outlined" color="error">
          Limpar
        </Button>
      </Grid>
    </Grid>
  );
};

export default FilterIndicadores;
