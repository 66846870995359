import styled from '@emotion/styled';
import { Box, IconButton } from '@mui/material';

export const ContainerEstablishment = styled(Box)`
  display: flex;
  border-radius: 4px;
  padding: 10px 20px;
  max-width: 428px;
  :hover {
    outline: 1.5px solid #fafafa;
  }
`;

export const DropdownButton = styled(IconButton)`
  margin-left: 14px;

  :hover {
    background: none;
  }
`;
