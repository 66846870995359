import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles({
  label: {
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '16px',
    fontWeight: 'bold',
    lineHeight: '150%',
    letterSpacing: '0.15px',
    margin: 0,
  },
});
