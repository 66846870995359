import api from '../../api';

export const handleOpenHpvList = async ({
  idPatient,
  setHpvList,
  setOpenModalHpv,
}) => {
  try {
    const response = await api.get('/pacientes/' + idPatient + '/exames');

    const allProcedimentos = response.data.reduce((procedimentos, year) => {
      const procedimentosDoAno = year.procedimentos.map((procedimento) => {
        return {
          ...procedimento,
          ano: year.ano,
        };
      });

      return [...procedimentos, ...procedimentosDoAno];
    }, []);

    setHpvList(allProcedimentos.filter((item) => item.hpv));
    setOpenModalHpv(true);
  } catch (error) {}
};
