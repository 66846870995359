import moment from 'moment';
import { getCidades } from '../../../../../lib/getCidadesEstabelecimentos';
import { getEstabelecimentos } from '../../../../../lib/getEstabelecimentos';

const useHandleChangesFilters = ({
  setCidades,
  setEstabelecimentos,
  setValue,
  setListFilter,

  estados,
}) => {
  const handleSetFilter = (newValue: any, inputName: string, origin?: any) => {
    setListFilter((prev) => {
      return prev.map((item) =>
        item.inputName === inputName ? { ...item, value: newValue } : item
      );
    });
  };

  const getCidadesEstabelecimentos = async (sigla) => {
    const response = await getCidades(sigla);
    setCidades(response);
  };

  const getEstabelecimento = async ({ municipio }) => {
    const response = await getEstabelecimentos({
      municipio,
    });

    setEstabelecimentos(response.data);
  };

  const handleChangeUf = ({ newValue, onChange }) => {
    onChange(newValue);
    getCidadesEstabelecimentos(
      estados.find((item) => item.nome === newValue).sigla
    );
    handleSetFilter(newValue, 'estado', 'linha 37 useHandleChangesFilters');
  };

  const handleChangeCidade = ({ newValue, onChange }) => {
    onChange(newValue);
    getEstabelecimento({ municipio: newValue });
    handleSetFilter(newValue, 'municipio', 'linha 43 useHandleChangesFilters');
  };

  const handleChangeEstabelecimento = ({ newValue, onChange }) => {
    if (newValue) {
      onChange(newValue);
    } else {
      setValue('estabelecimento_saude', null);
    }

    handleSetFilter(
      newValue?.nome_fantasia,
      'estabelecimento_saude',
      'linha 56 useHandleChangesFilters'
    );
  };

  const handleChangeDatePicker = ({ date, onChange, datePickerName }) => {
    const dataOriginal = moment(date);
    const dataFormatada = dataOriginal.format('DD/MM/YYYY');
    // const dateToPayload = dataOriginal.format('YYYY-MM-DD');

    onChange(date);
    handleSetFilter(
      dataFormatada,
      datePickerName,
      'linha 66 useHandleChangesFilters'
    );
  };

  const handleChangeRealizouAtendimento = (
    newValue,
    setShowMotivoNaoAtendimento,
    onChange
  ) => {
    if (newValue === 'Sim' || !newValue) {
      setShowMotivoNaoAtendimento(false);
      setValue('atendimento_status', 'REALIZADO');
      handleSetFilter('', 'atendimento_status');
      handleSetFilter('Sim', 'realizou_atendimento');
    } else if (newValue === 'Não') {
      setShowMotivoNaoAtendimento(true);
      setValue('atendimento_status', '');
      handleSetFilter('Não', 'realizou_atendimento');
    }
    if (!newValue) {
      setShowMotivoNaoAtendimento(false);
      setValue('atendimento_status', null);
      handleSetFilter('', 'atendimento_status');
      handleSetFilter('', 'realizou_atendimento');
    }
    onChange(newValue);
  };

  return {
    handleChangeUf,
    handleChangeCidade,
    handleChangeEstabelecimento,
    handleSetFilter,
    handleChangeDatePicker,
    handleChangeRealizouAtendimento,
    getCidadesEstabelecimentos,
    getEstabelecimento,
  };
};

export default useHandleChangesFilters;
