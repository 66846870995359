import { useContext } from 'react';
import { LoadingContext } from '../../../../../context/LoadingContext';
import { getReportsIntraepitelial } from '../../../../../lib/Reports/getReportsIntraepitelial';

const useRequestFilter = ({ setTableData }) => {
  const { setLoading } = useContext(LoadingContext);
  const loadReportsIntraepitelial = async (payload) => {
    const response = await getReportsIntraepitelial({
      payload,
    });

    setTableData(response);
    setLoading(false);
  };

  return {
    loadReportsIntraepitelial,
  };
};

export default useRequestFilter;
