export const valuesMotivoAnamnese = {
  RASTREAMENTO: 'Rastreamento',
  REPETICAO: 'Repetição',
  SEGUIMENTO: 'Seguimento',
};

export const valuesBooleanos = {
  SIM: 'Sim',
  NAO: 'Não',
  NAO_SABE: 'Não sabe',
};

export const valuesInspecaoColo = {
  NORMAL: 'Normal',
  AUSENTE: 'Ausente',
  ALTERADO: 'Alterado',
  NAO_VISUALIZADO: 'Não visualizado',
};

export const valuesMetodoColeta = {
  CONVENCIONAL: 'Convencional',
  MEIO_LIQUIDO: 'Meio Líquido',
};

export const valuesMotivoNaoAtendida = {
  NAO_REALIZOU: 'Compareceu mas não realizou o exame',
  MENSTRUADA: 'Estava menstruada',
  NAO_FAIXA_ETARIA: 'Não está na faixa etária',
  HISTERECTOMIZADA: 'Histerectomizada por patologia benigna',
  OUTRO: 'Outro',
};

export const valuesResultado = {
  INVALIDO: 'Inconclusivo',
  NEGATIVO_INDETECTADO: 'Não Detectável',
  POSITIVO: 'Detectável',
};

export const valuesLocalColeta = {
  RESIDENCIA: 'Residência da mulher',
  SERVICO_SAUDE: 'Serviço de Saúde',
  UNIDADE_MOVEL: 'Unidade Móvel',
  OUTRO: 'Outro Local',
};
