import React, { useEffect, useRef, useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import Chart from 'react-apexcharts';
import api from '../../../../api';
import { DisplayFilter, ItemsDisplayFilter } from '../../../Convocation/styles';
import { BarChart } from '../../../../components/Charts/barChart';
import styles from './styles.module.css';
import ItemGraphic from '../components/RealizationProcedurePosNotRealized/ItemGraphic';
import { PieChart2 } from '../components/pieChart2';
import { AreaChart } from '../components/areaChart';
import { getUserToken } from '../../../../lib/auth';
import { useNavigate } from 'react-router-dom';
import { ChartCard } from '../../hpv/components/chartCard';
import { TableResults } from '../components/tableResults';
import FiltersVisaoGeral from '../../hpv/components/filters/FilterVisaoGeral';

interface FilterItem {
  inputName: string;
  value: string;
}

interface ExamResultsData {
  negativo: number[];
  alteracao: number[];
  insatisfatorio: number[];
}

interface AtypicalCellsData {
  escamosa: { count_neoplasica: number; count_lesao: number };
  glandular: { count_neoplasica: number; count_lesao: number };
  indefinida: { count_neoplasica: number; count_lesao: number };
}

interface GlandularAdenomaData {
  label: string;
  count: number;
}

interface SquamousCellAtypiaData {
  tipo: string;
  count: number;
}

interface DataRow {
  label: string;
  hpv_outros_count: number;
  citologia_realizar: number;
  citologia_count: number;
}

interface Payload {
  params: {
    estados?: string;
    periodo_inicio?: string;
    periodo_fim?: string;
    tipo?: string;
    page?: number;
    size?: number;
    [key: string]: any;
  };
}

const initialStateListFilter: FilterItem[] = [
  { inputName: 'estado', value: '' },
  { inputName: 'municipio', value: '' },
  { inputName: 'estabelecimento', value: '' },
  { inputName: 'periodo', value: '' },
];

export function VisaoGeral() {
  const [listFilter, setListFilter] = useState<FilterItem[]>(
    initialStateListFilter
  );
  const [showListFilter, setShowListFilter] = useState<FilterItem[]>([]);
  const [estadosSelected, setEstadosSelected] = useState<any[]>([]);
  const [municipiosSelected, setMunicipiosSelected] = useState<any>([]);
  const [estabelecimentosSelected, setEstabelecimentosSelected] = useState<any>(
    []
  );
  const [totalTestsPerMonth, setTotalTestsPerMonth] = useState<number[]>([]);
  const [categories, setCategories] = useState<string[]>([]);
  const [payload, setPayload] = useState<Payload>({
    params: {
      page: 1,
      estados: 'PE',
      size: 20,
      tipo: 'Reflexo',
    },
  });
  const [tableData, setTableData] = useState<DataRow[]>([]);
  const [totalTableItems, setTotalTableItems] = useState(0);
  const [periodToDisplay, setPeriodToDisplay] = useState('Todos os períodos');
  const [valueInputPeriod, setValueInputPeriod] = useState<any>();
  const [total, setTotal] = useState(0);
  const [disableGraphicsOnHover, setDisableGraphicsOnHover] = useState(0);
  const [examResultsData, setExamResultsData] = useState<ExamResultsData>({
    negativo: [],
    alteracao: [],
    insatisfatorio: [],
  });
  const [examResultsCategories, setExamResultsCategories] = useState<string[]>(
    []
  );
  const [atypicalCells, setAtypicalCells] = useState<AtypicalCellsData>({
    escamosa: { count_neoplasica: 0, count_lesao: 0 },
    glandular: { count_neoplasica: 0, count_lesao: 0 },
    indefinida: { count_neoplasica: 0, count_lesao: 0 },
  });
  const [glandularAdenoma, setGlandularAdenoma] = useState<
    GlandularAdenomaData[]
  >([]);
  const [squamousCellAtypia, setSquamousCellAtypia] = useState<
    SquamousCellAtypiaData[]
  >([]);
  const [filtersData, setFiltersData] = useState<any>();

  const refRealized = useRef<HTMLSpanElement>(null);
  const refNotRealized = useRef<HTMLSpanElement>(null);
  const { control } = useForm();
  const userToken = getUserToken();

  const navigate = useNavigate();

  const isPadrao = userToken.permissao_atual.id === 1;
  const isMunicipal = userToken.permissao_atual.id === 3;
  const isEstadual = userToken.permissao_atual.id === 4;

  useEffect(() => {
    if (isPadrao) {
      setEstadosSelected(filtersData?.estados ? [filtersData?.estados] : []);
      setMunicipiosSelected(
        filtersData?.municipios ? [filtersData?.municipios] : []
      );
      setEstabelecimentosSelected(
        filtersData?.estabelecimentos ? [filtersData?.estabelecimentos] : []
      );
    }

    if (isMunicipal) {
      setEstadosSelected(filtersData?.estados ? [filtersData?.estados] : []);
      setMunicipiosSelected(
        filtersData?.municipios ? [filtersData?.municipios] : []
      );
    }

    if (isEstadual) {
      setEstadosSelected(filtersData?.estados ? [filtersData?.estados] : []);
    }
  }, [filtersData]);

  useEffect(() => {
    setTotal(totalTestsPerMonth.reduce((prev, curr) => prev + curr, 0));
  }, [totalTestsPerMonth]);

  useEffect(() => {
    if (isPadrao) {
      setEstabelecimentosSelected([userToken.estabelecimentoId]);
      getAllCharts({
        cleared: false,
        payload: {
          params: {
            ...payload.params,
            estabelecimentos: userToken.estabelecimentoId,
          },
        },
      });
    } else {
      getAllCharts({
        cleared: false,
        payload: {
          params: {
            ...payload.params,
          },
        },
      });
    }
  }, []);

  const handleMouseMove = (event: React.MouseEvent) => {
    const element = document.elementFromPoint(event.clientX, event.clientY);
    setDisableGraphicsOnHover(
      element === refRealized.current
        ? 1
        : element === refNotRealized.current
        ? 2
        : 0
    );
  };

  const calculateSum = (data: number[]) =>
    data.reduce((sum, value) => sum + value, 0);

  const getTestesPerMonth = async (cleared: boolean, payload: Payload) => {
    try {
      const response = await api.get(
        '/dashboard/exames/citologia/panel/quantity-month',
        {
          params: {
            ...payload.params,
            tipo: 'Reflexo',
          },
        }
      );
      const data = response.data?.data || [];
      setTotalTestsPerMonth(data.map((item: any) => parseInt(item.count)));
      setCategories(
        data.map((item: any) => `${item.month}/${item.year.slice(-2)}`)
      );
      setTotal(
        data.reduce((sum: number, item: any) => sum + parseInt(item.count), 0)
      );
    } catch (error) {
      console.error('Error fetching tests per month:', error);
    }
  };

  const getExamResults = async (cleared: boolean, payload: Payload) => {
    try {
      const response = await api.get(
        '/dashboard/exames/citologia/panel/resultados',
        {
          params: {
            ...payload.params,
            tipo: 'Reflexo',
          },
        }
      );
      const data = response.data?.data || [];

      const processedData: ExamResultsData = {
        negativo: [],
        alteracao: [],
        insatisfatorio: [],
      };
      const categories: string[] = [];

      data.forEach((item: any) => {
        processedData.negativo.push(parseInt(item.count_negativo));
        processedData.alteracao.push(parseInt(item.count_alteracao));
        processedData.insatisfatorio.push(parseInt(item.count_insatisfatorio));
        categories.push(`${item.month}/${item.year.slice(-2)}`);
      });

      setExamResultsData(processedData);
      setExamResultsCategories(categories);
    } catch (error) {
      console.error('Error fetching exam results:', error);
    }
  };

  const getAtypicalCells = async (cleared: boolean, payload: Payload) => {
    try {
      const response = await api.get(
        '/dashboard/exames/citologia/panel/celulas-atipicas',
        {
          params: {
            ...payload.params,
            tipo: 'Reflexo',
          },
        }
      );
      if (response.data?.data) {
        setAtypicalCells({
          escamosa: {
            count_neoplasica: parseInt(
              response.data.data.escamosa.count_neoplasica
            ),
            count_lesao: parseInt(response.data.data.escamosa.count_lesao),
          },
          glandular: {
            count_neoplasica: parseInt(
              response.data.data.glandular.count_neoplasica
            ),
            count_lesao: parseInt(response.data.data.glandular.count_lesao),
          },
          indefinida: {
            count_neoplasica: parseInt(
              response.data.data.indefinida.count_neoplasica
            ),
            count_lesao: parseInt(response.data.data.indefinida.count_lesao),
          },
        });
      }
    } catch (error) {
      console.error('Error fetching atypical cells data:', error);
      setAtypicalCells({
        escamosa: { count_neoplasica: 0, count_lesao: 0 },
        glandular: { count_neoplasica: 0, count_lesao: 0 },
        indefinida: { count_neoplasica: 0, count_lesao: 0 },
      });
    }
  };

  const getGlandularAdenoma = async (cleared: boolean, payload: Payload) => {
    try {
      const response = await api.get(
        '/dashboard/exames/citologia/panel/celulas-glandulares-adeno',
        {
          params: {
            ...payload.params,
            tipo: 'Reflexo',
          },
        }
      );
      const data = response.data?.data || [];

      const cleanLabel = (label: string): string[] => {
        const cleanedLabel = label.replace(/Adenocarcinoma\s*/g, '').trim();

        if (cleanedLabel.toLowerCase().includes('invasor')) {
          if (cleanedLabel.toLowerCase().includes('em outras especificações')) {
            return ['Invasor em', 'outras especificações'];
          } else {
            const [first, ...rest] = cleanedLabel.split(/\s+/);
            return [first, rest.join(' ')];
          }
        } else if (cleanedLabel.toLowerCase().includes('in situ')) {
          return ['In situ'];
        } else {
          return [cleanedLabel];
        }
      };

      setGlandularAdenoma(
        data.map((item) => ({
          label: cleanLabel(item.label),
          count: parseInt(item.count),
        }))
      );
    } catch (error) {
      console.error('Error fetching glandular adenoma data:', error);
    }
  };

  const getSquamousCellAtypia = async (cleared: boolean, payload: Payload) => {
    try {
      const response = await api.get(
        '/dashboard/exames/citologia/panel/celulas-escamosas',
        {
          params: {
            ...payload.params,
            tipo: 'Reflexo',
          },
        }
      );
      const data = response.data?.data || [];
      setSquamousCellAtypia(
        data.map((item) => ({
          tipo: item.tipo,
          count: parseInt(item.count),
        }))
      );
    } catch (error) {
      console.error('Error fetching squamous cell atypia data:', error);
    }
  };

  const fetchTableData = async (currentPayload: Payload) => {
    try {
      console.log('Fetching data with params:', currentPayload.params);
      const response = await api.get(
        '/dashboard/exames/citologia/panel/quantity-citologia-outros',
        {
          params: {
            ...currentPayload.params,
            page: currentPayload.params.page,
            size: currentPayload.params.size,
          },
        }
      );
      console.log('Received data:', response.data);
      setTableData(response.data.data.data);
      setTotalTableItems(response.data.data.totalItems);
    } catch (error) {
      console.error('Error fetching table data:', error);
    }
  };

  useEffect(() => {
    if (payload && payload.params) {
      console.log('Fetching table data with payload:', payload);
      fetchTableData(payload);
    }
  }, [payload]);

  const handleTablePageChange = (newPage: number) => {
    console.log('Changing to page:', newPage + 1);
    setPayload((prevPayload) => {
      const newPayload = {
        ...prevPayload,
        params: { ...prevPayload.params, page: newPage + 1 },
      };
      console.log('New payload:', newPayload);
      return newPayload;
    });
  };

  const redirectToReports = () => {
    navigate('/relatorios/citologia-reflexo', {
      state: {
        origin: 'tables',
        table: 'totalValue',
        payload: payload,
      },
    });
  };

  const handleTableRowsPerPageChange = (newRowsPerPage: number) => {
    setPayload((prevPayload) => ({
      ...prevPayload,
      params: { ...prevPayload.params, size: newRowsPerPage, page: 1 },
    }));
  };

  const getAllCharts = ({
    cleared,
    payload: newPayload,
  }: {
    cleared: boolean;
    payload: Payload;
  }) => {
    console.log('🚀 ~ VisaoGeral ~ newPayload:', newPayload);
    if (newPayload === undefined) return;
    setTotalTestsPerMonth([]);
    setCategories([]);
    setTotal(0);

    setPayload(newPayload);

    getTestesPerMonth(cleared, newPayload);
    getExamResults(cleared, newPayload);
    getAtypicalCells(cleared, newPayload);
    getGlandularAdenoma(cleared, newPayload);
    getSquamousCellAtypia(cleared, newPayload);
    fetchTableData(newPayload);
  };

  return (
    <Box mt="31px">
      <Box sx={{ marginBottom: '42px' }}>
        <FiltersVisaoGeral
          setPeriodToDisplay={setPeriodToDisplay}
          valueInputPeriod={valueInputPeriod}
          setValueInputPeriod={setValueInputPeriod}
          payload={payload}
          setPayload={getAllCharts}
          setEstabelecimentosSelected={setEstabelecimentosSelected}
          setEstadosSelected={setEstadosSelected}
          setMunicipiosSelected={setMunicipiosSelected}
          municipiosSelected={municipiosSelected}
          estadosSelected={estadosSelected}
          estabelecimentosSelected={estabelecimentosSelected}
          control={control}
          setShowListFilter={setShowListFilter}
          getAllCharts={getAllCharts}
          setListFilter={setListFilter}
          initialStateListFilter={initialStateListFilter}
          listFilter={listFilter}
        />

        <DisplayFilter style={{ marginTop: '17px', marginBottom: '37px' }}>
          <p style={{ margin: 0, width: '138px' }}>Filtros aplicados:</p>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              width: '100%',
              gap: '10px',
              flexWrap: 'wrap',
            }}
          >
            {showListFilter.map(
              (item, index) =>
                item.value && (
                  <ItemsDisplayFilter key={index}>
                    {item.value}
                  </ItemsDisplayFilter>
                )
            )}
          </div>
        </DisplayFilter>
      </Box>

      <div className={styles.testsDoneCard}>
        <div className={styles.testsDoneCardTitle}>
          <Typography variant="h5" fontWeight="bold">
            Exames de Citologia - Reflexo
            <br />
            Total de Exames Realizados
          </Typography>

          <p style={{ margin: 0, fontSize: '12px', color: '#9E9E9E' }}>
            {valueInputPeriod === 'Todos' || !valueInputPeriod
              ? 'Todos os períodos'
              : valueInputPeriod}
          </p>

          <Typography
            variant="h5"
            fontWeight="bold"
            style={{ cursor: 'pointer' }}
            onClick={redirectToReports}
          >
            {total?.toLocaleString('pt-BR') || ''}
          </Typography>
        </div>
        <AreaChart
          data={totalTestsPerMonth}
          categories={categories}
          payload={payload}
          total={total}
        />
      </div>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ChartCard
            title="Exames de Citologia - Reflexo"
            subtitle="Resultado do Exame"
          >
            <div className="chartOnCenter">
              <Chart
                options={{
                  chart: {
                    height: 350,
                    type: 'line',
                    zoom: { enabled: false },
                    toolbar: { show: false },
                  },
                  stroke: { width: 3, curve: 'straight', dashArray: [] },
                  dataLabels: { enabled: false },
                  markers: { size: 0 },
                  xaxis: {
                    categories: examResultsCategories,
                  },
                  colors: ['#ed2805', '#008000', '#808080'],
                  tooltip: {
                    y: { formatter: (val: number) => `${val} exames` },
                  },
                  legend: {
                    show: true,
                    position: 'top',
                    itemMargin: {
                      horizontal: 25,
                      vertical: 5,
                    },
                    formatter: (seriesName, opts) => {
                      const dataSum = calculateSum(
                        opts.w.globals.series[opts.seriesIndex]
                      );
                      return `${seriesName} (${dataSum})`;
                    },
                  },
                }}
                series={[
                  { name: 'Negativo', data: examResultsData.negativo },
                  { name: 'Com alterações', data: examResultsData.alteracao },
                  {
                    name: 'Insatisfatório',
                    data: examResultsData.insatisfatorio,
                  },
                ]}
                type="line"
                height={350}
              />
            </div>
          </ChartCard>
        </Grid>

        <Grid item xs={12} md={6}>
          <ChartCard
            title="Exames de Citologia - Reflexo"
            subtitle="Células Atípicas de Significado Indeterminado"
          >
            <div className="boxData" onMouseMove={handleMouseMove}>
              <div className="boxDatalegends">
                <div
                  className="legendItem"
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <div
                    className="boxDatalegendscolorizedLegend"
                    style={{
                      backgroundColor: '#7b61ff',
                      width: '12px',
                      height: '12px',
                      borderRadius: '50%',
                      display: 'inline-block',
                      marginRight: '8px',
                    }}
                  />
                  <span ref={refRealized}>
                    Possívelmente não neoplásticas (ASC-US)
                  </span>
                </div>
                <div
                  className="legendItem"
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <div
                    className="boxDatalegendscolorizedLegend"
                    style={{
                      backgroundColor: '#ed6c02',
                      width: '12px',
                      height: '12px',
                      borderRadius: '50%',
                      display: 'inline-block',
                      marginRight: '8px',
                    }}
                  />
                  <span ref={refNotRealized}>
                    Não se pode afastar lesão de alto grau (ASC-H)
                  </span>
                </div>
              </div>
              <div
                className="boxDatacontent"
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '300px',
                  width: '100%',
                  padding: '20px',
                  boxSizing: 'border-box',
                }}
              >
                {Object.entries(atypicalCells).map(([key, value], index) => (
                  <ItemGraphic
                    key={index}
                    value1={value.count_neoplasica}
                    value2={value.count_lesao}
                    label={key.charAt(0).toUpperCase() + key.slice(1)}
                    disableGraphicsOnHover={disableGraphicsOnHover}
                  />
                ))}
              </div>
            </div>
          </ChartCard>
        </Grid>

        <Grid item xs={12} md={6}>
          <ChartCard
            title="Exames de Citologia - Reflexo"
            subtitle="Atipias em Células Glandulares"
          >
            <BarChart
              data={glandularAdenoma.map((item) => item.count)}
              categories={glandularAdenoma.map((item) => item.label)}
              colors={['#2dc4cc']}
            />
          </ChartCard>
        </Grid>

        <Grid item xs={12}>
          <ChartCard
            title="Exames de Citologia - Reflexo"
            subtitle="Atipias em Células Escamosas"
          >
            <PieChart2
              data={squamousCellAtypia.map((item) => item.count)}
              height={284}
              labels={squamousCellAtypia.map((item) => item.tipo)}
              colors={['#bdbdbd', '#2196f3', '#ffb547', '#f44336']}
              legendWidth={500}
            />
          </ChartCard>
        </Grid>

        <Grid item xs={12}>
          <Box mt="2px">
            <TableResults
              data={tableData}
              totalItems={totalTableItems}
              onPageChange={handleTablePageChange}
              onRowsPerPageChange={handleTableRowsPerPageChange}
              page={(payload.params.page || 1) - 1}
              rowsPerPage={payload.params.size || 20}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
