/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import {
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  Autocomplete,
  Snackbar,
  Alert,
} from '@mui/material';
import { ButtonIcon } from '../../../components/ModalStatus/ModalStatus.style';
import CloseIcon from '@mui/icons-material/Close';
import { SeuTipoDeRegistro } from './AgendadaModal';
import { StyledAsteriskTextField } from '../../../components/StyledAsteriskTextField/styles';
import api from '../../../api';
import moment from 'moment';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import { toast } from 'react-toastify';

interface IPendenteModalProps {
  open: boolean;
  onClose: () => void;
  registro: SeuTipoDeRegistro;
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 577,
  bgcolor: 'background.paper',
  borderRadius: '4px',
  boxShadow: 24,
  padding: '16px 24px',
};

function PendenteModal({ open, onClose, registro }: IPendenteModalProps) {
  const [selectOption, setSelectOption] = React.useState('');
  const [professional, setProfessional] = React.useState<any>({});
  const [professionalSchedule, setProfessionalSchedule] = React.useState<any[]>(
    []
  );
  const [selectedProfessionalName, setSelectedProfessionalName] =
    React.useState('');
  const [selectedProfessionalId, setSelectedProfessionalId] = React.useState();
  const [selectedDate, setSelectedDate] = React.useState<any | null>(null);
  const [selectedHour, setSelectedHour] = React.useState<string>('');
  const [, setAvailableHours] = React.useState<string[]>([]);
  // const [exam, setExam] = React.useState<any>('');
  const [motivoSelecionado, setMotivoSelecionado] = React.useState('');

  const [inelegivel, setInelegivel] = React.useState('');
  const [message, setMessage] = React.useState(false);
  const [datesWithSchedule, setDatesWithSchedule] = React.useState<any>();
  const [dates, setDates] = React.useState<any>();

  // const [alterarAgendamento, setAlterarAgendamento] = React.useState({
  //   procedimento: exam,
  //   nomeProfissional: professional.nomeProfissional,
  //   dtAgendamento: selectedDate,
  //   idProfissional: professional.idProfissional,
  //   horarioAgendamento: selectedHour,
  // });

  const nome_paciente = registro?.pessoa?.nome;
  // const exame = registro?.procedimento?.categoria;

  const fetchProfessionals = async () => {
    const response = await api.get(
      `cidadao_agenda/estabelecimento/${registro?.pessoa?.estabelecimento_saude?.id}`
    );
    if (response.status !== 200) return;
    setProfessional(response.data);
  };

  const fetchProfessionalSchedule = async (professionalId): Promise<any[]> => {
    try {
      const path = `profissional_agenda/horarios/${professionalId}/${registro?.pessoa?.estabelecimento_saude?.id}`;
      const professionalAgendaResponse = await api.get(path);
      if (professionalAgendaResponse.status !== 200) return [];
      return professionalAgendaResponse.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  const autocompleteOnChange = async (_, value: any) => {
    if (!value?.idProfissional) return;
    const professionalsScheduleData = await fetchProfessionalSchedule(
      value.idProfissional
    );

    setDates(professionalsScheduleData['Teste de HPV']?.horariosDisponiveis);

    setProfessionalSchedule(professionalsScheduleData);
    setSelectedProfessionalName(value.nomeProfissional);
    setSelectedProfessionalId(value.idProfissional);
  };

  const testeDeHPV = professionalSchedule['Teste de HPV'];
  const datasDisponiveis = testeDeHPV?.horariosDisponiveis
    ? Object.keys(testeDeHPV.horariosDisponiveis)
    : [];
  const horasDisponiveis = datasDisponiveis.map(
    (data) => testeDeHPV?.horariosDisponiveis[data]
  );
  // const datasObjetoDate = datasDisponiveis.map((data) => new Date(data));

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectOption(event.target.value);
  };

  // const handleExamChange = (event: any) => {
  //   setExam(event.target.value);
  // };

  const handleDateChange = (date: any) => {
    setSelectedDate(date);
    if (date && testeDeHPV?.horariosDisponiveis) {
      const formattedDate = moment(date).format('yyyy-MM-DD');
      const availableHoursForDate =
        testeDeHPV.horariosDisponiveis[formattedDate] || [];
      setAvailableHours(availableHoursForDate);
    } else {
      setAvailableHours([]);
    }
  };

  React.useEffect(() => {
    fetchProfessionals();
  }, []);

  const formatDataHoraAgendamento = ({
    dataAgendamento,
    horarioAtendimento,
  }) => {
    const partesDataHora = dataAgendamento.split('T');
    const data = partesDataHora[0];

    const partesHora = horarioAtendimento.split(':');

    const novaDataHora =
      data + 'T' + partesHora[0] + ':' + partesHora[1] + ':00' + '.000Z';

    return novaDataHora;
  };

  const agendarSubmit = async () => {
    try {
      const response = await api.post('/cidadao_agenda/agendamento', {
        id_paciente: registro.pessoa.id,
        nome: registro.pessoa.nome,
        cns: registro.pessoa.cns,
        cpf: registro.pessoa.cpf,
        dt_nascimento: registro.pessoa.dt_nascimento,
        nacionalidade: registro.pessoa.nacionalidade,
        estabelecimentoId: registro.pessoa.estabelecimento_saude.id,
        nome_fantasia: registro.pessoa.estabelecimento_saude.nome_fantasia,
        endereco_local: `${registro.pessoa.estabelecimento_saude.endereco.logradouro} - ${registro.pessoa.estabelecimento_saude.endereco.numero} - ${registro.pessoa.estabelecimento_saude.endereco.municipio.nome} - ${registro.pessoa.estabelecimento_saude.endereco.municipio.estadoSigla}`,
        procedimento: 'Teste de HPV',
        isagendamento: true,
        nomeProfissional: selectedProfessionalName,
        dt_agendamento: selectedDate,
        idProfissional: selectedProfessionalId,
        horario_atendimento: selectedHour,
        status: 'Agendado',
        motivo_cancelamento: '',
        motivo_outro: '',
      });

      if (response.status === 201) {
        formatDataHoraAgendamento({
          dataAgendamento: response.data.dtAgendamento,
          horarioAtendimento: response.data.horarioAtendimento,
        });

        const responseUpdateConvocation = await api.put(
          `convocacao/${registro.id}`,
          {
            convocado_em: registro.convocado_em,
            agendado_em: formatDataHoraAgendamento({
              dataAgendamento: response.data.dtAgendamento,
              horarioAtendimento: response.data.horarioAtendimento,
            }),
            procedimentoId: registro.procedimento,
            profissionalId: response.data.profissionalId,
            n_convocacao: registro.n_convocacao,
            status: 0,
          }
        );

        if (responseUpdateConvocation.status === 200) {
          onClose();
          setTimeout(() => {
            window.location.reload();
          }, 2000);
          toast.success('Dados editados com sucesso.', {
            position: 'bottom-right',
          });
        }
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleMotivoChange = (event) => {
    setMotivoSelecionado(event.target.value);
  };

  const handleMotivoNaoElegivel = (event) => {
    setInelegivel(event.target.value);
  };

  const naoAtendidaSubmit = async () => {
    const requestBody = {
      naoEstavaEmCasa: motivoSelecionado === 'naoEstavaEmCasa',
      naoAtendeu: motivoSelecionado === 'naoAtendeu',
      mudouEndereco: motivoSelecionado === 'mudouEndereco',
    };

    const response = await api.put(
      `busca_ativa/nao_atendida/${registro.id}`,
      requestBody
    );

    if (response.status === 200) {
      const responseUpdateConvocation = await api.put(
        `convocacao/${registro.id}`,
        {
          convocado_em: registro.convocado_em,
          agendado_em: null,
          procedimentoId: registro.procedimento,
          profissionalId: null,
          n_convocacao: registro.n_convocacao,
          status: 2,
        }
      );

      if (responseUpdateConvocation.status === 200) {
        onClose();
        setTimeout(() => {
          window.location.reload();
        }, 2000);
        toast.success('Dados editados com sucesso.', {
          position: 'bottom-right',
        });
      }
    }
  };

  const inelegivelSubmit = async () => {
    const requestBody = {
      conv_inativo_sexo: inelegivel === 'naoIniciouAtividadeSexual',
      conv_fora_idade: inelegivel === 'foraFaixaEtaria',
      conv_histerectomizada: inelegivel === 'pacienteHisterectomizada',
      conv_outros: inelegivel === 'outros',
    };

    try {
      const response = await api.put(
        `busca_ativa/nao_elegivel/${registro.id}`,
        requestBody
      );
      if (response.status === 200) {
        const responseUpdateConvocation = await api.put(
          `convocacao/${registro.id}`,
          {
            convocado_em: registro.convocado_em,
            agendado_em: null,
            procedimentoId: registro.procedimento,
            profissionalId: null,
            n_convocacao: registro.n_convocacao,
            status: 3,
          }
        );

        if (responseUpdateConvocation.status === 200) {
          onClose();
          setTimeout(() => {
            window.location.reload();
          }, 2000);
          toast.success('Dados editados com sucesso.', {
            position: 'bottom-right',
          });
        }
      }
    } catch (error: any) {
      toast.error(error.message, { position: 'bottom-right' });
    }
  };

  const data: { [date: string]: { hour: string; disponivel: boolean }[] } =
    dates;

  React.useEffect(() => {
    if (data) {
      const selectedDates = Object.entries(data)
        .filter(([date, schedule]) =>
          schedule.some(
            (item) =>
              item.hasOwnProperty('hour') && item.hasOwnProperty('disponivel')
          )
        )
        .reduce((filteredData, [date, schedule]) => {
          filteredData[date] = schedule;
          return filteredData;
        }, {});
      const selectedDatesArray = Object.entries(selectedDates).map(
        ([date, schedule]) => ({ date, schedule })
      );

      setDatesWithSchedule(selectedDatesArray);
    }
  }, [data]);

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={style}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography
            id="modal-title"
            variant="h6"
            component="h6"
            fontWeight="bold"
          >
            Busca ativa
          </Typography>
          <ButtonIcon onClick={onClose}>
            <CloseIcon fontSize="small" style={{ color: '#BDBDBD' }} />
          </ButtonIcon>
        </Box>
        <Box
          style={{
            border: '1px solid #bdbdbd',
            borderRadius: '8px',
            padding: '16px',
            marginBottom: '20px',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '24px' }}>
            <img src={'/exams.svg'} alt="exams" width={50} />
            <Typography fontWeight={700} fontSize={20}>
              {nome_paciente}
            </Typography>
          </Box>
          <Divider style={{ marginTop: 10 }} />
          <Typography fontWeight={400} fontSize={16} mt={2} mb={0}>
            Resultados da convocação <span style={{ color: '#f88078' }}>*</span>
          </Typography>
          <FormControl>
            <RadioGroup
              style={{ display: 'flex', flexDirection: 'row' }}
              value={selectOption}
              onChange={handleChange}
            >
              <FormControlLabel
                style={{ marginRight: '14px' }}
                value="atendida"
                control={<Radio style={{ fontSize: '14px', padding: '3px' }} />}
                label={<Typography fontSize={16}>Agendada</Typography>}
              />
              <FormControlLabel
                value="naoAtendida"
                control={<Radio />}
                label={<Typography fontSize={16}>Não atendida</Typography>}
              />
              <FormControlLabel
                value="inelegivel"
                control={<Radio />}
                label={<Typography fontSize={16}>Não elegível</Typography>}
              />
            </RadioGroup>
          </FormControl>
          {selectOption === 'atendida' && (
            <>
              <Grid
                container
                rowSpacing={3}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                <Grid item xs={12} sx={{ marginTop: 2 }}>
                  <StyledAsteriskTextField
                    required
                    variant="outlined"
                    fullWidth
                    disabled
                    value={nome_paciente}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    options={[]}
                    disabled
                    value="HPV"
                    renderInput={(params) => {
                      return (
                        <TextField
                          {...params}
                          label="Selecione um exame"
                          fullWidth
                          size="small"
                          disabled
                          variant="outlined"
                        />
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <StyledAsteriskTextField
                    label="UBS"
                    variant="outlined"
                    value={
                      registro.pessoa?.estabelecimento_saude?.nome_fantasia
                    }
                    fullWidth
                    disabled
                  />
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    options={professional}
                    onChange={autocompleteOnChange}
                    getOptionLabel={(option) => option.nomeProfissional}
                    renderInput={(params) => (
                      <TextField {...params} label="Profissional" />
                    )}
                  />
                </Grid>
                <Grid item xs={6} sx={{ marginTop: '10px' }}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                      label="Data"
                      inputFormat="dd/MM/yyyy"
                      value={selectedDate}
                      onChange={handleDateChange}
                      shouldDisableDate={(date) => {
                        const originalDate = moment(date);
                        const convertedDate = originalDate.format('YYYY-MM-DD');

                        const disableDate = !!datesWithSchedule?.find(
                          (item) => {
                            return item?.date === convertedDate;
                          }
                        );
                        return !disableDate;
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          placeholder="__/__/____"
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={6} sx={{ marginTop: '10px' }}>
                  <Autocomplete
                    options={
                      testeDeHPV?.horariosDisponiveis[
                        selectedDate?.toISOString().slice(0, 10)
                      ]?.map((hourObj, hourIndex) => {
                        return hourObj?.hour;
                      }) ?? []
                    }
                    onChange={(_, newValue) =>
                      setSelectedHour(newValue as string)
                    }
                    noOptionsText="Nenhum horário disponível"
                    renderInput={(params) => {
                      return (
                        <TextField
                          {...params}
                          label="Selecione um horário"
                          fullWidth
                          size="small"
                          variant="outlined"
                        />
                      );
                    }}
                  />
                </Grid>
              </Grid>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: '15px',
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  style={{
                    marginTop: '15px',
                    height: '60px',
                    width: '50%',
                    fontSize: '20px',
                  }}
                  onClick={agendarSubmit}
                >
                  Confirmar
                </Button>
              </Box>
            </>
          )}
          {selectOption === 'naoAtendida' && (
            <div style={{ marginTop: '15px' }}>
              <FormControl>
                <RadioGroup
                  style={{ display: 'flex', flexDirection: 'row' }}
                  value={motivoSelecionado}
                  onChange={handleMotivoChange}
                >
                  <FormControlLabel
                    style={{ marginRight: '14px' }}
                    value="naoEstavaEmCasa"
                    control={
                      <Radio style={{ fontSize: '14px', padding: '3px' }} />
                    }
                    label={
                      <Typography fontSize={16}>Não estava em casa</Typography>
                    }
                  />
                  <FormControlLabel
                    value="naoAtendeu"
                    control={
                      <Radio style={{ fontSize: '14px', padding: '3px' }} />
                    }
                    label={<Typography fontSize={16}>Não atendeu</Typography>}
                  />
                  <FormControlLabel
                    value="mudouEndereco"
                    control={
                      <Radio style={{ fontSize: '14px', padding: '3px' }} />
                    }
                    label={
                      <Typography fontSize={16}>Mudou de endereço</Typography>
                    }
                  />
                </RadioGroup>
              </FormControl>
              <TextField
                multiline
                style={{ marginTop: '28px' }}
                sx={{ width: '100%' }}
                label="Observação adicional"
              />
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: '15px',
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  style={{
                    marginRight: '10px',
                    height: '60px',
                    width: '50%',
                    fontSize: '20px',
                  }}
                  onClick={naoAtendidaSubmit}
                >
                  CONFIRMAR
                </Button>
              </Box>
            </div>
          )}
          {selectOption === 'inelegivel' && (
            <div>
              <Typography fontWeight={400} fontSize={16} mt={2} mb={0}>
                Motivo <span style={{ color: '#f88078' }}>*</span>
              </Typography>
              <FormControl>
                <RadioGroup
                  style={{ display: 'flex', flexDirection: 'row' }}
                  onChange={handleMotivoNaoElegivel}
                >
                  <FormControlLabel
                    style={{ marginRight: '' }}
                    value="naoIniciouAtividadeSexual"
                    control={
                      <Radio style={{ fontSize: '14px', padding: '3px' }} />
                    }
                    label={
                      <Typography fontSize={16}>
                        Não iniciou atividade sexual
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    value="foraFaixaEtaria"
                    control={
                      <Radio style={{ fontSize: '14px', padding: '3px' }} />
                    }
                    label={
                      <Typography fontSize={16}>
                        Fora da faixa-etária
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    style={{ marginRight: '13px' }}
                    value="pacienteHisterectomizada"
                    control={
                      <Radio style={{ fontSize: '14px', padding: '3px' }} />
                    }
                    label={
                      <Typography fontSize={16}>
                        Histerectomizada por patologia benigna
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    style={{ marginLeft: '0px' }}
                    value="outros"
                    control={
                      <Radio style={{ fontSize: '14px', padding: '3px' }} />
                    }
                    label={<Typography fontSize={16}>Outros</Typography>}
                  />
                </RadioGroup>
              </FormControl>
              <TextField
                multiline
                style={{ marginTop: '28px' }}
                sx={{ width: '100%' }}
                label="Observação adicional"
                disabled
              />
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: '15px',
                }}
              >
                {message && (
                  <Snackbar
                    open={message}
                    autoHideDuration={1000}
                    onClose={() => setMessage(false)}
                  >
                    <Alert severity="success" onClose={() => setMessage(false)}>
                      Dados editados com sucesso!
                    </Alert>
                  </Snackbar>
                )}
                <Button
                  variant="contained"
                  color="primary"
                  style={{
                    marginRight: '10px',
                    height: '60px',
                    width: '50%',
                    fontSize: '20px',
                  }}
                  onClick={inelegivelSubmit}
                >
                  CONFIRMAR
                </Button>
              </Box>
            </div>
          )}
        </Box>
      </Box>
    </Modal>
  );
}

export default PendenteModal;
