/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect } from 'react';
import * as echarts from 'echarts';
import { useNavigate } from 'react-router-dom';

const GraphicTotalConvocation = ({ data }) => {
  const navigate = useNavigate();
  useEffect(() => {
    const chart = echarts.init(
      document.getElementById('echarts-chart-total-convocation')
    );

    const option = {
      tooltip: {
        trigger: 'item',
      },
      grid: {
        left: '5px',
        right: '0',
        bottom: '0',
        top: '40px',
        containLabel: true,
      },
      xAxis: {
        type: 'category',
        data: data?.map((item) => item.mes),
      },
      yAxis: {
        type: 'value',
      },
      series: [
        {
          data: data?.map((item) => Number(item.numero_de_convocados)),
          type: 'bar',
          itemStyle: {
            color: '#27AAB0',
          },
          barWidth: 40,
        },
      ],
    };

    chart.setOption(option);

    return () => {
      chart.dispose();
    };
  }, [data]);

  return (
    <div
      id="echarts-chart-total-convocation"
      onClick={() => navigate('/relatorios/convocatoria')}
      style={{
        width: '100%',
        height: '400px',
      }}
    />
  );
};

export default GraphicTotalConvocation;
