import { createContext } from 'react';
import { getUserToken } from '../lib/auth';

interface PerfilContextValue {
  getPermissao: () => string;
  hasPermission: (p: string | string[]) => boolean;
}

export const PerfilContext = createContext<PerfilContextValue>({
  getPermissao: () => {
    return '';
  },
  hasPermission: (p) => false,
});

function PerfilProvider({ children }) {
  // const [perfilState, setPerfilState] = useState();
  // const perfil = getUserToken().permissao_atual?.nome;

  const getPermissao = () => {
    let permissao;

    if (getUserToken().permissoes?.includes('SUPER_ADMIN')) {
      permissao = 'isSuperAdmin';
    }

    if (getUserToken().permissao_atual?.nome === 'Padrão') {
      permissao = 'isPadrao';
    }

    if (
      getUserToken().permissoes?.includes('ZONE_MUNICIPIO') &&
      getUserToken().permissao_atual?.nome !== 'Padrão' &&
      !getUserToken().permissoes?.includes('SUPER_ADMIN')
    ) {
      permissao = 'isMunicipal';
    }

    if (
      getUserToken().permissoes?.includes('ZONE_ESTADO') &&
      !getUserToken().permissoes?.includes('SUPER_ADMIN')
    ) {
      permissao = 'isEstadual';
    }

    return permissao;
    // const isSuperAdmin = getUserToken().permissoes??.includes('SUPER_ADMIN');

    // const isPadrao = perfil === 'Padrão';

    // const isMunicipial =
    //   getUserToken()?.permissoes??.includes('ZONE_MUNICIPIO') &&
    //   !isPadrao &&
    //   !isSuperAdmin;

    // const isEstadual =
    //   getUserToken()?.permissoes??.includes('ZONE_ESTADO') && !isSuperAdmin;
  };
  const hasPermission = (permission: string | string[]) => {
    if (getUserToken().permissoes?.includes('SUPER_ADMIN')) {
      return true;
    }
    if (typeof permission === 'object') {
      return !!permission?.find((p) => getUserToken().permissoes?.includes(p));
    }

    return getUserToken().permissoes?.includes(permission);
  };

  return (
    <PerfilContext.Provider
      value={{
        getPermissao,
        hasPermission,
      }}
    >
      {children}
    </PerfilContext.Provider>
  );
}

export default PerfilProvider;
