import logoJordana from '../../../../assets/imgs/logo.png';
import wavyCheck from '../../../../assets/imgs/circle-wavy-check.svg';

import { Box, Button, Typography } from '@mui/material';

import { FormContainer, BoxInputButton } from '../TypeEmail/style';
import { ArrowForward } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

const SendedEmail = ({myEmail}) => {
  let navigate = useNavigate();
  const returnToSigninPage = () => {
    navigate('/');
  };

  return (
    <FormContainer variant="outlined">
      <Box py={7.5} px={3} textAlign="center">
        <img src={logoJordana} alt="logo jornada" width="181" />

        <Box>
          <Typography variant="h5" fontWeight="bold" sx={{ margin: '15px' }}>
            E-mail Enviado!
          </Typography>
          <p>{myEmail}</p>
          <img
            src={wavyCheck}
            alt="check"
            width="85px"
            style={{ marginBottom: '20px' }}
          />
        </Box>

        <p
          style={{
            color: '#00000099',
            fontWeight: '500',
            fontSize: '14px',
            margin: '0px',
          }}
        >
          Você irá receber um e-mail com link para confirmar a sua redefinição
          de senha. Caso você não receba esse e-mail, por favor entre em contato
          com a nossa equipe de suporte no endereço:{' '}
          <span style={{ color: '#238884', margin: '0', fontSize: '14px' }}>
            suporte@digitalcare2u.com.br
          </span>
        </p>

        <Box>
          <BoxInputButton>
            <Button
              onClick={returnToSigninPage}
              variant="contained"
              endIcon={<ArrowForward />}
              fullWidth
              sx={{
                backgroundColor: '#238884',
                color: '#FFFFFF',
                width: '108px',
                marginTop: '32px',
              }}
            >
              Entrar
            </Button>
          </BoxInputButton>
        </Box>
      </Box>
    </FormContainer>
  );
};

export default SendedEmail;
