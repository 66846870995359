import { useContext } from 'react';
import { LoadingContext } from '../../../../../context/LoadingContext';
import { getReportsCitologiaReflexo } from '../../../../../lib/Reports/getReportsCtiologiaReflexo';

const useRequestFilter = ({ setTableData }) => {
  const { setLoading } = useContext(LoadingContext);
  const loadReportsCitologiaReflexo = async (payload) => {
    const response = await getReportsCitologiaReflexo({
      payload,
    });

    setTableData(response);
    setLoading(false);
  };

  return {
    loadReportsCitologiaReflexo,
  };
};

export default useRequestFilter;
