import Background1 from '../assets/imgs/Background1.png';
import Background2 from '../assets/imgs/Background2.png';
import Background3 from '../assets/imgs/Background3.png';
import Background4 from '../assets/imgs/Background4.png';

const backgrounds = [Background1, Background2, Background3, Background4];

function randomNumberInRange(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export const background = () => {
  return backgrounds.at(randomNumberInRange(0, backgrounds.length - 1));
};
