export const phoneMask = (value) => {
  if (!value) return '';

  value = value.replace(/\D/g, '');

  if (value.length === 11) {
    value = value.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
  } else if (value.length === 10) {
    value = value.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3');
  }

  return value;
};