import { SetStateAction, createContext, useEffect, useState } from 'react';
import { getUserToken } from '../lib/auth';
import { getAlerst, getHaveResults } from '../lib/getAlertList';
import { HelperSendingBox } from '../components/HelperBox/HelperSending';

type HelperSendingData = {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  openModal: (type: 'TIP' | 'SUPPORT') => void;
  isOpen: boolean;
};

export const HelperSendingContext = createContext({} as HelperSendingData);

export const HelperSendingProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [type, setType] = useState<'TIP' | 'SUPPORT'>('TIP');

  const openModal = (_type: 'TIP' | 'SUPPORT') => {
    setType(_type);
    setIsOpen(true);
  };

  return (
    <HelperSendingContext.Provider value={{ isOpen, setIsOpen, openModal }}>
      <HelperSendingBox enabled={isOpen} setEnabled={setIsOpen} type={type} />
      {children}
    </HelperSendingContext.Provider>
  );
};
