import { Breadcrumbs, Typography } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Link } from 'react-router-dom';

const PageTitlePatients = ({ id }) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      {id ? (
        <div>
          <Typography variant="h4" fontWeight="bold">
            Editar Ficha Individual da Paciente
          </Typography>
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
            style={{ marginBottom: '16px', marginTop: '16px' }}
          >
            <Link
              key="1"
              style={{
                textDecoration: 'none',
                color: '#646464',
              }}
              to="/pacientes"
            >
              Listagem de Pacientes
            </Link>
            <Link
              key="2"
              style={{
                textDecoration: 'none',
                color: '#646464',
              }}
              to={`/pacientes/${id}`}
            >
              Ficha Individual da Paciente
            </Link>
            <Typography key="3" color="text.primary">
              Editar
            </Typography>
          </Breadcrumbs>
        </div>
      ) : (
        <div>
          <Typography variant="h4" fontWeight="bold">
            Cadastro da Paciente
          </Typography>
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
            style={{ marginBottom: '16px', marginTop: '16px' }}
          >
            <Link
              key="1"
              style={{
                textDecoration: 'none',
                color: '#646464',
              }}
              to="/pacientes"
            >
              Listagem de Pacientes
            </Link>
            <Typography key="3" color="text.primary">
              Cadastro de Paciente
            </Typography>
          </Breadcrumbs>
        </div>
      )}
    </div>
  );
};

export default PageTitlePatients;
