export const tiposAchados = [
  {
    label: 'Achado Colposcópico Anormal',
    value: 2,
  },
  {
    label: 'Achado Colposcópico Normal',
    value: 1,
  },
];
