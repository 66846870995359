import { styled } from '@mui/system';

export const StyledScrollBar = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
  height: calc(100vh - 300px);
  overflow: auto;
  width: 100%;
  margin-top: 50px;
  ::-webkit-scrollbar {
    width: 14px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #299b9b;
    border-radius: 20px;
    border: 4px solid #e8e8e8;
  }

  ::-webkit-scrollbar-track {
    background-color: #e8e8e8;
    width: 14px;
  }
`;
