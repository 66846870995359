/* eslint-disable react-hooks/exhaustive-deps */

import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { login, refreshLogin } from '../../lib/auth';
import logoJordana from '../../assets/imgs/logo.png';
import { ArrowForward } from '@mui/icons-material';
// import { tokenToString } from 'typescript';
import jwt_decode from 'jwt-decode';
import {
  FormContainer,
  ContainerOffButtons,
  RecoverPasswordButton,
  LoginButton,
  ScreenBackgroundLogin,
} from './style';
import { InputPassword } from '../../components/InputPassword';
import { schema } from './schema';
import { Inputs } from './interfaces';
import { BackgroundContext } from '../../context/BackgroundContext';
import AcceptCookies from '../../components/AcceptCookies';
import { returnCpfValidation } from '../../utils/validateCPF';
import MaskedInput, { MaskedInputProps } from 'react-text-mask';
import { useStyles } from '../RegisterPatient/RegisterPatient.style';
import { StyledAsteriskTextField } from '../../components/StyledAsteriskTextField/styles';
import { AlertListContext } from '../../context/AlertListContext';
import { LoadingContext } from '../../context/LoadingContext';
import { HelperSendingContext } from '../../context/HelperSendingContext';

export default function SignIn() {
  const { setIsOpen: setHelperOpen, isOpen: isHelperOpen } =
    useContext(HelperSendingContext);
  const [helperOpened, setHelperOpened] = useState(false);
  const {
    handleSubmit,
    formState: { errors },
    watch,
    getValues,
    setError,
    clearErrors,
    control,
  } = useForm<Inputs>({
    resolver: yupResolver(schema),
  });
  let navigate = useNavigate();

  const { setUpdateAlertList } = useContext(AlertListContext);

  const [errorUserPassword, setErrorUserPassword] = useState('');

  const { backgroundImage } = useContext(BackgroundContext);

  const styles = useStyles();

  localStorage.removeItem('perfilType');

  const { setLoading } = useContext(LoadingContext);

  const onSubmit = async (input: Inputs) => {
    setLoading(true);
    localStorage.setItem('lastRequest', String(new Date()));
    try {
      const { data } = await login(
        input.cpf.replace(/[-.]/g, ''),
        input.password,
        setErrorUserPassword
      );

      if (!!data.error) {
        setError('password', { message: data.error.message });
        setLoading(false);
        return;
      }

      localStorage.setItem(
        btoa('login'),
        btoa(
          JSON.stringify({
            cpf: input.cpf.replace(/[-.]/g, ''),
            password: input.password,
          })
        )
      );
      const token = data.access_token;
      const decodedToken: any = jwt_decode(token);
      const personalData = {
        nome: decodedToken.user.pessoa.nome,
        cns: decodedToken.user.pessoa.cns,
      };
      const establishments = decodedToken.user.perfis.map((perfil) => ({
        id: perfil.id,
        estabelecimento_saude_id: perfil.estabelecimento_saude.id,
        nome_empresa: perfil.estabelecimento_saude.nome_fantasia,
        cnes: perfil.estabelecimento_saude.cnes,
        pessoa: personalData,
      }));

      localStorage.setItem(
        'token_estabelecimentos',
        JSON.stringify(establishments)
      );

      const lastEstablishmentId = localStorage.getItem('id_last_establishment');

      const verifyIfLastEstablishmentInLocalStorageIsIncludedInUserPerfis =
        establishments.find(
          (item) =>
            item.estabelecimento_saude_id === Number(lastEstablishmentId)
        );

      if (
        !lastEstablishmentId ||
        !!verifyIfLastEstablishmentInLocalStorageIsIncludedInUserPerfis ===
          false
      ) {
        localStorage.setItem(
          'id_last_establishment',
          String(establishments[0].estabelecimento_saude_id)
        );

        makeRefreshLogin(
          input.cpf.replace(/[-.]/g, ''),
          input.password,
          Number(establishments[0].estabelecimento_saude_id)
        );
      } else {
        makeRefreshLogin(
          input.cpf.replace(/[-.]/g, ''),
          input.password,
          Number(lastEstablishmentId)
        );
      }
      setHelperOpen(helperOpened);
    } catch (error: any) {
      console.error(error);
    }
  };

  const makeRefreshLogin = (
    email: string,
    password: string,
    establishment: number
  ) => {
    refreshLogin(email, password, establishment)
      .then(() => {
        localStorage.setItem('isLogged', 'logged');
        navigate('/home');
        setLoading(false);

        setUpdateAlertList((prev) => prev + 1);
      })
      .catch((err: any) => {
        setLoading(false);

        // setError(err.message);
        // throw new Error(err.message);
      });
  };

  const handleForgotPassword = () => {
    navigate('/esqueceu-senha-envio-email');
  };

  const CPF = watch('cpf');

  const validateCpfWhenUserTypeElevenNumbers = () => {
    const cpfIsValid = returnCpfValidation(getValues);

    const CpfWithoutChar = CPF.replace(/[-.]/g, '');

    const setOrClearErrorsOnCpfInput = () => {
      cpfIsValid === false
        ? setError('cpf', { type: 'manual', message: 'CPF inválido' })
        : clearErrors('cpf');
    };

    if (CpfWithoutChar.length === 11) setOrClearErrorsOnCpfInput();
  };

  useEffect(() => {
    if (CPF) {
      validateCpfWhenUserTypeElevenNumbers();
    } else {
      clearErrors('cpf');
    }
  }, [CPF]);

  useEffect(() => {
    localStorage.removeItem('bG9naW4=');
    localStorage.removeItem('isLogged');
    setHelperOpened(isHelperOpen);
    setHelperOpen(false);
  }, []);

  return (
    <ScreenBackgroundLogin
      bgcolor="lightblue"
      sx={{
        backgroundImage: `url(${backgroundImage})`,
      }}
    >
      <FormContainer>
        <img src={logoJordana} alt="logo jornada" width="181" />
        <Box my={4}>
          <Typography variant="h5" fontWeight="bold">
            Boas Vindas!
          </Typography>
          <Typography variant="body2">
            Insira os seus dados para acessar o sistema
          </Typography>
        </Box>
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <>
            <Controller
              control={control}
              name="cpf"
              render={({ field: { onChange, value } }) => (
                <StyledAsteriskTextField
                  data-testid="login"
                  onChange={(e) => {
                    const cpf = e.target.value.replace(/_/g, ''); // Remover os "_"
                    onChange(cpf);
                  }}
                  value={value ? value : ''}
                  label="Digite seu CPF"
                  variant="outlined"
                  className={styles.textField}
                  error={!!errors.cpf}
                  helperText={errors.cpf?.message}
                  sx={{ width: '100%' }}
                  inputMode="numeric"
                  InputProps={{
                    autoComplete: 'off',
                    inputComponent: MaskedInput as any,
                    inputProps: {
                      mask: [
                        /\d/,
                        /\d/,
                        /\d/,
                        '.',
                        /\d/,
                        /\d/,
                        /\d/,
                        '.',
                        /\d/,
                        /\d/,
                        /\d/,
                        '-',
                        /\d/,
                        /\d/,
                      ],
                    } as MaskedInputProps,
                  }}
                />
              )}
            />

            {/* <TextField
              type="number"
              fullWidth
              label="Digite seu CPF"
              size="medium"
              variant="outlined"
              {...register('cpf', { required: true })}
              error={!!errors.cpf || !!errorUserPassword}
              helperText={errors.cpf?.message}
              inputMode="numeric"
            /> */}
            <Controller
              control={control}
              name="password"
              render={({ field: { onChange, value } }) => (
                <InputPassword
                  fullWidth
                  data-testid="password"
                  label="Senha"
                  size="medium"
                  variant="outlined"
                  onChange={onChange}
                  value={value}
                  sx={{ marginTop: '24px', marginBottom: '32px' }}
                  error={!!errors.password || !!errorUserPassword}
                  helperText={errors.password?.message || errorUserPassword}
                />
              )}
            />

            <ContainerOffButtons>
              <RecoverPasswordButton onClick={handleForgotPassword}>
                Esqueceu a senha?
              </RecoverPasswordButton>
              <LoginButton
                data-testid="loginButton"
                type="submit"
                variant="contained"
                endIcon={<ArrowForward />}
                fullWidth
              >
                Entrar
              </LoginButton>
            </ContainerOffButtons>
          </>
        </form>
      </FormContainer>
    </ScreenBackgroundLogin>
  );
}
