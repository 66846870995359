import {
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { SetStateAction } from 'react';
import Exame from '../../../assets/imgs/exame.svg';

export const Anamnese = ({ setSelectedValue }) => {
  const handleChange = (event: {
    target: { value: SetStateAction<string> };
  }) => {
    setSelectedValue(event.target.value);
  };

  return (
    <Box sx={{ width: '100%' }} p={3}>
      <Grid container alignItems="center" spacing={3}>
        <Grid item xs={12} sm={2} display="flex" justifyContent="center">
          <img src={Exame} alt="Exames Icon" />
        </Grid>
        <Grid item xs={12} sm={9}>
          <Typography variant="h6" fontWeight="bold">
            Para qual procedimento você está usando a anamnese?
          </Typography>
        </Grid>
      </Grid>

      <Box sx={{ marginTop: '16px', marginBottom: '16px' }}>
        <Divider />
      </Box>
      <FormControl>
        <RadioGroup aria-labelledby="Tpoe de exame" name="exame-type">
          <FormControlLabel
            value="hpv"
            control={<Radio color="primary" onChange={handleChange} />}
            label="Teste HPV"
          />
          <FormControlLabel
            value="citologia-rastreio"
            control={<Radio color="primary" onChange={handleChange} />}
            label="Citologia - Como Anamnese"
          />
          <FormControlLabel
            value="citologia-reflexo"
            control={<Radio color="primary" onChange={handleChange} />}
            label="Citologia - Reflexo (após teste de HPV Positivo)"
          />

          <FormControlLabel
            value="colposcopia"
            control={<Radio color="primary" onChange={handleChange} />}
            label="Colposcopia"
          />

          <FormControlLabel
            value="histopatologia"
            control={<Radio color="primary" onChange={handleChange} />}
            label="Histopatologia"
          />
        </RadioGroup>
      </FormControl>
    </Box>
  );
};
