const useHandleSelect = () => {
  const handleSelectMultiplesFields = ({
    value,
    setState,
    state,
    paramToSave,
  }) => {
    const isSelected = state?.find(
      (itemSaved) => String(itemSaved) === String(value[paramToSave])
    );

    if (isSelected) {
      setState((prev) => {
        return prev.filter(
          (itemSaved) => String(itemSaved) !== String(value[paramToSave])
        );
      });
    } else {
      setState((prev) => {
        return [...prev, value[paramToSave]];
      });
    }
  };

  return {
    handleSelectMultiplesFields,
  };
};

export default useHandleSelect;
