import React, { Dispatch, SetStateAction } from 'react';
import api from '../api';

interface Props {
  children: JSX.Element;
}

export interface ResponseData {
  count: number;
  data: [];
  page: number;
  totalItems: number;
  totalPages: number;
}

export type Filter = {
  idadeInicial: number;
  idadeFinal: number;
  quantidade: number;
  citologia: number;
  page: number;
  size: number;
};

interface SampleContextProps {
  getSample: (page?: number, size?: number) => void;
  samples: ResponseData;
  setFilters: Dispatch<SetStateAction<Filter>>;
};

export type UseSampleContext = SampleContextProps & {
  getSample: (filters: Filter) => void;
  updateFilters: (field: 'idadeInicial' | 'idadeFinal' | 'quantidade' | 'page' | 'size' | 'citologia', value: number) => void;
};

export const SampleContext = React.createContext<SampleContextProps>({} as SampleContextProps);

export const SampleStorage = ({ children }: Props) => {
  const [samples, setSample] = React.useState<ResponseData>({} as ResponseData);
  const [filters, setFilters] = React.useState<Filter>({
    idadeInicial: 25,
    idadeFinal: 35,
    quantidade: 1500,
    citologia: 0,
    page: 1,
    size: 5
  });

  async function getSample(page: number = filters.page, size: number = filters.size) {
    const { idadeInicial, idadeFinal, quantidade } = filters;
    try {
      const res = await api.get('/amostras', {
        params: {
          idadeInicial,
          idadeFinal,
          quantidade,
          page,
          size
        }
      });
      setSample(res.data);
    } catch (error) {
      console.error(error);
    }
  }
  return <SampleContext.Provider value={{ samples, getSample, setFilters }}>{children}</SampleContext.Provider>;
};
