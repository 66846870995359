export const formatCitologia = (data) => {
  data.escamosa_indeterminada = [
    {
      escamosa_indeterminada: {
        id: 1,
        nome: 'Possivelmente não neoplásicas (ASC-US)',
      },
      value: data.escamosa_indeterminada.ASC_US ? true : false,
    },
    {
      escamosa_indeterminada: {
        id: 2,
        nome: 'Não se pode afastar lesão de alto grau (ASC-H)',
      },
      value: data.escamosa_indeterminada.ASC_H ? true : false,
    },
  ];

  data.glandular_indeterminada = [
    {
      glandular_indeterminada: {
        id: 1,
        nome: 'Possivelmente não neoplásicas',
      },
      value: data.glandular_indeterminada.neoplasicas ? true : false,
    },
    {
      glandular_indeterminada: {
        id: 2,
        nome: 'Não se pode afastar lesão de alto grau',
      },
      value: data.glandular_indeterminada.alto_grau ? true : false,
    },
  ];

  data.origem_indefinida = [
    {
      origem_indefinida: {
        id: 1,
        nome: 'Possivelmente não neoplásicas',
      },
      value: data.origem_indefinida.neoplasicas ? true : false,
    },
    {
      origem_indefinida: {
        id: 2,
        nome: 'Não se pode afastar lesão de alto grau',
      },
      value: data.origem_indefinida.alto_grau ? true : false,
    },
  ];

  data.atipia_glandular = [
    {
      atipia_glandular: {
        id: 1,
        nome: 'Adenocarcinoma ‘in situ’',
      },
      value: data.atipia_glandular.in_situ ? true : false,
    },
    {
      atipia_glandular: {
        id: 2,
        nome: 'Adenocarcinoma invasor cervical',
      },
      value: data.atipia_glandular.cervical ? true : false,
    },
    {
      atipia_glandular: {
        id: 3,
        nome: 'Adenocarcinoma invasor endometrial',
      },
      value: data.atipia_glandular.endometrial ? true : false,
    },
    {
      atipia_glandular: {
        id: 4,
        nome: 'Adenocarcinoma invasor em outras especificações',
      },
      value: data.atipia_glandular.outras ? true : false,
    },
  ];

  data.atipia_escamosa = [
    {
      atipia_escamosa: {
        id: 1,
        nome: 'Lesão intraepitelial de baixo grau (compreendendo o efeito citopático pelo HPV e noplasia intraepitelial cervical grau I',
      },
      value: data.atipia_escamosa.grau_I ? true : false,
    },
    {
      atipia_escamosa: {
        id: 2,
        nome: 'Lesão intraepitelial de alto grau (compreendendo neoplasias intraepiteliais cervicais grau II e III',
      },
      value: data.atipia_escamosa.grau_II ? true : false,
    },
    {
      atipia_escamosa: {
        id: 3,
        nome: 'Lesão intraepitelial de alto grau, não podendo excluir microinvasão',
      },
      value: data.atipia_escamosa.alto_grau ? true : false,
    },
    {
      atipia_escamosa: {
        id: 4,
        nome: 'Carcinoma epidermóide invasor',
      },
      value: data.atipia_escamosa.carcinoma ? true : false,
    },
  ];
};
