import { ApexOptions } from 'apexcharts';
import { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';

interface PieChartProps {
  data: number[];
  categories?: string[] | string[][];
  height?: number;
  width?: number;
  colors: string[];
  labels?: string[];
  legendWidth?: number;
  startAngle?: number;
  endAngle?: number;
}

export function PieChart2({
  data,
  width,
  height,
  labels,
  colors,
  legendWidth,
  startAngle = -135,
  endAngle = 225,
}: PieChartProps) {
  const [chartData, setChartData] = useState<ApexOptions>();

  useEffect(() => {
    setChartData({
      series: data,
      chart: {
        width: width || 284,
        height,
        type: 'pie',
      },
      labels,
      responsive: [
        {
          breakpoint: 768,
          options: {
            legend: {
              position: 'bottom',
              offsetY: 0,
              horizontalAlign: 'left',
              width: 300,
            },
          },
        },
      ],
      colors,
      yaxis: {
        min: 0,
      },
      legend: {
        position: 'right',
        offsetY: 100,
        width: legendWidth || 140,
      },
      plotOptions: {
        pie: {
          dataLabels: {
            offset: 1,
            minAngleToShowLabel: 0,
          },
          startAngle,
          endAngle,
        },
      },
      dataLabels: {
        enabled: true,
        style: {
          fontSize: '12px',
          colors: ['rgba(0,0,0,0.7)'],
        },
        background: {
          enabled: true,
          foreColor: '#ffffff',
          borderRadius: 6,
          borderColor: 'transparent',
        },
        formatter: function (val, options) {
          if (typeof val === 'number') {
            return `${val.toFixed()}%`;
          }
          return String(val);
        },
      },
    });
  }, [data, colors, labels, height, legendWidth, width, endAngle, startAngle]);

  return (
    <>
      {chartData ? (
        <>
          <ReactApexChart
            options={chartData}
            series={chartData.series}
            type={'pie'}
            height={height ?? 350}
          />
        </>
      ) : undefined}
    </>
  );
}
