import { TableCell, Typography } from '@mui/material';
import moment from 'moment';

const RowsClinico = ({ row }) => {
  const origem = {
    1: 'Biópsia',
    2: 'Peças Cirúrgicas',
  };

  const margensCirugicaList = {
    1: 'Livre',
    2: 'Comprometidas',
    3: 'Impossível de Serem Avaliadas',
  };
  return (
    <>
      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {row?.pessoa?.rede_atendimento}
        </Typography>
      </TableCell>
      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {row?.laboratorioResponsavel?.cnes}
        </Typography>
      </TableCell>
      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {row?.laboratorioResponsavel?.nome_fantasia}
        </Typography>
      </TableCell>
      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {row?.protocolo}
        </Typography>
      </TableCell>
      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {row?.dt_coleta ? moment(row?.dt_coleta).format('DD/MM/YYYY') : ''}
        </Typography>
      </TableCell>
      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {origem[row?.origem]}
        </Typography>
      </TableCell>

      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {margensCirugicaList[row?.margem_cirurgica]}
        </Typography>
      </TableCell>
      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {row?.dt_resultado
            ? moment(row?.dt_resultado).format('DD/MM/YYYY')
            : ''}
        </Typography>
      </TableCell>
    </>
  );
};

export default RowsClinico;
