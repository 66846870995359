import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';
import { toast } from 'react-toastify';

import { useState } from 'react';
import { AlertInfo } from '../../../../components/AlertInfo';
import { Label } from '../../../../components/Typo';
import { PeriodList, Periods } from '../periodList';
import { ProfessionalSchedule } from '../..';
import { Controller, useForm } from 'react-hook-form';
import { postProfessionalSchedule } from '../../../../lib/professionalSchedule';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { ptBR } from 'date-fns/locale';
import { getUserToken } from '../../../../lib/auth';

interface CreateNewAgendaProps {
  handleClickCancel: () => void;
  selectedProfessional: any;
  handleSetProfessionalSchedule: (professionalSchedule) => void;
  savedProfessionalSchedule: ProfessionalSchedule[];
  professionalSchedule: any;
}

const interval = [
  '20 min',
  '30 min',
  '40 min',
  '60 min',
  '80 min',
  '100 min',
  '120 min',
];

export const CreateNewAgenda = ({
  handleClickCancel,
  selectedProfessional,
  handleSetProfessionalSchedule,
}: CreateNewAgendaProps) => {
  const [, setIntervalBetweenCalls] = useState('');
  const [periods, setPeriods] = useState<Periods>({
    seg_hours: [],
    ter_hours: [],
    qua_hours: [],
    qui_hours: [],
    sex_hours: [],
  });

  const { handleSubmit: submitForm, control, watch, getValues } = useForm();

  const handleIntervalBetweenCallsChange = (event: SelectChangeEvent) => {
    setIntervalBetweenCalls(event.target.value as string);
  };

  const currentEstablishmentId = localStorage.getItem('id_last_establishment');

  const perfilProfissional = getUserToken().permissao_atual.nome;

  const handleSubmit = async (data) => {
    const intervalFormated = data.intervalo?.replace(/[a-zA-Z\s]/g, '');

    try {
      const response = await postProfessionalSchedule({
        profissionalId: selectedProfessional.idprofissional,
        estabelecimentoSaudeId:
          perfilProfissional === 'Padrão'
            ? Number(currentEstablishmentId)
            : selectedProfessional?.estabelecimentoprofissional,
        procedimento: 'Teste de HPV',
        n_dias: data.n_dias,
        intervalo: intervalFormated,
        idade_min: data.idade_min,
        idade_max: data.idade_max,
        sexo: data.sexo,
        periods,
        ativo: true,
        start_at: data.start_at || new Date(),
      });

      if (response.status !== 201) {
        toast.error(response.data.message, {
          position: 'bottom-right',
        });
      } else {
        toast.success('Agenda cadastrada!', {
          position: 'bottom-right',
        });
        handleClickCancel();
      }

      handleSetProfessionalSchedule({
        profissionalId: selectedProfessional.idprofissional,
        estabelecimentoSaudeId: currentEstablishmentId,
        procedimento: 'Teste de HPV',
        n_dias: data.n_dias,
        intervalo: data.intervalo,
        idade_min: data.idade_min,
        idade_max: data.idade_max,
        sexo: data.sexo,
        periods,
        ativo: true,
        start_at: data.start_at || new Date(),
      });
    } catch (error) {
      console.error(error);
    }
  };

  // useEffect(() => {
  //   if (savedProfessionalSchedule.length) {
  //     setPeriods(savedProfessionalSchedule[0].periods);
  //   }
  // }, [savedProfessionalSchedule]);

  return (
    <form onSubmit={submitForm(handleSubmit)}>
      <Grid container rowSpacing={3} columnSpacing={2}>
        <Grid item xs={12} md={6}>
          <Label labelcolor="rgba(0, 0, 0, 0.87)">Profissional</Label>
          <Typography variant="body1" mt={1}>
            {selectedProfessional?.nomeprofissional}
          </Typography>
        </Grid>

        <Grid item xs={12} md={6}>
          <Label labelcolor="rgba(0, 0, 0, 0.87)">Procedimento</Label>
          <Typography variant="body1" mt={1}>
            Teste de HPV
          </Typography>
        </Grid>

        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <InputLabel id="interval-between-calls-label">
              Tempo de atendimento para cada paciente
            </InputLabel>
            <Controller
              control={control}
              name="intervalo"
              render={({ field: { onChange, value } }) => (
                <Select
                  size="small"
                  labelId="interval-between-calls-label"
                  id="interval-between-calls"
                  value={value}
                  label="Tempo de atendimento para cada paciente"
                  onChange={onChange}
                >
                  {interval.map((item) => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </FormControl>
        </Grid>

        <Grid
          item
          xs={12}
          md={6}
          display={'flex'}
          alignItems={'center'}
          gap={2}
        >
          <div>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
              <Controller
                control={control}
                name="start_at"
                render={({ field: { onChange, onBlur, value, ref } }) => {
                  return (
                    <DatePicker
                      mask="__/__/____"
                      onChange={onChange}
                      value={value ? value : new Date()}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={false}
                          style={{ minWidth: 220 }}
                          label="Data de início dos agendamentos"
                          size="small"
                          variant="outlined"
                          fullWidth
                        />
                      )}
                    />
                  );
                }}
              />
            </LocalizationProvider>
          </div>
          <Controller
            control={control}
            name="n_dias"
            render={({ field: { onChange, value } }) => (
              <TextField
                label="Quantidade de dias futuros pela liberação da agenda"
                size="small"
                variant="outlined"
                fullWidth
                InputLabelProps={{ shrink: true }}
                placeholder="ex: 15"
                onChange={onChange}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="sexo"
            render={({ field: { onChange, value } }) => (
              <FormControl onChange={onChange}>
                <Label mb={1} labelcolor="rgba(0, 0, 0, 0.87)">
                  Restrição por sexo
                </Label>
                <RadioGroup
                  row
                  aria-labelledby="restriction-by-gender-label"
                  name="restriction-by-gender"
                >
                  <FormControlLabel
                    value="N"
                    control={<Radio />}
                    label="Não restringir"
                  />
                  <FormControlLabel
                    value="F"
                    control={<Radio />}
                    label="Apenas feminino"
                  />
                  <FormControlLabel
                    value="M"
                    control={<Radio />}
                    label="Apenas Masculino"
                  />
                </RadioGroup>
              </FormControl>
            )}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Label mb={1} labelcolor="rgba(0, 0, 0, 0.87)">
            Restrição por idade
          </Label>

          <Box
            display="flex"
            justifyContent="space-evenly"
            width="220px"
            gap={2}
          >
            <Controller
              control={control}
              name="idade_min"
              render={({ field: { onChange, value } }) => (
                <TextField
                  label="Entre:"
                  size="small"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  placeholder="ex: 12"
                  onChange={onChange}
                />
              )}
            />
            <Controller
              control={control}
              name="idade_max"
              render={({ field: { onChange, value } }) => (
                <TextField
                  label="E:"
                  size="small"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  placeholder="ex: 60"
                  onChange={onChange}
                />
              )}
            />
          </Box>
        </Grid>
      </Grid>

      <Box mt={3} gap={3} display="flex" alignItems="center" flexWrap="wrap">
        <Typography variant="h6" component="h2" fontWeight="bold">
          Configuração de Horários
        </Typography>

        <AlertInfo text="Recomendamos que os períodos inseridos sejam múltiplos do tempo de atendimento para cada paciente" />
      </Box>

      <PeriodList
        periods={periods}
        setPeriods={setPeriods}
        interval={getValues().intervalo?.replace(/[a-zA-Z\s]/g, '')}
      />

      <Box mt={3} display="flex" justifyContent="space-between">
        <Button type="submit" variant="contained">
          Salvar agenda
        </Button>
        <Button onClick={handleClickCancel} variant="outlined" color="error">
          Cancelar
        </Button>
      </Box>
    </form>
  );
};
