const useClearValues = ({
  setSelectedOptionsStatus,
  setSelectedOptionsNotificationType,
  watch,
  loadDataCentralNotification,
  setDataNotificacaoFinal,
  setDataNotificacaoIni,
  setLoading,
  initialStateListFilter,
  setListFilter,
  setListFilterShow,
}: any) => {
  const clearValues = () => {
    setLoading(true);
    setSelectedOptionsStatus([]);
    setSelectedOptionsNotificationType([]);

    setDataNotificacaoFinal(null);
    setDataNotificacaoIni(null);

    setListFilter(initialStateListFilter);
    setListFilterShow(initialStateListFilter);

    loadDataCentralNotification({ size: watch().size, page: watch().page });
  };

  return { clearValues };
};

export default useClearValues;
