
import styled from '@emotion/styled';
import { Box } from '@mui/material';

export const Container = styled(Box)`
  width: 375px;
  //height: 205px;
  background-color: #fff;
  border: 2px solid #238884;
  color: black;
  border-radius: 8px;
  padding: 24px;
  box-shadow: 0px 1px 18px 0px #0000001F;
  box-shadow: 0px 6px 10px 0px #00000024;
  box-shadow: 0px 3px 5px -1px #00000033;


  /* border-radius: 10px; */
  /* margin: 0.5rem; */
  /* box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);  */
  display: flex;
  flex-direction: column;
  position: fixed;
  bottom: 0;
  right: 0;
  margin: 16px;
  gap: 8px;
  z-index: 9000;

  @media (max-width: 700px) {
    margin-right: 0;
    width: 80vw;
    margin: 20px 10vw;
    padding: 16px;
    background-color: #fffa;
    backdrop-filter: blur(8px);
  }
`
export const CharCount = styled.p`
  font-size: 12px;
  font-weight: 500;
`

export const SubTitle = styled.h3`
  font-size: 14px;
`


export const ContainerButtons = styled(Box)`
  display: flex;
  gap: 16px;
  font-weight: bold;
`
export const ScreenshotContainer = styled(Box)`
  display: flex;
  padding: 0 8px;
  gap: 16px;
  align-items: center;
  margin-bottom: 8px;
  background-color: #fff;
  transition: background-color .2s;

  &:hover {
    background-color: #eee;
    svg {
      opacity: 100%;
    }
  }
  p {
    width: 100%;
  }
  svg {
    width: 30px;
    height: 30px;
    cursor: pointer;
    transition: opacity .2s;
    opacity: 10%;
  }

  img {
    border: 2px solid #238884;
    background-color: #fff;
    width: 70px;
    height: 40px;
    object-fit: contain;

  }
`


export const ContainerHeader = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;

  p {
    width: 100%;
    text-align: center;
    margin: 0;
    font-weight: bold;
    font-size: 1rem;
  }
  svg {
    width: 32px;
    height: 32px;
    cursor: pointer;
  }

`