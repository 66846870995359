import {
  Box,
  Breadcrumbs,
  Paper,
  Container,
  Divider,
  Grid,
  Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';
import ConvocatoriaImage from '../../assets/imgs/undraw_mobile_app_re_catg 1.svg';
import AdministracaoImage from '../../assets/imgs/undraw_doctor_kw-5-l 4.svg';
import AtendimentosImage from '../../assets/imgs/undraw_certificate_re_yadi 1.svg';
import RelatoriosImage from '../../assets/imgs/undraw_data_trends_re_2cdy 1.svg';
import DocumentacaoImage from '../../assets/imgs/undraw_resume_folder_re_e0bi 1.svg';

import * as React from 'react';
import { useStyles } from './Home.style';
import { PeopleContext } from '../../context/PeopleContext';
import { SideMenu } from '../../components/SideMenu';
import { getUserToken } from '../../lib/auth';
import { PerfilContext } from '../../context/PerfilContext';
import AcceptCookies from '../../components/AcceptCookies';

import { AlertListContext } from '../../context/AlertListContext';
import { Bounce, toast } from 'react-toastify';

export default function Home() {
  const { people } = React.useContext(PeopleContext);
  const [loading, setLoading] = React.useState(false);

  const classes = useStyles();

  const auth = getUserToken();

  localStorage.setItem('perfilType', auth?.permissao_atual?.nome);

  React.useEffect(() => {
    setLoading(true);
    if (people) {
      setLoading(false);
    }
  }, [people]);

  // Recupera os estabelecimentos do LocalStorage
  const tokenEstabelecimentosString = localStorage.getItem(
    'token_estabelecimentos'
  );
  const estabelecimentos = tokenEstabelecimentosString
    ? JSON.parse(tokenEstabelecimentosString)
    : [];

  // Função para ajustar a capitalização do nome
  const capitalizeName = (name) => {
    const words = name.trim().split(' ');
    if (words?.length > 0) {
      return words[0][0].toUpperCase() + words[0].slice(1).toLowerCase();
    }
    return '';
  };

  const { alertList, haveResults, setHaveResults } =
    React.useContext(AlertListContext);
  // React.useEffect(() => {
  //   if (alertList?.length > 0) {
  //     const text =
  //       alertList.length === 1
  //         ? `${alertList.length} notificação disponível!`
  //         : `${alertList.length} notificações disponíveis!`;
  //     toast.info(<span>{text}</span>, {
  //       position: 'bottom-right',
  //       autoClose: 5000,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //       theme: 'light',
  //       transition: Bounce,
  //     });
  //   }
  //   if (haveResults) {
  //     toast.success('Resultados disponíveis!', {
  //       position: 'bottom-right',
  //       autoClose: 5000,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //       theme: 'light',
  //       transition: Bounce,
  //     });
  //   }
  //   setHaveResults(false);
  // });

  // Acessa o primeiro estabelecimento do array e obtém o nome formatado
  const primeiroNomeFormatado =
    estabelecimentos?.length > 0
      ? capitalizeName(estabelecimentos[0].pessoa.nome)
      : '';

  const { hasPermission } = React.useContext(PerfilContext);

  if (loading) {
    return <h1>Carregando...</h1>;
  } else {
    return (
      <SideMenu>
        <Container>
          <AcceptCookies />
          <Box sx={{ marginTop: '64px' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography variant="h4" className={classes.customTypography}>
                Boas Vindas, <b>{primeiroNomeFormatado}</b>
              </Typography>
            </div>

            <span data-testid="homeLabel" className={classes.customSpan}>
              Home
            </span>
            <Divider style={{ marginTop: '1.5rem' }} />

            <Breadcrumbs
              className={classes.customBreadcrumbs}
              aria-label="breadcrumb"
              style={{ marginBottom: '16px', marginTop: '16px' }}
            >
              <b>Acesse os serviços disponíveis no Jordana</b>
            </Breadcrumbs>

            <Paper elevation={0}>
              <Grid container spacing={4}>
                {/* {hasPermission('VIEW_CONVOCACAO') && (
                  <Grid item xs={6} sm={3}>
               
                    <Link to="/convocatoria" className={classes['link']}>
                      <img
                        src={ConvocatoriaImage}
                        alt=""
                        className={classes.image}
                      />
                    </Link>
                    <Typography variant="body1">
                      <Link to="/convocatoria" className={classes['link']}>
                        <span className={classes.linkText}>Convocatória</span>
                      </Link>
                    </Typography>
                  </Grid>
                )} */}
                <Grid item xs={6} sm={3}>
                  {/* Coluna 2 */}
                  <Link to="/pacientes" className={classes['link']}>
                    <img
                      src={AtendimentosImage}
                      alt=""
                      className={classes.image}
                    />
                  </Link>
                  <Typography variant="body1" className={classes.linkText}>
                    <Link to="/pacientes" className={classes['link']}>
                      <span className={classes.linkText}>Atendimentos</span>
                    </Link>
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={3}>
                  {/* Coluna 3 */}
                  <Link to="/gerenciar-conta" className={classes['link']}>
                    <img
                      src={AdministracaoImage}
                      alt=""
                      className={classes.image}
                    />
                  </Link>
                  <Typography variant="body1" className={classes.linkText}>
                    <Link to="/gerenciar-conta" className={classes['link']}>
                      <span className={classes.linkText}>Administração</span>
                    </Link>
                  </Typography>
                </Grid>
                {/* {hasPermission(
                  
                  'VIEW_DASHBOARD'
                ) && (
                  <Grid item xs={6} sm={3}>
                  
                    <Link to="/paineis" className={classes['link']}>
                      <img
                        src={RelatoriosImage}
                        alt=""
                        className={classes.image}
                      />
                    </Link>
                    <Typography variant="body1" className={classes.linkText}>
                      <Link to="/paineis" className={classes['link']}>
                        <span className={classes.linkText}>
                          Painéis e Relatórios
                        </span>
                      </Link>
                    </Typography>
                  </Grid>
                )} */}
                <Grid item xs={6} sm={3}>
                  {/* Coluna 5 */}
                  <Link to="/termos-de-uso" className={classes['link']}>
                    <img
                      src={DocumentacaoImage}
                      alt=""
                      className={classes.image}
                    />
                  </Link>
                  <Typography variant="body1" className={classes.linkText}>
                    <Link to="/termos-de-uso" className={classes['link']}>
                      <span className={classes.linkText}>Documentação</span>
                    </Link>
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          </Box>
        </Container>
      </SideMenu>
    );
  }
}
