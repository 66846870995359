import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {
  Box,
  Button,
  Container,
  Divider,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import React, { SyntheticEvent, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ReturnButton from '../../components/ReturnButton';
import { SideMenu } from '../../components/SideMenu';
import { useStyles } from './styles';
import { ProfessionalsSchedule } from './components/ProfessionalsSchedule';
import { PatientSchedule } from './components/PatientSchedule';
import { PerfilContext } from '../../context/PerfilContext';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export const Schedule = () => {
  const styles = useStyles();
  const navigate = useNavigate();
  const { hasPermission } = React.useContext(PerfilContext);

  const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  };

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  const [tabSize, setTabSize] = useState('100%');
  const [value, setValue] = useState(0);

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);

    if (windowDimensions.width < 768) {
      setTabSize('100%');
    } else {
      setTabSize('100%');
    }

    return () => window.removeEventListener('resize', handleResize);
  }, [windowDimensions]);

  return (
    <SideMenu>
      <Container>
        <Box mt={12}>
          <ReturnButton backPage="/home" />
          <Box className={styles.header}>
            <div className={styles.title}>
              <Typography variant="h4" fontWeight="bold" color="text.primary">
                Agenda Digital
              </Typography>

              <Breadcrumbs
                separator={<NavigateNextIcon fontSize="small" />}
                aria-label="breadcrumb"
                style={{ marginBottom: '16px', marginTop: '16px' }}
              >
                <Link key="1" to="/home" className={styles.link}>
                  <Typography key="3" variant="body1" color="text.secundary">
                    Home
                  </Typography>
                </Link>
                <Typography key="2" color="text.secundary">
                  Agenda Digital
                </Typography>
              </Breadcrumbs>
            </div>
            {hasPermission(['CREATE_AGENDA', 'UPDATE_AGENDA']) && (
              <div>
                <Button
                  variant="outlined"
                  onClick={() => navigate(`/schedule-setting`)}
                >
                  Configurar agenda
                </Button>
              </div>
            )}
          </Box>

          <Divider />
        </Box>

        <Box mt={3}>
          <Tabs
            orientation={
              windowDimensions.width < 768 ? 'vertical' : 'horizontal'
            }
            variant="scrollable"
            sx={{
              '& button': {
                maxWidth: `${windowDimensions.width < 768 ? '100%' : '50%'}`,
              },
            }}
            value={value}
            onChange={handleChange}
            className={styles.tabContainer}
          >
            <Tab
              sx={{ width: tabSize }}
              label="AGENDA DO PROFISSIONAL"
              {...a11yProps(0)}
              tabIndex={0}
            />
            <Tab
              sx={{ width: tabSize }}
              label="AGENDA DO PACIENTE"
              {...a11yProps(1)}
              tabIndex={1}
            />
          </Tabs>

          <TabPanel value={value} index={0}>
            <ProfessionalsSchedule />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <PatientSchedule />
          </TabPanel>
        </Box>
      </Container>
    </SideMenu>
  );
};
