import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles({
  container: {
    position: 'fixed',
    width: '100vw',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    boxShadow:
      '0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 11px 15px -7px rgba(0, 0, 0, 0.20)',
    backgroundColor: '#fff',
    borderRadius: '4px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: '24px',
    maxWidth: '567px',
    maxHeight: 'calc(100vh - 8px)',
    paddingBottom: '16px',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '16px 24px',
  },
  closeIcon: {
    backgroundColor: '#e0e0e0',
    width: '24px',
    height: '24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    color: 'gray',
    border: 'none',
    cursor: 'pointer',
    '&:active': {
      backgroundColor: '#c0c0c0',
    },
  },
  body: {
    border: '1px solid #BDBDBD',
    borderRadius: '12px',
    padding: '16px',
    margin: 'auto 16px',
    overflowY: 'auto',

    '&::-webkit-scrollbar': {
      width: '16px',
      height: '0',
    },
    '&::-webkit-scrollbar-thumb': {
      height: '8px',
      border: '2px solid rgba(0, 0, 0, 0)',
      backgroundClip: 'padding-box',
      '-webkit-border-radius': '8px',
      backgroundColor: 'rgba(0, 0, 0, 0.15)',
      '-webkit-box-shadow': 'inset 0 0 0 1px rgba(0, 0, 0, 0.025)',
    },
    '&::-webkit-scrollbar-button': {
      width: '0',
      height: '0',
      display: 'none',
    },
    '&::-webkit-scrollbar-corner': {
      backgroundColor: 'transparent',
    },
  },
  h6: {
    color: 'rgba(0, 0, 0, 0.87)',
  },
  footer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '8px 8px 0',
  },
  newPatientLink: {
    textDecoration: 'none',
    color: '#2196F3',
  },
  errorText: {
    '& > p': {
      marginLeft: '0 !important',
    },
  },
});
