import { BarChart } from '../barChart';

interface GlandularPreNeoplasticProps {
  data: number[];
  categories?: String[];
  height?: number;
}

export function GlandularPreNeoplastic({ data }: GlandularPreNeoplasticProps) {
  return (
    <BarChart
      data={data}
      categories={[
        ['Adenocarcinoma', 'in situ'],
        ['Adenocarcinoma', 'invasor'],
        ['Outras Neoplasias', 'Malignas'],
      ]}
      colors={['#FF9800']}
    />
  );
}
