import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Radio,
  RadioGroup,
  Tooltip,
} from '@mui/material';

import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { useState } from 'react';

const ModalAmostragem = ({
  setOpeModalAmostragem,
  openModalAmostragem,
  selectedOption,
  setSelectedOption,
  prepareDownload,
  stringToModalExportActualList,
  totalItems,
  title,
  titleTooltip,
}) => {
  const [sizeFile, setSizeFile] = useState('all_list');
  const [disableButton, setDisableButton] = useState(false);
  const handleCloseDialogCancel = () => {
    setOpeModalAmostragem(false);
  };

  const handleChangeOptionFile = (e) => {
    setSelectedOption(e.target.value);
  };

  const handleChangeSizeFile = (e) => {
    setSizeFile(e.target.value);
  };

  return (
    <Dialog
      open={openModalAmostragem}
      onClose={handleCloseDialogCancel}
      sx={{
        '.MuiPaper-root': {
          width: '567px !important',
        },
      }}
    >
      <DialogTitle style={{ fontSize: '20px', margin: 0, fontWeight: 'bold' }}>
        {title}
      </DialogTitle>
      {!!titleTooltip && (
        <Tooltip
          title={titleTooltip}
          placement="top"
          sx={{ position: 'relative' }}
        >
          <div
            style={{
              backgroundColor: 'transparent',
              height: '32px',
              width: '100%',
              position: 'absolute',
              top: '13px',
            }}
          />
        </Tooltip>
      )}
      <DialogContent sx={{ maxWidth: '567px' }}>
        <Box
          sx={{
            border: '1px solid #BDBDBD',
            borderRadius: '12px',
            padding: '16px',
            marginBottom: '24px',
          }}
        >
          <h1 style={{ fontSize: '20px', margin: 0 }}>Formato</h1>
          <Divider sx={{ margin: '10px 0px' }} />
          <RadioGroup>
            <FormControlLabel
              value="XLSX"
              control={
                <Radio
                  color="primary"
                  checked={selectedOption === 'XLSX'}
                  onChange={handleChangeOptionFile}
                />
              }
              label="Excel"
              sx={{ margin: 0 }}
            />
            <FormControlLabel
              value="CSV"
              control={
                <Radio
                  color="primary"
                  checked={selectedOption === 'CSV'}
                  onChange={handleChangeOptionFile}
                />
              }
              label="CSV"
              sx={{ margin: 0 }}
            />
          </RadioGroup>
        </Box>

        <Box
          sx={{
            border: '1px solid #BDBDBD',
            borderRadius: '12px',
            padding: '16px',
          }}
        >
          <h1 style={{ fontSize: '20px', margin: 0 }}>Impressão</h1>
          <Divider sx={{ margin: '10px 0px' }} />
          <RadioGroup>
            <div style={{ display: 'flex', gap: '10px' }}>
              <FormControlLabel
                value="current_list"
                control={
                  <Radio
                    color="primary"
                    checked={sizeFile === 'current_list'}
                  />
                }
                label="Listagem atual"
                sx={{ margin: 0 }}
                onChange={handleChangeSizeFile}
              />
              <p style={{ fontSize: '12px' }}>
                ({stringToModalExportActualList})
              </p>
            </div>

            <div style={{ display: 'flex', gap: '10px' }}>
              <FormControlLabel
                value="all_list"
                control={
                  <Radio color="primary" checked={sizeFile === 'all_list'} />
                }
                label="Listagem completa"
                sx={{ margin: 0 }}
                onChange={handleChangeSizeFile}
              />
              <p style={{ fontSize: '12px' }}>({totalItems} linhas)</p>
            </div>
          </RadioGroup>
        </Box>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center', padding: '16px' }}>
        <Button
          endIcon={<FileDownloadIcon />}
          variant="contained"
          disabled={disableButton}
          onClick={() => {
            setDisableButton(true);
            prepareDownload({
              selectedOption,
              sizeFile,
            });
          }}
        >
          {disableButton ? 'GERANDO ARQUIVO' : 'GERAR ARQUIVO'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalAmostragem;
