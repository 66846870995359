import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  Paper,
  Typography,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { schemaChangePassword } from './schemas';
import { InputPassword } from '../../../components/InputPassword';
import { toast } from 'react-toastify';
import { changePassword } from '../../../lib/auth';
import { useState } from 'react';

const ChangePassword = () => {
  const [loading, setLoading] = useState(false);
  const {
    handleSubmit: handleSubmitPasswordForm,
    formState: { errors: errorsPasswordForm },
    control: controlPasswordForm,
    setValue,
  } = useForm({ resolver: yupResolver(schemaChangePassword) });

  const styleFormControl = {
    '& .MuiFormLabel-asterisk': {
      color: '#d32f2f',
    },
  };

  const handleSubmitPassword = async (data) => {
    setLoading(true);
    const response = await changePassword({
      senhaAtual: window.btoa(data.senha_atual),
      novaSenha: window.btoa(data.nova_senha),
    });

    if (response.success) {
      toast.success('Senha Alterada com Sucesso', {
        position: 'bottom-left',
      });
      setLoading(false);
    } else {
      toast.error(response.message, {
        position: 'bottom-left',
        autoClose: false,
      });
      setLoading(false);
    }
  };

  const handleResetForm = () => {
    setValue('senha_atual', '');
    setValue('nova_senha', '');
    setValue('confirme_nova_senha', '');
    window.history.back()
  };

  return (
    <Box>
      <Paper variant="outlined" sx={{ padding: '32px' }}>
        <Typography
          variant="h5"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          overflow="hidden"
          fontWeight="bold"
          sx={{ marginBottom: '12px' }}
        >
          Alterar Senha
        </Typography>

        <Divider />

        <form
          onSubmit={handleSubmitPasswordForm(handleSubmitPassword)}
          noValidate
        >
          <Grid container>
            <Grid
              sm={3.7}
              item
              flexDirection="column"
              justifyContent="space-between"
            >
              <Box>
                <p style={{ fontSize: '16px' }}>
                  Use uma senha segura para ajudar a proteger a sua conta
                </p>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <FormControl required sx={styleFormControl}>
                  <Controller
                    control={controlPasswordForm}
                    name="senha_atual"
                    render={({ field: { onChange, value } }) => (
                      <InputPassword
                        required
                        onChange={onChange}
                        value={value ? value : ''}
                        error={!!errorsPasswordForm.senha_atual}
                        sx={{ marginBottom: '24px' }}
                        label="Senha Atual"
                        size="small"
                        helperText={errorsPasswordForm.senha_atual?.message}
                      />
                    )}
                  />
                </FormControl>
                <FormControl required sx={styleFormControl}>
                  <Controller
                    control={controlPasswordForm}
                    name="nova_senha"
                    render={({ field: { onChange, value } }) => (
                      <InputPassword
                        required
                        onChange={onChange}
                        value={value ? value : ''}
                        sx={{ marginBottom: '24px' }}
                        label="Nova senha"
                        size="small"
                        error={
                          !!errorsPasswordForm.nova_senha ||
                          errorsPasswordForm.confirme_nova_senha
                        }
                      />
                    )}
                  />
                </FormControl>
                <FormControl required sx={styleFormControl}>
                  <Controller
                    control={controlPasswordForm}
                    name="confirme_nova_senha"
                    render={({ field: { onChange, value } }) => (
                      <InputPassword
                        required
                        label="Confirme a Nova Senha"
                        onChange={onChange}
                        value={value ? value : ''}
                        sx={{ marginBottom: '24px' }}
                        size="small"
                        error={!!errorsPasswordForm.confirme_nova_senha}
                        helperText={
                          errorsPasswordForm.confirme_nova_senha?.message
                        }
                      />
                    )}
                  />
                </FormControl>

                {!!errorsPasswordForm.nova_senha ? (
                  <>
                    <p style={{ color: '#d32f2f', margin: 0 }}>
                      Essa senha não contempla os requisitos mínimos de
                      segurança. Sua senha deve ter no mínimo 8 caracteres
                      contendo:
                    </p>
                    <ul style={{ fontSize: '14px', color: '#d32f2f' }}>
                      {schemaChangePassword.fields.nova_senha.tests.map(
                        (test) =>
                          test.OPTIONS.message ? (
                            <li>{test.OPTIONS.message}</li>
                          ) : undefined
                      )}
                    </ul>
                  </>
                ) : (
                  <p style={{ fontSize: '14px' }}>
                    Sua senha deve ter no mínimo 8 caracteres, incluindo uma
                    letra maiúscula, uma letra minúscula, um número e um
                    caractere especial
                  </p>
                )}
              </Box>
            </Grid>
            <Grid container item justifyContent="space-between">
              <Button
                sx={{
                  marginTop: '10px',
                  height: '36px',
                }}
                variant="contained"
                type="submit"
                endIcon={<CheckIcon />}
                disabled={loading}
              >
                Alterar Senha
              </Button>

              <Button
                sx={{
                  marginTop: '10px',
                  height: '36px',
                }}
                variant="outlined"
                type="button"
                color="error"
                onClick={handleResetForm}
              >
                Cancelar
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Box>
  );
};

export default ChangePassword;
