import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import CustomizedButton from '../CustomizedButton';
import ButtonNew from '../ButtonNew';
import { Button, Chip } from '@mui/material';
import { useState } from 'react';
import ModalActions from '../ModalActions';
import {
  BoxButtonsActionsHeaderTable,
  ButtonGenerate,
} from '../TableUserManagements/styles';
import { ContainerButtonsActions } from './styles';
import api from '../../api';
import { toast } from 'react-toastify';
import { PerfilContext } from '../../context/PerfilContext';
interface Column {
  id: any;
  label: string;
  minWidth?: number;
  align?: 'right';
  format?: (value: number) => string;
}

const columns: readonly Column[] = [
  { id: 'cnes', label: 'CNES', minWidth: 170 },
  { id: 'estabelecimentoNome', label: 'Nome', minWidth: 100 },
  { id: 'email', label: 'E-mail', minWidth: 170 },
  {
    id: 'tipoEstabelecimento',
    label: 'Tipo de Estabelecimento',
    minWidth: 170,
  },
  { id: 'status', label: 'Status', minWidth: 170 },
  { id: '', label: '', minWidth: 170 },
];

export default function TableEstablismentsManagements({
  // getUsers,
  list,
  nome,
  filter,
  setEstablishmentList,
}) {
  let navigate = useNavigate();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const { hasPermission } = React.useContext(PerfilContext);
  const [selectedEstabelecimento, setSelectedEstabelecimento] = useState<any>(
    {}
  );
  const [openModalDisable, setOpenModalDisable] = useState(false);
  const getPagination = async ({ pageSize, page }) => {
    const response = await api.get('/estabelecimentos-saude', {
      params: {
        ...filter,
        size: pageSize,
        page: page,
      },
    });

    if (response.status === 200) {
      setEstablishmentList(response.data);
    }
  };

  const handleChangePage = async (event: unknown, newPage: number) => {
    setPage(newPage + 1);
    try {
      const response = await api.get('/estabelecimentos-saude', {
        params: {
          ...filter,
          size: String(rowsPerPage),
          page: newPage + 1,
        },
      });

      if (response.status === 200) {
        setEstablishmentList(response.data);
      }
    } catch (error) {}
    // getPagination({ page: newPage + 1, pageSize: rowsPerPage });
  };
  const handleChangeRowsPerPage = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(Number(event.target.value));
    try {
      const response = await api.get('/estabelecimentos-saude', {
        params: {
          ...filter,
          size: event.target.value,
          page: page,
        },
      });

      if (response.status === 200) {
        setEstablishmentList(response.data);
      }
    } catch (error) {}
  };
  const visualizarPaciente = (data: any) => {
    setSelectedEstabelecimento(data);
    setOpenModalDisable(true);
  };

  const handleCloseModalDisable = () => {
    setOpenModalDisable(false);
  };

  const toggleEstabelecimento = async (id, isAtivo) => {
    try {
      const response = await api.put(`/estabelecimentos-saude/${id}`, {
        inativo: !isAtivo,
      });

      if (response.status === 200) {
        toast.success('Estabelecimento atualizado com sucesso!', {
          position: 'bottom-right',
        });
        getPagination({ pageSize: rowsPerPage, page: page + 1 });
      }
    } catch (error) {
      toast.error('Erro ao atualizar o estabelecimento:', {
        position: 'bottom-right',
      });
    }
  };

  const handleDisable = () => {
    toggleEstabelecimento(
      selectedEstabelecimento.id,
      selectedEstabelecimento.inativo
    );
    handleCloseModalDisable();
  };

  const handleEnable = () => {
    toggleEstabelecimento(
      selectedEstabelecimento.id,
      selectedEstabelecimento.inativo
    );
    handleCloseModalDisable();
  };

  const getChipLabel = (inativo) => {
    let label;
    switch (inativo) {
      case true:
        label = 'Inativo';
        break;
      case false:
        label = 'Ativo';
        break;
    }
    return label;
  };

  return (
    <Box sx={{ width: '100%', marginTop: '15px', marginBottom: '50px' }}>
      <ContainerButtonsActions>
        {hasPermission('CREATE_ESTABELECIMENTO') && (
          <ButtonNew
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => navigate('/cadastrar-estabelecimento')}
          >
            Cadastrar Estabelecimento
          </ButtonNew>
        )}
        <BoxButtonsActionsHeaderTable>
          <ButtonGenerate variant="outlined" onClick={() => console.log()}>
            Gerar relatório
          </ButtonGenerate>
          <ButtonGenerate variant="outlined" onClick={() => console.log()}>
            Gerar Dashboard
          </ButtonGenerate>
        </BoxButtonsActionsHeaderTable>
      </ContainerButtonsActions>
      <Paper sx={{ width: '100%', mb: 2, pt: 2 }}>
        {hasPermission('UPDATE_ESTABELECIMENTO') && (
          <ModalActions
            open={openModalDisable}
            handleClose={handleCloseModalDisable}
            title={
              selectedEstabelecimento.inativo !== true
                ? 'Desabilitar'
                : 'Habilitar'
            }
            content={
              <p>
                Você deseja{' '}
                {selectedEstabelecimento.inativo !== true
                  ? 'desabilitar'
                  : 'habilitar'}{' '}
                o Estabelecimento de Saúde{' '}
                <b>{selectedEstabelecimento.estabelecimentoNome}</b> no Jordana
                ?
              </p>
            }
            buttons={
              <>
                {selectedEstabelecimento.inativo !== true ? (
                  <Button
                    variant="contained"
                    onClick={handleDisable}
                    endIcon={<CheckIcon />}
                  >
                    Desabilitar
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    onClick={handleEnable}
                    endIcon={<CheckIcon />}
                  >
                    Habilitar
                  </Button>
                )}
                <Button
                  variant="outlined"
                  color="error"
                  onClick={handleCloseModalDisable}
                  endIcon={<CloseIcon />}
                >
                  Cancelar
                </Button>
              </>
            }
          />
        )}

        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={'medium'}
          >
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth, fontWeight: 'bold' }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {list.data?.map((row: any, index: number) => {
                return (
                  <TableRow hover tabIndex={-1} key={index}>
                    <TableCell component="th" scope="row">
                      {row.cnes}
                    </TableCell>
                    <TableCell>{row.nome_fantasia}</TableCell>
                    <TableCell>{row.email}</TableCell>
                    <TableCell>
                      {row.tipo_estabelecimento
                        ? row.tipo_estabelecimento.tipo_estabelecimento
                        : 'Não Definido'}
                    </TableCell>
                    <TableCell>
                      <Chip
                        color={row.inativo === true ? 'error' : 'success'}
                        label={getChipLabel(row.inativo)}
                      />
                    </TableCell>
                    <TableCell>
                      {hasPermission('UPDATE_ESTABELECIMENTO') ? (
                        row.inativo !== true ? (
                          <CustomizedButton
                            variant="outlined"
                            onClick={() => visualizarPaciente(row)}
                            startIcon={<CloseIcon />}
                          >
                            Desabilitar
                          </CustomizedButton>
                        ) : (
                          <CustomizedButton
                            variant="outlined"
                            onClick={() => visualizarPaciente(row)}
                            startIcon={<CheckIcon />}
                          >
                            Habilitar
                          </CustomizedButton>
                        )
                      ) : undefined}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[25, 50, 100]}
            component="div"
            count={list.totalItems || 0}
            rowsPerPage={rowsPerPage}
            page={list.page - 1}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage={'Itens por página'}
            labelDisplayedRows={({ from, to, count }) => {
              return `${from}–${to} de ${
                count !== -1 ? count : `mais do que ${to}`
              }`;
            }}
          />
        </TableContainer>
      </Paper>
    </Box>
  );
}
