import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Paper,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { LaboratoryRounded } from '../../assets/imgs/LaboratoryRounded';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';

export const ModalHpvCitologiaReflexo = ({
  openModalHpv,
  setOpenModalHpv,
  setHpvSelected,
  hpvSelected,
  hpvList,
  setObjHpv,
  objHpv,
  data,
}) => {
  const handleRedirectToHpv = () => {
    const target = `/pacientes/${data?.examData?.pessoa?.id}/exame/${hpvSelected}/visualizar`;

    const objFormatedToLocalStorage = { examData: { ...objHpv } };

    localStorage.setItem('state', JSON.stringify(objFormatedToLocalStorage));
    window.open(target, '_blank', 'noreferrer');
  };

  return (
    <Dialog open={openModalHpv} onClose={() => setOpenModalHpv(false)}>
      <DialogTitle>
        <Typography variant="h6" fontWeight="bold">
          Visualizar Exame: Teste de HPV
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Paper
          variant="outlined"
          style={{ padding: '16px', borderRadius: '12px' }}
        >
          <Box display="flex" alignItems="center" gap="24px">
            <LaboratoryRounded />
            <Typography variant="h6" fontWeight="bold">
              Qual teste de HPV você deseja visualizar?
            </Typography>
          </Box>

          <Box my={3}>
            <Divider />
          </Box>
          <Box>
            <RadioGroup
              onChange={(_, newValue) => setHpvSelected(newValue)}
              value={hpvSelected}
            >
              {hpvList?.slice(0, 5).map((hpvItem: any) => {
                const dateHpv = hpvItem?.hpv?.dt_coleta.split('-');
                return (
                  <>
                    <FormControlLabel
                      value={hpvItem?.hpv.id}
                      onClick={() => setObjHpv(hpvItem)}
                      control={<Radio />}
                      label={`Teste de HPV (${
                        dateHpv[2] + '/' + dateHpv[1] + '/' + dateHpv[0]
                      })`}
                    />
                  </>
                );
              })}
            </RadioGroup>
          </Box>
        </Paper>
      </DialogContent>
      <DialogActions
        sx={{ justifyContent: 'center', gap: '24px', padding: '16px' }}
      >
        <Button
          sx={{
            backgroundColor: '#238884',
          }}
          onClick={handleRedirectToHpv}
          variant="contained"
          startIcon={<CheckIcon />}
          disabled={!hpvSelected}
        >
          CONTINUAR
        </Button>

        <Button
          variant="outlined"
          color="error"
          onClick={() => setOpenModalHpv(false)}
          startIcon={<ClearIcon />}
        >
          CANCELAR
        </Button>
      </DialogActions>
    </Dialog>
  );
};
