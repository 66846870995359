import { Box, Divider, FormControl, FormControlLabel, Grid, Radio, RadioGroup, Typography } from '@mui/material';
import { SetStateAction } from 'react';
import TratamentoIcon from '../../../assets/imgs/tratamento.svg';

export const Tratamento = ({ setSelectedValue }) => {
  const handleChange = (event: { target: { value: SetStateAction<string>; }; }) => {
    setSelectedValue(event.target.value);
  }

  return (
    <Box sx={{ width: '100%' }} p={3}>
      <Grid container alignItems="center" spacing={3}>
        <Grid item xs={12} sm={2} display="flex" justifyContent="center">
          <img src={TratamentoIcon} alt="Exames Icon" />
        </Grid>
        <Grid item xs={12} sm={9}>
          <Typography variant="h6" fontWeight="bold">Tratamento</Typography>
        </Grid>
      </Grid>

      <Box sx={{ marginTop: '16px', marginBottom: '16px' }}>
        <Divider />
      </Box>
      <FormControl>
        <RadioGroup
          aria-labelledby="Tipo de tratamento"
          name="treatment-type"
        >
          <FormControlLabel
            value="exerese-tipo-1"
            control={<Radio color="primary" onChange={handleChange} />} label="Exerese da Zona de Transformação - Tipo 1"
          />
          <FormControlLabel
            value="exerese-tipo-2"
            control={<Radio color="primary" onChange={handleChange} />} label="Exerese da Zona de Transformação - Tipo 2"
          />
          <FormControlLabel
            value="exerese-tipo-3"
            control={<Radio color="primary" onChange={handleChange} />} label="Exerese da Zona de Transformação - Tipo 3"
          />
          <FormControlLabel
            value="conizacao"
            control={<Radio color="primary" onChange={handleChange} />} label="Conização"
          />
          <FormControlLabel
            value="histerectomia"
            control={<Radio color="primary" onChange={handleChange} />} label="Histerectomia"
          />
          <FormControlLabel
            value="quimioterapia"
            control={<Radio color="primary" onChange={handleChange} />} label="Quimioterapia"
          />
          <FormControlLabel
            value="indicado"
            control={<Radio color="primary" onChange={handleChange} />} label="Exame indicado pelo sistema"
          />
        </RadioGroup>
      </FormControl>

    </Box>

  );
}
