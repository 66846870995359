import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, BoxProps, Chip, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { formatDate } from '../../utils/formatters';
import statusJson from '../../utils/status.json';
import { IProcedimento } from '../Timeline';
import { Label } from '../Typo';
import { Details } from './Details';
import { Card, ExpandIconWrapper } from './styles';
import api from '../../api';

interface ICardExam extends BoxProps {
  icon?: string;
  label?: string;
  procedimentoData?: IProcedimento;
  borderLeftColor?: string;
  chipLabel?: string;
  chipColor?: string;
  index?: number;
  chipBackgroundColor?: string;
  children?: React.ReactNode;
  suggestion?: boolean;
  loadData?: (id: number) => void;
}

export default function CardExam({
  icon,
  label,
  procedimentoData,
  borderLeftColor,
  chipLabel,
  index = -1,
  chipColor,
  chipBackgroundColor,
  suggestion,
  loadData,
  ...props
}: ICardExam) {
  const [showDetails, setShowDetails] = useState(false);
  const [status, setStatus] = useState('');
  const [result, setResult] = useState();
  const [showButtonResultadoCitologia, setShowButtonResultadoCitologia] =
    useState(false);

  const [disableButtonResultado, setDisableButtonResultado] = useState(true);

  useEffect(() => {
    if (procedimentoData?.status) {
      setStatus(procedimentoData?.status);
    }
  }, [procedimentoData?.status]);

  const handleSetStatus = (status: string) => {
    setStatus(status);
  };

  const getCitologiaResultado = async () => {
    try {
      const response = await api.get(
        `/citologia/${procedimentoData?.citologia?.id}/resultado`
      );

      setResult(response.data);

      setDisableButtonResultado(false);
      setShowButtonResultadoCitologia(true);
    } catch (error) {
      setShowButtonResultadoCitologia(true);
      setDisableButtonResultado(false);
      setResult(undefined);
    }
  };

  const getLabelChip = (label) => {
    if (label === 'Colposcopia' || label === 'Histopatologia') {
      return 'Disponivel';
    }

    if (label === 'Intraepitelial LIEAG' && status === 'COLETADO') {
      return 'Resultado Disponível';
    }

    if (label === 'Intraepitelial LIEAG' && status === 'FALHA') {
      return 'Excluir Registro';
    }
    return statusJson[status || 'FALHA'].label;
  };

  const getColor = () => {
    if (label === 'Colposcopia' || label === 'Histopatologia') {
      return '#4CAF50';
    }

    if (
      label === 'Teste de HPV' ||
      label === 'Citologia - Reflexo (após Teste de HPV Positivo)'
    ) {
      return statusJson[status || 'FALHA'].color;
    }
    if (label === 'Intraepitelial LIEAG' && status === 'COLETADO') {
      return '#4caf50';
    }

    if (label === 'Intraepitelial LIEAG' && status === 'FALHA') {
      return '#f44336';
    }
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      {...props}
      data-testid={`exam-item-card-${index}`}
    >
      <Card
        p={4}
        borderLeftColor={
          label === 'Colposcopia' ||
          label === 'Histopatologia' ||
          label === 'Intraepitelial LIEAG'
            ? '#4CAF50'
            : statusJson[status || 'FALHA'].color
        }
      >
        <Box
          display="flex"
          justifyContent="space-between"
          flexWrap="wrap"
          alignItems="center"
          width="100%"
        >
          <Box className="card-date">
            <Label>
              {label === 'Colposcopia' ||
              label === 'Histopatologia' ||
              label === 'Citologia - Reflexo (após Teste de HPV Positivo)'
                ? 'Resultado'
                : 'Realizado'}{' '}
              em
            </Label>
            <Box display="flex" alignItems="center">
              <Typography
                variant="subtitle1"
                component="p"
                fontWeight="bold"
                color="text.secondary"
                data-testid={`exam-${index}-data`}
              >
                {formatDate(
                  procedimentoData?.hpv?.dt_coleta ||
                    procedimentoData?.citologia?.dt_coleta ||
                    procedimentoData?.colposcopia?.dt_coleta ||
                    procedimentoData?.histopatologia?.dt_coleta ||
                    procedimentoData?.created_at
                )}
              </Typography>
            </Box>
          </Box>

          <Box className="card-type">
            <Label>
              {label === 'Intraepitelial LIEAG'
                ? 'Nome do Tratamento'
                : 'Nome do Exame'}
            </Label>
            <Typography
              variant="body1"
              component="p"
              color="text.secondary"
              data-testid={`exam-${index}-name`}
            >
              {label === 'Intraepitelial LIEAG' ? (
                <p>
                  {' '}
                  Tratamento das Lesões
                  <br /> Intraepiteliais
                </p>
              ) : (
                label
              )}
            </Typography>
          </Box>

          <Box className="card-status" data-testid={`exam-${index}-status`}>
            <Chip
              data-testid={`exam-${index}-status-item`}
              label={getLabelChip(label)}
              sx={{
                color: `${'white'}`,
                backgroundColor: getColor(),
              }}
            />
          </Box>

          <ExpandIconWrapper
            data-testid={`exam-${index}-collapse`}
            onClick={() => {
              if (procedimentoData?.citologia) {
                getCitologiaResultado();
              }
              setShowDetails(!showDetails);
            }}
          >
            <ExpandMoreIcon
              fontSize="medium"
              sx={{
                color: 'white',
                transform: showDetails ? 'rotate(180deg)' : 'rotate(0deg)',
              }}
            />
          </ExpandIconWrapper>
        </Box>

        {showDetails && (
          <Details
            showButtonResultadoCitologia={showButtonResultadoCitologia}
            disableButtonResultado={disableButtonResultado}
            hasResult={result}
            index={index}
            setStatus={handleSetStatus}
            data={procedimentoData}
          />
        )}
      </Card>
    </Box>
  );
}
