import * as Yup from 'yup';
import { Escolha } from './Hpv';

export const schemaCitologia = Yup.object({
  anamnese: Yup.object({
    motivo: Yup.string().required('Campo Obrigatório'),
    papanicolaou: Yup.string().required('Campo Obrigatório'),
    ano_papanicolaou: Yup.number().when('papanicolaou', {
      is: (papanicolaou: any) => {
        return papanicolaou === Escolha.Sim;
      },
      then: Yup.number().required('Campo Obrigatório'),
    }),
    diu: Yup.string().required('Campo Obrigatório'),
    gravida: Yup.string().required('Campo Obrigatório'),
    anticoncepcional: Yup.string().required().required('Campo Obrigatório'),
    radioterapia: Yup.string().required('Campo Obrigatório'),
    dt_menstruacao_desconhecida: Yup.boolean().default(false),
    dt_menstruacao: Yup.string().when('dt_menstruacao_desconhecida', {
      is: (dt_menstruacao_desconhecida: boolean) => {
        return dt_menstruacao_desconhecida === false;
      },
      then: Yup.string().required('Campo Obrigatório'),
      otherwise: Yup.string(),
    }),
    sangramento_relacao: Yup.string().required('Campo Obrigatório'),
    menopausa: Yup.string().required('Campo Obrigatório'),
    sangramento_menopausa: Yup.string().when('menopausa', {
      is: (menopausa) => {
        return menopausa === 'SIM';
      },
      then: Yup.string().required('Campo Obrigatório').nullable(),
      otherwise: Yup.string().nullable(),
    }),

    hormonio: Yup.string().when('menopausa', {
      is: (menopausa) => {
        return menopausa === 'SIM';
      },
      then: Yup.string().required('Campo Obrigatório').nullable(),
      otherwise: Yup.string().nullable(),
    }),
  }),

  clinico: Yup.object({
    inspecao: Yup.string().required('Campo Obrigatório'),
    dst: Yup.boolean().required('Campo Obrigatório'),
    dt_coleta: Yup.string().required('Campo Obrigatório'),
    local_coleta: Yup.string().required('Campo Obrigatório'),
    mtd_coleta: Yup.object().required('Campo Obrigatório'),
  }),
});

//   dt_coleta: Yup.string().required('Campo Obrigatório'),
// //   dt_resultado: Yup.string().required('Campo Obrigatório'),
//   rede_atendimento: Yup.boolean().required('Campo Obrigatório'),
//   laboratorio_responsavel: Yup.string(),
//   protocolo: Yup.string(),
// //   resultado: Yup.string().required('Campo Obrigatório'),
//   escamosa_indeterminada: Yup.object({
//     ASC_US: Yup.boolean(),
//     ASC_H: Yup.boolean(),
//   }),
//   glandular_indeterminada: Yup.object({
//     neoplasicas: Yup.boolean(),
//     alto_grau: Yup.boolean(),
//   }),
//   origem_indefinida: Yup.object({
//     neoplasicas: Yup.boolean(),
//     alto_grau: Yup.boolean(),
//   }),
//   atipia_glandular: Yup.object({
//     in_situ: Yup.boolean(),
//     cervical: Yup.boolean(),
//     endometrial: Yup.boolean(),
//     outras: Yup.boolean(),
//   }),
//   atipia_escamosa: Yup.object({
//     grau_I: Yup.boolean(),
//     grau_II: Yup.boolean(),
//     altograu: Yup.boolean(),
//     carcinoma: Yup.boolean(),
//   }),
//   outra_neoplasia_maligna: Yup.boolean(),
//   pessoaId: Yup.number(),
//   profissionalId: Yup.number(),
//   estabelecimentoSaudeId: Yup.number(),
