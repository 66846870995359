import { Box, Typography } from '@mui/material';
import styles from './styles.module.css';
import { DataSource } from '../dataSource';
import { ReactNode } from 'react';

interface ChartCardProps {
  title: string;
  subtitle: string;
  children?: ReactNode;
  fullWidth?: boolean;
}
export function ChartCard({
  title,
  subtitle,
  children,
  fullWidth,
}: ChartCardProps) {
  return (
    <div
      className={styles.container}
      style={{
        maxWidth: `${fullWidth ? '100%' : '566px'}`,
      }}
    >
      <Box px={2}>
        <Typography variant="h5" fontWeight="bold" className={styles.cardTitle}>
          {title} <br />
          {subtitle}
        </Typography>
      </Box>
      <Box mt={{ xs: 2, md: 7 }} mb={{ xs: 2, md: 4 }} pr={{ xs: 0, md: 4 }}>
        {children}
      </Box>
      <DataSource />
    </div>
  );
}
