import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles({
  textField: {
    '& label.Mui-focused': {
      color: '#238884',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#238884',
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: '#238884',
      },
    },
  },
  link: {
    '&:hover:not(.Mui-disabled)': {
      cursor: 'pointer',
    },
  },
});
