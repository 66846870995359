import {
  Box,
  Breadcrumbs,
  Container,
  Divider,
  Paper,
  Typography,
} from '@mui/material';
import { SideMenu } from '../../../components/SideMenu';
import ReturnButton from '../../../components/ReturnButton';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Link } from 'react-router-dom';

export const ReportsCitologia = () => {
  return (
    <SideMenu>
      <Container>
        <Box mt={12}>
          <ReturnButton backPage="/home" />
          <Typography
            variant="h4"
            fontWeight="bold"
            style={{ marginBottom: '30px' }}
          >
            Relatório Geral de Citologia
          </Typography>
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
            style={{ marginBottom: '16px', marginTop: '16px' }}
          >
            <Link
              key="1"
              style={{
                textDecoration: 'none',
                color: '#646464',
              }}
              to="/"
            >
              Home
            </Link>

            <Typography key="3" color="text.primary">
              Relatório Geral de Citologia
            </Typography>
          </Breadcrumbs>
        </Box>

        <Box mt={5}>
          <Paper variant="outlined" sx={{ borderRadius: '10px', padding: 4 }}>
            <Typography
              variant="h4"
              fontWeight="bold"
              style={{ marginBottom: '30px', fontSize: '24px' }}
            >
              Listagem
            </Typography>
            <Divider />
            <form>
              {/* <Controller />
              <Controller />
              <Controller />
              <Controller />
              <Controller />
              <Controller />
              <Controller />
              <Controller />
              <Controller />
              <Controller />
              <Controller />
              <Controller />
              <Controller />
              <Controller /> */}
            </form>
          </Paper>
        </Box>
      </Container>
    </SideMenu>
  );
};

export default ReportsCitologia;
