import moment from 'moment';

const useSetPayload = ({ watch }) => {
  const setPayload = () => {
    let payload: any;
    return new Promise((resolve, reject) => {
      payload = {
        idade_inicio: watch().idade_ini,
        idade_final: watch().idade_fin,
        motivo: watch().motivo,
        atendimento_status: watch().atendimento_status,
        n_convocacao: watch().n_convocacao,
        status: watch().status,
        profissionalColetaId: watch().responsavel_coleta?.idprofissional,
        profissionalAgendadoId: watch().profissionalAgendadoId?.idprofissional,
        profissionalLaudoId: watch().responsavel_laudo?.idprofissional,
        dt_resultado: watch().dt_resultado
          ? moment(watch().dt_resultado).format('YYYY-MM-DD')
          : null,
        dt_coleta: watch().dt_coleta
          ? moment(watch().dt_coleta).format('YYYY-MM-DD')
          : null,
        resultado: watch().resultado?.value,
        estado: watch().estado,
        municipio: watch().municipio,
        estabelecimentoSaudeId: watch().estabelecimento_saude?.id,
        search: watch().search,
        size: watch().size,
        page: watch().page,
      };

      resolve(payload);
    });
  };

  return { setPayload };
};

export default useSetPayload;
