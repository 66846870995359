import { yupResolver } from '@hookform/resolvers/yup';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import {
  Autocomplete,
  Box,
  Breadcrumbs,
  Button,
  Checkbox,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';
import { ptBR } from 'date-fns/locale';
import { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import api from '../../../api';
import BoxInfoPatient from '../../../components/BoxInfoPatient/BoxInfoPatient';
import ReturnButton from '../../../components/ReturnButton';
import { SideMenu } from '../../../components/SideMenu';
import { StyledAsteriskTextField } from '../../../components/StyledAsteriskTextField/styles';
import { PerfilContext } from '../../../context/PerfilContext';
import { ArrowBack, Style } from '@mui/icons-material';
import moment from 'moment';
import { toast } from 'react-toastify';
import { schema } from './schema';
import { getUserToken } from '../../../lib/auth';

// interface Inputs {
//   status: string;
// }

interface FormValues {
  dt_realizado: Date | null;
  estabelecimentoSaudeId: number;
  indicacao_ezt: string;
  realizado_in: string;
  tipo_exerese_ezt: string;
  anestesia_local: boolean;
  anestesia_sedacao: boolean;
  anestesia_raqui: boolean;
  anestesia_outras: boolean;
  anestesia_outras_desc: string | null;
  tipo_procedimento: string;
  pessoaId: string;
  profissionalId: {
    idprofissional: number;
    nomeprofissional: string;
    cbo: string;
  };
  cbo: string;
}

export const Asterisk = () => {
  return <span style={{ color: '#d32f2f' }}>*</span>;
};

export function InjuryTreatment() {
  const [patientData, setPatientData] = useState<any>([]);
  const [examData, setExamData] = useState<any>([]);
  const { hasPermission } = useContext(PerfilContext);
  const [dataRealizacao, setDataRealizacao] = useState<Date | null>(null);
  const [servicoSaude, setServicoSaude] = useState('');
  const [indicacaoEZT, setIndicacaoEZT] = useState('');
  const [tipoExerese, setTipoExerese] = useState('');
  const [localRealizacao, setLocalRealizacao] = useState('');
  const location = useLocation();
  const state = location.state;
  const [responsaveis, setResponsaveis] = useState<any>([]);
  const [optionsEstabelecimentos, setOptionsEstabelecimentos] = useState<any>(
    []
  );
  const [estabelecimentoNome, setEstabelecimentoNome] = useState('');
  const [disableInputEstabelecimento, setDisableInputEstabelecimento] =
    useState(false);
  const [disableInputProfissional, setDisableInputProfissional] =
    useState(false);
  const [errorAnestesia, setErrorAnestesia] = useState(false);

  const handleChangeIndicacaoEZT = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setIndicacaoEZT((event.target as HTMLInputElement).value);
  };

  const handleChangeServicoSaude = (event: SelectChangeEvent) => {
    setServicoSaude(event.target.value as string);
  };

  const handleChangeTipoExerese = (event: SelectChangeEvent) => {
    setTipoExerese(event.target.value as string);
  };

  const handleChangeLocalRealizacao = (event: SelectChangeEvent) => {
    setLocalRealizacao(event.target.value as string);
  };

  const {
    formState: { errors },
    register,
    control,
    setValue,
    watch,
    handleSubmit,
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
  });

  let navigate = useNavigate();

  const { id } = useParams();

  useEffect(() => {
    async function loadData(id: any) {
      await api
        .get('/pessoas/' + id)
        .then((response) => {
          setPatientData(response.data);
        })
        .catch((err) => {
          console.error('ops! ocorreu um erro' + err);
        });

      hasPermission('VIEW_EXAME') &&
        (await api
          .get('/pacientes/' + id + '/exames')
          .then((response) => {
            setExamData(response.data.exames);
          })
          .catch((err) => {
            console.error('ops! ocorreu um erro' + err);
          }));
    }
    loadData(id);
  }, [id]);

  const editPaciente = () => {
    navigate(`/pacientes/${patientData.id}/edit`, {
      state: { ...patientData, url: window.location.pathname },
    });
  };

  const estabelecimentoId = watch('estabelecimentoSaudeId');

  const userToken = getUserToken();

  const isOutros =
    userToken.permissao_atual.contexto === 'HOSPITAL/UPAE' &&
    userToken.permissao_atual.id === 15;

  const isAdmin =
    (userToken.permissao_atual.contexto === 'HOSPITAL/UPAE' &&
      userToken.permissao_atual.id === 16) ||
    userToken.permissao_atual.id === 2;

  const isAdminHospital =
    userToken.permissao_atual.contexto === 'HOSPITAL/UPAE' &&
    userToken.permissao_atual.id === 16;

  const isMedicoHospital =
    userToken.permissao_atual.contexto === 'HOSPITAL/UPAE' &&
    userToken.permissao_atual.id === 14;

  const isAdminLaboratorio =
    userToken.permissao_atual.contexto === 'LABORATÓRIO' &&
    userToken.permissao_atual.nome === 'Admin Estabelecimento';

  const isMedicoLaboratorio =
    userToken.permissao_atual.contexto === 'LABORATÓRIO' &&
    userToken.permissao_atual.id === 7;

  const isOutrosLaboratorio =
    userToken.permissao_atual.contexto === 'LABORATÓRIO' &&
    userToken.permissao_atual.id === 8;

  async function loadResponsaveis() {
    setValue('pessoaId', state.id);
    setValue('estabelecimentoSaudeId', Number(estabelecimentoId));
    const response = await api.get('/profissionais/cbo/list', {
      params: {
        cboTypes: 'MÉDICO',
        estabelecimentoId: estabelecimentoId,
      },
    });

    const profissionais = response.data.map((profissional) => {
      return {
        idprofissional: profissional.id,
        nomeprofissional: `${profissional.pessoa.nome}`,
        label: `${profissional.pessoa.nome}`,
        cbo: profissional.cbo,
        idUsuario: profissional?.pessoa?.usuario?.id,
      };
    });

    profissionais.forEach((prof) => {
      if (prof?.idUsuario === userToken.id && !isAdmin && !isOutros) {
        setValue('profissionalId', prof);
        setDisableInputProfissional(true);
        setValue('cbo', prof.cbo);
      }
    });
    setResponsaveis(profissionais);
  }

  const loadEstabelecimentos = async () => {
    try {
      const response = await api.get('/intraepitelial/estabelecimentos');

      if (response.status === 200) {
        setOptionsEstabelecimentos(response.data);

        if (
          isAdminLaboratorio ||
          isMedicoLaboratorio ||
          isOutrosLaboratorio ||
          isAdminHospital ||
          isMedicoHospital ||
          isOutros
        ) {
          const estabelecimentoUser = response.data.find(
            (estab) => estab.id === userToken.estabelecimentoId
          );
          setEstabelecimentoNome(
            userToken?.estabelecimento_saude?.nome_fantasia
          );
          setValue('estabelecimentoSaudeId', estabelecimentoUser.id);
          setDisableInputEstabelecimento(true);
        }

        response.data.forEach((estab) => {
          if (String(estab.id) === String(userToken?.estabelecimentoId)) {
            setValue('estabelecimentoSaudeId', estab.id);
            setDisableInputEstabelecimento(true);
          }
        });
      } else {
        toast.error('Recarregue a página', { position: 'bottom-right' });
      }
    } catch (error) {}
  };

  const onSubmit = async (data) => {
    const payload = {
      dt_realizado: data.dt_realizado
        ? moment(data.dt_realizado).format('YYYY-MM-DD')
        : null,
      indicacao_ezt: data.indicacao_ezt ?? 0,
      realizado_in: data.realizado_in ?? 0,
      tipo_exerese_ezt: data.tipo_exerese_ezt ?? 0,
      tipo_procedimento: data.tipo_procedimento ?? 0,
      anestesia_local: data.anestesia_local ?? false,
      anestesia_sedacao: data.anestesia_sedacao ?? false,
      anestesia_raqui: data.anestesia_raqui ?? false,
      anestesia_outras: data.anestesia_outras ?? false,
      anestesia_outras_desc: data.anestesia_outras_desc ?? null,
      profissionalId: data.profissionalId.idprofissional ?? null,
      estabelecimentoSaudeId: estabelecimentoId,
      pessoaId: data.pessoaId ?? 0,
    };

    try {
      const response = await api.post('/intraepitelial', payload);

      if (response.status === 201) {
        navigate(`/pacientes/${location.state.id}`);
      }
    } catch (error: any) {
      toast.error('Ocorreu um erro, recarregue a página.', {
        position: 'bottom-right',
      });
      toast.error(error.error?.message, { position: 'bottom-right' });
    }
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  useEffect(() => {
    if (estabelecimentoId) {
      loadResponsaveis();
    }
  }, [estabelecimentoId]);

  useEffect(() => {
    loadEstabelecimentos();
  }, []);

  useEffect(() => {
    setValue('pessoaId', location.state.id);
  }, []);

  useEffect(() => {
    if (!hasPermission('CREATE_EXAME_INTRAEPITELIAL')) {
      navigate('/home');
    }
  }, []);

  const anestesiaLocal = watch('anestesia_local');
  const anestesiaRaqui = watch('anestesia_raqui');
  const anestesiaSedacao = watch('anestesia_sedacao');
  const anestesiaOutras = watch('anestesia_outras');

  useEffect(() => {
    if (
      Object.keys(errors).length > 0 &&
      !anestesiaLocal &&
      !anestesiaRaqui &&
      !anestesiaSedacao &&
      !anestesiaOutras
    ) {
      setErrorAnestesia(true);
      return;
    } else {
      setErrorAnestesia(false);
    }
  }, [
    anestesiaLocal,
    anestesiaRaqui,
    anestesiaSedacao,
    anestesiaOutras,
    errors,
  ]);

  // useEffect(() => {
  //   setValue('anestesia_local', true);
  // }, [setValue]);

  return (
    <SideMenu>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Container>
          <Box mt={12}>
            <ReturnButton backPage="/pacientes" />
            <Typography
              variant="h4"
              fontWeight="bold"
              style={{ marginBottom: '5px' }}
            >
              Tratamento das Lesões Intraepiteliais
            </Typography>
            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
              <Link
                style={{
                  textDecoration: 'none',
                  color: '#646464',
                }}
                to="/pacientes"
              >
                Listagem de Pacientes
              </Link>
              <Link
                style={{
                  textDecoration: 'none',
                  color: '#646464',
                }}
                to={`/pacientes/${id}`}
              >
                Ficha da Paciente
              </Link>
              <Typography color="text.primary">
                Tratamento das Lesões Intraepiteliais
              </Typography>
            </Breadcrumbs>
            <BoxInfoPatient
              data={patientData}
              buttonEditar={editPaciente}
              viewButton={true}
            ></BoxInfoPatient>
          </Box>

          <p
            style={{
              fontSize: '12px',
              textAlign: 'right',
              color: '#d32f2f',
              margin: '24px 0',
            }}
          >
            <i>* Resposta obrigatória</i>
          </p>

          <Paper variant="outlined" sx={{ borderRadius: '10px', padding: 4 }}>
            <Typography
              variant="h4"
              fontWeight="bold"
              style={{ marginBottom: '30px', fontSize: '24px' }}
            >
              Exérese da Zona de Transformação (EZT)
            </Typography>
            <Divider />

            <Grid container spacing={3}>
              <Grid item xs={12}>
                <FormControl sx={{ mt: 3, width: 220 }} size="small">
                  <Controller
                    name="dt_realizado"
                    control={control}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <LocalizationProvider
                          dateAdapter={AdapterDateFns}
                          locale={ptBR}
                        >
                          <DatePicker
                            onChange={(value) => onChange(value)}
                            mask="__/__/____"
                            value={value ? value : null}
                            renderInput={(params) => (
                              <StyledAsteriskTextField
                                {...params}
                                label="Data da Realização"
                                error={!!errors?.dt_realizado}
                                helperText={errors.dt_realizado?.message}
                                size="small"
                                variant="outlined"
                                fullWidth
                                required
                              />
                            )}
                          />
                        </LocalizationProvider>
                      );
                    }}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <FormControl fullWidth size="small">
                  <Controller
                    control={control}
                    name="estabelecimentoSaudeId"
                    render={({ field: { onChange, value } }) => {
                      return (
                        <>
                          {isAdminHospital ||
                          isMedicoHospital ||
                          isOutros ||
                          isAdminLaboratorio ||
                          isOutrosLaboratorio ||
                          isMedicoLaboratorio ? (
                            <>
                              <TextField
                                size="small"
                                disabled
                                label="Serviço de Saúde da realização da EZT"
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                value={estabelecimentoNome}
                              />
                            </>
                          ) : (
                            <>
                              <StyledAsteriskTextField
                                required
                                error={!!errors?.estabelecimentoSaudeId}
                                helperText={
                                  errors.estabelecimentoSaudeId?.message
                                }
                                label="Serviço de Saúde da realização da EZT"
                                fullWidth
                                disabled={disableInputEstabelecimento}
                                size="small"
                                select
                                value={value ? value : ''}
                                onChange={onChange}
                              >
                                {optionsEstabelecimentos.map((estab) => {
                                  return (
                                    <MenuItem value={estab?.id}>
                                      {estab?.label}
                                    </MenuItem>
                                  );
                                })}
                              </StyledAsteriskTextField>
                            </>
                          )}
                        </>
                      );
                    }}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <Typography
                  color={!!errors?.indicacao_ezt ? '#d32f2f' : 'gray'}
                  mb={1}
                >
                  Indicação da EZT? <Asterisk />
                </Typography>

                <Box ml={3}>
                  <FormControl error={!!errors?.indicacao_ezt}>
                    <FormLabel>Terapêutica:</FormLabel>
                    <Controller
                      name="indicacao_ezt"
                      control={control}
                      render={({ field: { onChange, value } }) => {
                        return (
                          <>
                            <RadioGroup value={value} onChange={onChange}>
                              <FormControlLabel
                                value={1}
                                control={<Radio />}
                                label=" Biópsia prévia com lesão de alto grau"
                              />
                              <FormControlLabel
                                value={2}
                                control={<Radio />}
                                label="Critérios ver e tratar"
                              />
                              <Box mt={1} display="flex" flexDirection="column">
                                <FormLabel>Diagnóstica:</FormLabel>
                                <FormControlLabel
                                  value={3}
                                  control={<Radio />}
                                  label=" Investigação do canal"
                                />
                              </Box>
                            </RadioGroup>
                          </>
                        );
                      }}
                    />
                    <FormHelperText>
                      {errors?.indicacao_ezt?.message}
                    </FormHelperText>
                  </FormControl>
                </Box>
              </Grid>

              <Grid item xs={12}>
                <FormControl
                  error={!!errors?.realizado_in}
                  required
                  sx={{
                    '& .MuiFormLabel-asterisk': {
                      color: '#d32f2f',
                    },
                  }}
                >
                  <FormLabel>Local de Realização do Procedimento</FormLabel>
                  <Controller
                    name="realizado_in"
                    control={control}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <RadioGroup value={value} onChange={onChange} row>
                          <FormControlLabel
                            value={1}
                            control={<Radio />}
                            label=" Ambulatorial"
                          />
                          <FormControlLabel
                            value={2}
                            control={<Radio />}
                            label="Sala de procedimentos/pequenas cirurgias"
                          />
                          <FormControlLabel
                            value={3}
                            control={<Radio />}
                            label="Bloco cirúrgico"
                          />
                        </RadioGroup>
                      );
                    }}
                  />
                  <FormHelperText>
                    {errors?.realizado_in?.message}
                  </FormHelperText>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <FormControl
                  error={!!errors?.tipo_exerese_ezt}
                  required
                  sx={{
                    '& .MuiFormLabel-asterisk': {
                      color: '#d32f2f',
                    },
                  }}
                >
                  <FormLabel>
                    Tipo de Exérese da Zona de Transformação (EZT)
                  </FormLabel>
                  <Controller
                    name="tipo_exerese_ezt"
                    control={control}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <RadioGroup value={value} onChange={onChange} row>
                          <FormControlLabel
                            value={1}
                            control={<Radio />}
                            label=" Tipo 1"
                          />
                          <FormControlLabel
                            value={2}
                            control={<Radio />}
                            label="Tipo 2"
                          />
                          <FormControlLabel
                            value={3}
                            control={<Radio />}
                            label="Tipo 3"
                          />
                        </RadioGroup>
                      );
                    }}
                  />
                  <FormHelperText>
                    {errors?.tipo_exerese_ezt?.message}
                  </FormHelperText>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <FormControl
                  error={errorAnestesia}
                  component="fieldset"
                  required
                  sx={{
                    '& .MuiFormLabel-asterisk': {
                      color: '#d32f2f',
                    },
                  }}
                >
                  <FormLabel
                    component="legend"
                    sx={{ color: errorAnestesia ? '#d32f2f' : 'gray' }}
                  >
                    Anestesia Utilizada
                  </FormLabel>

                  <FormGroup aria-label="position">
                    <Controller
                      name="anestesia_local"
                      control={control}
                      render={({ field: { onChange, value } }) => {
                        return (
                          <FormControlLabel
                            value="local"
                            control={
                              <Checkbox onChange={onChange} checked={value} />
                            }
                            label="Local"
                          />
                        );
                      }}
                    />
                    <Controller
                      name="anestesia_sedacao"
                      control={control}
                      render={({ field: { onChange, value } }) => {
                        return (
                          <FormControlLabel
                            value="sedacao"
                            control={
                              <Checkbox onChange={onChange} checked={value} />
                            }
                            label="Sedação"
                          />
                        );
                      }}
                    />
                    <Controller
                      name="anestesia_raqui"
                      control={control}
                      render={({ field: { onChange, value } }) => {
                        return (
                          <FormControlLabel
                            value="raqui"
                            control={
                              <Checkbox onChange={onChange} checked={value} />
                            }
                            label="Raqui"
                          />
                        );
                      }}
                    />
                    <Controller
                      name="anestesia_outras"
                      control={control}
                      render={({ field: { onChange, value } }) => {
                        return (
                          <FormControlLabel
                            value="outras"
                            control={
                              <Checkbox
                                onChange={(_, checked) => {
                                  if (!checked) {
                                    setValue('anestesia_outras_desc', null);
                                  }
                                  onChange(checked);
                                }}
                                checked={value}
                              />
                            }
                            label="Outras"
                          />
                        );
                      }}
                    />
                  </FormGroup>
                  <FormHelperText>
                    {errorAnestesia ? 'Campo Obrigatório' : ''}
                  </FormHelperText>
                </FormControl>
              </Grid>

              {watch('anestesia_outras') === true && (
                <Grid item xs={12}>
                  <Controller
                    name="anestesia_outras_desc"
                    control={control}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <TextField
                          label="Outras - Descrição"
                          multiline
                          fullWidth
                          onChange={onChange}
                          value={value}
                          InputLabelProps={{ shrink: true }}
                          rows={4}
                        />
                      );
                    }}
                  />
                </Grid>
              )}

              <Grid item xs={12}>
                <Typography
                  color={!!errors?.tipo_procedimento ? '#d32f2f' : 'gray'}
                  mb={1}
                >
                  Tipo de Procedimento Realizado? <Asterisk />
                </Typography>

                <Box ml={3}>
                  <FormControl error={!!errors?.tipo_procedimento}>
                    <FormLabel>Por alça diatérmica:</FormLabel>
                    <Controller
                      name="tipo_procedimento"
                      control={control}
                      render={({ field: { onChange, value } }) => {
                        return (
                          <RadioGroup value={value} onChange={onChange}>
                            <FormControlLabel
                              value={1}
                              control={<Radio />}
                              label=" Excisão com uma passagem da alça diatérmica"
                            />
                            <FormControlLabel
                              value={2}
                              control={<Radio />}
                              label="Excisão com múltiplas passagens da alça diatérmica com ampliação de margens laterais"
                            />
                            <FormControlLabel
                              value={3}
                              control={<Radio />}
                              label="Excisão com múltiplas passagens da alça diatérmica com ampliação de canal endocervical (margem endocervical)"
                            />

                            <Box mt={4} display="flex" flexDirection="column">
                              <FormLabel>Por bisturi a fio:</FormLabel>
                              <FormControlLabel
                                value={4}
                                control={<Radio />}
                                label=" Cone clássico"
                              />
                            </Box>
                          </RadioGroup>
                        );
                      }}
                    />
                    <FormHelperText>
                      {errors?.tipo_procedimento?.message}
                    </FormHelperText>
                  </FormControl>
                </Box>
              </Grid>

              <Grid item xs={12} md={8}>
                <Controller
                  name="profissionalId"
                  control={control}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <Autocomplete
                        options={responsaveis}
                        getOptionLabel={(option: any) => {
                          return option?.nomeprofissional;
                        }}
                        disabled={disableInputProfissional}
                        onChange={(_, newValue: any) => {
                          setValue('profissionalId', newValue);
                          setValue('cbo', newValue?.cbo);
                        }}
                        value={value ? value : null}
                        renderInput={(params) => (
                          <StyledAsteriskTextField
                            {...params}
                            required
                            fullWidth
                            error={!!errors?.profissionalId?.idprofissional}
                            helperText={
                              errors?.profissionalId?.idprofissional?.message
                            }
                            label="Profissional Responsável"
                            size="small"
                            variant="outlined"
                          />
                        )}
                      />
                    );
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Controller
                  name="cbo"
                  control={control}
                  render={({ field: { value } }) => {
                    return (
                      <StyledAsteriskTextField
                        required
                        disabled
                        value={value ? value : ''}
                        label="CBO"
                        fullWidth
                        size="small"
                        InputLabelProps={{
                          shrink: value ? true : false,
                        }}
                      />
                    );
                  }}
                />
              </Grid>
            </Grid>
          </Paper>

          <Box mt={3}>
            {state.examData && (
              <Button
                sx={{
                  backgroundColor: '#17BEBB',
                }}
                variant="contained"
                type="submit"
                startIcon={<ArrowBack />}
                onClick={() => navigate(`/pacientes/${id}`)}
              >
                Voltar
              </Button>
            )}
          </Box>

          {!state.examData && (
            <Box mt={3} display="flex" justifyContent="space-between">
              <Button
                sx={{
                  backgroundColor: '#17BEBB',
                }}
                variant="contained"
                type="submit"
                startIcon={<CheckIcon />}
              >
                Cadastrar
              </Button>

              <Button
                variant="outlined"
                color="error"
                onClick={() => navigate(`/pacientes/${location.state.id}`)}
                startIcon={<ClearIcon />}
              >
                Cancelar
              </Button>
            </Box>
          )}
        </Container>
      </form>
    </SideMenu>
  );
}
