import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import ArrowForward from '@mui/icons-material/ArrowForward';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { ButtonIcon, ButtonUpdate } from './ModalStatus.style';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { ptBR } from 'date-fns/locale';
import {
  Autocomplete,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material';
import api from '../../api';
import jwtDecode from 'jwt-decode';
import { getAccessToken } from '../../lib/auth';
import { StyledAsteriskTextField } from '../StyledAsteriskTextField/styles';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 432,
  bgcolor: 'background.paper',
  borderRadius: '4px',
  boxShadow: 24,
  padding: '16px 24px',
};

const schema = Yup.object({
  status: Yup.string(),
});

// interface ExamInfo {
//   status: string;
//   updated_at?: Date;
// }

interface Inputs {
  status: string;
}

export interface User {
  id: number;
  usuario: string;
  email: string;
  pessoa: {
    id: number;
    nome: string;
    apelido: string;
    cpf: number;
    dt_nascimento: string;
    cns: string;
    raca: string;
    nacionalidade: string;
    nome_mae: string;
    sexo: string;
    contato_preferencial: string;
    escolaridade: string;
    orientacao: number;
    identidade_genero: number;
  };
}

export interface TokenData {
  aud: string;
  exp: number;
  iat: number;
  scope: string;
  sub: number;
  user: User;
}

export default function ModalStatus({
  setModal,
  open,
  examData,
  setCardStatus,
  examRoute,
}) {
  const {
    handleSubmit,
    formState: { errors },
    register,
  } = useForm<Inputs>({
    resolver: yupResolver(schema),
  });

  const handleClose = () => setModal(false);
  const [dataAlteracao, setDataAlteracao] = useState<Date | null>(null);
  const [status, setStatus] = useState();

  const [user, setUser] = useState({} as User);

  const handleStatusChange = (newValue) => {
    // setStatus(event.target.value as string);
  };

  const handleChange = (event) => {
    setStatus(event.target.value);
  };

  useEffect(() => {
    if (examData.status) {
      setStatus(examData.status);
      setDataAlteracao(examData.updated_at);
    } else if (examData.procedimento) {
      setStatus(examData.procedimento.status);
      setDataAlteracao(examData.procedimento.updated_at);
    }
  }, [examData]);

  useEffect(() => {
    const token: TokenData = jwtDecode(getAccessToken());
    if (token) {
      setUser(token.user);
    }
  }, [examData]);

  const onSubmit = async () => {
    //TODO Debito tecnico implementar rota para atualizar status independente do tipo do exame

    const res = !!examData.intraepitelial
      ? await api.put(`/intraepitelial/${examData.intraepitelial?.id}/status`, {
          status: status,
        })
      : await api.patch(`/exames/${examData.id}`, {
          status,
        });

    if (res.data) {
      setCardStatus(status);
      handleClose();
    }
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={style}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            mb={5}
          >
            <Typography
              id="modal-title"
              variant="h6"
              component="h6"
              fontWeight="bold"
            >
              Alterar Status
            </Typography>
            <ButtonIcon onClick={handleClose}>
              <CloseIcon fontSize="small" style={{ color: '#BDBDBD' }} />
            </ButtonIcon>
          </Box>

          <form onSubmit={handleSubmit(onSubmit)}>
            <Box>
              <Box mb={3}>
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  locale={ptBR}
                >
                  <DatePicker
                    disabled
                    label="Data da alteração"
                    value={dataAlteracao}
                    onChange={(newValue) => {
                      setDataAlteracao(newValue);
                    }}
                    renderInput={(params) => (
                      <StyledAsteriskTextField
                        {...params}
                        {...register('status', { required: true })}
                        required
                        error={!!errors.status}
                        helperText={errors.status?.message}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Box>

              <FormControl fullWidth>
                {!!examData.intraepitelial ? (
                  <TextField
                    value={status}
                    onChange={handleChange}
                    select
                    label="Status"
                  >
                    <MenuItem value="COLETADO">Resultado Disponível</MenuItem>
                    <MenuItem value="FALHA">Excluir o Registro</MenuItem>
                  </TextField>
                ) : (
                  <TextField
                    value={status}
                    onChange={handleChange}
                    select
                    label="Status"
                  >
                    <MenuItem value="NOVO">Novo</MenuItem>
                    <MenuItem value="COLETADO">Coletado</MenuItem>
                    <MenuItem value="ENVIADO_PARA_ANALISE">
                      Enviado para Análise
                    </MenuItem>
                    <MenuItem value="EM_ANALISE">Em análise</MenuItem>
                    <MenuItem value="RESULTADO_DISPONIVEL">Disponível</MenuItem>
                    <MenuItem value="ENTREGUE">Entregue</MenuItem>
                    <MenuItem value="RESULTADO_NAO_ENTREGUE">
                      Não Entregue
                    </MenuItem>
                    <MenuItem value="FALHA">Falha</MenuItem>
                  </TextField>
                )}

                {/* <TextField value={'COLETADO'} select>
                  {examData?.intraepitelial ? (
                    <>
                      <MenuItem value="COLETADO">
                        Resultado Disponível{' '}
                      </MenuItem>
                      <MenuItem value="FALHA">Excluir o Registro</MenuItem>
                    </>
                  ) : (
                    <>
                      <MenuItem value="NOVO">Novo </MenuItem>
                      <MenuItem value="COLETADO">Coletado</MenuItem>
                      <MenuItem value="ENVIADO_PARA_ANALISE">
                        Enviado para Análise
                      </MenuItem>
                      <MenuItem value="EM_ANALISE">Em análise</MenuItem>
                      <MenuItem value="RESULTADO_DISPONIVEL">
                        Disponível
                      </MenuItem>
                      <MenuItem value="ENTREGUE">Entregue</MenuItem>
                      <MenuItem value="RESULTADO_NAO_ENTREGUE">
                        Não Entregue
                      </MenuItem>
                      <MenuItem value="FALHA">Falha</MenuItem>
                    </>
                  )}
                </TextField> */}
                {/* <Autocomplete
                  value={status ? status : null}
                  getOptionLabel={(option) => option?.label}
                  renderOption={(option: any) => option?.label}
                  onChange={(_, newValue) => handleStatusChange(newValue)}
                  options={
                    examData?.intraepitelial
                      ? [
                          { value: 'COLETADO', label: 'Resultado Disponível' },
                          { value: 'FALHA', label: 'Excluir Registro' },
                        ]
                      : [
                          { value: 'NOVO', label: 'Novo' },
                          { value: 'COLETADO', label: 'Coletado' },
                          {
                            value: 'ENVIADO_PARA_ANALISE',
                            label: ' Enviado para Análise',
                          },
                          { value: 'EM_ANALISE', label: 'Em análise' },
                          { value: 'ENTREGUE', label: 'Entregue' },
                          {
                            value: 'RESULTADO_NAO_ENTREGUE',
                            label: 'Não Entregue',
                          },
                          { value: 'FALHA', label: 'Falha' },
                        ]
                  }
                  renderInput={(params) => {
                    return (
                      <TextField
                        {...params}
                        InputLabelProps={{ shrink: status ? true : false }}
                      />
                    );
                  }}
                /> */}
                {/* <Autocomplete
                  placeholder=""
                  options={
                    examData?.intraepitelial
                      ? [
                          { value: 'COLETADO', label: 'Resultado Disponível' },
                          { value: 'FALHA', label: 'Excluir Registro' },
                        ]
                      : [
                          { value: 'NOVO', label: 'Novo' },
                          { value: 'COLETADO', label: 'Coletado' },
                          {
                            value: 'ENVIADO_PARA_ANALISE',
                            label: ' Enviado para Análise',
                          },
                          { value: 'EM_ANALISE', label: 'Em análise' },
                          { value: 'ENTREGUE', label: 'Entregue' },
                          {
                            value: 'RESULTADO_NAO_ENTREGUE',
                            label: 'Não Entregue',
                          },
                          { value: 'FALHA', label: 'Falha' },
                        ]
                  }
                  getOptionLabel={(option) => {
                    return option?.label;
                  }}
                  // onChange={(e, newValue) => {
                  //   onChange(newValue);
                  // }}
                  renderOption={(props, item) => (
                    <li {...props}>{item.label}</li>
                  )}
                  value={status ? status : undefined}
                  renderInput={(params) => (
                    <StyledAsteriskTextField
                      {...params}
                      fullWidth
                      label="Status"
                      size="small"
                    />
                  )}
                /> */}
              </FormControl>

              <Box my={3}>
                <StyledAsteriskTextField
                  fullWidth
                  required
                  label="Profissional Responsável"
                  variant="outlined"
                  value={user ? user.pessoa?.nome : ''}
                  disabled
                />
              </Box>

              <Box mb={6}>
                <StyledAsteriskTextField
                  required
                  fullWidth
                  label="CNS do Profissional"
                  variant="outlined"
                  value={user ? user.pessoa?.cns : ''}
                  disabled
                />
              </Box>

              <Box display="flex" justifyContent="center" my={1}>
                <ButtonUpdate
                  variant="contained"
                  endIcon={<ArrowForward />}
                  type="submit"
                >
                  Atualizar
                </ButtonUpdate>
              </Box>
            </Box>
          </form>
        </Box>
      </Modal>
    </div>
  );
}
