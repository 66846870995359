import styled from '@emotion/styled';
import { Box } from '@mui/material';

export const ContainerDialog = styled(Box)`
  padding: 24px;
`;

export const BoxHeaderDialog = styled(Box)`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const TitleDialog = styled('p')`
  font-size: 20px;
  font-weight: 700;
  margin: 0;
`;

export const BoxButtonsDialog = styled(Box)`
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 24px;
`;
