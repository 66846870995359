import { Box } from '@mui/material';
import { useContext, useState } from 'react';
import TypeEmail from './TypeEmail';

import SendedEmail from './SendedEmail';
import { BackgroundContext } from '../../../context/BackgroundContext';

const ForgotPasswordSendEmail = () => {
  const [pageCurrent, setPageCurrent] = useState('type_email');
  const [myEmail,  setMyEmail] = useState("");
  const { backgroundImage } = useContext(BackgroundContext);

  return (
    <Box
      bgcolor="lightblue"
      width="100vw"
      height="100vh"
      sx={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0 16px',
      }}
    >
      {pageCurrent === 'type_email' && (
        <TypeEmail setPageCurrent={setPageCurrent} setMyEmail={setMyEmail}/>
      )}
      {pageCurrent === 'sended_email' && <SendedEmail myEmail={myEmail}/>}
    </Box>
  );
};

export default ForgotPasswordSendEmail;
