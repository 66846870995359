import React from 'react';
import TablePagination from '@mui/material/TablePagination';
import IconButton from '@mui/material/IconButton';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { PeopleContext } from '../../context/PeopleContext';

function CustomTablePagination(props) {
  const { count, page, rowsPerPage, setPage } = props;

  const { getPeople } = React.useContext(PeopleContext);

  const handleFirstPageButtonClick = () => {
    getPeople(0 + 1, rowsPerPage);
    setPage(0);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const handleLastPageButtonClick = () => {
    getPeople(Math.ceil(count / rowsPerPage - 1), rowsPerPage);
    setPage(Math.ceil(count / rowsPerPage - 1));

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const handleChangePage = (newPage: number) => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    getPeople(newPage + 1, rowsPerPage);
    setPage(newPage);
  };

  return (
    <TablePagination
      {...props}
      ActionsComponent={() => (
        <div style={{ display: 'flex' }}>
          <IconButton
            onClick={handleFirstPageButtonClick}
            disabled={page === 0}
          >
            <KeyboardDoubleArrowLeftIcon />
          </IconButton>
          <IconButton
            onClick={() => handleChangePage(page - 1)}
            disabled={page === 0}
          >
            <KeyboardArrowLeftIcon />
          </IconButton>
          <IconButton
            onClick={() => handleChangePage(page + 1)}
            disabled={page >= Math.floor(count / rowsPerPage) - 1}
          >
            <KeyboardArrowRightIcon />
          </IconButton>
          <IconButton
            onClick={handleLastPageButtonClick}
            disabled={page >= Math.floor(count / rowsPerPage) - 1}
          >
            <KeyboardDoubleArrowRightIcon />
          </IconButton>
        </div>
      )}
    />
  );
}

export default CustomTablePagination;
