import { Button, Dialog } from '@mui/material';
import {
  BoxButtonsDialog,
  BoxHeaderDialog,
  ContainerDialog,
  TitleDialog,
} from './styles';
import CancelIcon from '@mui/icons-material/Cancel';

const ModalActions = ({ content, buttons, title, handleClose, open }) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      <ContainerDialog>
        <BoxHeaderDialog>
          <TitleDialog>{title}</TitleDialog>
          <Button onClick={handleClose}>
            <CancelIcon sx={{ color: '#A6A6A6' }} />
          </Button>
        </BoxHeaderDialog>
        {content}
        <BoxButtonsDialog>{buttons}</BoxButtonsDialog>
      </ContainerDialog>
    </Dialog>
  );
};

export default ModalActions;
