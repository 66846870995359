import pdfFile1 from './document/terms-use.png';
import { SideMenu } from '../../components/SideMenu';
import { Box, Breadcrumbs, Container, Typography } from '@mui/material';
import ReturnButton from '../../components/ReturnButton';
import { StyledScrollBar } from './styles';
import JordanaLogo from '../../assets/imgs/Jordana-Normal.svg';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Link } from 'react-router-dom';

const TermsUse = () => {
  return (
    <SideMenu>
      <Container sx={{marginTop: "64px"}}>
        <Box>
          <ReturnButton backPage="/home" />
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <div>
              <Typography variant="h4" fontWeight="bold">
                Termos de Uso
              </Typography>
              <Breadcrumbs
                separator={<NavigateNextIcon fontSize="small" />}
                aria-label="breadcrumb"
    style={{ marginBottom: '16px', marginTop: '16px' }}
                sx={{ marginBottom: '24px' }}
              >
                <Link
                  style={{
                    textDecoration: 'none',
                    color: '#646464',
                  }}
                  to="/home"
                >
                  Home
                </Link>
                <Typography key="3" color="text.primary">
                  Termos de Uso
                </Typography>
              </Breadcrumbs>
            </div>
          </div>

          <StyledScrollBar>
            <img src={JordanaLogo} alt="" style={{ width: '245px' }} />
            <img style={{ width: '740px' }} src={pdfFile1} alt="" />
          </StyledScrollBar>
        </Box>
      </Container>
    </SideMenu>
  );
};

export default TermsUse;
