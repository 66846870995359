import { useContext } from 'react';
import { LoadingContext } from '../../../../../context/LoadingContext';
import { getReportsHistopatologia } from '../../../../../lib/Reports/getReportsHistopatologia';

const useRequestFilter = ({ setTableData }) => {
  const { setLoading } = useContext(LoadingContext);
  const loadReportsHistopatologia = async (payload) => {
    const response = await getReportsHistopatologia({
      payload,
    });

    setTableData(response);
    setLoading(false);
  };

  return {
    loadReportsHistopatologia,
  };
};

export default useRequestFilter;
