import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

const ButtonConfirm = styled(Button)`
  color: #ffffff;
  background-color: #238884;
  border-color: #238884;
  :hover {
    background-color: #238884;
    color: #ffffff;
    border-color: #238884;
  }
`;

export default ButtonConfirm;
