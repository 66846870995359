import { ApexOptions } from 'apexcharts';
import { useState } from 'react';
import ReactApexChart from 'react-apexcharts';

interface CollectionGoalProps {
  value: number;
}

export function CollectionGoal({ value }: CollectionGoalProps) {
  const [chartData, setChartData] = useState<ApexOptions>({
    series: [value, 100 - value],
    chart: {
      type: 'donut',
      height: 40,
    },
    colors: ['#FF9800', '#D9E4E5'],
    yaxis: {
      min: 0,
    },
    xaxis: {
      min: 0,
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      pie: {
        donut: {
          size: '50%',
          labels: {
            show: false,
          },
        },
      },
    },
    tooltip: {
      enabled: false,
    },
    grid: {
      padding: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
      },
    },
  });

  return (
    <ReactApexChart
      options={chartData}
      series={chartData.series}
      type="donut"
      height={34}
    />
  );
}
