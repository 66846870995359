import { DvStyled } from './styles';
import AlertInfo from '../../assets/imgs/AlertInfo.svg';
import { Tooltip } from '@mui/material';

const ItemTotalCardConvocation = ({
  color,
  icon,
  title,
  value,
  bottomPhrase,
  tooltipTitle,
}) => {
  return (
    <DvStyled style={{ boxShadow: `inset 8px 0 0 0 ${color}` }}>
      <div style={{ display: 'flex' }}>
        <img src={icon} alt="" style={{ marginRight: '8px' }} />
        <p style={{ fontWeight: 'bold', color: 'rgba(0, 0, 0, 0.60)' }}>
          {title}
        </p>
        <Tooltip title={tooltipTitle} placement="top">
          <img src={AlertInfo} alt="" style={{ marginLeft: '13px' }} />
        </Tooltip>
      </div>
      <h1 style={{ fontSize: '48px', margin: 0, color: 'rgba(0, 0, 0, 0.60)' }}>
        {value}
      </h1>
      <span style={{ color: 'rgba(0, 0, 0, 0.60)' }}>{bottomPhrase}</span>
    </DvStyled>
  );
};

export default ItemTotalCardConvocation;
