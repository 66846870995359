import { toast } from 'react-toastify';
import api from '../api';
interface GetProfissionaisParams {
  estabelecimentoId?: string;
}

export async function getProfissionais({
  estabelecimentoId,
}: GetProfissionaisParams) {
  return await api
    .get(`/profissionais/estabelecimento/${estabelecimentoId}`)
    .then((res) => {
      return res;
    })
    .catch((e: any) => {
      toast.error(e.message, { position: 'bottom-right' });
      return e;
    });
}
export async function getProfissionaisScheduledConvocatoria({
  estabelecimentoId,
}: GetProfissionaisParams) {
  return await api
    .get(`/cidadao_agenda/estabelecimento/${estabelecimentoId}`)
    .then((res) => {
      return res;
    })
    .catch((e: any) => {
      toast.error(e.message, { position: 'bottom-right' });
      return e;
    });
}

export async function getUsersAgenda({ estabelecimentoId }) {
  return await api
    .get(`/usuarios/estabelecimento/${estabelecimentoId}`)
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e;
    });
}

export async function postProfissionais(data) {
  return await api
    .post('/profissionais', { ...data })
    .then((res) => {
      return res;
    })
    .catch((e: any) => {
      toast.error(e.error.message, { position: 'bottom-right' });
      return e;
    });
}

export async function putProfissionais(data, id) {
  return await api
    .put(`/profissionais/${id}`, { ...data })
    .then((res) => {
      return res;
    })
    .catch((e: any) => {
      toast.error(e.error.message, { position: 'bottom-right' });

      return e;
    });
}
