import { useState } from 'react';
import { Box, Button, IconButton, Modal } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import './style.css';
import DocSvg from '../../assets/imgs/doc.svg';
import { useNavigate } from 'react-router-dom';
import { ExamePendente } from '../NotificationModal';
import api from '../../api';
import React from 'react';
import { AlertListContext } from '../../context/AlertListContext';
import { LoadingContext } from '../../context/LoadingContext';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { confirmMissingHpv } from '../../lib/getAlertList';
import moment from 'moment';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import RestoreIcon from '@mui/icons-material/Restore';

interface Props {
  examePendente: any;
}

const NotificationModalExam = (props) => {
  const [examHpvSelected, setExamHpvSelected] = useState<any>();
  const { alertList, setAlertList, setUpdateAlertList } =
    React.useContext(AlertListContext);
  const { setLoading } = React.useContext(LoadingContext);
  const handleBuscarButtonClick = async (id: number, idPaciente: number) => {
    const newTab = window.open(
      `${window.location.origin}/pacientes/${idPaciente}`,
      '_blank'
    );
    try {
      setLoading(true);
      api.delete(`importacao_gal/exame/hpv/resultados/${id}`).then(() => {
        setAlertList(alertList.filter((item) => item.id !== id));
      });
    } catch (error) {
    } finally {
      setUpdateAlertList((prev) => Number(prev + 1));
      setLoading(false);
    }
    newTab?.focus();
  };

  const handleRedirectToHpv = async () => {
    const target = `/pacientes/${props.examePendente.notify_data.pessoa.id}/exam`;

    localStorage.setItem(
      'stateHpv',
      JSON.stringify({
        origin: 'notification',
        gal_id: props.examePendente?.notify_data?.gal_id,
        examType: 'hpv',
        patientData: props.examePendente.notify_data.pessoa,
        dt_coleta: props.examePendente?.notify_data?.dt_coleta,
      })
    );

    window.open(target, '_blank', 'noreferrer');
  };

  return (
    <>
      <p>
        Um resultado do Teste de HPV para a paciente{' '}
        <b>{props.examePendente?.notify_data?.pessoa?.nome}</b> foi identificado
        no GAL, porém não há dados de Anamnese e Exame Clínico registrados no
        Jordana.
      </p>
      <Box className="subModalTextBody">
        <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <p style={{ margin: '0' }}>
            <b>Requisição do GAL:</b> {props.examePendente?.notify_data?.gal_id}
          </p>
          <p style={{ margin: '0' }}>
            <b>Data de Coleta:</b>{' '}
            {moment(props.examePendente?.notify_data?.dt_coleta).format(
              'DD/MM/YYYY'
            )}
          </p>
        </div>
      </Box>
      <p style={{ margin: '24px 0px 24px 0px' }}>
        Faça registro dos dados para associar o resultado corretamente.
      </p>

      <Box
        className="subModalTextBody"
        sx={{
          fontWeight: 700,
          marginBottom: '24px',
          '&:hover': { cursor: 'pointer' },
        }}
        onClick={() => handleRedirectToHpv()}
      >
        Cadastre um novo Teste
        <KeyboardArrowRightIcon sx={{ width: '24px', color: '#757575' }} />
      </Box>

      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        gap="24px"
      >
        <Button
          variant="outlined"
          color="error"
          onClick={() => {
            props.closeModal(false);
          }}
          endIcon={<RestoreIcon />}
        >
          CADASTRAR DEPOIS
        </Button>
      </Box>
    </>
  );
};

export default NotificationModalExam;
