import { Option } from '../types/Option';

export const resultadoCitologia: Option[] = [
  {
    label: 'Com Alterações Epiteliais',
    value: 'COM_ALTERACOES_EPITELIAIS',
  },
  {
    label: 'Insatisfatório',
    value: 'INSATISFATORIO',
  },
  {
    label: 'Negativo',
    value: 'NEGATIVO',
  },
];
