import moment from 'moment';
import {
  formatedMultiples,
  formatDate,
} from '../../../../../utils/requestUtils/formatMultiples';

const useSetPayload = ({
  watch,
  estadosSelected,
  municipiosSelected,
  estabelecimentosSelected,
  equipesSelected,
  dtColetaIni,
  dtColetaFinal,
  dtResultadoIni,
  dtResultadoFinal,
  selectedServicos,
  selectedEzt,
  selectedLocal,
  selectedTipoEzt,
  selectedAnestesia,
  selectedTipoProcedimento,
  selectedHasHistoPos,
  selectedHasHistoPre,
}: any) => {
  const setPayload = () => {
    let payload: any;
    return new Promise((resolve, reject) => {
      payload = {
        estado: formatedMultiples(estadosSelected),
        municipioId: formatedMultiples(municipiosSelected),
        estabelecimentoSaudeId: formatedMultiples(estabelecimentosSelected),
        servicoSaudeId: formatedMultiples(selectedServicos),
        indicacao_ezt: formatedMultiples(selectedEzt),
        tipo_exerese_ezt: formatedMultiples(selectedTipoEzt),
        realizado_in: formatedMultiples(selectedLocal),
        anestesia: formatedMultiples(selectedAnestesia),
        tipo_procedimento: formatedMultiples(selectedTipoProcedimento),
        dt_realizado_inicio: formatDate(dtColetaIni),
        dt_realizado_fim: formatDate(dtColetaFinal),
        has_histo_pos: formatedMultiples(selectedHasHistoPos),
        has_histo_pre: formatedMultiples(selectedHasHistoPre),

        profissionalId: watch().profissionalId?.idprofissional ?? undefined,
        motivo: watch().motivo ?? undefined,
        search: watch().search ?? undefined,
        size: watch().size,
        page: watch().page,
      };

      resolve(payload);
    });
  };

  return { setPayload };
};

export default useSetPayload;
