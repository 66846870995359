import { Box, Grid, Typography } from '@mui/material';
import moment from 'moment';

import calculaIdade from '../../../utils/calculaIdade';

const PessoalInformation = ({ patientInformation }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
      data-testid='info-box'
    >
      <Grid container mb={3}>
        <Grid item xs={12} md={2.4}>
          <Typography sx={{ fontSize: '10px', color: '#555555' }}>
            Nº do Cartão SUS
          </Typography>
          <Typography sx={{ fontSize: '14px', fontWeight: 600 }} data-testid='info-infsus'>
            {String(patientInformation.data?.cns || '').toUpperCase()}
          </Typography>
        </Grid>
        <Grid item xs={12} md={4.8}>
          <Typography sx={{ fontSize: '10px', color: '#555555' }}>
            Nome
          </Typography>
          <Typography sx={{ fontSize: '14px', fontWeight: 600 }} data-testid='info-nome'>
            {String(patientInformation.data?.nome || '').toUpperCase()}
          </Typography>
        </Grid>
        <Grid item xs={12} md={2.4}>
          <Typography sx={{ fontSize: '10px', color: '#555555' }}>
            Nome social
          </Typography>
          <Typography sx={{ fontSize: '14px', fontWeight: 600 }} data-testid='info-nome_social'>
            {String(patientInformation.data?.apelido || '').toUpperCase()}
          </Typography>
        </Grid>
        <Grid item xs={12} md={2.4}>
          <Typography sx={{ fontSize: '10px', color: '#555555' }}>
            Nome da Mãe
          </Typography>
          <Typography sx={{ fontSize: '14px', fontWeight: 600 }} data-testid='info-nome_mae'>
            {String(patientInformation.data?.nome_mae || '').toUpperCase()}
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} md={2.4}>
          <Typography sx={{ fontSize: '10px', color: '#555555' }}>
            CPF
          </Typography>
          <Typography sx={{ fontSize: '14px', fontWeight: 600 }} data-testid='info-cpf'>
            {['0', '11111111111', undefined].includes(
              patientInformation.data?.cpf
            )
              ? ''
              : patientInformation.data?.cpf}
          </Typography>
        </Grid>
        <Grid item xs={12} md={2.4}>
          <Typography sx={{ fontSize: '10px', color: '#555555' }}>
            Data de Nascimento
          </Typography>
          <Typography sx={{ fontSize: '14px', fontWeight: 600 }} data-testid='info-nascimento'>
            {patientInformation.data?.dt_nascimento !== null
              ? moment(patientInformation.data?.dt_nascimento).format(
                  'DD/MM/YYYY'
                )
              : 'Data não informada'}
          </Typography>
        </Grid>
        <Grid item xs={12} md={2.4}>
          <Typography sx={{ fontSize: '10px', color: '#555555' }}>
            Idade
          </Typography>
          <Typography sx={{ fontSize: '14px', fontWeight: 600 }} data-testid='info-idade'>
            {patientInformation.data?.dt_nascimento !== null
              ? calculaIdade(
                  moment(patientInformation.data?.dt_nascimento).format(
                    'DD/MM/YYYY'
                  )
                ) + ' anos'
              : 'Idade não informada'}
          </Typography>
        </Grid>
        <Grid item xs={12} md={2.4}>
          <Typography sx={{ fontSize: '10px', color: '#555555' }}>
            Nacionalidade
          </Typography>
          <Typography sx={{ fontSize: '14px', fontWeight: 600 }} data-testid='info-nacionalidade'>
            {String(patientInformation.data?.nacionalidade || '').toUpperCase()}
          </Typography>
        </Grid>
        <Grid item xs={12} md={2.4}>
          <Typography sx={{ fontSize: '10px', color: '#555555' }}>
            Raça/Cor
          </Typography>
          <Typography sx={{ fontSize: '14px', fontWeight: 600 }} data-testid='info-raca_cor'>
            {String(patientInformation.data?.raca || '').toUpperCase()}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PessoalInformation;
