/* eslint-disable react-hooks/exhaustive-deps */
import { Box } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import PasswordSuccessChange from './PasswordSuccessChange';
import TypeNewPassword from './TypeNewPassword';
import { BackgroundContext } from '../../../context/BackgroundContext';
import { useLocation, useNavigate } from 'react-router-dom';

const ForgotPasswordTypeNewPassword = () => {
  const [pageCurrent, setPageCurrent] = useState('new_password');
  const { backgroundImage } = useContext(BackgroundContext);
  const location = useLocation();
  let navigate = useNavigate();

  const params = new URLSearchParams(location.search);

  useEffect(() => {
    if (!params.get('token')) navigate('/');
  }, []);

  return (
    <Box
      bgcolor="lightblue"
      width="100vw"
      height="100vh"
      sx={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0 16px',
      }}
    >
      {pageCurrent === 'new_password' && (
        <TypeNewPassword
          setPageCurrent={setPageCurrent}
          token={params.get('token')}
        />
      )}
      {pageCurrent === 'success' && <PasswordSuccessChange />}
    </Box>
  );
};
export default ForgotPasswordTypeNewPassword;
