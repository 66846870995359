import { TableCell, Typography } from '@mui/material';
import { relatoriocolposcopia } from '../../../../../../mocks/relatorio-colposcopia-mock';
import moment from 'moment';
import CollapseColumn from '../../../../../CollapseColumn';
import { useState } from 'react';
import { makeStyles } from '@material-ui/styles';

const RowsColposcopia = ({ row }) => {
  const [openedRow, setOpenedRow] = useState<any>();
  const useStyles = makeStyles({
    table: {
      minWidth: 750,
      border: 'none',
      '& td': {
        borderBottom: 'none',
      },
    },
    icon: {
      fontSize: 20,
    },
  });

  const classes = useStyles();

  const exameMacro = {
    1: 'Sem Alterações',
    2: 'Com Alterações',
  };

  const avalGeral = {
    1: 'Adequada',
    2: 'Inadequada',
  };

  const visibilidadeEscamo = {
    1: 'Completamente Visível',
    3: 'Parcialmente Visível',
    2: 'Não Visível',
  };

  const zonaTransformacao = {
    1: 'Tipo 1',
    2: 'Tipo 2',
    3: 'Tipo 3',
  };

  const tipoAchados = {
    1: 'Achado Colposcópico Normal',
    2: 'Achado Colposcópico Anormal',
  };

  const aspectosNaoEspecificos = {
    1: 'Leucoplasia (queratose, hiperqueratose)',
    2: 'Erosão',
  };

  const localizacaoZt = {
    1: 'Dentro da ZT',
    2: 'Fora da ZT',
  };

  const nquadrantes = {
    1: '1',
    2: '2',
    3: '3',
    4: '4',
  };

  const n_especifico = {
    1: 'Leucoplasia (queratose, hiperqueratose)',
    2: 'Erosão',
  };

  const objMotivoInadequada = {
    sangramento_intenso: {
      value: row?.motivo_inadequada?.[0]?.value,
      label: 'Sangramento Intenso',
    },
    cicatriz: { value: row?.motivo_inadequada?.[1]?.value, label: 'Cicatriz' },
    inflamacao_intensa: {
      value: row?.motivo_inadequada?.[2]?.value,
      label: 'Inflamação Intensa',
    },
    atrofia_intensa: {
      value: row?.motivo_inadequada?.[3]?.value,
      label: 'Atrofia Intensa',
    },
  };

  const objLocalAchadoAnormal = {
    vagina: {
      // value: row?.localizacao_biopsia?.find((item) => item === 'VAGINA'),
      value: row?.aspectos_achados_anormais?.localizacao_vagina,
      label: 'Vagina',
    },
    colo: {
      // value: row?.localizacao_biopsia?.find((item) => item === 'COLO'),
      value: row?.aspectos_achados_anormais?.localizacao_colo,
      label: 'Colo',
    },
  };

  const objGrauMenor = {
    epitelio: {
      value:
        row?.aspectos_achados_anormais?.grau_menor
          ?.map((item) => {
            if (item?.grau_menor.id === 1) {
              return item;
            }
          })
          ?.find((item) => item?.grau_menor)?.grau_menor?.id === 1,
      label: 'Epitélio acetobranco tênue, borda irregular ou geográfica',
    },
    mosaico: {
      value:
        row?.aspectos_achados_anormais?.grau_menor
          ?.map((item) => {
            if (item?.grau_menor.id === 2) {
              return item;
            }
          })
          ?.find((item) => item?.grau_menor)?.grau_menor?.id === 2,
      label: 'Mosaico fino, borda irregular e geográfica',
    },
    pontilhado: {
      value:
        row?.aspectos_achados_anormais?.grau_menor
          ?.map((item) => {
            if (item?.grau_menor.id === 3) {
              return item;
            }
          })
          ?.find((item) => item?.grau_menor)?.grau_menor?.id === 3,
      label: 'Pontilhado fino, borda irregular ou geográfica',
    },
  };

  const objGrauMaior = {
    epitelio: {
      value: row?.aspectos_achados_anormais?.grau_maior
        ?.map((item) => {
          if (item?.grau_maior.id === 1) {
            return item;
          }
        })
        ?.find((item) => item?.grau_maior?.id === 1)?.value,
      label: 'Epitélio acetobranco denso',
    },
    acetobranqueamento: {
      value: row?.aspectos_achados_anormais?.grau_maior
        ?.map((item) => {
          if (item?.grau_maior.id === 2) {
            return item;
          }
        })
        ?.find((item) => item?.grau_maior?.id === 2)?.value,
      label: 'Acetobranqueamento de aparecimento rápido',
    },
    orificios: {
      value: row?.aspectos_achados_anormais?.grau_maior
        ?.map((item) => {
          if (item?.grau_maior?.id === 3) {
            return item;
          }
        })
        ?.find((item) => item?.grau_maior?.id === 3)?.value,
      label: 'Orifícios glandulares espessados',
    },
    mosaico: {
      value: row?.aspectos_achados_anormais?.grau_maior
        ?.map((item) => {
          if (item?.grau_maior?.id === 4) {
            return item;
          }
        })
        ?.find((item) => item?.grau_maior?.id === 4)?.value,
      label: 'Mosaico grosseiro',
    },
    pontilhado: {
      value: row?.aspectos_achados_anormais?.grau_maior
        ?.map((item) => {
          if (item?.grau_maior?.id === 5) {
            return item;
          }
        })
        ?.find((item) => item?.grau_maior?.id === 5)?.value,
      label: 'Pontilhado grosseiro',
    },
    margem: {
      value: row?.aspectos_achados_anormais?.grau_maior
        ?.map((item) => {
          if (item?.grau_maior?.id === 6) {
            return item;
          }
        })
        ?.find((item) => item?.grau_maior?.id === 6)?.value,
      label: 'Margem demarcada',
    },
    sinal_margem: {
      value: row?.aspectos_achados_anormais?.grau_maior
        ?.map((item) => {
          if (item?.grau_maior?.id === 7) {
            return item;
          }
        })
        ?.find((item) => item?.grau_maior?.id === 7)?.value,
      label: 'Sinal da margem interna',
    },
    sinal_crista: {
      value: row?.aspectos_achados_anormais?.grau_maior
        ?.map((item) => {
          if (item?.grau_maior?.id === 8) {
            return item;
          }
        })
        ?.find((item) => item?.grau_maior?.id === 8)?.value,
      label: 'Sinal da crista (sobrelevado)',
    },
  };

  const objSuspeitaInvasao = {
    vasos: {
      value: row?.aspectos_achados_anormais?.suspeita_de_invasao
        ?.map((item) => {
          if (item?.suspeitas_invasao?.id === 1) {
            return item;
          }
        })
        ?.find((item) => item?.suspeitas_invasao?.id === 1)?.value,
      label: 'Vasos atípicos',
    },
    sinais: {
      value: row?.aspectos_achados_anormais?.suspeita_de_invasao
        ?.map((item) => {
          if (item?.suspeitas_invasao?.id === 2) {
            return item;
          }
        })
        ?.find((item) => item?.suspeitas_invasao?.id === 2)?.value,
      label:
        'Sinais adicionais: vasos frágeis, superfície irregular, lesão exofítica, necrose, ulceração (necrótica), neoplasia tumoral/grosseira',
    },
  };

  const objResultadoMiscelania = {
    ztc: {
      value: row?.resultados_miscelania
        ?.map((item) => {
          if (item?.miscelania?.id === 1) {
            return item;
          }
        })
        ?.find((item) => item?.miscelania?.id === 1)?.value,
      label: 'Zona de transformação congênita',
    },
    condiloma: {
      value: row?.resultados_miscelania
        ?.map((item) => {
          if (item?.miscelania?.id === 2) {
            return item;
          }
        })
        ?.find((item) => item?.miscelania?.id === 2)?.value,
      label: 'Condiloma',
    },
    inflamacao: {
      value: row?.resultados_miscelania
        ?.map((item) => {
          if (item?.miscelania?.id === 4) {
            return item;
          }
        })
        ?.find((item) => item?.miscelania?.id === 4)?.value,
      label: 'Inflamação',
    },
    estenose: {
      value: row?.resultados_miscelania
        ?.map((item) => {
          if (item?.miscelania?.id === 5) {
            return item;
          }
        })
        ?.find((item) => item?.miscelania?.id === 5)?.value,
      label: 'Estenose',
    },
    anomalia: {
      value: row?.resultados_miscelania
        ?.map((item) => {
          if (item?.miscelania?.id === 6) {
            return item;
          }
        })
        ?.find((item) => item?.miscelania?.id === 6)?.value,
      label: 'Anomalia congênita',
    },
    sequela: {
      value: row?.resultados_miscelania
        ?.map((item) => {
          if (item?.miscelania?.id === 7) {
            return item;
          }
        })
        ?.find((item) => item?.miscelania?.id === 7)?.value,
      label: 'Sequela pós tratamento',
    },
    sugestivo: {
      value: row?.resultados_miscelania
        ?.map((item) => {
          if (item?.miscelania?.id === 8) {
            return item;
          }
        })
        ?.find((item) => item?.miscelania?.id === 8)?.value,
      label: 'Sugestivo de Endometriose',
    },
    polipo: {
      value: row?.resultados_miscelania
        ?.map((item) => {
          if (item?.miscelania?.id === 3) {
            return item;
          }
        })
        ?.find((item) => item?.miscelania?.id === 3)?.value,
      label: 'Pólipo (ectocervical / endocervical)',
    },
  };

  const objLocalBiopsia = {
    vagina: {
      value: !!row?.localizacao_biopsia?.find((item) => item === 'VAGINA'),
      label: 'Vagina',
    },
    colo: {
      value: !!row?.localizacao_biopsia?.find((item) => item === 'COLO'),
      label: 'Colo',
    },
  };

  const motivoInadequadaList = Object.keys(objMotivoInadequada);
  const localAchadoAnormalList = Object.keys(objLocalAchadoAnormal);
  const localBiopsiaList = Object.keys(objLocalBiopsia);

  for (const itemGrau1 in objGrauMenor) {
    if (objGrauMenor[itemGrau1]?.value === false) {
      delete objGrauMenor[itemGrau1];
    }
  }

  for (const itemGrau2 in objGrauMaior) {
    if (objGrauMaior[itemGrau2]?.value === false) {
      delete objGrauMaior[itemGrau2];
    }
  }

  for (const resultadoMiscelania in objResultadoMiscelania) {
    if (objResultadoMiscelania[resultadoMiscelania]?.value === false) {
      delete objResultadoMiscelania[resultadoMiscelania];
    }
  }

  const grauMaiorList = Object.keys(objGrauMaior);

  const grauMenorList = Object.keys(objGrauMenor);
  const suspeitasInvasaoList = Object.keys(objSuspeitaInvasao);
  const resultadoMiscelaniaList = Object.keys(objResultadoMiscelania);

  return (
    <>
      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {row?.dt_coleta ? moment(row?.dt_coleta).format('DD/MM/YYYY') : ''}
        </Typography>
      </TableCell>

      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {exameMacro[row?.exame_macroscopico]}
        </Typography>
      </TableCell>

      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {avalGeral[row?.avaliacao_geral]}
        </Typography>
      </TableCell>

      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          <CollapseColumn
            arrayItens={motivoInadequadaList}
            classes={classes}
            objItens={objMotivoInadequada}
            setStateOpenedRow={setOpenedRow}
            stateOpenRow={openedRow}
            valueToOpen="motivo_inadequada"
          />
        </Typography>
      </TableCell>

      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {visibilidadeEscamo[row?.visibilidade_juncao_escamocolunar]}
        </Typography>
      </TableCell>

      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {zonaTransformacao[row?.zona_transformacao]}{' '}
        </Typography>
      </TableCell>

      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {row?.tipo_achados === 3 && 'Normal e Anormal'}
          {row?.tipo_achados === 1 && 'Normal'}
          {row?.tipo_achados === 2 && 'Anormal'}
        </Typography>
      </TableCell>

      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          <CollapseColumn
            arrayItens={localAchadoAnormalList}
            classes={classes}
            objItens={objLocalAchadoAnormal}
            setStateOpenedRow={setOpenedRow}
            stateOpenRow={openedRow}
            valueToOpen="local_achado_anormal"
          />
        </Typography>
      </TableCell>

      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {
            localizacaoZt[
              row?.aspectos_achados_anormais?.localizacao_zona_de_transformacao
            ]
          }
        </Typography>
      </TableCell>
      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {nquadrantes[row?.aspectos_achados_anormais?.n_quadrantes]}
        </Typography>
      </TableCell>

      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          <CollapseColumn
            arrayItens={grauMenorList}
            classes={classes}
            objItens={objGrauMenor}
            setStateOpenedRow={setOpenedRow}
            stateOpenRow={openedRow}
            valueToOpen="grau_menor"
          />
        </Typography>
      </TableCell>

      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          <CollapseColumn
            arrayItens={grauMaiorList}
            classes={classes}
            objItens={objGrauMaior}
            setStateOpenedRow={setOpenedRow}
            stateOpenRow={openedRow}
            valueToOpen="grau_maior"
          />
        </Typography>
      </TableCell>
      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {n_especifico[row?.nao_especifico]}
        </Typography>
      </TableCell>

      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          <CollapseColumn
            arrayItens={suspeitasInvasaoList}
            classes={classes}
            objItens={objSuspeitaInvasao}
            setStateOpenedRow={setOpenedRow}
            stateOpenRow={openedRow}
            valueToOpen="suspeitas"
          />
        </Typography>
      </TableCell>
      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          <CollapseColumn
            arrayItens={resultadoMiscelaniaList}
            classes={classes}
            objItens={objResultadoMiscelania}
            setStateOpenedRow={setOpenedRow}
            stateOpenRow={openedRow}
            valueToOpen="resultado_miscelania"
          />
        </Typography>
      </TableCell>

      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {row?.captacao_lugol ? 'Positiva (corado)' : 'Negativa (não corado)'}
        </Typography>
      </TableCell>
      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {row?.teste_schiller ? 'Positivo' : 'Negativo'}
        </Typography>
      </TableCell>
      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {row?.realizar_biopsia ? 'Sim' : 'Não'}
        </Typography>
      </TableCell>

      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          <CollapseColumn
            arrayItens={localBiopsiaList}
            classes={classes}
            objItens={objLocalBiopsia}
            setStateOpenedRow={setOpenedRow}
            stateOpenRow={openedRow}
            valueToOpen="local_biopsia"
          />
        </Typography>
      </TableCell>
      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {'Rede SUS'}
        </Typography>
      </TableCell>
      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {row?.procedimento?.estabelecimento_saude?.cnes}
        </Typography>
      </TableCell>
      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {row?.procedimento?.estabelecimento_saude?.nome_fantasia}
        </Typography>
      </TableCell>
      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography
          color="#000001de"
          variant="body2"
          fontSize="14px"
        ></Typography>
      </TableCell>
      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {row?.procedimento?.profissional?.pessoa?.nome}
        </Typography>
      </TableCell>
      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {row?.procedimento?.profissional?.cbo}
        </Typography>
      </TableCell>
      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {row?.procedimento?.profissional?.cns}
        </Typography>
      </TableCell>
      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {row?.procedimento?.profissional?.profissional_equipe?.ine}
        </Typography>
      </TableCell>

      {/** ------------------------------------------------ */}

      {/* <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {row?.motivo_inadequada[1]?.value ? 'Sim' : 'Não'}
        </Typography>
      </TableCell>

      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {row?.motivo_inadequada[2]?.value ? 'Sim' : 'Não'}
        </Typography>
      </TableCell>

      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {row?.motivo_inadequada[3]?.value ? 'Sim' : 'Não'}
        </Typography>
      </TableCell>

     

      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {row?.tipo_achados === 3 || row?.tipo_achados === 2 ? 'Sim' : 'Não'}
        </Typography>
      </TableCell>

      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {!!row?.localizacao_biopsia?.find(
            (localizacao) => localizacao === 'VAGINA'
          )
            ? 'Sim'
            : 'Não'}
        </Typography>
      </TableCell>

      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {row?.aspectos_achados_anormais?.grau_menor[0]?.value ? 'Sim' : 'Não'}
        </Typography>
      </TableCell>

      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {row?.aspectos_achados_anormais?.grau_menor[1]?.value ? 'Sim' : 'Não'}
        </Typography>
      </TableCell>

      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {row?.aspectos_achados_anormais?.grau_menor[2]?.value ? 'Sim' : 'Não'}
        </Typography>
      </TableCell>

      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {row?.aspectos_achados_anormais?.grau_maior[0]?.value ? 'Sim' : 'Não'}
        </Typography>
      </TableCell>

      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {row?.aspectos_achados_anormais?.grau_maior[1]?.value ? 'Sim' : 'Não'}
        </Typography>
      </TableCell>

      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {row?.aspectos_achados_anormais?.grau_maior[2]?.value ? 'Sim' : 'Não'}
        </Typography>
      </TableCell>

      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {row?.aspectos_achados_anormais?.grau_maior[3]?.value ? 'Sim' : 'Não'}
        </Typography>
      </TableCell>

      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {row?.aspectos_achados_anormais?.grau_maior[4]?.value ? 'Sim' : 'Não'}
        </Typography>
      </TableCell>

      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {row?.aspectos_achados_anormais?.grau_maior[5]?.value ? 'Sim' : 'Não'}
        </Typography>
      </TableCell>

      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {row?.aspectos_achados_anormais?.grau_maior[6]?.value ? 'Sim' : 'Não'}
        </Typography>
      </TableCell>

      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {row?.aspectos_achados_anormais?.grau_maior[7]?.value ? 'Sim' : 'Não'}
        </Typography>
      </TableCell>

      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {
            aspectosNaoEspecificos[
              row?.aspectos_achados_anormais?.nao_especifico
            ]
          }
        </Typography>
      </TableCell>

      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {row?.aspectos_achados_anormais?.suspeita_de_invasao[0]?.value
            ? 'SIM'
            : 'NÃO'}
        </Typography>
      </TableCell>

      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {row?.aspectos_achados_anormais?.suspeita_de_invasao[1]?.value
            ? 'SIM'
            : 'NÃO'}
        </Typography>
      </TableCell>

      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {row?.miscelania ? 'SIM' : 'NÃO'}{' '}
        </Typography>
      </TableCell>

      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {row?.resultados_miscelania[0]?.value ? 'SIM' : 'NÃO'}
        </Typography>
      </TableCell>

      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {row?.resultados_miscelania[1]?.value ? 'SIM' : 'NÃO'}
        </Typography>
      </TableCell>

      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {row?.resultados_miscelania[2]?.value ? 'SIM' : 'NÃO'}
        </Typography>
      </TableCell>

      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {row?.resultados_miscelania[3]?.value ? 'SIM' : 'NÃO'}
        </Typography>
      </TableCell>

      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {row?.resultados_miscelania[4]?.value ? 'SIM' : 'NÃO'}
        </Typography>
      </TableCell>

      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {row?.resultados_miscelania[5]?.value ? 'SIM' : 'NÃO'}
        </Typography>
      </TableCell>

      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {row?.resultados_miscelania[6]?.value ? 'SIM' : 'NÃO'}
        </Typography>
      </TableCell>

      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {row?.resultados_miscelania[7]?.value ? 'SIM' : 'NÃO'}
        </Typography>
      </TableCell>

      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {row?.captacao_lugol ? 'Positiva (corado)' : 'Negativa (não corado)'}
        </Typography>
      </TableCell>

      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {row?.teste_schiller ? 'Positivo' : 'Negativo'}
        </Typography>
      </TableCell>

      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {row?.observacoes}{' '}
        </Typography>
      </TableCell>

      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {row?.realizar_biopsia ? 'SIM' : 'Não'}{' '}
        </Typography>
      </TableCell>

      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {row?.procedimento?.profissional?.pessoa?.nome}{' '}
        </Typography>
      </TableCell>

      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {row?.procedimento?.profissional?.cbo}{' '}
        </Typography>
      </TableCell>

      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {row?.procedimento?.profissional?.cns}{' '}
        </Typography>
      </TableCell> */}
    </>
  );
};

export default RowsColposcopia;
