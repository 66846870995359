import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { useNavigate } from 'react-router';

const ModalCancelRegister = ({ id, setOpenDialogCancel, openDialogCancel }) => {
  const navigate = useNavigate();
  const handleCloseDialogCancel = () => {
    setOpenDialogCancel(false);
  };

  const handleCloseConfirmCancel = () => {
    navigate('/pacientes');
  };
  return (
    <Dialog open={openDialogCancel} onClose={handleCloseDialogCancel}>
      <DialogTitle>{id ? 'Cancelar Edição' : 'Cancelar Cadastro'}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {id
            ? 'Deseja realmente cancelar a edição do paciente ? '
            : 'Deseja realmente cancelar o cadastro de paciente ?'}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDialogCancel}>Não</Button>
        <Button onClick={handleCloseConfirmCancel} autoFocus>
          Sim
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalCancelRegister;
