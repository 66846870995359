import { useState, useEffect } from 'react';
import { AgendaHeader, ContentContainer, Label, Value } from './styles';
import {
  Typography,
  Grid,
  FormControlLabel,
  Radio,
  RadioGroup,
  Button,
  Autocomplete,
  TextField,
} from '@mui/material';

import { useForm, Controller } from 'react-hook-form';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import { useNavigate, useParams } from 'react-router-dom';
import { IPaciente } from '../../lib/getCitzenSchedule';
import moment from 'moment';

import { toast } from 'react-toastify';

const AgendaCitizen = () => {
  const [acceptExam, setAcceptExam] = useState<any>(null);
  const [professional, setProfessional] = useState<any>();
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedReason, setSelectedReason] = useState<any>(null); // Adicione este estado
  const [horarios, setHorarios] = useState<any>([]);
  const [citizenData, setCitizenData] = useState<IPaciente | null>(null);
  const [professionalList, setProfessionalList] = useState<any>([]);
  const [hours, setHours] = useState<any>('');
  const [payload, setPayload] = useState({});
  const [enderecoValue, setEnderecoValue] = useState('');
  const [errorHour, setErrorHour] = useState<any>(null);

  const { id } = useParams();

  const getProfessional = async (estabelecimentoId) => {
    try {
      await fetch(
        `${process.env.REACT_APP_API_URL}/cidadao_agenda/estabelecimento/${estabelecimentoId}`
      ).then((res) =>
        res.json().then((response) => {
          if (Array.isArray(response)) {
            setProfessionalList(response);
          } else {
            return;
          }
        })
      );
    } catch (error) {}
  };

  useEffect(() => {
    const getCitizen = async () => {
      try {
        await fetch(
          `${process.env.REACT_APP_API_URL}/cidadao_agenda/paciente/${id}`
        ).then((res) =>
          res
            .json()
            .then((response) => {
              setCitizenData(response);
              const endereco =
                response?.estabelecimento_saude.endereco.logradouro +
                ', ' +
                response?.estabelecimento_saude.endereco.numero +
                ' - ' +
                response?.estabelecimento_saude.endereco.bairro +
                ' - ' +
                response?.estabelecimento_saude.endereco.municipio.nome +
                ' - ' +
                response?.estabelecimento_saude.endereco.municipio.estado.nome +
                ', ' +
                response?.estabelecimento_saude.endereco.cep;
              setEnderecoValue(endereco);
              setPayload((prev) => {
                const {
                  cns,
                  cpf,
                  dt_nascimento,
                  estabelecimentoSaudeId,
                  nome,
                } = response;
                return {
                  ...prev,
                  cns,
                  cpf,
                  dt_nascimento,
                  nacionalidade: 'Brasileira',
                  nome,
                  id_paciente: Number(id),
                  estabelecimentoId: estabelecimentoSaudeId,
                  nome_fantasia: response.estabelecimento_saude.nome_fantasia,
                  procedimento: 'Teste de HPV',
                  isagendamento: true,
                  status: 'AGENDADO',
                  motivo_cancelamento: '',
                  motivo_outro: '',
                };
              });
              getProfessional(response.estabelecimento_saude.id);
            })
            .catch((e) => console.log(e))
        );
      } catch (error) {
        console.error(error);
      }
    };

    getCitizen();
  }, [id]);

  const dataNascimento = citizenData?.dt_nascimento
    ?.split('-')
    .reverse()
    .join('/')
    .toString();

  const localProcedimento = citizenData?.estabelecimento_saude?.nome_fantasia;

  const handleProfessionalChange = async (event: any, newValue: any) => {
    setPayload((prev) => {
      return {
        ...prev,
        idProfissional: newValue?.idProfissional,
        nomeProfissional: newValue?.nomeProfissional,
      };
    });
    setProfessional(newValue);
  };

  const handleDateChange = async (newValue: any) => {
    const originalDate = moment(newValue);
    const convertedDate = originalDate.format('YYYY-MM-DD');
    try {
      await fetch(
        `${process.env.REACT_APP_API_URL}/cidadao_agenda/horarios-disponiveis?data=${convertedDate}&id=${professional?.idAgenda}`
      ).then((res) =>
        res.json().then((response) => {
          setHours(null);

          if (response.message) {
            setErrorHour(response.message);
            setHorarios([]);

            return;
          } else {
            setErrorHour(null);
            setHorarios(response.horarios);
          }
        })
      );
    } catch (error) {
      console.error(error);
    }

    setSelectedDate(newValue);
    setPayload((prev) => {
      return {
        ...prev,
        dt_agendamento: convertedDate,
      };
    });
  };

  const handleHourChange = (event, newValue) => {
    setHours(newValue);
    setPayload((prev) => {
      return {
        ...prev,
        horario_atendimento: newValue,
      };
    });
  };

  const { handleSubmit: handleSubmitNotAccepted, control: controlNotAccepted } =
    useForm();

  const submitNotAccepted = (data) => {};

  const navigate = useNavigate();

  const handleAgendamento = async () => {
    try {
      await fetch(
        `${process.env.REACT_APP_API_URL}/cidadao_agenda/agendamento`,
        {
          method: 'POST',
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
          body: acceptExam
            ? JSON.stringify(payload)
            : JSON.stringify({
                ...payload,
                status: 'Agenda Não Realizada',
                nomeProfissional: '',
                dt_agendamento: null,
                idProfissional: 0,
                horario_atendimento: '',
              }),
        }
      )
        .then((res) =>
          res.json().then((response) => {
            if (response.error || response.statusCode === 500) {
              toast.error('Ocorreu um erro, tente novamente', {
                position: 'bottom-right',
              });

              return;
            }
            if (acceptExam) {
              navigate(`/agendamento/${id}`, {
                state: {
                  agendamentoData: payload,
                  idAgendamenxto: response.id,
                },
              });
            } else {
              navigate('/agendamento_nao_realizado');
            }
          })
        )
        .catch((e) =>
          toast.error('Erro ao agendar', {
            position: 'bottom-right',
          })
        );
    } catch (error) {
      toast.error('Erro ao agendar', {
        position: 'bottom-right',
      });
    }
  };

  // const handleChangeDatePicker = (newValue) => {
  //   const originalDate = moment(newValue);
  //   const convertedDate = originalDate.format('YYYY-MM-DD');

  //   setSelectedDayTimes(dates[convertedDate]);
  //   setSelectedDay(convertedDate);
  //   setValue(newValue);
  // };

  const maskedCPF = `***${citizenData?.cpf.substring(
    3,
    citizenData?.cpf.length - 3
  )}***`;
  useEffect(() => {
    if (enderecoValue) {
      setPayload((prev) => {
        return {
          ...prev,
          endereco_local: enderecoValue,
        };
      });
    }
  }, [enderecoValue]);

  if (!citizenData) return <p>Carregando...</p>;

  return (
    <>
      <AgendaHeader>
        <Typography
          sx={{ fontSize: '20px', color: 'white', fontWeight: '700' }}
        >
          Agendamento
        </Typography>
      </AgendaHeader>
      <ContentContainer>
        <Grid
          container
          spacing={1}
          sx={{
            '@media (min-width: 965px)': {
              boxShadow: '0px 0px 14px -8px black',
              width: '350px',
              padding: '25px',
            },
          }}
        >
          <Grid item xs={12}>
            <Typography fontWeight="bold" fontSize="20px">
              Dados do Cidadão
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Label>Nº do Cartão do SUS</Label>
            <Value>{citizenData?.cns}</Value>
          </Grid>
          <Grid item xs={6}>
            <Label>CPF</Label>
            <Value>{maskedCPF}</Value>
          </Grid>
          <Grid item xs={12}>
            <Label>Nome</Label>
            <Value>{citizenData?.nome}</Value>
          </Grid>
          <Grid item xs={6}>
            <Label>Data de Nascimento</Label>
            <Value>{dataNascimento}</Value>
          </Grid>
          <Grid item xs={6}>
            <Label>Nacionalidade</Label>
            <Value>{citizenData?.nacionalidade}</Value>
          </Grid>
          <Grid item xs={12}>
            <Typography fontWeight="bold" fontSize="20px" marginTop="15px">
              Dados do Procedimento
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Label>Local do Procedimento</Label>
            <Value>{localProcedimento}</Value>
          </Grid>
          <Grid item xs={12}>
            <Label>Endereço</Label>
            <Value>{enderecoValue}</Value>
          </Grid>
          <Grid item xs={12}>
            <Label>Procedimento</Label>
            <Value>Exame Citopatológico</Value>
          </Grid>
          <Grid item xs={12}>
            <Label>Você gostaria de agendar o exame?</Label>
          </Grid>
          <Grid item xs={12}>
            <RadioGroup
              aria-labelledby="Tpoe de exame"
              name="exame-type"
              sx={{ flexDirection: 'row' }}
            >
              <FormControlLabel
                value="sim"
                control={<Radio color="primary" />}
                label="Sim"
                onChange={() => setAcceptExam(true)}
              />
              <FormControlLabel
                value="nao"
                control={<Radio color="primary" />}
                label="Não"
                onChange={() => setAcceptExam(false)}
              />
            </RadioGroup>
          </Grid>

          {acceptExam === true && (
            <>
              <Grid item xs={12}>
                <Autocomplete
                  options={professionalList}
                  noOptionsText="Não há profissionais disponíveis para agendamento."
                  getOptionLabel={(option: any) => option?.nomeProfissional}
                  isOptionEqualToValue={(option: any, value: any) =>
                    option?.idAgenda === value?.idAgenda
                  }
                  onChange={handleProfessionalChange}
                  value={professional ? professional : null}
                  renderInput={(params) => (
                    <TextField {...params} size="small" label="Profissional" />
                  )}
                />
              </Grid>
              <>
                <Grid item xs={6} marginTop="8px">
                  {/* <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    locale={ptBR}
                  >
                    <StaticDatePicker
                      displayStaticWrapperAs="desktop"
                      label="Week picker"
                      value={value}
                      shouldDisableDate={(date) => {
                        if (!dates) {
                          return true;
                        }
                        const originalDate = moment(date);
                        const convertedDate = originalDate.format('YYYY-MM-DD');
                        const disableDate = !!datesWithSchedule?.find(
                          (item) => item === convertedDate
                        );
                        return !disableDate;
                      }}
                      onChange={(newValue) => {
                        handleChangeDatePicker(newValue);
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider> */}
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                      disabled={professional === '' ? true : false}
                      label="Data"
                      inputFormat="dd/MM/yyyy"
                      value={selectedDate}
                      onChange={handleDateChange}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={!!errorHour}
                          size="small"
                          placeholder="__/__/____"
                        />
                      )}
                    />
                  </LocalizationProvider>
                  {errorHour ? (
                    <p
                      style={{ color: '#e74c3c', margin: 0, fontSize: '10px' }}
                    >
                      {errorHour}
                    </p>
                  ) : undefined}
                </Grid>
                <Grid item xs={6} marginTop="8px">
                  <Autocomplete
                    disabled={horarios.length < 1 ? true : false}
                    options={horarios}
                    onChange={handleHourChange}
                    value={hours ? hours : ''}
                    getOptionLabel={(option: any) => option}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        label="Horário"
                        placeholder="hh:mm"
                      />
                    )}
                  />
                </Grid>
              </>
            </>
          )}
          {acceptExam === false && (
            <form
              onSubmit={handleSubmitNotAccepted(submitNotAccepted)}
              style={{ width: '100%' }}
            >
              <Grid item xs={12}>
                <Label>Motivo da não realização do exame</Label>
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="motivo_nao_aceite"
                  control={controlNotAccepted}
                  render={({ field: { onChange, value } }) => (
                    <RadioGroup
                      aria-labelledby="Tpoe de exame"
                      name="exame-type"
                      onChange={(e) => {
                        setSelectedReason(e.target.value);
                        onChange(e);
                      }}
                    >
                      <FormControlLabel
                        value="mora_longe"
                        control={<Radio color="primary" />}
                        label="Moro longe da unidade de saúde"
                      />
                      <FormControlLabel
                        value="ja_realizou"
                        control={<Radio color="primary" />}
                        label="Já realizei o exame em outro local"
                      />
                      <FormControlLabel
                        value="outro"
                        control={<Radio color="primary" />}
                        label="Outro"
                      />
                    </RadioGroup>
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  placeholder="Outro motivo"
                  size="small"
                  fullWidth
                  disabled={!selectedReason}
                />
              </Grid>
            </form>
          )}
          {acceptExam && (
            <Grid xs={12}>
              <Button
                onClick={handleAgendamento}
                sx={{ width: '100%', marginTop: '15px' }}
                disabled={
                  !professional ||
                  (!selectedDate && acceptExam) || // Adicione essa verificação para a data
                  (!acceptExam && !selectedReason) // Habilita o botão se um motivo for selecionado
                }
                variant="contained"
                type="submit"
              >
                {'AGENDAR'}
              </Button>
            </Grid>
          )}
          {acceptExam === false && (
            <Grid xs={12}>
              <Button
                onClick={handleAgendamento}
                sx={{ width: '100%', marginTop: '15px' }}
                disabled={!selectedReason}
                variant="contained"
                type="submit"
              >
                {'INFORMAR'}
              </Button>
            </Grid>
          )}
        </Grid>
      </ContentContainer>
    </>
  );
};

export default AgendaCitizen;
