import { Box, Typography } from '@mui/material';
import { ApexOptions } from 'apexcharts';
import { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { useNavigate } from 'react-router-dom';

const ChartTotalFaixa = ({
  loadingChart,
  categoriesFaixa,
  data,
  subtitleGraphic,
  payload,
}) => {
  const [seriesState, setSeriesState] = useState<any>([]);

  const navigate = useNavigate();

  const handleBarClick = (event, chartContext, config) => {
    const clickedSeriesIndex = config.seriesIndex;
    const clickedCategoryIndex = config.dataPointIndex;
    const clickedCategory = categoriesFaixa[clickedCategoryIndex];

    if (clickedCategory) {
      navigate('/relatorios/rastreamento-hpv', {
        state: {
          origin: 'tables',
          table: 'faixa',
          payload: payload,
          clickedCategory: clickedCategory,
          clickedSeries: seriesState[clickedSeriesIndex]?.field,
        },
      });
    }
  };

  const optionsBar = {
    colors: ['#2DC4CC', '#25A2A8', '#1C7B80', '#135357'],

    chart: {
      type: 'bar',
      height: 350,
      stacked: true,
      stackType: '100%',
      events: {
        click: handleBarClick,
      },
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: '26px',
      },
    },
    stroke: {
      width: 1,
      colors: ['#fff'],
    },

    xaxis: {
      categories: categoriesFaixa,
    },

    tooltip: {
      y: {
        formatter: function (val, opts) {
          const hoveredCategory =
            Object.keys(data).find(
              (_, index) => index === opts.dataPointIndex
            ) ?? '';

          const lineHovered = data[hoveredCategory];
          const seriesIndex = opts.seriesIndex;
          const hoveredSeries =
            Object.keys(lineHovered.faixas).find(
              (_, index) => index === seriesIndex
            ) ?? '';

          const valueIntegerNumber = lineHovered.faixas[hoveredSeries];

          return `${valueIntegerNumber}`;
        },
      },
    },
    noData: {
      text: loadingChart ? 'Carregando informações...' : 'Não há resultados',
    },
    fill: {
      opacity: 1,
    },
    legend: {
      position: 'top',
      horizontalAlign: 'center',
      offsetX: 40,
      height: 100,
      markers: {
        onClick: undefined,
      },
      onItemClick: {
        toggleDataSeries: false,
      },
    },
  } as ApexOptions;

  const seriesBar = [
    {
      name: '25 a 34 anos',
      data: [],
      field: '25_34',
    },
    {
      name: '35 a 45 anos',
      data: [],
      field: '35_45',
    },
    {
      name: '46 a 64 anos',
      data: [],
      field: '46_64',
    },
  ];
  useEffect(() => {
    if (data) {
      Object.keys(data)?.forEach((municipio) => {
        const total = data[municipio].total;

        seriesBar.forEach((faixa: any) => {
          const percentual =
            (data[municipio].faixas[faixa.field] / total) * 100;
          faixa.data.push(percentual.toFixed(2));
        });

        setSeriesState(seriesBar);
      });
    }
  }, [data]);

  useEffect(() => {
    if (categoriesFaixa.length === 0 || categoriesFaixa[0] === '') {
      setSeriesState([]);
    }
  }, [categoriesFaixa]);
  //   useEffect(() => {
  //     function setDataValues(field) {
  //       const values: any = [];

  //       for (const state in data) {
  //         values.push(data[state][field]);
  //       }

  //       return values;
  //     }

  //     seriesBar.forEach((serie) => {
  //       serie.data = setDataValues(serie.field);
  //     });
  //     setSeriesState(seriesBar);
  //   }, [data]);

  return (
    <Box
      sx={{
        border: '1px solid rgba(0, 0, 0, 0.23)',
        borderRadius: '12px',
        padding: '16px',
      }}
    >
      <Typography variant="h1" style={{ fontSize: '20px', fontWeight: 'bold' }}>
        Mulheres Elegíveis que Não Realizaram o Teste de HPV
      </Typography>
      <p style={{ margin: 0 }}>
        Disponíveis no Banco de Dados, distribuido por Faixa Etária
      </p>
      <p style={{ margin: 0, color: 'rgba(0, 0, 0, 0.6)' }}>
        {subtitleGraphic}
      </p>
      <div
        style={{
          width: '100%',
          overflowX: 'auto',
          overflowY: 'hidden',
        }}
      >
        {!!seriesBar === true && (
          <Chart
            options={optionsBar}
            series={seriesState}
            type="bar"
            height={600}
          />
        )}
      </div>
    </Box>
  );
};

export default ChartTotalFaixa;
