import './styles.scss';
import mismatchIcon from '../../assets/imgs/CentralNotification/mismatch.svg';
import missingIcon from '../../assets/imgs/CentralNotification/missing.svg';
import missingDisabledIcon from '../../assets/imgs/CentralNotification/missing-disabled.svg';
import availableIcon from '../../assets/imgs/CentralNotification/available.svg';
import moment from 'moment';
import api from '../../api';
import { useContext, useEffect } from 'react';
import { LoadingContext } from '../../context/LoadingContext';
import { useNavigate } from 'react-router-dom';
import mismatchDisabledIcon from '../../assets/imgs/CentralNotification/mismatch-disabled.svg';

const Chip = ({ text, color }) => {
  return (
    <div className={`chip chip-${color}`}>
      <p style={{ fontSize: '13px', fontWeight: 400 }}>{text}</p>
    </div>
  );
};

const CardCentralNotification = ({
  description,
  status,
  statusLida,
  data,
  namePatient,
  id,
  viewed_at,
  setAlertId,
  setModalIsOpen,
  alertId,
  isResolved,
  idPessoa,
  idExam,
  resolved_at,
  notificationResolved,
}: any) => {
  const icon = {
    MISMATCH_HPV_COLETA: notificationResolved
      ? mismatchDisabledIcon
      : mismatchIcon,
    MISSING_HPV_EXAM: notificationResolved ? missingDisabledIcon : missingIcon,
    AVAILABLE_HPV_RESULT: availableIcon,
  };

  const colorSide = {
    MISMATCH_HPV_COLETA: 'yellow',
    MISSING_HPV_EXAM: 'red',
    AVAILABLE_HPV_RESULT: 'green',
  };

  const text = {
    MISMATCH_HPV_COLETA: {
      title: 'Divergência de Dados do Teste de HPV',
      description: `Um resultado do Teste de HPV para a paciente ${namePatient} foi 
      identificado no GAL, porém não há dados de Anamnese e Exame Clínico registrados no Jordana.`,
    },
    MISSING_HPV_EXAM: {
      title: 'Inexistência de Dados do Teste de HPV',
      description: `Um resultado do Teste de HPV para a paciente ${namePatient} foi
         identificado no GAL, porém não há dados de Anamnese e Exame Clínico registrados no Jordana.`,
    },
    AVAILABLE_HPV_RESULT: {
      title: 'Resultado Disponível do Teste de HPV',
      description: `O resultado do Teste de HPV da paciente 
        ${namePatient} está disponível, selecione aqui para visualizá-lo`,
    },
  };

  const { setLoading } = useContext(LoadingContext);

  const navigate = useNavigate();

  const handleViewNotification = async () => {
    if (notificationResolved) {
      return;
    }

    setLoading(true);
    const response = await api.post(`/notificacao/view/${id}`);

    if (response?.status === 201) {
      if (status === 'AVAILABLE_HPV_RESULT') {
        try {
          navigate(`/pacientes/${idPessoa}/result/${idExam}`, {
            state: { view: 'visualizar' },
          });
          setLoading(false);
        } catch (error) {
          setLoading(false);

          console.error(error);
        }

        return;
      }
      setAlertId(alertId);
      setModalIsOpen(true);
      setLoading(false);
    }
  };

  const notRead = viewed_at === null;

  const dateResolved = `${moment(resolved_at).format('DD/MM/YYYY')}`;
  const timeResolved = `${moment(resolved_at).utcOffset(-3).format('HH:mm')}`;

  return (
    <div
      className={`card-item ${notificationResolved ? 'disabled' : ''}`}
      onClick={handleViewNotification}
    >
      <div
        className={`side side-${
          notificationResolved && status !== 'AVAILABLE_HPV_RESULT'
            ? 'gray'
            : colorSide[status]
        }`}
      />
      <div className="card-item__content">
        <img src={icon[status]} alt="" />
        <div>
          <p className="card-item__content__date">
            Recebida em {data ? moment(data).format('DD/MM/YYYY') : ''}
            {resolved_at && (
              <span>
                , resolvido por {isResolved.full_name} em {dateResolved} às{' '}
                {timeResolved}
              </span>
            )}
          </p>
          <p>{text[status].title}</p>
          <span>{text[status].description}</span>

          <div style={{ display: 'flex', gap: '10px', marginTop: '10px' }}>
            <Chip
              text={notRead ? 'Não Lida' : 'Lida'}
              color={notRead ? 'blue' : 'gray'}
            />

            {status !== 'AVAILABLE_HPV_RESULT' && (
              <Chip
                text={notificationResolved ? 'Resolvida' : 'Não Resolvida'}
                color={notificationResolved ? 'gray' : 'red'}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardCentralNotification;
