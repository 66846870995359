import * as React from 'react';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';

import AddIcon from '@mui/icons-material/Add';
import CreateIcon from '@mui/icons-material/Create';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import EmailIcon from '@mui/icons-material/Email';

import { useNavigate } from 'react-router-dom';
import CustomizedButton from '../CustomizedButton';
import ButtonNew from '../ButtonNew';
import { Box, Button, Chip, Collapse, Radio } from '@mui/material';
import { ResponseData } from './interface';
import { columns } from './structure';
import { useState } from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import {
  BoxButtonsActionsHeaderTable,
  ButtonGenerate,
  ContainerButtonsTableHeader,
  ContainerScreen,
  PaperContainer,
  StyledTable,
} from './styles';
import ModalActions from '../ModalActions';
import { toast } from 'react-toastify';
import api from '../../api';
import { PerfilContext } from '../../context/PerfilContext';
export default function TablePatients({
  getUsers,
  list,
  nome,
  setPayloadFilter,
  payloadFilter,
}: ResponseData) {
  let navigate = useNavigate();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [nameSelected, setNameSelected] = React.useState('');
  // const [estabelecimentoSelected, setEstabelecimentoSelected] = React.useState(
  //   []
  // );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [estabelecimentosDisable, setEstabelecimentosToDisable] = useState<any>(
    []
  );
  const [openDisabled, setOpenDisabled] = React.useState(false);
  const [openedRow, setOpenedRow] = useState(-1);

  const [userSelectedToDisable, setUserSelectedToDisable] = useState<any>({});
  const [establishmentsUser, setEstablishmentsUser] = useState([]);

  const [radioDisableChecked, setRadioDisableChecked] = useState(-1);

  const [perfilSelected, setPerfilSelected] = useState<any>();

  const { hasPermission } = React.useContext(PerfilContext);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPayloadFilter((prev) => {
      return { ...prev, page: newPage + 1 };
    });

    setPage(newPage);

    getUsers({ ...payloadFilter, page: newPage + 1 });
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPayloadFilter((prev) => {
      return { ...prev, size: event.target.value };
    });

    setPage(0);

    getUsers({ ...payloadFilter, size: event.target.value });
  };
  const editUser = (data: any, toAble: boolean, typeScreen: string) => {
    data = { ...data, toAble: toAble, typeScreen };

    navigate(`/editar-usuario/${data.id}`, { state: data });
  };
  const useStyles = makeStyles({
    table: {
      minWidth: 750,
      border: 'none',
      '& td': {
        borderBottom: 'none',
      },
    },
    icon: {
      fontSize: 20,
    },
  });

  const handleReInviteUser = async (id) => {
    try {
      const response = await api.post(`/usuarios/reinvite/${id}`);

      if (response.data.success) {
        toast.success('Convite re-enviado com sucesso.', {
          position: 'bottom-left',
        });
      }
    } catch (error) {
      toast.error('Não foi possível completar a solicitação.');
    }
  };

  // const handleAddEstabelecimentoToDisable = (event) => {
  //
  //   setEstabelecimentosToDisable((prev) => [
  //     ...prev,
  //     event.currentTarget.value,
  //   ]);
  // };

  const getColorChips = (arrStatus) => {
    let color;

    if (arrStatus.every((item) => item === 0)) {
      color = 'success';
    } else {
      if (arrStatus.every((item) => item === 1)) {
        color = 'error';
      }

      if (!!arrStatus.find((item) => item === 2)) {
        color = 'warning';
      }

      if (!!arrStatus.some((item) => item === 0)) {
        color = 'success';
      }
    }

    return color;
  };

  const getLabelChips = (arrStatus) => {
    let label;
    if (arrStatus.every((item) => item === 0)) {
      label = 'Ativo';
    } else {
      if (arrStatus.every((item) => item === 1)) {
        label = 'Inativo';
      }

      if (!!arrStatus.find((item) => item === 2)) {
        label = 'Pendente';
      }

      if (!!arrStatus.some((item) => item === 0)) {
        label = 'Ativo';
      }
    }

    return label;
  };

  const handleCloseDisableDialog = () => {
    setOpenDisabled(false);
  };

  const handleDisable = async () => {
    try {
      const response = await api.put(
        `/usuarios/perfil/${userSelectedToDisable.id}/${perfilSelected?.id}`,
        {
          estabelecimentoSaudeId: String(
            perfilSelected?.estabelecimento_saude?.id
          ),
          descricao: perfilSelected?.descricao,
          status: '1',
        }
      );

      if (response.status === 200) {
        handleCloseDisableDialog();
        window.location.reload();
      }
    } catch (error) {}
  };
  const classes = useStyles();
  return (
    <ContainerScreen>
      <ContainerButtonsTableHeader>
        {hasPermission('CREATE_USUARIO') && (
          <ButtonNew
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => navigate('/cadastrar-usuario')}
          >
            Cadastrar Usuário
          </ButtonNew>
        )}
        {/* <Box sx={{ display: 'flex', flexDirection: 'row' }}>
							<CustomizedButton
								aria-controls={open ? 'demo-customized-menu' : undefined}
								aria-haspopup="true"
								aria-expanded={open ? 'true' : undefined}
								onClick={handleClick}
								endIcon={<KeyboardArrowDownIcon />}
								variant="outlined"
							>
								Exportar
							</CustomizedButton>
							<StyledMenu
								id="demo-customized-menu"
								MenuListProps={{
									'aria-labelledby': 'demo-customized-button'
								}}
								open={open}
								anchorEl={anchorEl}
								onClose={handleClose}
							>
								<MenuItem disableRipple>CSV</MenuItem>
								<MenuItem disableRipple>PDF</MenuItem>
							</StyledMenu>
						</Box> */}
        {/* <BoxButtonsActionsHeaderTable>
          <ButtonGenerate variant="outlined" onClick={() => console.log()}>
            Gerar relatório
          </ButtonGenerate>
          <ButtonGenerate variant="outlined" onClick={() => console.log()}>
            Gerar Dashboard
          </ButtonGenerate>
        </BoxButtonsActionsHeaderTable> */}
      </ContainerButtonsTableHeader>

      <PaperContainer>
        {hasPermission('UPDATE_USUARIO') && (
          <ModalActions
            open={openDisabled}
            handleClose={handleCloseDisableDialog}
            title="Desabilitar"
            buttons={
              <>
                <Button
                  variant="contained"
                  onClick={handleDisable}
                  endIcon={<CheckIcon />}
                  disabled={!!!perfilSelected}
                >
                  Desabilitar
                </Button>
                <Button
                  variant="outlined"
                  color="error"
                  onClick={handleCloseDisableDialog}
                  endIcon={<CloseIcon />}
                >
                  Cancelar
                </Button>
              </>
            }
            content={
              <>
                <p>
                  Você deseja desabilitar o Profissional de Saúde{' '}
                  <b>{nameSelected}</b> no estabelecimento:{' '}
                  {
                    userSelectedToDisable?.pessoa?.estabelecimento_saude
                      ?.nome_fantasia
                  }
                </p>
                {establishmentsUser?.map((perfil: any) => {
                  return (
                    <>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'flex-start',
                          alignItems: 'center',
                        }}
                      >
                        <Radio
                          key={perfil?.estabelecimento_saude?.id}
                          value={perfil?.estabelecimento_saude}
                          onChange={() => {
                            setRadioDisableChecked(perfil?.id);
                            setPerfilSelected(perfil);
                          }}
                          checked={perfil?.id === radioDisableChecked}
                        />
                        <p style={{ margin: 0 }}>
                          {perfil?.descricao}:{' '}
                          {perfil?.estabelecimento_saude?.nome_fantasia}
                        </p>
                      </Box>
                    </>
                  );
                })}
              </>
            }
          />
        )}
        <TableContainer>
          <StyledTable aria-labelledby="tableTitle" size={'medium'}>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    sx={{
                      color: '#238884',
                    }}
                    key={column.id}
                    align={column.align}
                    style={{ width: column.minWidth, fontWeight: 'bold' }}
                  >
                    {column.label !== 'Estabelecimento' ? column.label : ''}
                    {column.label === 'Estabelecimento' && (
                      <span>
                        Estabe-
                        <br />
                        lecimento
                      </span>
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {list.data?.map((row: any, index: number) => {
                const isInactive = row.perfis.every(
                  (item) => item.status === 1
                );

                const arrStatus = row.perfis.map((item) => item.status);

                return (
                  <TableRow hover tabIndex={-1} key={index}>
                    <TableCell scope="row" sx={{ minWidth: 50 }}>
                      {row.pessoa.cpf}
                    </TableCell>
                    <TableCell sx={{ minWidth: 100 }}>
                      {row.pessoa.nome}
                    </TableCell>
                    <TableCell
                      sx={{
                        maxWidth: 200,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        wordWrap: 'break-word',
                      }}
                    >
                      {row.email}
                    </TableCell>
                    <TableCell size="small" sx={{ minWidth: 100 }}>
                      {/* {row.perfis[0]?.descricao +
                        ' - ' +
                        row.pessoa?.estabelecimento_saude?.nome_fantasia} */}
                      {row.perfis?.length > 1 ? (
                        <>
                          <div>
                            <p>
                              {row.perfis[0]?.descricao}:{' '}
                              {
                                row?.perfis[0]?.estabelecimento_saude
                                  ?.nome_fantasia
                              }
                            </p>
                            {row.perfis
                              .filter((_, index) => index > 0)
                              .map((perfil) => (
                                <Collapse in={openedRow === row.id}>
                                  <p>
                                    {perfil?.descricao}:{' '}
                                    {
                                      perfil?.estabelecimento_saude
                                        ?.nome_fantasia
                                    }
                                  </p>
                                </Collapse>
                              ))}
                          </div>

                          {openedRow === row.id ? (
                            <Button
                              onClick={() => setOpenedRow(-1)}
                              sx={{ textTransform: 'none' }}
                              endIcon={
                                <KeyboardArrowUpIcon className={classes.icon} />
                              }
                            >
                              Ver menos
                            </Button>
                          ) : (
                            <Button
                              onClick={() => setOpenedRow(row.id)}
                              sx={{ textTransform: 'none' }}
                              endIcon={
                                <KeyboardArrowDownIcon
                                  className={classes.icon}
                                />
                              }
                            >
                              Ver mais
                            </Button>
                          )}
                        </>
                      ) : (
                        <>
                          {row.perfis?.map((perfil) => (
                            <p>
                              {perfil.descricao}:{' '}
                              {perfil.estabelecimento_saude.nome_fantasia}
                            </p>
                          ))}
                        </>
                      )}{' '}
                    </TableCell>
                    <TableCell
                      sx={{
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Chip
                        color={getColorChips(arrStatus)}
                        label={getLabelChips(arrStatus)}
                      />
                    </TableCell>
                    <TableCell
                      sx={{ display: 'flex', justifyContent: 'space-between' }}
                    >
                      {hasPermission('UPDATE_USUARIO') && (
                        <CustomizedButton
                          variant="outlined"
                          onClick={(a) => {
                            editUser(row, false, 'editar');
                          }}
                          startIcon={<CreateIcon />}
                        >
                          Editar
                        </CustomizedButton>
                      )}
                      {hasPermission('UPDATE_USUARIO') ? (
                        !isInactive ? (
                          <CustomizedButton
                            variant="outlined"
                            onClick={(a) => {
                              setUserSelectedToDisable(row);
                              setOpenDisabled(true);
                              setNameSelected(row.pessoa.nome);
                              setEstablishmentsUser(row.perfis);
                              //setEstabelecimentoSelected(row.estabelecimentos);
                            }}
                            sx={{ textAlign: 'start' }}
                            startIcon={<CloseIcon />}
                          >
                            Desabili-
                            <br />
                            tar
                          </CustomizedButton>
                        ) : (
                          <CustomizedButton
                            variant="outlined"
                            onClick={() => editUser(row, true, 'habilitar')}
                            startIcon={<CheckIcon />}
                          >
                            Habilitar
                          </CustomizedButton>
                        )
                      ) : undefined}
                      {!!arrStatus.find((item) => item === 2) &&
                      hasPermission('CREATE_USUARIO') ? (
                        <CustomizedButton
                          variant="outlined"
                          sx={{ width: '122px' }}
                          startIcon={<EmailIcon />}
                          onClick={() => handleReInviteUser(row.id)}
                        >
                          REENVIAR <br />
                          CONVITE
                        </CustomizedButton>
                      ) : (
                        <div
                          style={{
                            width: '122px',
                            padding: '5px 15px',
                            margin: '10px',
                          }}
                        />
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </StyledTable>
          <TablePagination
            rowsPerPageOptions={[25, 50, 100]}
            component="div"
            count={list.totalItems || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage={'Itens por página'}
            labelDisplayedRows={({ from, to, count }) => {
              return `${from}–${to} de ${
                count !== -1 ? count : `mais do que ${to}`
              }`;
            }}
          />
        </TableContainer>
      </PaperContainer>
    </ContainerScreen>
  );
}
