export const optionsResultadoHPV = [
  { value: 'INVALIDO', label: 'Inconclusivo' },
  {
    value: 'NEGATIVO_INDETECTADO',
    label: 'Não Detectável',
  },
  {
    value: 'POSITIVO',
    label: 'Detectável',
  },
];

export const optionsTiposVirus = [
  { value: 'hpv_16', label: 'HPV 16' },
  {
    value: 'hpv_18',
    label: 'HPV 18',
  },
  {
    value: 'hpv_outros',
    label: 'Outros',
  },
];

export const optionsMotivoNaoAtendida = [
  { value: 'NAO_REALIZOU', label: 'Compareceu mas não realizou o exame' },
  {
    value: 'MENSTRUADA',
    label: 'Estava Menstruada',
  },
  {
    value: 'NAO_FAIXA_ETARIA',
    label: 'Não está na faixa etária',
  },
  {
    value: 'HISTERECTOMIZADA',
    label: 'Histerectomizada por patologia benigna',
  },
  {
    value: 'OUTRO',
    label: 'Outro',
  },
];
