import * as React from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import { Grid, Paper, Typography } from '@mui/material';
import ButtonConfirm from './ModalNewRegister.style';
import { Rastreio } from './Fragments/Rastreio';
import { Diagnostico } from './Fragments/Diagnostico';
import { Tratamento } from './Fragments/Tratamento';
import { Anamnese } from './Fragments/Anamnese';
import { useNavigate } from 'react-router-dom';

interface Props {
  open: boolean;
  registerType: string;
  canCreateHpv?: any;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  patientData: {
    id: number;
  };
  onClickConfirmbutton?: () => void | null;
}

export const ModalNewRegister = ({
  open,
  setOpen,
  registerType,
  patientData,
  onClickConfirmbutton,
  canCreateHpv,
}: Props) => {
  const [selectedValue, setSelectedValue] = React.useState('');
  let navigate = useNavigate();

  const handleClose = () => {
    setOpen(false);
    setSelectedValue('');
  };

  const handleRegisterTypeForm = () => {
    switch (registerType) {
      case 'rastreio':
        return (
          <Rastreio
            setSelectedValue={setSelectedValue}
            canCreateHpv={canCreateHpv}
          />
        );
      case 'diagnostico':
        return <Diagnostico setSelectedValue={setSelectedValue} />;
      case 'tratamento':
        return <Tratamento setSelectedValue={setSelectedValue} />;
      case 'anamnese':
        return <Anamnese setSelectedValue={setSelectedValue} />;
      default:
        return null;
    }
  };

  const handleClickConfirmButton = () => {
    if (onClickConfirmbutton && typeof onClickConfirmbutton === 'function') {
      onClickConfirmbutton();
      return;
    }

    navigate(`/pacientes/${patientData.id}/exam`, {
      state: { patientData, examType: selectedValue, origin: 'patient_ficha' },
    });
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="dialog-title"
      fullWidth={true}
    >
      <DialogTitle id="dialog-title">
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h6" fontWeight="bold">
            Novo Registro
          </Typography>
          <ClearIcon
            style={{ cursor: 'pointer' }}
            onClick={handleClose}
            color="disabled"
          />
        </Grid>
      </DialogTitle>
      <DialogContent sx={{ padding: '24px' }}>
        <Paper
          variant="outlined"
          sx={{ width: '100%', borderRadius: '10px', marginTop: '23px' }}
        >
          {handleRegisterTypeForm()}
        </Paper>
      </DialogContent>

      <DialogActions>
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          p={1}
          gap={3}
        >
          <Grid item>
            <ButtonConfirm
              variant="contained"
              endIcon={<ArrowForwardIcon />}
              onClick={handleClickConfirmButton}
              disabled={selectedValue === ''}
            >
              Continuar
            </ButtonConfirm>
          </Grid>

          {registerType === 'anamnese' && (
            <Grid item>
              <Button
                variant="outlined"
                startIcon={<CloseIcon />}
                onClick={handleClose}
                color="error"
              >
                Cancelar
              </Button>
            </Grid>
          )}
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
