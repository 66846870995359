import { toast } from 'react-toastify';
import api from '../../api';

export const DownloadFilesReportsCitologia = async ({ payload, fileType }) => {
  try {
    const response = await api.get(
      `/reports/export/citologia/${String(fileType).toLowerCase()}`,
      {
        params: {
          ...payload,
        },
        responseType: 'arraybuffer',
      }
    );

    if (response.status === 200) {
      return response.data;
    }
  } catch (error: any) {
    toast.error(error.message, {
      position: 'bottom-right',
    });
  }
};
