import { TableCell, Typography } from '@mui/material';
import {
  valuesBooleanos,
  valuesMotivoAnamnese,
} from '../../../variablesRowsValues';
import moment from 'moment';

const RowsAnamneseData = ({ row }) => {
  const motivoNaoAtendimento = {
    NAO_REALIZOU: 'Compareceu mas não realizou o exame',
    MENSTRUADA: 'Estava Menstruada',
    NAO_FAIXA_ETARIA: 'Não está na faixa etária',
    HISTERECTOMIZADA: 'Histerectomizada por patologia benigna',
    OUTRO: 'Outro',
  };

  const motivoAnamnese = {
    RASTREAMENTO: 'Rastreamento',
    REPETICAO: 'Repetição',
    SEGUIMENTO: 'Seguimento',
  };

  const papanicolaou = {
    SIM: 'Sim',
    NAO: 'Não',
    NAO_SABE: 'Não sabe',
  };

  const radioterapia = {
    SIM: 'Sim',
    NAO: 'Não',
    NAO_SABE: 'Não sabe',
  };

  return (
    <>
      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {!!motivoNaoAtendimento[row?.atendimento_status]
            ? 'Não Realizado'
            : 'Realizou'}
        </Typography>
      </TableCell>
      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {motivoNaoAtendimento[row?.atendimento_status]}
        </Typography>
      </TableCell>
      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {/* {row.anamnese?.ano_papanicolaou} */}
          {motivoAnamnese[row?.anamnese?.motivo]}
        </Typography>
      </TableCell>

      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {/* {row.anamnese?.ano_papanicolaou} */}
          {papanicolaou[row?.anamnese?.papanicolaou]}
        </Typography>
      </TableCell>
      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {row.anamnese?.ano_papanicolaou}
        </Typography>
      </TableCell>
      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {valuesBooleanos[row?.anamnese?.diu]}
        </Typography>
      </TableCell>
      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {valuesBooleanos[row?.anamnese?.gravida]}
        </Typography>
      </TableCell>
      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {valuesBooleanos[row?.anamnese?.anticoncepcional]}
        </Typography>
      </TableCell>
      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {radioterapia[row?.anamnese?.radioterapia]}
        </Typography>
      </TableCell>
      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {row?.anamnese?.dt_menstruacao
            ? moment(row?.anamnese?.dt_menstruacao).format('DD/MM/YYYY')
            : ''}
        </Typography>
      </TableCell>

      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {valuesBooleanos[row?.anamnese?.sangramento_relacao]}
        </Typography>
      </TableCell>

      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {valuesBooleanos[row?.anamnese?.menopausa]}
        </Typography>
      </TableCell>

      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {valuesBooleanos[row?.anamnese?.sangramento_menopausa]}
        </Typography>
      </TableCell>

      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {valuesBooleanos[row?.anamnese?.hormonio]}
        </Typography>
      </TableCell>
    </>
  );
};

export default RowsAnamneseData;
