import { TableBody, TableRow } from '@mui/material';
import RowsHealthcareFacility from './RowsHealthcareFacility';
import RowsAddressData from './RowsAddressData';
import RowsClinico from './RowsClinico';
import RowsPersonalData from './RowsPersonalData';
import RowsResultadoData from './RowsResultadoData';

const Rows = ({ tableData }) => {
  return (
    <TableBody>
      {tableData?.data?.map((row) => (
        <TableRow key={row?.pessoa?.id}>
          <RowsPersonalData row={row} />
          {/* <RowsHealthcareFacility row={row} /> */}
          <RowsAddressData row={row} />
          <RowsClinico row={row} />
          <RowsResultadoData row={row} />
        </TableRow>
      ))}
    </TableBody>
  );
};
export default Rows;
