import { NavigateFunction } from 'react-router-dom';

interface useLogoutProps {
  navigate: NavigateFunction;
  timeoutLogoutRef: any;
}

const thirty_minutes_timeout_logout = 30 * 60 * 1000;

const useLogout = ({ navigate, timeoutLogoutRef }: useLogoutProps) => {
  const automaticLogoutFunction = ({ isMountComponent }) => {
    const handleUpdateTimeout = () => {
      // Limpa o timeout existente
      clearTimeout(timeoutLogoutRef.current);

      // Configura um novo timeout
      timeoutLogoutRef.current = setTimeout(() => {
        localStorage.removeItem('@jornada-ccu/auth');
        localStorage.removeItem('perfil');
        navigate('/');
      }, thirty_minutes_timeout_logout);
    };

    if (isMountComponent) {
      // Adiciona os event listeners
      window.addEventListener('mousemove', handleUpdateTimeout);
      window.addEventListener('keydown', handleUpdateTimeout);

      // Configura o timeout inicial
      timeoutLogoutRef.current = setTimeout(() => {
        localStorage.removeItem('@jornada-ccu/auth');
        localStorage.removeItem('perfil');
        navigate('/');
      }, thirty_minutes_timeout_logout);
    } else {
      window.removeEventListener('mousemove', handleUpdateTimeout);
      window.removeEventListener('keydown', handleUpdateTimeout);
      clearTimeout(timeoutLogoutRef.current);
    }
  };

  return { automaticLogoutFunction };
};

export default useLogout;
