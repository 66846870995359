import { Button, Checkbox, Menu, MenuItem, TextField } from '@mui/material';
import { useRef, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import useStatesFields from '../../hooks/useStatesFields';
import useHandleSelect from '../../hooks/useHandleSelect';

const ModalEquipe = ({
  allEquipesOptions,
  searchEquipes,
  anchorElEquipe,
  arrayEquipes,
  equipesSelected,
  disableEquipeInput,
  setAllEquipesOptions,
  setSearchEquipes,
  setAnchorElEquipe,
  setArrayEquipes,
  setEquipesSelected,
  loadingEquipes,
}) => {
  const { handleSelectMultiplesFields } = useHandleSelect();

  return (
    <>
      <TextField
        size="small"
        data-testid="modalEquipeInput"
        disabled={disableEquipeInput}
        label="Ine da Equipe"
        value={
          equipesSelected.length > 1
            ? 'Vários'
            : arrayEquipes.find((item: any) => item?.id === equipesSelected[0])
                ?.ine ?? ''
        }
        sx={{
          padding: 0,
          width: '100%',
          '.MuiOutlinedInput-root': {
            paddingRight: '9px',
          },
        }}
        InputProps={{
          endAdornment: (
            <Button
              data-testid="modalEquipeDropdownButton"
              sx={{
                padding: 0,
                minWidth: '24px !important',
              }}
              disabled={disableEquipeInput}
              onClick={(event) => {
                setAnchorElEquipe(event.currentTarget);
              }}
            >
              <ArrowDropDownIcon
                sx={{
                  cursor: 'pointer',
                  color: disableEquipeInput ? '#BDBDBD' : 'gray',
                }}
              />
            </Button>
          ),
        }}
      />

      <Menu
        anchorEl={anchorElEquipe}
        open={!!anchorElEquipe}
        onClose={() => setAnchorElEquipe(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          position: 'absolute',
          width: '100%',
          marginTop: '10px',
          left: '10px',

          '.MuiList-root': {
            paddingTop: 0,
            paddingBottom: 0,
          },
        }}
      >
        <div
          style={{
            width: '100%',

            padding: '24px 32px',
            flexDirection: 'column',
          }}
        >
          <TextField
            size="small"
            data-testid="searchEquipes"
            placeholder="Pesquise por Ine da Equipe"
            onChange={(e) => setSearchEquipes(e.target.value)}
            value={searchEquipes}
            sx={{
              width: '289px',
              '.MuiOutlinedInput-root': {
                paddingRight: '9px',
              },
            }}
            onClick={() => {
              if (!searchEquipes) {
                setArrayEquipes(allEquipesOptions);
              } else {
                const newArrEquipes = allEquipesOptions.filter((equipe) =>
                  equipe.nome
                    .toLowerCase()
                    .includes(searchEquipes.toLowerCase())
                );

                setArrayEquipes(newArrEquipes);
              }
            }}
            InputProps={{
              endAdornment: (
                <Button
                  data-testid="submitSearchEquipes"
                  sx={{
                    padding: 0,
                    minWidth: '24px !important',
                  }}
                >
                  <SearchIcon
                    sx={{
                      cursor: 'pointer',
                      color: 'gray',
                    }}
                  />
                </Button>
              ),
            }}
          />
          <div>
            <Button
              data-testid="selectAllEquipes"
              variant="text"
              sx={{ padding: '0' }}
              onClick={() => {
                const allItemsArray = arrayEquipes.map(
                  (equipe: any) => equipe.id
                );

                setEquipesSelected(allItemsArray);
              }}
            >
              SELECIONAR TODOS -{' '}
            </Button>
            <Button
              sx={{ padding: '0' }}
              data-testid="clearEquipes"
              variant="text"
              onClick={() => setEquipesSelected([])}
            >
              LIMPAR
            </Button>
          </div>
          {loadingEquipes ? (
            <div
              style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: '20px',
              }}
            >
              <span
                className="loader"
                style={{ width: '40px', height: '40px' }}
              ></span>
            </div>
          ) : (
            <>
              {arrayEquipes?.map((equipe: any, index) => {
                const objectValue = {
                  nome: equipe?.nome,
                  id: equipe?.id,
                };

                return (
                  <div style={{ display: 'flex' }}>
                    <Checkbox
                      data-testid={`optionEquipe-${index + 1}`}
                      onChange={() => {
                        handleSelectMultiplesFields({
                          paramToSave: 'id',
                          setState: setEquipesSelected,
                          state: equipesSelected,
                          value: objectValue,
                        });
                      }}
                      checked={
                        equipesSelected.find((item) => item === equipe?.id)
                          ? true
                          : false
                      }
                    />
                    <MenuItem
                      sx={{ padding: 0 }}
                      onClick={() => {
                        handleSelectMultiplesFields({
                          paramToSave: 'id',
                          setState: setEquipesSelected,
                          state: equipesSelected,
                          value: objectValue,
                        });
                      }}
                    >
                      {String(equipe?.ine).toUpperCase()}
                    </MenuItem>
                  </div>
                );
              })}
            </>
          )}
        </div>
      </Menu>
    </>
  );
};

export default ModalEquipe;
