/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from '@hookform/resolvers/yup';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DatePicker from '@mui/lab/DatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import moment from 'moment';

import {
  Alert,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import ptBR from 'date-fns/locale/pt-BR';
import * as React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import api from '../../../api';
import { useStyles } from '../ViewExamStyle';
import { StyledAsteriskTextField } from '../../../components/StyledAsteriskTextField/styles';
import DownloadIcon from '@mui/icons-material/Download';
import AnmamneseForm from '../../RegisterExam/Anamnese';

interface MtdColeta {
  id: number;
  nome: string;
}

enum LocalColeta {
  Residencia = 'RESIDENCIA',
  ServicoSaude = 'SERVICO_SAUDE',
  UnidadeMovel = 'UNIDADE_MOVEL',
  Outro = 'OUTRO',
}

enum InspecaoColo {
  Normal = 'NORMAL',
  Ausente = 'AUSENTE',
  Alterado = 'ALTERADO',
  NaoVisualizado = 'NAO_VISUALIZADO',
}

enum ExameStatus {
  ResultadoDisponivel = 'RESULTADO_DISPONIVEL',
  ResultadoNaoEntregue = 'RESULTADO_NAO_ENTREGUE',
  Entregue = 'ENTREGUE',
}

export enum Escolha {
  Sim = 'SIM',
  Nao = 'NAO',
  NaoSabe = 'NAO_SABE',
  Default = '',
}

export enum Motivo {
  Rastreamento = 'RASTREAMENTO',
  Repeticao = 'REPETICAO',
  Seguimento = 'SEGUIMENTO',
}

interface Anamnese {
  motivo: Motivo;
  papanicolaou: Escolha;
  ano_papanicolaou: number;
  hpv: Escolha;
  ano_hpv: number;
  diu: Escolha;
  gravida: Escolha;
  anticoncepcional: Escolha;
  hormonio: Escolha;
  radioterapia: Escolha;
  menstruacao: Escolha;
  checkedMenstruacao: boolean;
  dt_menstruacao: string;
  sangramento_relacao: Escolha;
  sangramento_menopausa: Escolha;
  menopausa: any;
}

interface Clinico {
  inspecao: InspecaoColo;
  dst: boolean;
  dt_coleta: Date | string;
  responsavelId: number;
  coleta_consultorio: boolean;
  autocoleta: boolean;
  responsavelColeta: string;
  responsavelColetaId: string;
  local_coleta: LocalColeta;
  outro_local_coleta: string;
  mtd_coleta: MtdColeta;
  n_kit: string;
}

interface Inputs {
  tipo: string;
  status: ExameStatus;
  anamnese: Anamnese;
  clinico: Clinico;
  pessoaId: number;
  profissionalId: number;
  estabelecimentoSaudeId: number;
  atendimento_status: string;
  realizou_atendimento: string;
}

const schema = Yup.object({
  tipo: Yup.string(),
  status: Yup.string(),
  clinico: Yup.object({
    inspecao: Yup.string(),
    dst: Yup.boolean(),
    dt_coleta: Yup.string(),
    responsavelId: Yup.number(),
    coleta_consultorio: Yup.boolean(),
    autocoleta: Yup.boolean(),
    responsavelColeta: Yup.number(),
    local_coleta: Yup.string(),
    outro_local_coleta: Yup.string(),
    n_kit: Yup.string(),
    mtd_coleta: Yup.object({
      id: Yup.number(),
    }),
  }),

  anamnese: Yup.object({
    motivo: Yup.string(),
    papanicolaou: Yup.string(),
    ano_papanicolaou: Yup.number().when('papanicolaou', {
      is: (papanicolaou: any) => {
        return papanicolaou === Escolha.Sim;
      },
      then: Yup.number(),
    }),
    diu: Yup.string(),
    gravida: Yup.string(),
    anticoncepcional: Yup.string().required(),
    hormonio: Yup.string(),
    radioterapia: Yup.string(),
    checkedMenstruacao: Yup.boolean().default(false),
    dt_menstruacao: Yup.string(),
    sangramento_relacao: Yup.string(),
    sangramento_menopausa: Yup.string(),
  }),
  pessoaId: Yup.number(),
  profissionalId: Yup.number(),
  estabelecimentoSaudeId: Yup.number(),
});

export const HpvForm = ({ data, examId }) => {
  const styles = useStyles();
  const location = useLocation();

  const state = location.state
    ? location.state
    : JSON.parse(String(localStorage.getItem('state')));

  const fromModalMismatchDataNotification = !!state?.mismatchNotification;

  const { id } = useParams();
  const [, setPacienteData] = React.useState<any>();
  const [, setResponsaveis] = React.useState<any>([]);
  const [metodoColeta, setMetodoColeta] = React.useState<MtdColeta[]>([]);
  const [selectedOption, setSelectedOption] = React.useState<any>();
  const [lastHpvColeta, setLastHpvColeta] = React.useState<any>();
  const [lastCitologia, setLastCitologia] = React.useState<any>();

  const [checkedMenstruacao, setCheckedMenstruacao] =
    React.useState<boolean>(false);
  const {
    setValue,
    formState: { errors },
    control,
    watch,
    clearErrors,
  } = useForm<Inputs>({
    resolver: yupResolver(schema),
    defaultValues: {
      tipo: 'Teste de HPV',
      status: ExameStatus.ResultadoNaoEntregue,
    },
  });
  const rhf = watch();

  const styleRadio = {
    '&.Mui-checked': {
      color: '#238884',
    },
  };

  const styleFormLabel = {
    '&.Mui-focused': {
      color: '#238884',
    },

    '& .MuiFormLabel-asterisk': {
      color: '#d32f2f',
    },
  };

  let navigate = useNavigate();

  const handleGetMetodoColeta = async () => {
    const res = await api.get('/metodo-coleta');

    setMetodoColeta(res.data);
  };

  React.useEffect(() => {
    handleGetMetodoColeta();
  }, []);

  React.useEffect(() => {
    setPacienteData(state);
    async function loadResponsaveis() {
      const estabelecimentos = await api.get('/estabelecimentos-saude');
      const profissionais = await api.get('/profissionais');
      setResponsaveis(profissionais?.data?.data);
      setValue('estabelecimentoSaudeId', estabelecimentos?.data?.data?.[0]?.id);
      setValue('clinico.responsavelId', profissionais?.data?.data?.[0]?.id);
      setValue('profissionalId', profissionais?.data?.data?.[0]?.id);
    }
    loadResponsaveis();
  }, []);

  const handleBackPage = () => {
    navigate(`/pacientes/${id}`);
  };

  const getDataLastHpv = async () => {
    try {
      const response = await api.get(`/hpv/lastHpv/${id}`);

      setLastHpvColeta(response.data ? response.data : null);
    } catch (err) {}
  };

  const getDataLastCitologia = async () => {
    try {
      const response = await api.get(`/citologia/carga/last/${id}`);

      setLastCitologia(response.data ? response.data : null);
    } catch (err) {}
  };

  React.useEffect(() => {
    getDataLastHpv();
    getDataLastCitologia();
    handleGetMetodoColeta();
  }, []);

  React.useEffect(() => {
    if (data?.id && location?.state?.examData?.status !== 'NAO_ATENDIDA') {
      setValue('anamnese.motivo', data?.anamnese?.motivo);
      setValue('anamnese.papanicolaou', data?.anamnese?.papanicolaou);
      setValue('anamnese.papanicolaou', data?.anamnese?.papanicolaou);
      setValue('anamnese.ano_papanicolaou', data?.anamnese?.ano_papanicolaou);
      setValue('anamnese.diu', data?.anamnese?.diu);
      setValue('anamnese.gravida', data?.anamnese?.gravida);
      setValue('anamnese.anticoncepcional', data?.anamnese?.anticoncepcional);
      setValue('anamnese.hormonio', data?.anamnese?.hormonio);
      setValue('anamnese.radioterapia', data?.anamnese?.radioterapia);
      setValue('anamnese.dt_menstruacao', data?.anamnese?.dt_menstruacao);
      setValue('anamnese.menopausa', data?.anamnese?.menopausa);
      setValue('anamnese.hpv', data?.anamnese?.hpv);
      setValue('anamnese.ano_hpv', data?.anamnese?.ano_hpv);

      setSelectedOption('sim');

      if (data?.anamnese?.dt_menstruacao_desconhecida) {
        setCheckedMenstruacao(true);
      }

      setValue(
        'anamnese.sangramento_relacao',
        data?.anamnese?.sangramento_relacao
      );
      setValue(
        'anamnese.sangramento_menopausa',
        data?.anamnese?.sangramento_menopausa
      );
      setValue('clinico.inspecao', data.clinico?.inspecao);
      setValue('clinico.dst', data.clinico?.dst);
      setValue('clinico.autocoleta', data.clinico?.autocoleta);

      const dataColeta = data.clinico?.dt_coleta.split('-');

      const dataColetaFormatada = `${dataColeta?.[2]}/${dataColeta?.[1]}/${dataColeta?.[0]}`;
      setValue('clinico.dt_coleta', dataColetaFormatada);

      if (data.clinico?.responsavel_coleta) {
        setValue(
          'clinico.responsavelColeta',
          data.clinico?.responsavel_coleta?.pessoa?.nome
        );
      }

      setValue('clinico.local_coleta', data.clinico?.local_coleta);
      setValue('clinico.mtd_coleta.id', data?.clinico?.mtd_coleta?.id);
      setValue('clinico.n_kit', data.clinico?.n_kit);
      setValue('clinico.outro_local_coleta', data.clinico?.outro_local_coleta);
    } else {
      setValue('atendimento_status', state?.examData?.atendimento_status);
    }
  }, [data?.id]);

  const generatePDF = async (storedExamDataId) => {
    try {
      const response = await api.get(`/reports/hpv/exame/${storedExamDataId}`, {
        responseType: 'arraybuffer',
        headers: {
          Accept: 'application/pdf',
        },
      });

      const blob = new Blob([response.data], { type: 'application/pdf' });
      const link = document.createElement('a');

      link.href = window.URL.createObjectURL(blob);
      link.target = '_blank';

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Erro ao gerar PDF:', error);
    }
  };

  const storedExamDataId = examId;

  React.useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  return (
    <Box mt={3}>
      <Box sx={{ display: 'flex', justifyContent: 'end' }}>
        <Button
          variant="outlined"
          onClick={() => generatePDF(storedExamDataId)}
          startIcon={<DownloadIcon />}
          data-testid="exam-export"
        >
          EXPORTAR
        </Button>
      </Box>
      <p style={{ fontSize: '12px', textAlign: 'right', color: '#d32f2f' }}>
        <i>* Resposta obrigatória</i>
      </p>

      {state?.examData?.status === 'NAO_ATENDIDA' ? (
        <Box my={3}>
          <Paper variant="outlined">
            <Box p={3}>
              <Typography variant="h5" fontWeight="bold">
                Por que o procedimento não foi realizado?
              </Typography>
              <Box my={3}>
                <Divider />
              </Box>
              <Grid item xs={12}>
                <Controller
                  name="atendimento_status"
                  control={control}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        name="radio-buttons-group"
                        onChange={onChange}
                        value={'MENSTRUADA'}
                      >
                        <FormControlLabel
                          value={'NAO_REALIZOU'}
                          control={
                            <Radio
                              disabled
                              sx={styleRadio}
                              checked={value === 'NAO_REALIZOU'}
                            />
                          }
                          label="Compareceu mas não realizou o exame"
                        />
                        <FormControlLabel
                          value={'MENSTRUADA'}
                          control={
                            <Radio
                              disabled
                              sx={styleRadio}
                              checked={value === 'MENSTRUADA'}
                            />
                          }
                          label="Estava Menstruada  "
                        />
                        <FormControlLabel
                          value={'NAO_FAIXA_ETARIA'}
                          control={
                            <Radio
                              disabled
                              sx={styleRadio}
                              checked={value === 'NAO_FAIXA_ETARIA'}
                            />
                          }
                          label="Não está na faixa etária"
                        />
                        <FormControlLabel
                          value={'HISTERECTOMIZADA'}
                          control={
                            <Radio
                              disabled
                              sx={styleRadio}
                              checked={value === 'HISTERECTOMIZADA'}
                            />
                          }
                          label="Histerectomizada por patologia benigna"
                        />
                        <FormControlLabel
                          value={'OUTRO'}
                          control={
                            <Radio
                              disabled
                              sx={styleRadio}
                              checked={value === 'OUTRO'}
                            />
                          }
                          label="Outro"
                        />
                      </RadioGroup>
                    );
                  }}
                />
              </Grid>
              <TextField
                multiline
                rows={3}
                sx={{ width: '100%' }}
                disabled
                value={state.examData?.atendimento_observacao}
                label="Observações e informações adicionais"
                // onChange={(e) => setOutroMotivoNaoAtendida(e.target.value)}
              />
            </Box>
          </Paper>
        </Box>
      ) : (
        <>
          <Paper
            variant="outlined"
            sx={{
              p: '32px',
            }}
          >
            <Typography variant="h5" fontWeight="700">
              Informações sobre o atendimento
            </Typography>
            <Divider style={{ marginTop: '24px', marginBottom: '24px' }} />
            <FormLabel required sx={styleFormLabel}>
              Realizou o Procedimento?
            </FormLabel>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Controller
                  name="realizou_atendimento"
                  control={control}
                  render={({ field: { onChange } }) => (
                    <RadioGroup
                      value={selectedOption}
                      onChange={(e) => {
                        onChange(e);
                      }}
                      row
                    >
                      <FormControlLabel
                        value={`sim`}
                        disabled
                        control={
                          <Radio
                            sx={styleRadio}
                            disabled
                            checked={selectedOption === 'sim'}
                          />
                        }
                        label="Sim"
                      />
                      <FormControlLabel
                        value={`nao`}
                        control={
                          <Radio
                            sx={styleRadio}
                            disabled
                            checked={selectedOption === 'nao'}
                          />
                        }
                        label="Não"
                      />
                    </RadioGroup>
                  )}
                />
              </Grid>

              {selectedOption === 'sim' && (
                <>
                  <Grid
                    item
                    xs={12}
                    sx={{ flexDirection: 'column', display: 'flex' }}
                  >
                    <FormControl error={!!errors?.clinico?.autocoleta}>
                      <FormLabel
                        required
                        error={!!errors?.clinico?.autocoleta}
                        sx={styleFormLabel}
                      >
                        Método de Coleta de HPV
                      </FormLabel>
                      <Controller
                        render={({ field: { onChange, value } }) => {
                          return (
                            <RadioGroup value={value ? value : false} row>
                              <FormControlLabel
                                value={false}
                                control={
                                  <Radio
                                    sx={styleRadio}
                                    checked={value === false}
                                  />
                                }
                                label="Profissional de Saúde"
                                disabled
                              />
                              <FormControlLabel
                                value={true}
                                control={
                                  <Radio
                                    sx={styleRadio}
                                    checked={value === true}
                                  />
                                }
                                label="Autocoleta"
                                disabled
                              />
                            </RadioGroup>
                          );
                        }}
                        name="clinico.autocoleta"
                        control={control}
                      />
                      <FormHelperText>
                        {errors?.clinico?.autocoleta?.message}
                      </FormHelperText>
                    </FormControl>
                  </Grid>

                  {watch('clinico.autocoleta') !== undefined && (
                    <>
                      <Grid item xs={12} md={10.5}>
                        <Controller
                          render={({ field: { onChange, value } }) => (
                            <StyledAsteriskTextField
                              fullWidth
                              required
                              label={
                                String(watch('clinico.autocoleta')) === 'true'
                                  ? 'Profissional Responsável pela Orientação da Autocoleta'
                                  : 'Profissional Responsável pela Coleta'
                              }
                              size="small"
                              variant="outlined"
                              className={styles.textField}
                              error={!!errors.clinico?.responsavelColeta}
                              helperText={
                                errors.clinico?.responsavelColeta?.message
                              }
                              value={value ? value : ''}
                              onChange={() => {}}
                              disabled
                            />
                          )}
                          name="clinico.responsavelColeta"
                          control={control}
                        />
                      </Grid>
                      {String(watch('clinico.autocoleta')) === 'true' && (
                        <>
                          <Grid item xs={12} md={3}>
                            <FormControl
                              error={!!errors.clinico?.dt_coleta && true}
                            >
                              <LocalizationProvider
                                dateAdapter={AdapterDateFns}
                                locale={ptBR}
                              >
                                <div>
                                  <Controller
                                    control={control}
                                    name="clinico.dt_coleta"
                                    render={({
                                      field: { onChange, onBlur, value, ref },
                                    }) => (
                                      <DatePicker
                                        //disabled={checkedMenstruacao}
                                        mask="__/__/____"
                                        onChange={() => console.log('a')}
                                        value={value ? value : null}
                                        renderInput={(params) => (
                                          <StyledAsteriskTextField
                                            {...params}
                                            error={
                                              !!errors.clinico?.dt_coleta &&
                                              true
                                            }
                                            fullWidth
                                            required
                                            sx={{ width: '100%' }}
                                            helperText={
                                              errors.clinico?.dt_coleta?.message
                                            }
                                            // onBlur={() => {
                                            //   const isValid = validarData(
                                            //     moment(
                                            //       watch('clinico.dt_coleta')
                                            //     ).format('DD/MM/YYYY')
                                            //   );

                                            //   if (isValid) {
                                            //     clearErrors(
                                            //       'clinico.dt_coleta'
                                            //     );
                                            //   } else {
                                            //     setError('clinico.dt_coleta', {
                                            //       type: 'manual',
                                            //       message:
                                            //         'Digite uma data válida.',
                                            //     });
                                            //   }
                                            // }}
                                            label="Data da coleta"
                                            size="small"
                                            variant="outlined"
                                            className={styles.textField}
                                          />
                                        )}
                                      />
                                    )}
                                  />
                                </div>
                              </LocalizationProvider>
                            </FormControl>
                          </Grid>

                          <Grid item xs={12} md={3}>
                            <Controller
                              render={({ field: { onChange, value } }) => (
                                <StyledAsteriskTextField
                                  select
                                  label="Local da Coleta"
                                  required
                                  size="small"
                                  variant="outlined"
                                  fullWidth
                                  className={styles.textField}
                                  onChange={onChange}
                                  error={!!errors.clinico?.local_coleta}
                                  helperText={
                                    errors.clinico?.local_coleta?.message
                                  }
                                  value={value ? value : ''}
                                >
                                  <MenuItem value={LocalColeta.Residencia}>
                                    Residência da mulher
                                  </MenuItem>
                                  <MenuItem value={LocalColeta.ServicoSaude}>
                                    Serviço de Saúde
                                  </MenuItem>
                                  <MenuItem value={LocalColeta.UnidadeMovel}>
                                    Unidade Móvel
                                  </MenuItem>
                                  <MenuItem value={LocalColeta.Outro}>
                                    Outro Local
                                  </MenuItem>
                                </StyledAsteriskTextField>
                              )}
                              name="clinico.local_coleta"
                              control={control}
                            />
                          </Grid>
                          {watch().clinico?.local_coleta ===
                            LocalColeta.Outro && (
                            <Grid
                              item
                              xs={12}
                              md={4.5}
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                              }}
                            >
                              <Controller
                                render={({ field: { onChange, value } }) => (
                                  <StyledAsteriskTextField
                                    label="Outro Local"
                                    onChange={onChange}
                                    size="small"
                                    fullWidth
                                    variant="outlined"
                                    style={{ backgroundColor: '#fafafa' }}
                                    type="text"
                                  />
                                )}
                                name="clinico.n_kit"
                                control={control}
                              />
                            </Grid>
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </Grid>

            {/* <p>{errors?.realizou_atendimento?.message}</p> */}
          </Paper>
          <form onSubmit={() => {}}>
            <AnmamneseForm
              errors={errors}
              control={control}
              watch={watch}
              styles={styles}
              checkedMenstruacao={checkedMenstruacao}
              setCheckedMenstruacao={setCheckedMenstruacao}
              clearErrors={clearErrors}
              setValue={setValue}
              screenMode="view"
              exam={data}
              lastHpvColeta={
                lastHpvColeta?.dt_coleta
                  ? String(
                      moment(lastHpvColeta?.dt_coleta).format('DD/MM/YYYY')
                    )
                  : ''
              }
              lastCitologia={
                lastCitologia?.dt_coleta
                  ? String(
                      moment(lastCitologia?.dt_coleta).format('DD/MM/YYYY')
                    )
                  : ''
              }
            />
            <Box my={3}>
              <Paper variant="outlined">
                <Box p={3}>
                  <Typography variant="h5" fontWeight="bold">
                    Exame Clínico
                  </Typography>

                  <Box my={3}>
                    <Divider />
                  </Box>

                  {/* <Grid
                    container
                    rowSpacing={3}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  >
                    <Grid
                      item
                      xs={12}
                      md={6}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Controller
                        render={({ field: { onChange, value } }) => (
                          <StyledAsteriskTextField
                            required
                            label="Inspeção do Colo"
                            onChange={() => {}}
                            value={value ? value : ''}
                            select
                            fullWidth
                            size="small"
                            variant="outlined"
                            className={styles.textField}
                            error={!!errors.clinico?.inspecao}
                            helperText={errors.clinico?.inspecao?.message}
                            disabled
                          >
                            <MenuItem value={InspecaoColo.Normal}>
                              Normal
                            </MenuItem>
                            <MenuItem value={InspecaoColo.Ausente}>
                              Ausente (anomalias congênitas ou retirado
                              cirurgicamente)
                            </MenuItem>
                            <MenuItem value={InspecaoColo.Alterado}>
                              Alterado
                            </MenuItem>
                            <MenuItem value={InspecaoColo.NaoVisualizado}>
                              Colo Não Visualizado
                            </MenuItem>
                          </StyledAsteriskTextField>
                        )}
                        name="clinico.inspecao"
                        control={control}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Alert severity="warning">
                        Na presença de colo alterado, com{' '}
                        <b>lesão sugestiva de câncer</b>, não aguardar o
                        resultado do TESTE HPV para <b>encaminhar</b> a mulher
                        para <b>colposcopia.</b>
                      </Alert>
                    </Grid>
                    <Grid container item xs={12} spacing={2}>
                      <Grid item xs={12}>
                        <FormControl error={errors.clinico?.dst && true}>
                          <FormLabel
                            sx={styleFormLabel}
                            id="demo-row-radio-buttons-group-label"
                          >
                            Sinais sugestivos de doenças sexualmente
                            transmissíveis?{' '}
                          </FormLabel>
                          <Controller
                            render={({ field: { onChange, value } }) => {
                              return (
                                <RadioGroup
                                  onChange={() => {}}
                                  value={
                                    value !== undefined ? value : undefined
                                  }
                                  row
                                >
                                  <FormControlLabel
                                    value={true}
                                    control={
                                      <Radio
                                        sx={styleRadio}
                                        checked={value === true}
                                      />
                                    }
                                    label="Sim"
                                    disabled
                                  />
                                  <FormControlLabel
                                    value={false}
                                    control={
                                      <Radio
                                        sx={styleRadio}
                                        checked={value === false}
                                      />
                                    }
                                    label="Não"
                                    disabled
                                  />
                                </RadioGroup>
                              );
                            }}
                            name="clinico.dst"
                            control={control}
                          />
                          <FormHelperText>
                            {errors.clinico?.dst?.message}
                          </FormHelperText>
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} md={3}>
                        <Controller
                          control={control}
                          name="clinico.dt_coleta"
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => {
                            return (
                              <StyledAsteriskTextField
                                fullWidth
                                required
                                label="Data da coleta"
                                size="small"
                                variant="outlined"
                                className={styles.textField}
                                value={value ? value : ''}
                                onChange={() => {}}
                                disabled
                              />
                            );
                          }}
                        />
                        <FormHelperText>
                          {errors.clinico?.dt_coleta?.message}
                        </FormHelperText>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <Controller
                        render={({ field: { onChange, value } }) => (
                          <StyledAsteriskTextField
                            select
                            required
                            label="Local da Coleta"
                            size="small"
                            variant="outlined"
                            className={styles.textField}
                            onChange={() => {}}
                            error={!!errors.clinico?.local_coleta}
                            helperText={errors.clinico?.local_coleta?.message}
                            value={value ? value : ''}
                            disabled
                          >
                            <MenuItem value={LocalColeta.Residencia}>
                              Residência da mulher
                            </MenuItem>
                            <MenuItem value={LocalColeta.ServicoSaude}>
                              Serviço de Saúde
                            </MenuItem>
                            <MenuItem value={LocalColeta.UnidadeMovel}>
                              Unidade Móvel
                            </MenuItem>
                            <MenuItem value={LocalColeta.Outro}>
                              Outro Local
                            </MenuItem>
                          </StyledAsteriskTextField>
                        )}
                        name="clinico.local_coleta"
                        control={control}
                      />
                    </Grid>
                    {watch().clinico?.local_coleta === LocalColeta.Outro && (
                      <Grid
                        item
                        xs={12}
                        md={4.5}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <Controller
                          render={({ field: { onChange, value } }) => (
                            <StyledAsteriskTextField
                              label="Outro Local"
                              onChange={onChange}
                              size="small"
                              fullWidth
                              variant="outlined"
                              style={{ backgroundColor: '#fafafa' }}
                              type="text"
                            />
                          )}
                          name="clinico.n_kit"
                          control={control}
                        />
                      </Grid>
                    )}
                  </Grid> */}
                  <Grid
                    container
                    spacing={2}
                    // rowSpacing={3}
                    // columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  >
                    <Grid
                      item
                      xs={12}
                      md={6}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Controller
                        render={({ field: { onChange, value } }) => (
                          <StyledAsteriskTextField
                            required
                            label="Inspeção do Colo"
                            onChange={() => {}}
                            value={value ? value : ''}
                            select
                            fullWidth
                            size="small"
                            variant="outlined"
                            data-testid="exam-clinico-inspecao_colo"
                            inputProps={{
                              'data-testid': 'exam-clinico-inspecao_colo',
                            }}
                            className={styles.textField}
                            error={!!errors.clinico?.inspecao}
                            helperText={errors.clinico?.inspecao?.message}
                            disabled
                          >
                            <MenuItem value={InspecaoColo.Normal}>
                              Normal
                            </MenuItem>
                            <MenuItem value={InspecaoColo.Ausente}>
                              Ausente (anomalias congênitas ou retirado
                              cirurgicamente)
                            </MenuItem>
                            <MenuItem value={InspecaoColo.Alterado}>
                              Alterado
                            </MenuItem>
                            <MenuItem value={InspecaoColo.NaoVisualizado}>
                              Colo Não Visualizado
                            </MenuItem>
                          </StyledAsteriskTextField>
                        )}
                        name="clinico.inspecao"
                        control={control}
                      />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <Alert
                        severity="warning"
                        data-testid="exam-clinico-info_box"
                        style={{ display: 'flex', alignItems: 'center' }}
                      >
                        Na presença de colo alterado, com{' '}
                        <b>lesão sugestiva de câncer</b>, não aguardar o
                        resultado do TESTE HPV para <b>encaminhar</b> a mulher
                        para <b>colposcopia.</b>
                      </Alert>
                    </Grid>

                    <Grid item xs={12}>
                      <FormControl error={!!errors?.clinico?.dst}>
                        <FormLabel
                          required
                          error={!!errors?.clinico?.dst}
                          sx={styleFormLabel}
                          id="demo-row-radio-buttons-group-label"
                        >
                          Sinais sugestivos de doenças sexualmente
                          transmissíveis?{' '}
                        </FormLabel>
                        <Controller
                          render={({ field: { onChange, value } }) => {
                            return (
                              <RadioGroup
                                onChange={() => {}}
                                value={value !== undefined ? value : undefined}
                                row
                              >
                                <FormControlLabel
                                  value={true}
                                  control={
                                    <Radio
                                      sx={styleRadio}
                                      inputProps={
                                        {
                                          'data-testid': 'exam-clinico-dst-sim',
                                        } as React.InputHTMLAttributes<HTMLInputElement>
                                      }
                                      checked={value === true}
                                    />
                                  }
                                  label="Sim"
                                  disabled
                                />
                                <FormControlLabel
                                  value={false}
                                  control={
                                    <Radio
                                      sx={styleRadio}
                                      inputProps={
                                        {
                                          'data-testid': 'exam-clinico-dst-nao',
                                        } as React.InputHTMLAttributes<HTMLInputElement>
                                      }
                                      checked={value === false}
                                    />
                                  }
                                  label="Não"
                                  disabled
                                />
                              </RadioGroup>
                            );
                          }}
                          name="clinico.dst"
                          control={control}
                        />
                        <FormHelperText>
                          {errors?.clinico?.dst?.message}
                        </FormHelperText>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} md={3}>
                      <FormControl
                        error={!!errors.clinico?.dt_coleta && true}
                        sx={{ width: '100%' }}
                      >
                        <Controller
                          control={control}
                          name="clinico.dt_coleta"
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => {
                            return (
                              <StyledAsteriskTextField
                                fullWidth
                                required
                                label="Data da coleta"
                                size="small"
                                variant="outlined"
                                inputProps={{
                                  'data-testid': 'exam-clinico-data_coleta',
                                }}
                                className={styles.textField}
                                value={value ? value : ''}
                                onChange={() => {}}
                                disabled
                              />
                            );
                          }}
                        />
                      </FormControl>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      md={4.5}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Controller
                        render={({ field: { onChange, value } }) => (
                          <StyledAsteriskTextField
                            select
                            label="Local da Coleta"
                            required
                            disabled
                            size="small"
                            variant="outlined"
                            inputProps={{
                              'data-testid': 'exam-clinico-local_coleta',
                            }}
                            fullWidth
                            className={styles.textField}
                            onChange={onChange}
                            error={!!errors.clinico?.local_coleta}
                            helperText={errors.clinico?.local_coleta?.message}
                            value={value ? value : ''}
                          >
                            <MenuItem value={LocalColeta.Residencia}>
                              Residência da mulher
                            </MenuItem>
                            <MenuItem value={LocalColeta.ServicoSaude}>
                              Serviço de Saúde
                            </MenuItem>
                            <MenuItem value={LocalColeta.UnidadeMovel}>
                              Unidade Móvel
                            </MenuItem>
                            <MenuItem value={LocalColeta.Outro}>
                              Outro Local
                            </MenuItem>
                          </StyledAsteriskTextField>
                        )}
                        name="clinico.local_coleta"
                        control={control}
                      />
                    </Grid>
                    {watch().clinico?.local_coleta === LocalColeta.Outro && (
                      <Grid
                        item
                        xs={12}
                        md={4.5}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <Controller
                          render={({ field: { onChange, value } }) => (
                            <StyledAsteriskTextField
                              label="Outro Local"
                              onChange={onChange}
                              size="small"
                              fullWidth
                              disabled
                              variant="outlined"
                              style={{ backgroundColor: '#fafafa' }}
                              type="text"
                              value={value ? value : ''}
                            />
                          )}
                          name="clinico.outro_local_coleta"
                          control={control}
                        />
                      </Grid>
                    )}
                  </Grid>
                </Box>
              </Paper>
            </Box>

            <Box display="flex" justifyContent="space-between">
              <Button
                sx={{
                  backgroundColor: '#17BEBB',
                }}
                variant="contained"
                onClick={handleBackPage}
                data-testid="exam-return"
              >
                Voltar
              </Button>
            </Box>
          </form>
        </>
      )}
    </Box>
  );
};
