import { TableCell, TableHead, TableRow, Typography } from '@mui/material';

const TableHeaders = () => {
  return (
    <TableHead>
      <TableRow>
        <TableCell sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Nome Completo
            <br />
            da Mulher
          </Typography>
        </TableCell>
        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            {' '}
            Nº do Cartão SUS
          </Typography>
        </TableCell>
        <TableCell align="left" sx={{ borderBottom: 'none', width: '160px' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Nome Completo <br />
            da Mãe
          </Typography>
        </TableCell>
        <TableCell align="left" sx={{ borderBottom: 'none', width: '160px' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            CPF
          </Typography>
        </TableCell>
        <TableCell align="left" sx={{ borderBottom: 'none', width: '160px' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Data de
            <br />
            Nascimento
          </Typography>
        </TableCell>
        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Idade
          </Typography>
        </TableCell>
        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Nacionalidade
          </Typography>
        </TableCell>
        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Raça / Cor
          </Typography>
        </TableCell>

        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            CEP
          </Typography>
        </TableCell>
        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Logradouro
          </Typography>
        </TableCell>
        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Número
          </Typography>
        </TableCell>
        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Complemento
          </Typography>
        </TableCell>
        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Bairro
          </Typography>
        </TableCell>
        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Ponto de <br />
            Referência
          </Typography>
        </TableCell>
        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            UF
          </Typography>
        </TableCell>
        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Município
          </Typography>
        </TableCell>
        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Estabelecimento
            <br />
            Vinculado da <br />
            Paciente
          </Typography>
        </TableCell>
        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Data da
            <br />
            Colposcopia
          </Typography>
        </TableCell>
        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Exame
            <br />
            macroscópico da
            <br />
            vulva e região
            <br />
            perianal 
          </Typography>
        </TableCell>
        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Avaliação Geral da
            <br />
            Colposcopia
          </Typography>
        </TableCell>
        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Inadequada por
          </Typography>
        </TableCell>
        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Visibilidade da
            <br /> junção
            <br />
            escamocolunar
          </Typography>
        </TableCell>
        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Zonda de
            <br /> Transformação
            <br />
            (ZT)
          </Typography>
        </TableCell>
        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Tipos de Achados
          </Typography>
        </TableCell>

        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Local
          </Typography>
        </TableCell>

        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Localização em
            <br />
            relação a Zona de
            <br />
            Transformação
            <br />
            (ZT) 
          </Typography>
        </TableCell>

        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Nº de quadrantes
            <br />
            envolvidos
          </Typography>
        </TableCell>

        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Grau 1 (Menor)
          </Typography>
        </TableCell>

        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Grau 2 (Maior)
          </Typography>
        </TableCell>

        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Não especifico
          </Typography>
        </TableCell>

        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Suspeita de
            <br />
            Invasão
          </Typography>
        </TableCell>

        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Miscelânea
          </Typography>
        </TableCell>

        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Captação da <br />
            Solução de lugol
          </Typography>
        </TableCell>

        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Teste de Schiller
          </Typography>
        </TableCell>

        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Realizar Biópsia
          </Typography>
        </TableCell>

        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Localização da <br />
            Realização da
            <br />
            Biópsia
          </Typography>
        </TableCell>

        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Rede de
            <br />
            Atendimento
          </Typography>
        </TableCell>

        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            CNES do <br />
            Laboratório
          </Typography>
        </TableCell>

        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Laboratório
            <br />
            Responsável pelo
            <br />
            Laudo
          </Typography>
        </TableCell>

        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Número do <br />
            Protocolo do <br />
            Exame no SISCAN
          </Typography>
        </TableCell>

        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Profissional
            <br />
            Responsável
          </Typography>
        </TableCell>

        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            CBO do
            <br />
            Profissional
          </Typography>
        </TableCell>

        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            CNS do
            <br />
            Profissional
          </Typography>
        </TableCell>

        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            INE do
            <br />
            Profissional
          </Typography>
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

export default TableHeaders;
