import { createContext, useEffect, useState } from 'react';
import { getUserToken } from '../lib/auth';
import { getAlerst, getHaveResults } from '../lib/getAlertList';

type AlertListContextData = {
  alertList: any[];
  haveResults: boolean;
  setAlertList: (alertList: any[]) => void;
  setHaveResults: (haveResults: boolean) => void;
  setUpdateAlertList: (updateAlertList: any) => void;
  setPage: (page: any) => void;
  showNotificationList: boolean;
  setShowNotificationList: (showNotificationList: boolean) => void;
  loadingNotifications: boolean;
  alertListQuantity: number;
  reloadAlertList: () => void;
  dataCentral: any;
  setDataCentral: (data: any) => void;
};

export const AlertListContext = createContext({} as AlertListContextData);

export const AlertListProvider = ({ children }) => {
  const [alertListQuantity, setAlertListQuantity] = useState(0);
  const [showNotificationList, setShowNotificationList] = useState(false);
  const [alertList, setAlertList] = useState<any[]>([]);
  const [haveResults, setHaveResults] = useState<any>(false);
  const [updateAlertList, setUpdateAlertList] = useState<any>(0);
  const [loadingNotifications, setLoadingNotifications] = useState(true);
  const [page, setPage] = useState(1);
  const [dataCentral, setDataCentral] = useState<any>();

  const isLogged = !!localStorage.getItem('isLogged');
  const getAlertList = async () => {
    setLoadingNotifications(true);
    try {
      const response = await getAlerst(page);
      const resultadoHpvPendenteAlerts = response.pendentes ?? {};
      const resultadosDisponiveisAlerts = response.disponiveis ?? {};

      // let newArr: any = [];

      // newArr.push(...alertList);

      // response.data.forEach((item) => {
      //   newArr.push(item);
      // });

      setAlertListQuantity(response.totalItems);
      setAlertList((prevList) => [...prevList, ...response.data]);

      setLoadingNotifications(false);
    } catch (err) {
      setLoadingNotifications(false);
    }
  };

  const reloadAlertList = async () => {
    setLoadingNotifications(true);
    try {
      const response = await getAlerst(page);
      const resultadoHpvPendenteAlerts = response.pendentes ?? {};
      const resultadosDisponiveisAlerts = response.disponiveis ?? {};

      setAlertListQuantity(response.totalItems);
      setAlertList(response.data);

      setLoadingNotifications(false);
    } catch (err) {
      setLoadingNotifications(false);
    }
  };

  useEffect(() => {
    if (isLogged) getAlertList();
  }, [page, isLogged]);

  useEffect(() => {
    if (alertList.length > 5) {
      setShowNotificationList(true);
    }
  }, [alertList]);

  return (
    <AlertListContext.Provider
      value={{
        alertList,
        haveResults,
        setAlertList,
        setHaveResults,
        setUpdateAlertList,
        setPage,
        showNotificationList,
        setShowNotificationList,
        loadingNotifications,
        alertListQuantity,
        reloadAlertList,
        dataCentral,
        setDataCentral,
      }}
    >
      {children}
    </AlertListContext.Provider>
  );
};
