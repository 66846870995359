import React, { useEffect, useState } from 'react';
import { background } from '../utils/randomBackgrounds';

interface Props {
  children: JSX.Element;
}

interface ImageContextProps {
  setBackgroundImage: (data) => void;
  backgroundImage: any;
}

export const BackgroundContext = React.createContext<ImageContextProps>(
  {} as ImageContextProps
);

export const BackgroundProvider = ({ children }: Props) => {
  const [backgroundImage, setBackgroundImage] = useState<String | undefined>(
    ''
  );

  useEffect(() => {
    const randomImage = background();
    setBackgroundImage(randomImage);
  }, []);

  return (
    <BackgroundContext.Provider value={{ setBackgroundImage, backgroundImage }}>
      {children}
    </BackgroundContext.Provider>
  );
};
