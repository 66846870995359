import { Box, IconButton } from '@mui/material';
import { ExamePendente } from '../NotificationModal';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import './style.css';
import React from 'react';
import { AlertListContext } from '../../context/AlertListContext';
import { ca } from 'date-fns/locale';
import api from '../../api';
import { LoadingContext } from '../../context/LoadingContext';

interface Props {
  examePendente: ExamePendente;
  id?: number;
}

const NotificationModalSavePerson = (props: Props) => {
  const { alertList, setAlertList, setUpdateAlertList } =
    React.useContext(AlertListContext);
  const { setLoading } = React.useContext(LoadingContext);
  const handleBuscarButtonClick = async (id: number) => {
    const newTab = window.open(`${window.location.origin}/pacientes`, '_blank');
    try {
      setLoading(true);

      api.delete(`importacao_gal/exame/hpv/resultados/${id}`).then(() => {
        setAlertList(alertList.filter((item) => item.id !== id));
      });
    } catch (error) {
      console.error(error);
    } finally {
      newTab?.focus();
      setUpdateAlertList((prev) => Number(prev + 1));
      setLoading(false);
    }
  };
  return (
    <>
      <p>
        As informações do GAL não correspondem aos dados necessários para
        associar o resultado a uma paciente no Jordana.
      </p>
      <Box className="subModalTextBody">
        <div>
          <div>
            <span className="highlight">Requisição do GAL: </span>{' '}
            {props.examePendente.id_gal}
          </div>
        </div>
      </Box>
      <p>
        Procure os dados correspondentes da paciente e atualize-os para{' '}
        <span style={{ color: '#2196F3' }}>vincular</span> o resultado
        corretamente:{' '}
      </p>
      <Box className="subModalTextBody">
        <div>
          <span className="highlight" >Busca por Pacientes</span>
        </div>
        <IconButton
          onClick={() => {
            handleBuscarButtonClick(
              Number(props.examePendente.id_exame_pendente)
            );
          }}
        >
          <ChevronRightIcon />
        </IconButton>
      </Box>
    </>
  );
};

export default NotificationModalSavePerson;
