import { BarChart } from '../barChart';

interface SquamousPreNeoplasticProps {
  data: number[];
  categories?: String[];
  height?: number;
}

export function SquamousPreNeoplastic({ data }: SquamousPreNeoplasticProps) {
  return (
    <BarChart
      data={data}
      categories={[
        ['NIC I (Displasia', 'leve)'],
        ['NIC II (Displasia', 'moderada)'],
        ['NIC III (Displasia', 'acentuada / Carcinoma in)', 'situ'],
        ['Carcinoma', 'Epidermóide', 'Microinvasivo'],
        ['Carcinoma', 'Epidermóide', 'Invasivo'],
      ]}
      colors={['#F28787']}
    />
  );
}
