import { Alert, Box, Button, Tooltip, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import api from '../../api.js';
import CardExam from '../CardExam';
import { TimelineFilter } from '../TimelineFilter/';
import { BorderLeft, List } from './Timeline.style';
import { useEffect, useState } from 'react';
export interface IHistopatologia {
  dt_coleta: string;
  dt_resultado: string;
}

export interface IColposcopia {
  dt_coleta: string;
}
export interface ICitologia {
  protocolo?: string | null;
  laboratorio_responsavel?: string | null;
  dt_coleta: string;
  id?: number | null;
  clinico?: any;
}

export interface IHpv {
  clinico: {
    n_kit: string;
    protocolo: string | null;
    dt_coleta: string | null;
    autocoleta?: boolean | null;
  };
  dt_coleta: string | null;
  id: number;
}
export interface IProcedimento {
  categoria: {
    id: number;
    nome: string;
    tipo: {
      id: number;
      nome: string;
    };
  };
  citologia: ICitologia | null;
  colposcopia: IColposcopia | null;
  created_at: Date;
  dt_coleta: Date;
  histopatologia: IHistopatologia | null;
  intraepitelial: any;
  hpv: IHpv | null;
  id: number;
  status: string;
  updated_at: Date;
  intraepiteliais?: {};
}
export interface Procedimentos {
  ano: number;
  procedimentos: IProcedimento[];
}

// const examsMock: Procedimentos[] = [
//   {
//     ano: 2023,
//     procedimentos: [
//       {
//         id: 1883,
//         created_at: new Date('2023-09-01T21:32:22.483Z'),
//         updated_at: new Date('2024-02-28T18:54:37.034Z'),
//         status: 'DISPONIVEL',
//         dt_coleta: new Date('2023-09-01T21:32:22.483Z'),
//         hpv: null,
//         categoria: {
//           id: 1,
//           nome: 'Tratamento das Lesões Intraepiteliais',
//           tipo: {
//             id: 1,
//             nome: 'Exames de Rastreio',
//           },
//         },
//         colposcopia: null,
//         histopatologia: null,
//         citologia: null,
//         intraepiteliais: {
//           id: 1574,
//           dt_coleta: '2023-09-01',
//           clinico: {
//             dt_coleta: '2023-09-01',
//             n_kit: '223',
//             protocolo: '233',
//             autocoleta: true,
//           },
//         },
//       },
//     ],
//   },
// ];

const handleBorderLeftColor = (type: any) => {
  if (type.includes('Exames de Rastreio')) {
    return '#95C9C9';
  } else {
    return '#F88078';
  }
};

export default function Timeline() {
  const [exams, setExams] = useState<Procedimentos[]>([]);
  const [examsNaoColetados, setExamsNaoColetados] = useState<any[]>([]);

  function getYear(data) {
    return new Date(data).getFullYear();
  }

  const [typeExameSelected, setTypeExamSelected] = useState('coletado');
  const { id } = useParams();

  async function loadExams(id: string) {
    const res = await api.get(`pacientes/${id}/exames`);

    if (res.status === 200) {
      setExams(res.data);
    }
  }

  const loadExamsNaoColetados = async (id) => {
    const resNaoColetados: any = await api.get(`/hpv/nao-atendida/${id}`);

    if (resNaoColetados.status === 200) {
      const examsPerYear = {};

      resNaoColetados.data.forEach((examNaoColetado: any) => {
        const examObj = {
          ...examNaoColetado,
          categoria: {
            id: 1,
            nome: 'Teste de HPV',
            tipo: {
              id: 1,
              nome: 'Exames de Rastreio',
            },
          },
        };
        const year = getYear(examObj.created_at);

        if (!examsPerYear[year]) {
          examsPerYear[year] = {
            ano: year,
            procedimentos: [],
          };
        }

        examsPerYear[year].procedimentos.push(examObj);
      });

      const resultado = Object.values(examsPerYear);

      setExamsNaoColetados(resultado);
    }
  };

  useEffect(() => {
    if (id) {
      loadExamsNaoColetados(id);
      loadExams(id);
    }
  }, [id]);

  const handleListBorderLeft = (exam: Procedimentos) => {
    return exam.procedimentos?.map((procedimento, index) => {
      let tipoAtual = '';
      let tipoProximo = '';

      if (exam.procedimentos[index + 1]) {
        tipoAtual = procedimento.categoria.tipo.id.toString();
        tipoProximo =
          exam.procedimentos[index + 1].categoria.tipo.id.toString();

        if (tipoAtual === tipoProximo) {
          return (
            <List
              key={procedimento.id}
              borderLeftColor={handleBorderLeftColor(
                procedimento.categoria.tipo.nome
              )}
              data-testid={`exam-item-${index}`}
            >
              <Tooltip
                title={procedimento.categoria.tipo.nome}
                placement="right"
                arrow
              >
                <BorderLeft />
              </Tooltip>

              <Box pb={3} className="card-wrap">
                <CardExam
                  label={procedimento.categoria.nome}
                  key={procedimento.id}
                  index={index}
                  procedimentoData={procedimento}
                />
              </Box>
            </List>
          );
        }
      }

      return (
        <List
          key={procedimento.id}
          borderLeftColor={handleBorderLeftColor(
            procedimento.categoria.tipo.nome
          )}
          data-testid={`exam-item-${index}`}
          mb={4}
        >
          <Tooltip
            title={procedimento.categoria.tipo.nome}
            placement="right"
            arrow
          >
            <BorderLeft />
          </Tooltip>

          <CardExam
            label={procedimento.categoria.nome}
            key={procedimento.id}
            index={index}
            procedimentoData={procedimento}
          />
        </List>
      );
    });
  };

  if (exams.length || examsNaoColetados.length) {
    return (
      <Box px={3} mt={1}>
        <Typography mt={4} variant="h5" fontWeight="bold" component="h5" data-testid='historico-box'>
          Histórico
        </Typography>

        <Box mt={2}>
          <Button
            onClick={() => setTypeExamSelected('coletado')}
            data-testid='examesColetadosButton'
            sx={{
              fontSize: '13px',
              '&:hover': {
                backgroundColor: 'rgba(35, 136, 132, 0.2)',
              },
              backgroundColor:
                typeExameSelected === 'coletado'
                  ? 'rgba(35, 136, 132, 0.2)'
                  : '',
            }}
          >
            EXAMES COLETADOS
          </Button>
          <Button
            sx={{
              '&:hover': {
                backgroundColor: 'rgba(35, 136, 132, 0.2)',
              },
              fontSize: '13px',
              backgroundColor:
                typeExameSelected === 'nao_coletado'
                  ? 'rgba(35, 136, 132, 0.2)'
                  : '',
            }}
            data-testid='examesNaoColetadosButton'
            onClick={() => setTypeExamSelected('nao_coletado')}
          >
            EXAMES NÃO COLETADOS
          </Button>
        </Box>

        <TimelineFilter setExams={setExams} />

        {typeExameSelected === 'coletado' && (
          <>
            {exams.map((exam, index) => (
              <div key={index}>
                <Box
                  my={5}
                  maxWidth="144px"
                  bgcolor="#eee"
                  border="1px solid #E0E0E0"
                  borderRadius="50px"
                  textAlign="center"
                  p={1}
                >
                  <Typography variant="h6" component="h6" fontWeight="bold" data-testid={`yearLabel-${index}`}>
                    {exam.ano}
                  </Typography>
                </Box>

                {handleListBorderLeft(exam)}
              </div>
            ))}
          </>
        )}

        {typeExameSelected === 'nao_coletado' && (
          <>
            {examsNaoColetados.map((exam, index) => (
              <div key={index}>
                <Box
                  my={5}
                  maxWidth="144px"
                  bgcolor="#eee"
                  border="1px solid #E0E0E0"
                  borderRadius="50px"
                  textAlign="center"
                  p={1}
                >
                  <Typography variant="h6" component="h6" fontWeight="bold" data-testid={`yearLabel-${index}`}>
                    {exam.ano}
                  </Typography>
                </Box>

                {handleListBorderLeft(exam)}
              </div>
            ))}
          </>
        )}
      </Box>
    );
  }
  return (
    <Box px={3} mt={4} mb={4}>
      <TimelineFilter setExams={setExams} />

      <Alert severity="info" sx={{ marginTop: 4 }} data-testid={`no-exam`}>
        Não foi encontrado nenhum exame cadastrado na{' '}
        <strong data-testid={`platform-exam`}>Plataforma Jordana</strong>.
      </Alert>
    </Box>
  );
}
