import { toast } from 'react-toastify';
import api from '../../api';
import { formatedMultiples } from '../../utils/requestUtils/formatMultiples';

export const getReportsHPV = async ({ payload }) => {
  try {
    const response = await api.get('/reports/visualizar/hpv', {
      params: {
        ...payload,
      },
    });

    if (response.status === 200) {
      return response.data;
    }
  } catch (error: any) {
    toast.error(error.message, {
      position: 'bottom-right',
    });
  }
};

export const getProfissionaisColeta = async ({
  estadosSelected,
  municipiosSelected,
  estabelecimentosSelected,
}) => {
  try {
    const response = await api.get(
      '/dashboard/exames/hpv/panel/profissionais-coleta',
      {
        params: {
          estados: formatedMultiples(estadosSelected),
          municipios: formatedMultiples(municipiosSelected),
          estabelecimentos: formatedMultiples(estabelecimentosSelected),
        },
      }
    );

    return response;
  } catch (error) {
    return { data: error };
  }
};
