import { AlertListContext } from '../../context/AlertListContext';

import CancelIcon from '@mui/icons-material/Cancel';
import ErrorIcon from '@mui/icons-material/Error';
import React, { useContext } from 'react';
import api from '../../api';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HelpIcon from '@mui/icons-material/Help';
import { LoadingContext } from '../../context/LoadingContext';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import WarnIcon from '@mui/icons-material/ReportProblem';
import TipIcon from '@mui/icons-material/Chat';
import VideoIcon from '@mui/icons-material/VideoLibrary';
import { Container, ContainerHeader, CardContainer, CardHelper } from './styles';
import { HelperSendingContext } from '../../context/HelperSendingContext';
type HelperProps = {
  enabled,
  setEnabled
}

const Card = ({color, Icon, text, onClick, ...rest}) => {
  return <CardHelper style={{color: color}} onClick={onClick} {...rest}>
    <Icon/>
    <span >{text}</span>
  </CardHelper>
}


export const HelperBox = ({enabled, setEnabled}: HelperProps) => {
  const {openModal} = useContext(HelperSendingContext)

  if(!enabled) return <span></span>
  return ( <>
    <Container>
      <ContainerHeader>
        <p>Central de Ajuda</p>
        
        <CloseIcon onClick={() => setEnabled(prev => !prev)}/>
      </ContainerHeader>

      
      <CardContainer >
        <Card 
          color='#FFB547' 
          Icon={WarnIcon} 
          text='Suporte'
          onClick={() => {
            openModal('SUPPORT');
            setEnabled(false);
          }}
        />

        
        <Card 
          color='#17BEBB' 
          Icon={TipIcon} 
          text='Sugestões'
          onClick={() => {
            openModal('TIP');
            setEnabled(false);
          }}
        />

        
        <Card 
          color='#7B61FF' 
          Icon={VideoIcon} 
          text='Tutoriais'
          disabled={true}
          onClick={() => console.log('tutoriais')}
        />    
        
      </CardContainer>
    </Container>
    </>
  );
};
