import * as Yup from 'yup';

export const schema = (haventVinculo, hasEstabelecimento) => {
  return Yup.object({
    nome: Yup.string().required('Campo Obrigatório'),
    cpf: Yup.string()
      .test('cpf', 'CPF não tem 11 digítos', (cpf: any) => {
        return cpf?.replace(/\D/g, '').length === 11 || !cpf;
      })
      .required('Campo Obrigatório'),

    estabelecimento_saude: Yup.array().of(
      Yup.object().shape({
        id: Yup.number(),
        razao_social: Yup.string(),
        nome_fantasia: hasEstabelecimento
          ? Yup.string()
          : Yup.string().required('Campo Obrigatório'),
        cnes: Yup.string(),
        inativo: Yup.boolean(),
      })
    ),
    email: Yup.string().required('Campo Obrigatório'),
    cns: Yup.string().required('Campo Obrigatório'),
    cbo: Yup.string().nullable(),
    tipo: Yup.string().nullable(),
    uf: Yup.array().optional().of(Yup.string()),
    municipio: Yup.array().optional().of(Yup.object()),
    ine: haventVinculo
      ? Yup.string()
      : Yup.string().required('Campo Obrigatório'),
    nome_equipe: haventVinculo
      ? Yup.string()
      : Yup.string().required('Campo Obrigatório'),
    area: haventVinculo
      ? Yup.string()
      : Yup.string().required('Campo Obrigatório'),
    estabelecimento_equipe: Yup.object().shape({
      id: Yup.number(),
      razao_social: Yup.string(),
      nome_fantasia: haventVinculo
        ? Yup.string()
        : Yup.string().required('Campo Obrigatório'),
      cnes: Yup.string(),
      inativo: Yup.boolean(),
    }),
  });
};
