import { styled } from '@material-ui/styles';

export const DisplayFilter = styled('div')({
  backgroundColor: '#EEE',
  border: '1px dashed #b7b7b7',
  minHeight: '54px',
  borderRadius: '4px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '5px 15px',
});

export const ItemsDisplayFilter = styled('div')({
  backgroundColor: '#d3d3d3',
  padding: '5px 10px',
  borderRadius: '22px',
});

export const TotalCardsContainer = styled('div')({
  border: '1px solid rgba(0, 0, 0, 0.23)',
  borderRadius: '10px',
  padding: '24px',
  marginTop: '24px',
});
