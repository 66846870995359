import { Box, Paper, Typography } from '@mui/material';
import styles from './styles.module.css';
import { DataSource } from '../dataSource';
import { ReactNode } from 'react';

interface ChartCardProps {
  title: string;
  subtitle: string;
  children?: ReactNode;
  valueInputPeriod?: string;
}
export function ChartCard({
  title,
  subtitle,
  children,
  valueInputPeriod,
}: ChartCardProps) {
  return (
    <Paper
      variant="outlined"
      sx={{
        border: '0.8px solid rgba(0, 0, 0, 0.23)',
        height: '100%',
        position: 'relative',
        borderRadius: '10px',
        paddingTop: '21px',
      }}
    >
      <Box px={2}>
        <Typography variant="h5" fontWeight="bold" className={styles.cardTitle}>
          {title} <br />
          {subtitle}
        </Typography>
        <p style={{ margin: 0, fontSize: '12px', color: '#9E9E9E' }}>
          {valueInputPeriod === 'Todos' || !valueInputPeriod
            ? 'Todos os períodos'
            : valueInputPeriod}
        </p>
      </Box>
      <Box mt={{ xs: 2, md: 7 }} mb={{ xs: 2, md: 4 }} pr={{ xs: 0, md: 4 }}>
        {children}
      </Box>
      <DataSource />
    </Paper>
  );
}
