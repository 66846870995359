import api from '../api';

export const postProfessionalSchedule = async (data) => {
  return await api
    .post(`/profissional_agenda`, {
      profissionalId: data.profissionalId,
      estabelecimentoSaudeId: data.estabelecimentoSaudeId,
      procedimento: data.procedimento,
      n_dias: data.n_dias,
      intervalo: data.intervalo,
      idade_min: data.idade_min,
      idade_max: data.idade_max,
      sexo: data.sexo,
      qua_hours: data.periods.qua_hours.map(
        (item) => `${item.beginningPeriod}, ${item.endPeriod}`
      ),
      qui_hours: data.periods.qui_hours.map(
        (item) => `${item.beginningPeriod}, ${item.endPeriod}`
      ),
      sex_hours: data.periods.sex_hours.map(
        (item) => `${item.beginningPeriod}, ${item.endPeriod}`
      ),
      seg_hours: data.periods.seg_hours.map(
        (item) => `${item.beginningPeriod}, ${item.endPeriod}`
      ),
      ter_hours: data.periods.ter_hours.map(
        (item) => `${item.beginningPeriod}, ${item.endPeriod}`
      ),
      ativo: data.ativo,
      start_at: data.start_at,
    })
    .then((res) => {
      return res;
    })
    .catch((e: any) => {
      return e.response;
    });
};

export const editProfessionalSchedule = async (data) => {
  return await api
    .put(`/profissional_agenda/${data.scheduleId}`, {
      profissionalId: data.profissionalId,
      estabelecimentoSaudeId: data.estabelecimentoSaudeId,
      procedimento: data.procedimento,
      n_dias: data.n_dias,
      intervalo: data.intervalo,
      idade_min: data.idade_min,
      idade_max: data.idade_max,
      sexo: data.sexo,
      qua_hours: data.periods.qua_hours.map(
        (item) => `${item.beginningPeriod}, ${item.endPeriod}`
      ),
      qui_hours: data.periods.qui_hours.map(
        (item) => `${item.beginningPeriod}, ${item.endPeriod}`
      ),
      sex_hours: data.periods.sex_hours.map(
        (item) => `${item.beginningPeriod}, ${item.endPeriod}`
      ),
      seg_hours: data.periods.seg_hours.map(
        (item) => `${item.beginningPeriod}, ${item.endPeriod}`
      ),
      ter_hours: data.periods.ter_hours.map(
        (item) => `${item.beginningPeriod}, ${item.endPeriod}`
      ),
      ativo: data.ativo,
      start_at: data.start_at,
    })
    .then((res) => {
      return res;
    })
    .catch((e: any) => {
      return e.response;
    });
};

export const getTimesToEdit = async (scheduleId) => {
  return await api
    .get(`/profissional_agenda/${scheduleId}`)
    .then((res) => {
      return res;
    })
    .catch((e: any) => {
      return e.response;
    });
};

export const getProfessionalSchedule = async ({
  profissional_id,
  estabelecimento_id,
}) => {
  return await api
    .get(
      `/profissional_agenda/horarios/${profissional_id}/${estabelecimento_id}`
    )
    .then((res) => {
      return res;
    })
    .catch((e: any) => {
      return e.response;
    });
};

export const activationProfessionalSchedule = async ({ id, ativado }) => {
  return await api
    .put(`/profissional_agenda/ativacao/${id}?ativado=${ativado}`)
    .then((res) => {
      return res;
    })
    .catch((e: any) => {
      return e.response;
    });
};
