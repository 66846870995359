import { useNavigate } from 'react-router';

export const useHandlers = () => {
  const navigate = useNavigate();

  const handleClickOpenDialogRemoveContato = (setOpenDialogRemoveContato) => {
    setOpenDialogRemoveContato(true);
  };

  const handleClickOpenDialogCancel = (setOpenDialogCancel) => {
    setOpenDialogCancel(true);
  };

  return {
    handleClickOpenDialogRemoveContato,
    handleClickOpenDialogCancel,
  };
};
