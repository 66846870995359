import { toast } from 'react-toastify';
import api from '../../../api';

export const useRequests = ({ setValue }) => {
  const getCidades = async (sigla: string, setCidades) => {
    const response = await api.get(`/municipios?sigla=${sigla}`);
    setCidades(response.data);
  };

  const getAcsByName = async (
    value,
    setDisableInputsVinculo,
    setEquipesOptions,
    setEstabelecimentoVinculo,
    setProfissionalNotHasEquipe,
    setDisableEquipeNome,
    setOpenModalSelectEquipe
  ) => {
    if (value) {
      try {
        const response = await api.get(`/profissionais/acs-list/${value}`);

        if (response.data.length > 1) {
          setOpenModalSelectEquipe(true);
          toast.error('Digite o nome completo do profissional.', {
            position: 'bottom-right',
          });
        } else {
          const { profissional, equipes } = response.data[0];

          setDisableInputsVinculo(true);

          setValue('vinculo.profissionalNome', profissional.pessoa.nome);
          setValue('vinculo.profissionalCns', profissional.pessoa.cns);
          setValue('vinculo.cbo', profissional.cbo);
          setValue('vinculo.tipo', profissional.cargo);

          setValue(
            'vinculo.estabelecimento_saude',
            profissional?.estabelecimentos?.[0]?.estabelecimento
          );

          setEstabelecimentoVinculo(
            profissional?.estabelecimentos?.[0]?.estabelecimento?.id
          );

          setValue(
            'vinculo.municipio',
            profissional?.estabelecimentos?.[0]?.estabelecimento?.endereco
              ?.municipio?.nome
          );
          setValue(
            'vinculo.uf',
            profissional?.estabelecimentos?.[0]?.estabelecimento?.endereco
              ?.municipio?.estadoSigla
          );

          if (equipes.length > 1) {
            setProfissionalNotHasEquipe(false);
            setDisableEquipeNome(false);
            setEquipesOptions(equipes);
            setValue('vinculo.equipeNome', null);
            setValue('vinculo.equipeIne', null);
            setValue('vinculo.area', null);
            setValue('vinculo.micro_area', null);
          } else if (equipes.length === 1) {
            setValue('vinculo.equipeNome', '');
            setEquipesOptions(equipes);
            setValue('vinculo.equipeNome', equipes[0].equipe.nome);
            setValue('vinculo.equipeIne', equipes[0].equipe.ine);
            setValue('vinculo.area', equipes[0].area);
            setValue('vinculo.micro_area', equipes[0].micro_area);
            setDisableEquipeNome(true);
          } else if (equipes.length === 0) {
            setDisableEquipeNome(false);
          }
        }

        // setValue('vinculo.equipeIne', equi)
      } catch (error: any) {
        // toast.error(error.error.message, { position: 'bottom-right' });

        setValue('vinculo.cbo', '');
        setValue('vinculo.tipo', '');
        setValue('vinculo.equipeNome', '');
        setValue('vinculo.equipeIne', null);
        setValue('vinculo.area', null);
        setValue('vinculo.micro_area', null);
        setValue('vinculo.estabelecimento_saude', '');
        setValue('vinculo.uf', '');
        setValue('vinculo.municipio', '');
        setValue('vinculo.estabelecimento_saude', '');
        setValue('vinculo.estabelecimentoString', '');

        setDisableInputsVinculo(false);
      }
    }
  };

  const getCidadesEstabelecimentos = async (
    sigla: string,
    setCidadesEstabelecimentos
  ) => {
    const response = await api.get(`/municipios?sigla=${sigla}`);
    setCidadesEstabelecimentos(response.data);
  };

  const getEstabelecimentosByMunicipio = async (
    municipio,
    setEstabelecimentosSelect
  ) => {
    const response = await api.get('/estabelecimentos-saude/unpaged', {
      params: {
        municipio: municipio,
      },
    });
    setEstabelecimentosSelect(response.data.data);
  };

  const getEstados = async () => {
    let estados;
    const response = await api
      .get('/estados')
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        console.error('ops! ocorreu um erro' + err);
      });

    return (estados = response);
  };

  return {
    getCidades,

    getAcsByName,
    getCidadesEstabelecimentos,
    getEstabelecimentosByMunicipio,
    getEstados,
  };
};
