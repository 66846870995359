import React, { useState } from 'react';

interface Props {
  children: JSX.Element;
}

interface InformationPaperStateContextProps {
  setOpenInformationPaper: (data) => void;
  openInformationPaper: any;
  setDontShowAgainCheckbox: (data) => void;
  dontShowAgainCheckbox: boolean;
}

export const InformationPaperStateContext =
  React.createContext<InformationPaperStateContextProps>(
    {} as InformationPaperStateContextProps
  );

export const InformationPaperStateContextProvider = ({ children }: Props) => {
  const getDefaultShowInformationPaper = () => {
    const dontShow = localStorage.getItem(
      'dontShowInformationEstablishmentPaper'
    );

    if (dontShow) {
      return false;
    } else return true;
  };

  const [openInformationPaper, setOpenInformationPaper] = useState(
    getDefaultShowInformationPaper()
  );

  const [dontShowAgainCheckbox, setDontShowAgainCheckbox] = useState(false);

  return (
    <InformationPaperStateContext.Provider
      value={{
        openInformationPaper,
        setOpenInformationPaper,
        dontShowAgainCheckbox,
        setDontShowAgainCheckbox,
      }}
    >
      {children}
    </InformationPaperStateContext.Provider>
  );
};
