const useHandleChangeFilters = ({ setListFilter }) => {
  const handleSetFilter = (newValue: any, inputName: string, origin?: any) => {
    setListFilter((prev) => {
      return prev.map((item) =>
        item.inputName === inputName ? { ...item, value: newValue } : item
      );
    });
  };

  return { handleSetFilter };
};

export default useHandleChangeFilters;
