import api from '../api';

export async function getAlerst(page) {
  try {
    const response = await api.get(`/notificacao`, {
      params: {
        size: 5,
        page: page,
        // type_ids: 1,
      },
    });
    return response.data;
  } catch (err) {
    console.error(err);
  }
}

export async function getHaveResults(userId: number): Promise<any> {
  try {
    return await api.get(`/notificacao`, {
      params: {
        size: 5,
        page: 1,
      },
    });
  } catch (err) {
    console.error(err);
  }
}

export const confirmMissingHpv = async ({ user_notify_id, confirm }) => {
  try {
    const response = await api.post(
      `/notificacao/gal/resolve/missing-hpv/${user_notify_id}`,
      { confirm: confirm }
    );

    return response;
  } catch (error) {
    console.error(error);
  }
};
