import { TableBody, TableRow } from '@mui/material';
import RowsAddressData from './RowsAddressData';
import RowsClinico from './RowsClinico';
import RowsPersonalData from './RowsPersonalData';

const Rows = ({ tableData }) => {
  return (
    <TableBody>
      {tableData?.data?.map((row) => {
        return (
          <TableRow key={row?.pessoa?.id}>
            <RowsPersonalData row={row} />
            <RowsAddressData row={row} />
            <RowsClinico row={row} />
          </TableRow>
        );
      })}
    </TableBody>
  );
};

export default Rows;
