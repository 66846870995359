import * as Yup from 'yup';

export const schema = Yup.object({
  rede_atendimento: Yup.string().required('Campo Obrigatório'),
  laboratorio_responsavel: Yup.string().when('rede_atendimento', {
    is: (redeAtendimento) => redeAtendimento === 'suplementar',
    then: Yup.string().required('Campo Obrigatório'),
  }),
  protocolo: Yup.string(),
  dt_resultado: Yup.string().required('Campo Obrigatório'),
  resultado: Yup.string().required('Campo Obrigatório'),
  escamosa_indeterminada: Yup.string(),
  glandular_indeterminada: Yup.string(),
  origem_indefinida: Yup.string(),
  atipia_escamosa: Yup.string(),
  outra_neoplasia_maligna: Yup.boolean(),

  //   rede_atendimento: Yup.boolean().required('Campo Obrigatório'),
  //   laboratorio_responsavel: Yup.string(),
  //   escamosa_indeterminada: Yup.object({
  //     ASC_US: Yup.boolean(),
  //     ASC_H: Yup.boolean(),
  //   }),
  //   glandular_indeterminada: Yup.object({
  //     neoplasicas: Yup.boolean(),
  //     alto_grau: Yup.boolean(),
  //   }),
  //   origem_indefinida: Yup.object({
  //     neoplasicas: Yup.boolean(),
  //     alto_grau: Yup.boolean(),
  //   }),
  //   atipia_glandular: Yup.object({
  //     in_situ: Yup.boolean(),
  //     cervical: Yup.boolean(),
  //     endometrial: Yup.boolean(),
  //     outras: Yup.boolean(),
  //   }),
  //   atipia_escamosa: Yup.object({
  //     grau_I: Yup.boolean(),
  //     grau_II: Yup.boolean(),
  //     altograu: Yup.boolean(),
  //     carcinoma: Yup.boolean(),
  //   }),
  //   outra_neoplasia_maligna: Yup.boolean(),
  //   pessoaId: Yup.number(),
  //   profissionalId: Yup.number(),
  //   estabelecimentoSaudeId: Yup.number(),
});
