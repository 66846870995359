import { TableCell, TableHead, TableRow, Typography } from '@mui/material';

const TableHeaders = () => {
  return (
    <TableHead>
      <TableRow>
        <TableCell sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Realizou
            <br />
            Histopatologia
            <br />
            Pré-Tratamento?
          </Typography>
        </TableCell>
        <TableCell sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Realizou
            <br />
            Histopatologia
            <br />
            Pós-Tratamento?
          </Typography>
        </TableCell>
        <TableCell sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Nome Completo
            <br />
            da Mulher
          </Typography>
        </TableCell>
        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            {' '}
            Nº do Cartão SUS
          </Typography>
        </TableCell>
        <TableCell align="left" sx={{ borderBottom: 'none', width: '160px' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Nome Completo <br />
            da Mãe
          </Typography>
        </TableCell>
        <TableCell align="left" sx={{ borderBottom: 'none', width: '160px' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            CPF
          </Typography>
        </TableCell>
        <TableCell align="left" sx={{ borderBottom: 'none', width: '160px' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Data de
            <br />
            Nascimento
          </Typography>
        </TableCell>
        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Idade
          </Typography>
        </TableCell>

        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Raça / Cor
          </Typography>
        </TableCell>

        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            CEP
          </Typography>
        </TableCell>

        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Número
          </Typography>
        </TableCell>

        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Logradouro
          </Typography>
        </TableCell>

        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Complemento
          </Typography>
        </TableCell>

        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Bairro
          </Typography>
        </TableCell>

        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Ponto de
            <br /> Referência
          </Typography>
        </TableCell>

        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Município
          </Typography>
        </TableCell>

        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            UF
          </Typography>
        </TableCell>
        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Estabelecimento <br />
            Vinculado
          </Typography>
        </TableCell>
        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Data da
            <br />
            Realização
          </Typography>
        </TableCell>
        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Serviço de Saúde
            <br />
            da realização da
            <br />
            EZT
          </Typography>
        </TableCell>
        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Indicação da EZT?
          </Typography>
        </TableCell>
        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Local de
            <br />
            Realização do
            <br />
            Procedimento
          </Typography>
        </TableCell>
        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Tipo de Exérese da
            <br />
            Zona de
            <br />
            Transformação
            <br />
            (EZT)
          </Typography>
        </TableCell>
        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Anestesia
            <br />
            Utilizada
          </Typography>
        </TableCell>
        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Outras - Descrição
          </Typography>
        </TableCell>

        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Tipo de
            <br />
            Procedimento
            <br />
            Realizado?
          </Typography>
        </TableCell>

        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Profissional
            <br />
            Responsável
          </Typography>
        </TableCell>
        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            CBO
          </Typography>
        </TableCell>

        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            CNS
          </Typography>
        </TableCell>
        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            INE
          </Typography>
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

export default TableHeaders;
