import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import { ptBR } from 'date-fns/locale';
import { Controller, useForm } from 'react-hook-form';
import { StyledAsteriskTextField } from '../../../components/StyledAsteriskTextField/styles';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { SelectWithSearch } from '../../../components/SelectWithSearch';
import api from '../../../api';
import { LoadingContext } from '../../../context/LoadingContext';
import useSetPayload from '../hooks/useSetPayload';
import useRequestFilter from '../hooks/useRequestFilter';
import useClearValues from '../hooks/useClearValues';
import { DisplayFilter, ItemsDisplayFilter } from '../../Convocation/styles';

const FiltersCentralNotification = ({
  setDataCentral,
  control,
  watch,
  dataNotificacaoFinal,
  setDataNotificacaoFinal,
  dataNotificacaoIni,
  setDataNotificacaoIni,
  periodDtNotificacao,
  setPeriodDtNotificacao,
  selectedOptionsStatus,
  setSelectedOptionsStatus,
  selectedOptionsNotificationType,
  setSelectedOptionsNotificationType,
  selectedOptionsStatusLidas,
  setSelectedOptionsStatusLidas,
  handleSubmit,
  setListFilter,
  listFilter,
  initialStateListFilter,
  listFilterShow,
  setListFilterShow,
}) => {
  const [optionsTypes, setOptionsTypes] = useState<any>([]);
  const [errorInputDate, setErrorInputDate] = useState<any>();
  const handleSetPeriodDtNotificacao = (e) => {
    setPeriodDtNotificacao(e.target.value);
    setDataNotificacaoFinal(null);
    setDataNotificacaoIni(null);
    handleSetFilter('', 'Fim da Data de Notificação');
    handleSetFilter('', 'Início da Data de Notificação');
  };

  const { setLoading } = useContext(LoadingContext);

  const hasSelectedLidas = !!selectedOptionsStatus.find(
    (status) => status.label === 'Lidas'
  );

  const handleSetFilter = (newValue: any, inputName: string, origin?: any) => {
    // console.log(newValue);
    // console.log(inputName);
    setListFilter((prev) => {
      // console.log(prev);
      return prev.map((item) => {
        return item.inputName === inputName
          ? { ...item, value: newValue }
          : item;
      });
    });
  };

  const handleSetSelectedOptions =
    ({ setSelected, fieldName }) =>
    (options) => {
      // console.log(fieldName);
      setSelected(options);
      const optionLabel = () => {
        if (options.length === 0) {
          return '';
        }

        if (options.length === 1) {
          return `${fieldName}: ${options[0].label}`;
        }

        if (options.length > 1) {
          return `${fieldName}: Vários`;
        }
      };

      handleSetFilter(optionLabel(), fieldName);
    };

  const loadOptionsTypes = async () => {
    try {
      const response = await api.get('/notificacao/types/all');
      setOptionsTypes(response.data);
    } catch (err) {}
  };

  const { setPayload } = useSetPayload({
    watch,
    dataNotificacaoFinal,
    dataNotificacaoIni,
    selectedOptionsStatus,
    selectedOptionsNotificationType,
    selectedOptionsStatusLidas,
  });
  const { loadDataCentralNotification } = useRequestFilter({ setDataCentral });

  useEffect(() => {
    setLoading(true);
    loadOptionsTypes();
    setPayload().then((payload) => loadDataCentralNotification(payload));
  }, []);

  const onSubmit = () => {
    setLoading(true);
    setDataCentral([]);
    setPayload().then((payload) => loadDataCentralNotification(payload));
    setListFilterShow(listFilter);
  };

  const { clearValues } = useClearValues({
    setSelectedOptionsStatus,
    setSelectedOptionsNotificationType,
    watch,
    loadDataCentralNotification,
    setDataNotificacaoFinal,
    setDataNotificacaoIni,
    setLoading,
    initialStateListFilter,
    setListFilter,
    setListFilterShow,
  });

  useEffect(() => {
    const dtIni = moment(dataNotificacaoIni, 'DD-MM-YYYY');
    const dtFin = moment(dataNotificacaoFinal, 'DD-MM-YYYY');

    if (dtIni.isAfter(dtFin)) {
      setErrorInputDate({
        ini: 'Data final deve ser maior que a data inicial',
        fin: '',
      });
    } else {
      setErrorInputDate('');
    }
  }, [dataNotificacaoIni, dataNotificacaoFinal]);

  return (
    <Box>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <DisplayFilter style={{ marginTop: '24px', marginBottom: '24px' }}>
          {!listFilterShow?.find((item) => item.value !== '') && (
            <p style={{ margin: 0, width: '413px', color: '#838383' }}>
              Os filtros selecionados aparecerão aqui.
            </p>
          )}

          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              width: '100%',
              gap: '10px',
              flexWrap: 'wrap',
            }}
          >
            {listFilterShow.map((item) => (
              <>
                {item.value ? (
                  <ItemsDisplayFilter>{item.value}</ItemsDisplayFilter>
                ) : undefined}
              </>
            ))}
          </div>
        </DisplayFilter>
        <Grid container spacing={3} justifyContent="flex-end">
          <Grid item xs={12} sm={6}>
            <Controller
              control={control}
              name="status"
              render={({ field: { onChange, value } }) => {
                return (
                  <SelectWithSearch
                    options={[
                      { label: 'Não Lidas', value: '0' },
                      { label: 'Lidas', value: '1' },
                      { label: 'Não Resolvida', value: '2' },
                      { label: 'Resolvida', value: '3' },
                    ]}
                    label="Status"
                    selectedOptions={selectedOptionsStatus}
                    setSelectedOptions={setSelectedOptionsStatus}
                    required
                    setSelected={handleSetSelectedOptions({
                      setSelected: setSelectedOptionsStatus,
                      fieldName: 'Status',
                    })}
                    dataTestIdModal="modalResultInput"
                    dataTestIdInputSearch="inputSearchResultado"
                    dataTestSelectAll="selectAllResultados"
                    dataTestClearAll="clearResultados"
                  />
                );
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Controller
              control={control}
              name="type-notification"
              render={({ field: { onChange, value } }) => {
                return (
                  <SelectWithSearch
                    options={optionsTypes}
                    //   disabled={disableOtherInputs}
                    label="Tipo de Notificações"
                    selectedOptions={selectedOptionsNotificationType}
                    setSelectedOptions={setSelectedOptionsNotificationType}
                    required
                    setSelected={handleSetSelectedOptions({
                      setSelected: setSelectedOptionsNotificationType,
                      fieldName: 'Tipo de Notificações',
                    })}
                    dataTestIdModal="modalResultInput"
                    dataTestIdInputSearch="inputSearchResultado"
                    dataTestSelectAll="selectAllResultados"
                    dataTestClearAll="clearResultados"
                  />
                );
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              gap={3}
              flexWrap="wrap"
            >
              <Typography fontWeight="bold">Data da Notificação:</Typography>

              <FormControl>
                <RadioGroup
                  aria-labelledby="handleSetPeriod"
                  name="handleSetPeriod"
                  value={periodDtNotificacao}
                  onChange={handleSetPeriodDtNotificacao}
                  row
                >
                  <FormControlLabel
                    value="date"
                    control={<Radio />}
                    label="Filtrar por Data"
                  />
                  <FormControlLabel
                    value="period"
                    control={<Radio />}
                    label="Filtrar por período"
                  />
                </RadioGroup>
              </FormControl>

              {periodDtNotificacao === 'period' && (
                <Grid
                  container
                  spacing={3}
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  flex="1"
                  flexWrap="wrap"
                >
                  <Grid item xs={12} lg={6}>
                    <LocalizationProvider
                      dateAdapter={AdapterDateFns}
                      locale={ptBR}
                    >
                      <DatePicker
                        onChange={(date) => {
                          handleSetFilter(
                            moment(date).format('DD/MM/YYYY'),
                            'Início da Data de Notificação'
                          );
                          setDataNotificacaoIni(
                            date ? moment(date).format('DD-MM-YYYY') : null
                          );
                        }}
                        // disabled={disableOtherInputs}
                        maxDate={new Date()}
                        value={
                          dataNotificacaoIni &&
                          moment(dataNotificacaoIni, 'DD-MM-YYYY').isValid()
                            ? moment(dataNotificacaoIni, 'DD-MM-YYYY').toDate()
                            : null
                        }
                        renderInput={(params) => (
                          <StyledAsteriskTextField
                            {...params}
                            fullWidth
                            label="Início da Data da Notificacao"
                            size="small"
                            error={!!errorInputDate?.ini}
                            helperText={errorInputDate?.ini}
                            // disabled={disableOtherInputs}
                            variant="outlined"
                            sx={{
                              minWidth: '200px',
                              marginBottom: !!errorInputDate?.ini
                                ? '-22px'
                                : '',
                            }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>

                  <Grid item xs={12} lg={6}>
                    <LocalizationProvider
                      dateAdapter={AdapterDateFns}
                      locale={ptBR}
                    >
                      <DatePicker
                        onChange={(date) => {
                          handleSetFilter(
                            moment(date).format('DD/MM/YYYY'),
                            'Fim da Data de Notificação'
                          );
                          setDataNotificacaoFinal(
                            date ? moment(date).format('DD-MM-YYYY') : null
                          );
                        }}
                        maxDate={new Date()}
                        value={
                          dataNotificacaoFinal &&
                          moment(dataNotificacaoFinal, 'DD-MM-YYYY').isValid()
                            ? moment(
                                dataNotificacaoFinal,
                                'DD-MM-YYYY'
                              ).toDate()
                            : null
                        }
                        renderInput={(params) => (
                          <StyledAsteriskTextField
                            {...params}
                            // disabled={disableOtherInputs}
                            label="Fim da Data da Notificacao"
                            size="small"
                            fullWidth
                            error={!!errorInputDate?.fin}
                            helperText={errorInputDate?.fin}
                            variant="outlined"
                            sx={{
                              minWidth: '200px',
                              marginBottom: !!errorInputDate?.fin
                                ? '-22px'
                                : '',
                            }}
                            // sx={{
                            //   minWidth: '200px',
                            //   marginBottom: !!errorInputDateNotificacao?.fin
                            //     ? '-20px'
                            //     : '',
                            // }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>
              )}

              {periodDtNotificacao === 'date' && (
                <>
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    locale={ptBR}
                  >
                    <DatePicker
                      // disabled={disableOtherInputs}
                      onChange={(date) => {
                        // handleSetFilter(
                        //   moment(date).format('DD/MM/YYYY'),
                        //   'data_fin_resultado'
                        // );
                        setDataNotificacaoFinal(
                          date ? moment(date).format('DD-MM-YYYY') : null
                        );
                        setDataNotificacaoIni(
                          date ? moment(date).format('DD-MM-YYYY') : null
                        );
                      }}
                      maxDate={new Date()}
                      value={
                        dataNotificacaoFinal &&
                        moment(dataNotificacaoFinal, 'DD-MM-YYYY').isValid()
                          ? moment(dataNotificacaoFinal, 'DD-MM-YYYY').toDate()
                          : null
                      }
                      renderInput={(params) => (
                        <StyledAsteriskTextField
                          {...params}
                          label="Data de Notificacao"
                          // disabled={disableOtherInputs}
                          size="small"
                          variant="outlined"
                          sx={{ minWidth: '200px', flex: 1 }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </>
              )}
            </Box>
          </Grid>
          <Grid item xs={12} md={1.5}>
            <Button
              variant="contained"
              fullWidth
              type="submit"
              data-testid="submitFilterReportsTrackingHpv"
            >
              FILTRAR
            </Button>
          </Grid>
          <Grid item xs={12} md={1.5}>
            <Button
              variant="outlined"
              color="error"
              fullWidth
              data-testid="clearFilterReportsTrackingHpv"
              onClick={() => clearValues()}
            >
              LIMPAR
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default FiltersCentralNotification;
