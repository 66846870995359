import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const ButtonNew = styled(Button)`
  color: #ffffff;
  background-color: #238884;
  border-color: #238884;
  :hover {
    background-color: #238884;
    color: #ffffff;
    border-color: #238884;
  }
`;

export const ActionsContainer = styled(Box)`
  gap: 24px;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;

    button {
      width: 100%;
    }
  }
`;
