import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';

interface Props {
  labelcolor?: string;
}

export const Label = styled(Typography)(({ labelcolor: textColor }: Props) => ({
  fontSize: '12px',
  lineHeight: '12px',
  letterSpacing: '0.15px',
  color: `${textColor ? textColor : 'rgba(0, 0, 0, 0.6)'}`,
}));
