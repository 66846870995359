export enum ContatoPreferencial {
  Whatsapp = 'WHATSAPP',
  Ligacao = 'LIGACAO',
  SMS = 'SMS',
  Email = 'EMAIL',
  Presencial = 'CONTATO_PRESENCIAL',
  Carta = 'CARTA',
}

export enum OrientacaoSexual {
  Heterossexual = 1,
  Homossexual,
  Bissexual,
  Outro,
}

export enum IdentidadeGenero {
  HomemTrans = 1,
  MulherTrans,
  Travesti,
  Outro,
}

export interface Municipio {
  id: number;
  created_at: string;
  updated_at: string;
  nome: string;
  codigo: string;
  estadoSigla: string;
  ibge7: string;
  estado: Estado;
}

export interface Estado {
  id: number;
  created_at: string;
  updated_at: string;
  nome: string;
  sigla: string;
  codigo: string;
}

export interface Endereco {
  id: number;
  created_at: string;
  updated_at: string;
  cep: string;
  logradouro: string;
  numero: string;
  complemento?: any;
  referencia?: any;
  zona: string;
  bairro: string;
  municipio: Municipio;
}

export interface EstabelecimentoSaude {
  id: number;
  created_at: string;
  updated_at: string;
  razao_social: string;
  nome_fantasia: string;
  cnes: string;
  cnpj_proprio?: any;
  email?: any;
  cnpj_mantenedor?: any;
  inativo: boolean;
  dt_inicio: string;
  dt_fim?: any;
  tipo_estab_id?: any;
  telefone?: any;
  gestao?: any;
  dependencia?: any;
  regional?: any;
  endereco: Endereco;
}

export interface Equipe {
  id: number;
  area: number;
  micro_area: number;
  profissional: {
    id: number;
    cnes: string;
    cargo: string;
    cbo: number;
  };
  equipe: {
    id: number;
    ativa: true;
    nome: string;
    ine: number;
  };
}

export interface Inputs {
  cns: string;
  nome: string;
  apelido: string;
  nome_mae: string;
  cpf: string;
  dt_nascimento: Date | null;
  nacionalidade: string;
  raca: string;
  escolaridade: string;
  orientacao: string;
  identidade_genero: string;
  estabelecimento_saude: EstabelecimentoSaude;
  endereco: {
    cep: string;
    logradouro: string;
    numero: string;
    complemento: string;
    referencia: string;
    zona: string;
    bairro: string;
    municipio: Municipio;
  };
  contatos: [
    {
      forma_contato: 'EMAIL' | 'TELEFONE';
      descricao: string;
      isWhatsapp: boolean;
      observacao: string;
      inativo: boolean;
      is_favorito: boolean;
      foneZap?: string | null;
    }
  ];
  contato_preferencial: ContatoPreferencial;
  vinculo: {
    profissionalId: number;
    profissionalNome: string;
    profissionalCns: string;
    estabelecimentoSaudeId: number;
    estabelecimento_saude: any;
    pessoaId: number;
    equipeIne: number | null;
    equipeNome: Equipe | null;
    area: number | null;
    micro_area: number | null;
    profissionalEquipeId: number;
    cbo: string;
    tipo: string;
    municipio: string;
    uf: string;
    estabelecimentoString: string;
  };
}
