import { Box, Typography } from '@mui/material';
import styles from './styles.module.css';

interface TableRow {
  municipio: string;
  populacaoElegivel: number;
  coletasRealizadas: number;
  metaColeta: number;
  atingimentoMeta: string;
}

interface CardProps {
  row: TableRow;
}

function Card({ row }: CardProps) {
  return (
    <Box py="16px" className={styles.cardTableMobile}>
      {row.municipio && (
        <>
          <Typography fontSize="14px" fontWeight="bold">
            Município
          </Typography>
          <Typography fontSize="14px">{row.municipio}</Typography>
        </>
      )}

      {!row.municipio && (
        <Typography fontSize="14px" fontWeight="bold" mb={3}>
          Total:
        </Typography>
      )}

      <Typography fontSize="14px" fontWeight="bold">
        População Elegível
      </Typography>
      <Typography fontSize="14px">{row.populacaoElegivel}</Typography>

      <Typography fontSize="14px" fontWeight="bold">
        Coletas Realizadas
      </Typography>
      <Typography fontSize="14px">{row.coletasRealizadas}</Typography>

      <Typography fontSize="14px" fontWeight="bold">
        Meta de Coleta (20%)
      </Typography>
      <Typography fontSize="14px">{row.metaColeta}</Typography>

      <Typography fontSize="14px" fontWeight="bold">
        Atingimento da Meta (%)
      </Typography>
      <Typography fontSize="14px">{row.atingimentoMeta}%</Typography>
    </Box>
  );
}

const data = [
  {
    municipio: 'Amaraji',
    populacaoElegivel: 6057,
    coletasRealizadas: 714,
    metaColeta: 848,
    atingimentoMeta: '84.2',
  },
  {
    municipio: 'Barreiros',
    populacaoElegivel: 11321,
    coletasRealizadas: 586,
    metaColeta: 1585,
    atingimentoMeta: '37.0',
  },
  {
    municipio: 'Cortês',
    populacaoElegivel: 3250,
    coletasRealizadas: 376,
    metaColeta: 455,
    atingimentoMeta: '82.6',
  },
  {
    municipio: 'Lagoa dos Gatos',
    populacaoElegivel: 4142,
    coletasRealizadas: 265,
    metaColeta: 580,
    atingimentoMeta: '45.7',
  },
  {
    municipio: 'Primavera',
    populacaoElegivel: 3857,
    coletasRealizadas: 313,
    metaColeta: 540,
    atingimentoMeta: '58.0',
  },
  {
    municipio: 'Ribeirão',
    populacaoElegivel: 13035,
    coletasRealizadas: 346,
    metaColeta: 1825,
    atingimentoMeta: '19.0',
  },
  {
    municipio: 'São Benedito do Sul',
    populacaoElegivel: 4392,
    coletasRealizadas: 177,
    metaColeta: 615,
    atingimentoMeta: '28.8',
  },
  {
    municipio: 'Tamandaré',
    populacaoElegivel: 6242,
    coletasRealizadas: 224,
    metaColeta: 874,
    atingimentoMeta: '25.6',
  },
];

const calcularTotal = () => {
  const total = {
    municipio: '',
    populacaoElegivel: 0,
    coletasRealizadas: 0,
    metaColeta: 0,
    atingimentoMeta: '',
  };

  data.forEach((row) => {
    total.populacaoElegivel += row.populacaoElegivel;
    total.coletasRealizadas += row.coletasRealizadas;
    total.metaColeta += row.metaColeta;
  });

  total.atingimentoMeta = (
    (total.coletasRealizadas / total.metaColeta) *
    100
  ).toFixed(1);

  return total;
};

const total = calcularTotal();

export function TableMobile() {
  return (
    <div className={styles.container}>
      <Typography variant="h5" fontWeight="bold" fontSize="20px">
        Testes Realizados do HPV
        <br />
        Número de Coletas x Metas
      </Typography>

      {data.map((row, index) => (
        <Card row={row} />
      ))}

      <Card row={total} />
    </div>
  );
}
