import styled from '@emotion/styled';
import { makeStyles } from '@material-ui/styles';
import { Box } from '@mui/material';
import { responsiveBreakpoints } from '../../styles/breakpoinst';

export const useStyles = makeStyles({
  textField: {
    width: '100%',
    '& label.Mui-focused': {
      color: '#238884',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#238884',
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: '#238884',
      },
    },
  },
});

export const ContainerChips = styled(Box)`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  @media (min-width: ${responsiveBreakpoints.small}) {
    flex-direction: row;
  }
`;
