import { TableCell, TableHead, TableRow, Typography } from '@mui/material';

const TableHeaders = () => {
  return (
    <TableHead>
      <TableRow>
        <TableCell sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Nome
          </Typography>
        </TableCell>
        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            {' '}
            CPF
          </Typography>
        </TableCell>
        <TableCell sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Idade
          </Typography>
        </TableCell>
        <TableCell align="left" sx={{ borderBottom: 'none', width: '160px' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Endereço
          </Typography>
        </TableCell>
        <TableCell sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Município
          </Typography>
        </TableCell>
        <TableCell sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Estado
          </Typography>
        </TableCell>
        <TableCell sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Estabelecimento
            <br /> de Saúde
          </Typography>
        </TableCell>
        <TableCell sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Profissional Vinculado
          </Typography>
        </TableCell>
        <TableCell sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Status
          </Typography>
        </TableCell>
        <TableCell sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Data do
            <br /> Agendamento
          </Typography>
        </TableCell>
        <TableCell sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Horário
          </Typography>
        </TableCell>
        <TableCell sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Profissional
          </Typography>
        </TableCell>
        <TableCell sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Convocação
          </Typography>
        </TableCell>
        {/* <TableCell align="left" sx={{ borderBottom: 'none', width: '160px' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Convocação
          </Typography>
        </TableCell>
        <TableCell align="left" sx={{ borderBottom: 'none', width: '160px' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Local do Procedimento
          </Typography>
        </TableCell>
        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Endereço
          </Typography>
        </TableCell>
        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Profissional
          </Typography>
        </TableCell>
        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Procedimento
          </Typography>
        </TableCell>
        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Data
          </Typography>
        </TableCell>
        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Horário
          </Typography>
        </TableCell>
        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Status
          </Typography>
        </TableCell> */}
      </TableRow>
    </TableHead>
  );
};

export default TableHeaders;
