import { Modal } from '@mui/material';
import React from 'react';

import './style.css';
import { LoadingContext } from '../../context/LoadingContext';

export default function Loading() {
  const { loading } = React.useContext(LoadingContext);
  return (
    <div>
      <Modal
        open={loading}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div className="loader_body">
          <span className="loader"></span>
          <h3 style={{ color: 'black' }}>Carregando...</h3>
        </div>
      </Modal>
    </div>
  );
}
