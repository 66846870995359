import { TableCell, Typography } from '@mui/material';
import { formatCpf } from '../../../../../../utils/formatters';
import moment from 'moment';
import calculaIdade from '../../../../../../utils/calculaIdade';

const RowsPersonalData = ({ row }) => {
  return (
    <>
      <TableCell sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body1" fontSize="14px">
          {String(row?.pessoa?.nome).toUpperCase()}
        </Typography>
      </TableCell>
      <TableCell sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body1" fontSize="14px">
          {row?.pessoa?.cns}
        </Typography>
      </TableCell>
      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {row?.pessoa?.nome_mae}
        </Typography>
      </TableCell>
      <TableCell sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body1" fontSize="14px">
          {row?.pessoa?.cpf ? formatCpf(row?.pessoa?.cpf) : ''}
        </Typography>
      </TableCell>
      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {moment(row?.pessoa?.dt_nascimento).format('DD/MM/YYYY')}
        </Typography>
      </TableCell>
      {/* <TableCell sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body1" fontSize="14px">
          {row?.pessoa?.dt_nascimento
            ? `${calculaIdade(
                moment(row.pessoa.dt_nascimento).format('DD/MM/YYYY')
              )} anos`
            : ''}
        </Typography>
      </TableCell> */}
      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {row?.pessoa?.nacionalidade}
        </Typography>
      </TableCell>
      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {row?.pessoa?.raca}
        </Typography>
      </TableCell>
    </>
  );
};

export default RowsPersonalData;
