import { Box, Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import api from '../../../../api';
import { AreaChart } from '../../../../components/Charts/areaChart';
import { ChartCard } from '../../../../components/Charts/chartCard';
import { ExamCollectionOrigin } from '../../../../components/Charts/examCollectionOrigin';
import FiltersVisaoGeral from '../../../../components/Filters/FilterVisaoGeral';
import { DisplayFilter, ItemsDisplayFilter } from '../../../Convocation/styles';
import styles from './styles.module.css';
import { PieChart } from '../../../../components/Charts/pieChart';
import { PieChart2 } from '../../../../components/Charts/pieChart2';
const initialStateListFilter = [
  { inputName: 'estado', value: '' },
  { inputName: 'municipio', value: '' },
  { inputName: 'estabelecimento', value: '' },
  { inputName: 'equipe', value: '' },
  { inputName: 'periodo', value: '' },
];

export function VisaoGeral() {
  const [listFilter, setListFilter] = useState<any>(initialStateListFilter);
  const [showListFilter, setShowListFilter] = useState<any>([]);
  const { control } = useForm();
  const [totalTestsPerMonth, setTotalTestsPerMonth] = useState<any>([]);
  const [categories, setCategories] = useState<any>([]);
  const [examCollectionOriginData, setExamCollectionOriginData] = useState<any>(
    []
  );
  const [payload, setPayload] = useState<any>();
  const [total, setTotal] = useState<any>();
  const getTestesPerMonth = async (cleared) => {
    const response = await api.get(
      '/dashboard/exames/hpv/panel/quantity-month',
      cleared ? {} : payload
    );

    setTotalTestsPerMonth(
      response.data?.data.map((item) => Number(item?.count))
    );
    setCategories(
      response.data?.data.map((item) => String(item?.month)?.toUpperCase())
    );
  };

  const getexamCollectionOrigin = async (cleared) => {
    const response = await api.get(
      '/dashboard/exames/hpv/panel/natureza',
      cleared ? {} : payload
    );

    setExamCollectionOriginData([
      response.data.data.autocoleta ? Number(response.data.data.autocoleta) : 0,
      response.data.data.profissional
        ? Number(response.data.data.profissional)
        : 0,
    ]);
  };

  const getAllCharts = ({ cleared }) => {
    setTotalTestsPerMonth([]);
    setCategories([]);
    setExamCollectionOriginData([]);
    setTotal('');
    getTestesPerMonth(cleared);
    getexamCollectionOrigin(cleared);
  };

  useEffect(() => {
    getAllCharts({ cleared: false });
  }, []);

  useEffect(() => {
    setTotal(totalTestsPerMonth.reduce((prev, curr) => prev + curr, 0));
  }, [totalTestsPerMonth]);
  return (
    <Box mt="31px">
      <Box sx={{ marginBottom: '42px' }}>
        <FiltersVisaoGeral
          control={control}
          setShowListFilter={setShowListFilter}
          setPayload={setPayload}
          getAllCharts={getAllCharts}
          setListFilter={setListFilter}
          initialStateListFilter={initialStateListFilter}
          listFilter={listFilter}
        />
      </Box>
      <Box display="flex" flexDirection="column" gap="2px">
        <Typography variant="h5" fontWeight="bold" fontSize="24px">
          Exames de Colposcopia - Visão Geral
        </Typography>
        <Typography variant="h6" fontWeight="bold" fontSize="20px">
          Últimos 12 meses
        </Typography>
        <Typography variant="body1">
          Data de Atualização: 29/01/2024 16:15
        </Typography>
        <DisplayFilter style={{ marginTop: '17px', marginBottom: '37px' }}>
          <p style={{ margin: 0, width: '138px' }}>Filtros aplicados:</p>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              width: '100%',
              gap: '10px',
              flexWrap: 'wrap',
            }}
          >
            {showListFilter.map((item) => (
              <>
                {item.value ? (
                  <>
                    <ItemsDisplayFilter>{item.value}</ItemsDisplayFilter>
                  </>
                ) : undefined}
              </>
            ))}
          </div>
        </DisplayFilter>
      </Box>

      <div className={styles.testsDoneCard}>
        <div className={styles.testsDoneCardTitle}>
          <Typography variant="h5" fontWeight="bold">
            Exames de Colposcopia - Visão Geral
            <br />
            Quantidade de Testes Realizados
          </Typography>

          <Typography variant="h5" fontWeight="bold">
            {total ? total?.toLocaleString('pt-BR') : ''}
          </Typography>
        </div>

        <AreaChart
          payload={null}
          data={totalTestsPerMonth}
          categories={categories}
        />
      </div>

      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <ChartCard
            title="Exames de Colposcopia"
            subtitle="Exame macroscópico da vulva e região perianal"
          >
            <PieChart2
              data={[50, 50]}
              height={284}
              labels={[`Sem Alterações (50)`, `Com Alterações (50)`]}
              colors={['#0079AD', '#01AEFA']}
            />
          </ChartCard>
        </Grid>
        <Grid item xs={12} md={6}>
          <ChartCard
            title="Exames de Colposcopia"
            subtitle="Natureza da Coleta de Exames"
          >
            <PieChart2
              data={[50, 50]}
              height={284}
              labels={[`Adequada (50)`, `Inadequada (50)`]}
              colors={['#775DD0', '#CC64DD']}
            />
          </ChartCard>
        </Grid>

        <Grid item xs={12} md={6}>
          <ChartCard
            title="Exames de Colposcopia"
            subtitle="Visibilidade da junção escamocolunar"
          >
            <PieChart2
              data={[25, 25, 50]}
              height={284}
              labels={[
                `Completamente Visivel (25)`,
                `Parcialmente Visivel (25)`,
                `Não Visivel (50)`,
              ]}
              colors={['#F4284E', '#F598A7', '#ACB7B8']}
              startAngle={45}
              endAngle={405}
              legendWidth={180}
            />
          </ChartCard>
        </Grid>

        <Grid item xs={12} md={6}>
          <ChartCard
            title="Exames de Colposcopia"
            subtitle="Zona de Transformação (ZT)"
          >
            <PieChart2
              data={[50, 25, 25]}
              height={284}
              labels={[`Top 1 (50)`, `Top 2 (25)`, `Top 3 (25)`]}
              colors={['#FFDBA7', '#FFB547', '#CC7D07']}
            />
          </ChartCard>
        </Grid>

        <Grid item xs={12} md={6}>
          <ChartCard title="Exames de Colposcopia" subtitle="Tipos de Achados">
            <PieChart2
              data={[25, 25]}
              height={284}
              labels={[
                `Achado Colposcópico Normal (25)`,
                `Achado Colposcópico Anormal (25)`,
              ]}
              colors={['#54A77B', '#FF5050']}
              legendWidth={200}
            />
          </ChartCard>
        </Grid>

        <Grid item xs={12} md={6}>
          <ChartCard title="Exames de Colposcopia" subtitle="Captação de Iugol">
            <PieChart2
              data={[25, 25]}
              height={284}
              labels={[`Positiva (corado) (25)`, `Negativa (não corado) (25)`]}
              colors={['#54A77B', '#FF5050']}
              legendWidth={200}
            />
          </ChartCard>
        </Grid>

        <Grid item xs={12} md={6}>
          <ChartCard title="Exames de Colposcopia" subtitle="Teste de Schiller">
            <PieChart2
              data={[25, 25]}
              height={284}
              labels={[`Positivo (25)`, `Negativo (25)`]}
              colors={['#54A77B', '#FF5050']}
              legendWidth={200}
            />
          </ChartCard>
        </Grid>

        <Grid item xs={12} md={6}>
          <ChartCard
            title="Exames de Colposcopia"
            subtitle="Encaminhamento da Biópsia"
          >
            <PieChart2
              data={[25, 25]}
              height={284}
              labels={[
                `Realizar Biópsia - Sim (25)`,
                `Realizar Biópsia - Não (25)`,
              ]}
              colors={['#01AEFA', '#0079AD']}
              legendWidth={200}
            />
          </ChartCard>
        </Grid>
      </Grid>
    </Box>
  );
}
