import { Box, Divider, FormControl, FormControlLabel, Grid, Radio, RadioGroup, Typography } from '@mui/material';
import { SetStateAction } from 'react';
import Exame from '../../../assets/imgs/exame.svg';

export const Diagnostico = ({ setSelectedValue }) => {
  const handleChange = (event: { target: { value: SetStateAction<string>; }; }) => {
    setSelectedValue(event.target.value);
  }

  return (
    <Box sx={{ width: '100%' }} p={3}>
      <Grid container alignItems="center" spacing={3}>
        <Grid item xs={12} sm={2} display="flex" justifyContent="center">
          <img src={Exame} alt="Exames Icon" />
        </Grid>
        <Grid item xs={12} sm={9}>
          <Typography variant="h6" fontWeight="bold">Exames de Diagnóstico</Typography>
        </Grid>
      </Grid>

      <Box sx={{ marginTop: '16px', marginBottom: '16px' }}>
        <Divider />
      </Box>
      <FormControl>
        <RadioGroup
          aria-labelledby="Tipo de diagnóstico"
          name="diagnostic-type"
        >
          <FormControlLabel
            value="histopatologia"
            control={<Radio color="primary" onChange={handleChange} />} label="Histopatologia"
          />
          <FormControlLabel
            value="colposcopia"
            control={<Radio color="primary" onChange={handleChange} />} label="Colposcopia"
          />
        </RadioGroup>
      </FormControl>

    </Box>

  );
}
