/* eslint-disable react-hooks/exhaustive-deps */
import styled from '@emotion/styled';
import { Edit } from '@mui/icons-material';
import { Box, Button, Divider, Grid, Paper, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../../api';
import ModalStatus from '../ModalStatus';
import { IProcedimento } from '../Timeline';
import { Label } from '../Typo';
import { PerfilContext } from '../../context/PerfilContext';
import ButtonResults from '../ButtonResults';
import DescriptionIcon from '@mui/icons-material/Description';
import { getAtendimentoStatusName } from '../../utils/enum/atendimento_status';
import { toast } from 'react-toastify';
import { getUserToken } from '../../lib/auth';
import { formatTestId } from '../../utils/formatters';

interface IDetailsProps {
  data?: IProcedimento;
  setStatus: (status: string) => void;
  hasResult: any;
  disableButtonResultado: boolean;
}

const Actions = styled(Box)`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 24px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;

    button {
      width: 100%;
    }
  }
`;

export function Details({
  data,
  setStatus,
  hasResult,
  index = -1,
  disableButtonResultado,
  examRoute,
  showButtonResultadoCitologia,
}: any) {
  const [loadingButtonResultadoHpv, setLoadingButtonResultadoHpv] =
    useState(true);
  const [modalStatusOpen, setModalStatusOpen] = useState<boolean>(false);
  const [examResult, setExamResult] = useState('');
  const { hasPermission } = React.useContext(PerfilContext);
  const navigate = useNavigate();

  const getHpvResult = async (data) => {
    setLoadingButtonResultadoHpv(true);
    try {
      const res = await api.get(`/hpv/${data?.hpv?.id}/resultado`);
      if (res.status === 200) {
        setLoadingButtonResultadoHpv(false);
        return res.data.resultado;
        // setExamResult(res.data.resultado);
      }
    } catch (error) {
      setLoadingButtonResultadoHpv(false);
      console.log(error);
    }
  };

  const handleGetExamResult = useCallback(async () => {
    if (data?.hpv != null) {
      const result = await getHpvResult(data);
      setExamResult(result);
    }
  }, [data?.hpv?.id]);

  useEffect(() => {
    handleGetExamResult();
  }, [handleGetExamResult]);

  const RenderBody = () => {
    if (data?.citologia) {
      const citologia = data.citologia;

      return (
        <Grid container spacing={2} data-testid={`exam-${index}-collapse_item`}>
          <Grid item xs={12} md={6}>
            <Label>Rede de Atendimento</Label>
            <Typography
              variant="body1"
              data-testid={`exam-${index}-rede_de_atendiemnto`}
            >
              {citologia.laboratorio_responsavel
                ? citologia.laboratorio_responsavel
                : 'SUS'}
            </Typography>
          </Grid>

          <Grid item xs={12} md={6}>
            <Label>Nº Protocolo do Exame no SISCAN</Label>
            <Typography
              variant="body1"
              data-testid={`exam-${index}-n_protocolo_do_exame_no_siscan`}
            >
              {citologia.protocolo ? citologia.protocolo : '-------'}
            </Typography>
          </Grid>
        </Grid>
      );
    }

    if (data?.status === 'NAO_ATENDIDA' && data?.['tipo'] == 'Não Atendido') {
      const hpv = { ...data } as any;
      return (
        <Grid container spacing={2}>
          <Grid item xs={12} md={3.5}>
            <Label>Motivo do Não Atendimento</Label>
            <Typography
              variant="body1"
              data-testid={`exam-${index}-motivo_do_nao_atendimento`}
            >
              {getAtendimentoStatusName(hpv.atendimento_status)}
            </Typography>
          </Grid>

          <Grid item xs={12} md={8.5}>
            <Label>Observações Adicionais</Label>
            <Typography
              variant="body1"
              data-testid={`exam-${index}-observacoes_adicionais`}
            >
              {hpv?.atendimento_observacao || 'Não Informado'}
            </Typography>
          </Grid>
        </Grid>
      );
    }
    if (data?.intraepitelial) {
      return (
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <Label>Serviço de Saúde da realização da EZT</Label>
            <Typography
              variant="body1"
              data-testid={`exam-${index}-servico_de_saude_da_realizacao_da_ezt`}
            >
              {data?.estabelecimento_saude?.nome_fantasia}
            </Typography>
          </Grid>

          <Grid item xs={12} md={3}>
            <Label>Tipo de Exérese da EZT </Label>
            <Typography
              variant="body1"
              data-testid={`exam-${index}-tipo_exerese_da_ezt`}
            >
              {data?.intraepitelial?.tipo_exerese_ezt === 1 && 'Tipo 1'}
              {data?.intraepitelial?.tipo_exerese_ezt === 2 && 'Tipo 2'}
              {data?.intraepitelial?.tipo_exerese_ezt === 3 && 'Tipo 3'}
            </Typography>
          </Grid>

          <Grid item xs={12} md={3}>
            <Label>Profissional Responsável </Label>
            <Typography
              variant="body1"
              data-testid={`exam-${index}-profissional_responsavel`}
            >
              {data?.profissional?.pessoa?.nome}
            </Typography>
          </Grid>
        </Grid>
      );
    }
    if (data?.hpv) {
      const hpv = data.hpv;
      return (
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <Label>Método de Coleta</Label>
            <Typography
              variant="body1"
              data-testid={`exam-${index}-metodo_coleta`}
            >
              {data?.hpv.clinico?.autocoleta === true
                ? 'Autocoleta'
                : 'Coleta por Profissional de Saúde'}
            </Typography>
          </Grid>

          <Grid item xs={12} md={3}>
            <Label>Número do KIT</Label>
            <Typography
              variant="body1"
              data-testid={`exam-${index}-numero_do_kit`}
            >
              {hpv?.clinico?.n_kit}
            </Typography>
          </Grid>

          <Grid item xs={12} md={3}>
            <Label>Número de Protocolo</Label>
            <Typography
              variant="body1"
              data-testid={`exam-${index}-numero_do_protocolo`}
            >
              {hpv?.clinico?.protocolo ? hpv?.clinico?.protocolo : '-------'}
            </Typography>
          </Grid>
        </Grid>
      );
    }
    if (data?.histopatologia) {
      const histopatologia: any = data?.histopatologia;

      const origem = { 1: 'Biópsia', 2: 'Peças Cirúrgicas' };
      const redeAtendimento = { 1: 'Rede SUS', 2: 'Saúde suplementar' };
      const margens = {
        1: 'Livre',
        2: 'Comprometidas',
        3: 'Impossível de Serem Avaliadas',
      };

      return (
        <Grid container spacing={2}>
          <Grid item xs={12} md={2.5}>
            <Label>Rede de atendimento</Label>
            <Typography
              variant="body1"
              data-testid={`exam-${index}-rede_de_atendiemnto`}
            >
              {redeAtendimento[histopatologia?.rede_atendimento]}
            </Typography>
          </Grid>

          <Grid item xs={12} md={3.5}>
            <Label>Nº Protocolo do Exame no SISCAN</Label>
            <Typography
              variant="body1"
              data-testid={`exam-${index}-n_protocolo_do_exame_no_siscan`}
            >
              {}
            </Typography>
          </Grid>

          <Grid item xs={12} md={2.5}>
            <Label>Origem do material</Label>
            <Typography
              variant="body1"
              data-testid={`exam-${index}-origem_do_material`}
            >
              {origem[histopatologia?.origem]}
            </Typography>
          </Grid>
          <Grid item xs={12} md={2.5}>
            <Label>Margens cirúrgicas</Label>
            <Typography
              variant="body1"
              data-testid={`exam-${index}-margens_cirurgicas`}
            >
              {margens[histopatologia?.margem_cirurgica]}
            </Typography>
          </Grid>
        </Grid>
      );
    }
    if (data?.colposcopia) {
      const colposcopia: any = data?.colposcopia;

      return (
        <Grid container spacing={2}>
          {colposcopia?.realizar_biopsia === true && (
            <Grid item xs={12} md={6}>
              <Label>Encaminhamento</Label>
              <Typography
                variant="body1"
                data-testid={`exam-${index}-encaminhamento`}
              >
                {colposcopia?.realizar_biopsia === true
                  ? 'Realizar Biópsia'
                  : ''}
              </Typography>
            </Grid>
          )}

          <Grid item xs={12} md={6}>
            <Label>Localização da Realização da Biópsia</Label>
            <Typography
              variant="body1"
              data-testid={`exam-${index}-localizacao_da_realizacao_da_biopsia`}
            >
              {colposcopia?.localizacao_biopsia?.length > 1 && 'Colo e Vagina'}
              {colposcopia?.localizacao_biopsia?.length === 1 &&
                colposcopia?.localizacao_biopsia[0]}
            </Typography>
          </Grid>
        </Grid>
      );
    }

    return null;
  };

  const type = data?.citologia
    ? 'CITOLOGIA'
    : data?.colposcopia
    ? 'COLPOSCOPIA'
    : data?.histopatologia
    ? 'HISTOPATOLOGIA'
    : data?.hpv
    ? 'HPV'
    : data?.intraepitelial
    ? 'INTRAEPITELIAL'
    : 'NONE';

  return (
    <Paper
      variant="outlined"
      sx={{ borderRadius: '10px', width: '100%' }}
      data-testid={`exam-${index}-buttons`}
    >
      <Box width="100%" p={4}>
        <RenderBody />
        {data?.status != `NAO_ATENDIDA` && (
          <>
            <Box my={3}>
              <Divider />
            </Box>

            <Actions>
              <Typography variant="subtitle1">Ações:</Typography>
              {data?.categoria?.nome !== 'Teste de HPV' && (
                <Button
                  size="small"
                  variant="outlined"
                  disabled={!hasPermission(`UPDATE_EXAME_${type}`)}
                  onClick={() => setModalStatusOpen(true)}
                  startIcon={<Edit />}
                  data-testid={`button-${index}-alterar_status`}
                >
                  ALTERAR STATUS
                </Button>
              )}

              {(data?.categoria?.nome === 'Colposcopia' ||
                data?.categoria?.nome === 'Citologia - Como Rastreio' ||
                (data?.categoria?.nome === 'Teste de HPV' &&
                  data?.hpv?.clinico) ||
                data?.categoria?.nome === 'Histopatologia') && (
                <>
                  <Button
                    size="small"
                    variant="outlined"
                    onClick={() =>
                      navigate(`exame/${data?.id}/visualizar`, {
                        state: { examData: data },
                      })
                    }
                    data-testid={formatTestId(
                      `button-${index}-`,
                      data?.colposcopia || data?.histopatologia
                        ? 'VER RESULTADO'
                        : 'VISUALIZAR EXAME'
                    )}
                    disabled={!hasPermission(`VIEW_EXAME_${type}`)}
                    startIcon={<DescriptionIcon />}
                  >
                    {data?.colposcopia || data?.histopatologia
                      ? 'VER RESULTADO'
                      : 'VISUALIZAR EXAME'}
                  </Button>
                </>
              )}

              {data?.categoria?.nome === 'Intraepitelial LIEAG' && (
                <>
                  <Button
                    size="small"
                    variant="outlined"
                    onClick={() =>
                      navigate(`tratamento-lesoes-intraepiteliais/visualizar`, {
                        state: { examData: data, viewExam: true },
                      })
                    }
                    data-testid={`button-${index}-visualizar_tratamento`}
                    disabled={!hasPermission(`VIEW_EXAME_${type}`)}
                    startIcon={<DescriptionIcon />}
                  >
                    VISUALIZAR TRATAMENTO
                  </Button>
                </>
              )}

              {!data?.intraepiteliais && (
                <ButtonResults
                  data={data}
                  hasResult={hasResult}
                  disableButtonResultado={disableButtonResultado}
                  index={index}
                  examResult={examResult}
                  showButtonResultadoCitologia={showButtonResultadoCitologia}
                  loadingButtonResultadoHpv={loadingButtonResultadoHpv}
                />
              )}

              <ModalStatus
                examRoute={examRoute}
                open={modalStatusOpen}
                examData={data}
                setModal={setModalStatusOpen}
                setCardStatus={setStatus}
              />
            </Actions>
          </>
        )}
      </Box>
    </Paper>
  );
}
