import { AlertListContext } from '../../context/AlertListContext';

import CancelIcon from '@mui/icons-material/Cancel';
import ErrorIcon from '@mui/icons-material/Error';
import React from 'react';
import api from '../../api';
import AlertInfo from '../../assets/imgs/AlertInfo.svg';

import './style.css';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HelpIcon from '@mui/icons-material/Help';
import { LoadingContext } from '../../context/LoadingContext';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from '@mui/material';
import { toast } from 'react-toastify';

type NotificationBoxProps = {
  id: number;
  tipo?: string;
  nomeDoPaciente?: string;
  setClicked: (clicked: boolean) => void;
  setModalOpen: (modalOpen: boolean) => void;
  setAlertId: (alertId: number) => void;
  foiChecado?: boolean;
  resultadoHPV?: any;
  id_pessoa?: number;
  id_exame?: number;
  typeNotification: string;
  idPessoa: string;
  idExam: number;
  alertId: number;
};

export const NotificationBox = (props: NotificationBoxProps) => {
  const { alertList } = React.useContext(AlertListContext);
  const navigate = useNavigate();

  const tooltipText = () => {
    switch (props.typeNotification) {
      case 'MISMATCH_HPV_COLETA':
        return `Um resultado do Teste de HPV para a paciente ${props.nomeDoPaciente} foi identificado no GAL, porém não há dados de Anamnese e Exame Clínico registrados no Jordana.`;
      case 'MISSING_HPV_EXAM':
        return `Um resultado do Teste de HPV para a paciente ${props.nomeDoPaciente} foi identificado no GAL, porém não há dados de Anamnese e Exame Clínico registrados no Jordana.`;
      case 'PACIENTE_NAO_CADASTRADO':
        return `As informações do GAL não correspondem aos dados necessários para associar o resultado a uma paciente no Jordana.`;
      case 'AVAILABLE_HPV_RESULT':
        return `O resultado do Teste de HPV da paciente ${props.nomeDoPaciente} está disponível, selecione aqui para visualizá-lo`;
    }
  };

  const text = (() => {
    switch (props.typeNotification) {
      case 'MISMATCH_HPV_COLETA':
        return `Um resultado do Teste de HPV para a paciente ${props.nomeDoPaciente}...`;
      case 'MISSING_HPV_EXAM':
        return `Um resultado do Teste de HPV para a paciente ${props.nomeDoPaciente}... `;
      case 'PACIENTE_NAO_CADASTRADO':
        return `As informações do GAL não correspondem aos dados necessários para associar o resultado a uma paciente no Jordana.`;
      case 'AVAILABLE_HPV_RESULT':
        return `O resultado do Teste de HPV da paciente ${props.nomeDoPaciente}...`;
    }
  })();

  const title = (() => {
    switch (props.typeNotification) {
      case 'MISMATCH_HPV_COLETA':
        return `Divergência de Dados do Teste de HPV`;
      case 'MISSING_HPV_EXAM':
        return 'Inexistência de Dados  do Teste de HPV';
      case 'PACIENTE_NAO_CADASTRADO':
        return 'Falha de vinculação do Resultado do Teste de HPV por divergência de Dados Cadastrais';
      case 'AVAILABLE_HPV_RESULT':
        return `Resultado disponível`;
    }
  })();

  const icon = (() => {
    if (props.typeNotification === 'MISSING_HPV_EXAM')
      return <CancelIcon color="error" className="icon" />;
    else if (props.typeNotification === 'PACIENTE_NAO_CADASTRADO')
      return <HelpIcon color="error" className="icon" />;
    else if (props.typeNotification === 'MISMATCH_HPV_COLETA')
      return (
        <ErrorIcon
          color="warning"
          className="icon"
          sx={{
            color: '#FFB547',
          }}
        />
      );
    else {
      return <CheckCircleIcon color="success" className="icon" />;
    }
  })();

  const { setLoading } = React.useContext(LoadingContext);

  const { setAlertList } = React.useContext(AlertListContext);

  const handleClick = async (id: number) => {
    try {
      const response = await api.post(`/notificacao/view/${id}`);
      if (response?.status === 201) {
        if (props.typeNotification === 'AVAILABLE_HPV_RESULT') {
          navigate(`/pacientes/${props.id_pessoa}/result/${props.idExam}`, {
            state: { view: 'visualizar' },
          });
          setLoading(false);

          return;
        } else {
          props.setModalOpen(true);
          props.setAlertId(props.alertId);
          props.setClicked(true);
          setLoading(false);
        }
      }
    } catch (error: any) {
      setLoading(false);
      toast.error(error.error?.message, { position: 'bottom-right' });
    }
  };
  return (
    <div className="notificationBox">
      {icon}
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <span
          className="titleNotificationBox"
          onClick={() => handleClick(props.id)}
        >
          {title}
        </span>
        <div
          className="notificationBoxBody"
          onClick={() => handleClick(props.id)}
        >
          <Tooltip title={String(tooltipText())} placement="top">
            <p className="notificationBoxText">{text}</p>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};
