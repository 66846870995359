import { Column } from './interface';

export const columns: readonly Column[] = [
  { id: 'cpf', label: 'CPF', minWidth: 50 },
  { id: 'name', label: 'Nome', minWidth: 100 },
  { id: 'email', label: 'E-mail', minWidth: 100 },
  { id: 'estabelecimento', label: 'Estabelecimento', minWidth: 150 },
  // { id: 'userType', label: 'Tipo de Usuário', minWidth: 170 },
  { id: 'status', label: 'Status', minWidth: 100 },
  { id: '', label: '', minWidth: 50 },
];
