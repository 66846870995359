/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Paper,
  Typography,
  Breadcrumbs,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Autocomplete,
  Checkbox,
  FormControlLabel,
  Tooltip,
} from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ClearIcon from '@mui/icons-material/Clear';
import CheckIcon from '@mui/icons-material/Check';
import { useStyles } from './EditRegister.style';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import api from '../../api';
import { SideMenu } from '../../components/SideMenu';
import { StyledAsteriskTextField } from '../../components/StyledAsteriskTextField/styles';
import { useEffect, useState, Fragment } from 'react';
import { toast } from 'react-toastify';
import { schema } from './schema';
import { returnCpfValidation } from '../../utils/validateCPF';
import ReturnButton from '../../components/ReturnButton';
import { EstabelecimentoSaude } from '../RegisterPatient/types';
import { getUserToken } from '../../lib/auth';
import AlertInfo from '../../assets/imgs/AlertInfo.svg';
interface Inputs {
  cpf: string;
  email: string;
  nome_de_usuario: string;
  nome_completo: string;
  estabelecimento_funcao: any;

  uf: any;
  municipio: any;
  perfil: any;
  cargo: any;
  status: any;
}

export interface RootObject {
  id: number;
  created_at: string;
  updated_at: string;
  nome: string;
  apelido: string;
  cpf: string;
  dt_nascimento: string;
  cns: string;
  raca: string;
  nacionalidade: string;
  nome_mae: string;
  sexo: string;
  contato_preferencial: string;
  escolaridade: string;
  orientacao: number;
  identidade_genero: number;
  usuario: Usuario;
  estabelecimento_saude?: any;
}

export interface Usuario {
  id: number;
  created_at: string;
  updated_at: string;
  nome_de_usuario: string;
  email: string;
}

export interface Perfil {
  id?: number;
  descricao;
  estabelecimento: EstabelecimentoSaude;
  usuarioId: number;
  status: 0 | 1 | 2;
  permissao: Permissao;
}
export interface Permissao {
  id?: number;
  nome: string;
  descricao: string;
  contexto: string;
}

export function EditRegister() {
  // const loading = open && options.length === 0;

  const disableInputsWhenLaboratorio =
    getUserToken().permissao_atual.contexto === 'LABORATÓRIO' &&
    getUserToken().permissao_atual.id === 6;
  const styles = useStyles();
  let navigate = useNavigate();
  const location = useLocation();

  const userMunicipal =
    getUserToken().permissao_atual.nome === 'Admin Municipal';

  const userEstadual = getUserToken().permissao_atual.nome === 'Admin Estadual';

  const [openDialogCancel, setOpenDialogCancel] = useState(false);
  const disableAllFields = true;
  const [isLoadingPerfis, setIsLoadingPerfis] = useState(true);
  const [permissoesSelect, setPermissoesSelect] = useState<Permissao[]>([]);
  const [perfis, setPerfis] = useState<Perfil[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const {
    handleSubmit,
    setValue,
    formState: { errors },
    control,
    getValues,
    setFocus,
    watch,
  } = useForm<Inputs>({
    resolver: yupResolver(schema),
  });
  // const [requestError, setRequestError] = useState<any>();

  useEffect(() => {
    load();
  }, []);

  useEffect(() => {}, [watch().status]);

  async function load() {
    await api
      .get('/permissoes', {
        params: {
          contexto: getUserToken().permissao_atual?.contexto,
        },
      })
      .then((res) => setPermissoesSelect(res.data));
  }

  /*const onSubmit = async (data: Inputs) => {
    const cpfIsValid = returnCpfValidation(getValues);
    if(!cpfIsValid) {
      toast.error("CPF Inválido");
      return
    }
    if(!data.status.some(s => s)) {
      toast.error("Ative ao menos um Estabelecimento");
      return
    }
    setIsLoading(true);

    const perfs = perfis.map(perf => {
      const indexer = perf.estabelecimento.id
      if(!data.status[indexer]) return undefined;
      return {
        ...perf,
        status: data.status[indexer],
        permissao: data.perfil[indexer],
        descricao: data.cargo[indexer] || data.perfil[indexer]?.descricao,
      }
    }).filter(p => p);

    const payload = {
      perfis: perfs,
      cpf: data.cpf?.replace(/[-.]/g, ''),
      email: data.email,
      nome_de_usuario: data.nome_de_usuario,
    }
    
    //return setIsLoading(false);
    const createdUser = await createUser(payload);
    if (createdUser?.success === true) {
      toast.success(createdUser?.message, {
        position: 'bottom-right',
      });
      navigate('/usuarios');
    } else {
      toast.error(createdUser?.error?.message || "Erro ao criar usuário, contacte um administrador", {
        position: 'bottom-right',
      })
    }
    setIsLoading(false);
    return;


    //const cpfIsValid = returnCpfValidation(getValues);
    if (cpfIsValid) {
      const responseInvite = await inviteUser({
        cpf: data.cpf?.replace(/[-.]/g, ''),
        email: data.email,
        nome_de_usuario: data.nome_de_usuario,
        cargo: data.estabelecimento_funcao.nome,
        estabelecimentoSaudeId: data.estabelecimento_funcao.estabelecimentoId,
      });

      if (responseInvite?.success === false) {
        toast.success(responseInvite?.message, {
          position: 'bottom-left',
        });
      }
    }
  };*/

  const handleOpenDialogCancel = () => {
    setOpenDialogCancel(true);
  };

  const handleCloseDialogCancel = () => {
    setOpenDialogCancel(false);
  };

  const handleCancelInviteUser = () => {
    navigate('/usuarios');
  };

  const onSubmit = async (data: Inputs) => {
    const cpfIsValid = returnCpfValidation(getValues);
    if (!cpfIsValid) {
      toast.error('CPF Inválido');
      return;
    }
    setIsLoading(true);

    const perfs = perfis
      .map((perf) => {
        const indexer = perf.estabelecimento.id;
        return {
          ...perf,
          status: data.status[indexer],
          permissao: data.perfil[indexer],
          descricao: data.cargo[indexer] || data.perfil[indexer]?.descricao,
        };
      })
      .filter((perf) => perf.status || perf.id);

    const payload = {
      perfis: perfs,
      cpf: data.cpf?.replace(/[-.]/g, ''),
      email: data.email,
    };

    try {
      await api.put(`/usuarios/${location.state.id}`, payload);
      toast.success('Usuário Atualizado', {
        position: 'bottom-right',
      });
      setIsLoading(false);
      navigate('/usuarios');
    } catch (e: any) {
      toast.error(e.error.message);
    }

    setIsLoading(false);
    return;
  };

  /*
  const onSubmit = async (data) => {
    const selectedUser = location.state;
    const userId = selectedUser.id;

    if (true) {
      try {
        const responseUser = await api.put(`/usuarios/${userId}`, data);
        if (responseUser.status === 200) {
          toast.success('Usuário editado com sucesso.', {
            position: 'bottom-right',
          });
          navigate('/usuarios');
        }
      } catch (error) {
        console.error(error);
        toast.error('Erro ao editar usuário, contacte o suporte.', {
          position: 'bottom-right',
        });
      }
    } else if (true) {
      try {
      } catch (error) {
        console.error(error);
      }
    }
  };*/

  const setFields = async () => {
    if (location.state && location.state.id) {
      const user = (await api.get('/usuarios/' + location.state.id))?.data;
      if (!user) {
        toast.error('Usuário inexistente, ou você não possuí acesso');
        return;
      }
      setValue('cpf', user.pessoa.cpf);
      setValue('nome_completo', user.pessoa.nome);
      setValue('email', user.email);
      setValue('nome_de_usuario', user.nome_de_usuario);
      //setFuncoesList(perfis.map((item) => item.descricao));
      const profissional = await api.get('/profissionais', {
        params: {
          cpf: user.pessoa.cpf,
        },
      });

      const estabelecimentos = profissional.data.data[0].estabelecimentos?.map(
        (est, index) => {
          const indexer = est.estabelecimento.id;
          setValue(`status.${indexer}`, false);
          setValue(`cargo.${indexer}`, ''); //findedPeople.data.data[0].cargo);
          return {
            descricao: '',
            estabelecimento: est.estabelecimento,
            status: false,
            permissao: undefined,
          };
        }
      );
      const estabelecimentosSemPerfil = estabelecimentos.filter(
        (estab) =>
          !user.perfis.find(
            (perf) => perf.estabelecimento_saude.id === estab.estabelecimento.id
          )
      );
      const perfisDoUsuario = user.perfis.map((perf) => {
        const indexer = perf.estabelecimento_saude.id;
        setValue(`status.${indexer}`, [0, 2].includes(perf.status));
        setValue(`cargo.${indexer}`, perf.descricao);
        setValue(`perfil.${indexer}`, perf.permissao);
        return {
          descricao: perf.descricao,
          estabelecimento: perf.estabelecimento_saude,
          status: [0, 2].includes(perf.status),
          permissao: perf.permissao,
          id: perf.id,
        };
      });
      setPerfis([...perfisDoUsuario, ...estabelecimentosSemPerfil]);
      setIsLoadingPerfis(false);
    }
  };
  useEffect(() => {
    setFields();
  }, [location.state]);

  return (
    <SideMenu>
      <Container sx={{ marginTop: '96px' }}>
        <ReturnButton backPage="/usuarios" />

        <Box>
          <Dialog open={openDialogCancel} onClose={handleCloseDialogCancel}>
            <DialogTitle>Cancelar Edição de usuário ?</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Deseja realmente cancelar a edição do usuário ?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDialogCancel}>Não</Button>
              <Button onClick={handleCancelInviteUser} autoFocus>
                Sim
              </Button>
            </DialogActions>
          </Dialog>

          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
              <Typography
                variant="h4"
                fontWeight="bold"
                style={{ marginBottom: '5px' }}
              >
                Editar Usuário
              </Typography>
              <Breadcrumbs
                separator={<NavigateNextIcon fontSize="small" />}
                aria-label="breadcrumb"
                style={{ marginBottom: '16px', marginTop: '16px' }}
              >
                <Link
                  style={{
                    textDecoration: 'none',
                    color: '#646464',
                  }}
                  to="/usuarios"
                >
                  Gerenciamento de Usuários
                </Link>
                <Typography key="3" color="text.primary">
                  Editar Usuário
                </Typography>
              </Breadcrumbs>
            </div>
          </div>

          <p style={{ fontSize: '12px', textAlign: 'right', color: '#d32f2f' }}>
            <i>* Resposta obrigatória</i>
          </p>
          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <Paper
              elevation={0}
              sx={{
                width: '100%',
                border: `1px solid RGBA(0, 0, 0, 0.23)`,
                borderRadius: '10px',
                marginTop: '24px',
              }}
            >
              <Typography
                variant="h5"
                fontWeight="bold"
                style={{ margin: '25px', paddingTop: '10px' }}
              >
                Informações Gerais de Usuário
              </Typography>

              <Divider
                variant="middle"
                sx={{ width: '96%', margin: '0 auto' }}
              />

              <Box m={3} pb={3} sx={{ flexDirection: 'column' }}>
                <Grid
                  container
                  rowSpacing={3}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                  <Grid item xs={12} sm={8} md={4}>
                    <Controller
                      render={({ field: { value } }) => (
                        <StyledAsteriskTextField
                          required
                          disabled={disableAllFields}
                          aria-readonly="true"
                          fullWidth
                          id="cpf"
                          label="CPF"
                          size="small"
                          variant="outlined"
                          className={styles.textField}
                          //onChange={onChange}
                          value={
                            value
                              ? value.replace(
                                  /(\d{3})(\d{3})(\d{3})(\d{2})/,
                                  '$1.$2.$3-$4'
                                )
                              : ''
                          }
                          helperText={errors.nome_completo?.message}
                          InputProps={{
                            autoComplete: 'off',
                          }}
                        />
                      )}
                      name="cpf"
                      control={control}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Controller
                      render={({ field: { onChange, value } }) => (
                        <StyledAsteriskTextField
                          required
                          disabled={disableAllFields}
                          aria-readonly="true"
                          fullWidth
                          id="nome"
                          label="Nome Completo do Profissional de Saúde"
                          size="small"
                          variant="outlined"
                          className={styles.textField}
                          //onChange={onChange}
                          value={value ? value : ''}
                          helperText={errors.nome_completo?.message}
                          InputProps={{
                            autoComplete: 'off',
                          }}
                        />
                      )}
                      name="nome_completo" //Ajustar posteriormente
                      control={control}
                    />
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <Controller
                      render={({ field: { onChange, value } }) => (
                        <StyledAsteriskTextField
                          required
                          fullWidth
                          id="email"
                          label="E-mail"
                          size="small"
                          variant="outlined"
                          className={styles.textField}
                          onChange={onChange}
                          value={value ? value : ''}
                          error={!!errors.email}
                          helperText={errors.email?.message}
                          InputProps={{
                            autoComplete: 'off',
                          }}
                        />
                      )}
                      name="email"
                      control={control}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Paper>
            <Paper
              elevation={0}
              sx={{
                width: '100%',
                border: `1px solid RGBA(0, 0, 0, 0.23)`,
                borderRadius: '10px',
                marginTop: '24px',
              }}
            >
              <Grid item>
                <Typography
                  variant="h5"
                  fontWeight="bold"
                  style={{ margin: '25px', paddingTop: '10px' }}
                >
                  Estabelecimento{perfis?.length > 1 ? 's' : ''} Vinculado
                  {perfis?.length > 1 ? 's' : ''}
                  <Tooltip
                    title={`Para adicionar mais um estabelecimento ao usuário, abra a tela “Cadastro de Profissional de Saúde”`}
                    placement="top"
                  >
                    <img
                      src={AlertInfo}
                      alt=""
                      style={{ marginLeft: '13px' }}
                    />
                  </Tooltip>
                </Typography>
              </Grid>
              <Box m={3} pb={3} sx={{ flexDirection: 'column' }}>
                <Grid container>
                  <Grid
                    container
                    // key={estabelecimento.id}
                    spacing={2}
                    my={1}
                  >
                    {perfis.length > 0 ? (
                      perfis?.map((perfil, index) => {
                        const indexer = perfil.estabelecimento.id;
                        return (
                          <Fragment key={perfil.estabelecimento.id}>
                            <Divider
                              variant="middle"
                              sx={{ width: '96%', margin: '2rem auto 1rem' }}
                            />
                            <Grid
                              container
                              item
                              xs={12}
                              spacing={2}
                              key={perfil.estabelecimento.id}
                            >
                              <Grid
                                item
                                xs={1}
                                md={0.5}
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                <Controller
                                  name={`status.${indexer}`}
                                  control={control}
                                  render={({
                                    field: { onChange, value, ...rest },
                                  }) => (
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          disabled={
                                            userMunicipal || userEstadual
                                          }
                                          onChange={() => {
                                            onChange(!value);
                                          }}
                                          checked={value}
                                          {...rest}
                                        />
                                      }
                                      label={''}
                                    />
                                  )}
                                />
                              </Grid>
                              <Grid
                                container
                                item
                                spacing={2}
                                xs={11}
                                md={11.5}
                              >
                                <Grid item xs={5} md={3}>
                                  <Autocomplete
                                    readOnly={true}
                                    disabled={
                                      !getValues().status[indexer] ||
                                      getUserToken().permissao_atual.id === 6 ||
                                      userMunicipal ||
                                      userEstadual
                                    }
                                    placeholder="UF"
                                    className={styles.noSelectClamp}
                                    value={
                                      perfil.estabelecimento.endereco?.municipio
                                        ?.estadoSigla
                                    }
                                    options={[
                                      perfil.estabelecimento.endereco?.municipio
                                        ?.estadoSigla,
                                    ]}
                                    renderInput={(params) => (
                                      <StyledAsteriskTextField
                                        {...params}
                                        required={getValues().status[indexer]}
                                        fullWidth
                                        size="small"
                                        label="UF"
                                        variant="outlined"
                                        className={styles.textField}
                                      />
                                    )}
                                  />
                                </Grid>
                                <Grid item xs={7} md={3}>
                                  <Autocomplete
                                    readOnly={true}
                                    disabled={
                                      !getValues().status[indexer] ||
                                      getUserToken().permissao_atual.id === 6 ||
                                      userMunicipal ||
                                      userEstadual
                                    }
                                    placeholder="Municipio"
                                    className={styles.noSelectClamp}
                                    value={
                                      perfil.estabelecimento.endereco?.municipio
                                        ?.nome
                                    }
                                    options={[
                                      perfil.estabelecimento.endereco?.municipio
                                        ?.nome,
                                    ]}
                                    renderInput={(params) => (
                                      <StyledAsteriskTextField
                                        {...params}
                                        required={getValues().status[indexer]}
                                        fullWidth
                                        size="small"
                                        label="Municipio"
                                        variant="outlined"
                                        className={styles.textField}
                                      />
                                    )}
                                  />
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  md={6}
                                  onClick={() =>
                                    toast.warn(
                                      'Somente Alterável na Edição De Profissional'
                                    )
                                  }
                                >
                                  <Autocomplete
                                    readOnly={true}
                                    disabled={
                                      !getValues().status[indexer] ||
                                      getUserToken().permissao_atual.id === 6 ||
                                      userMunicipal ||
                                      userEstadual
                                    }
                                    placeholder="Estabelecimento de Saúde"
                                    className={styles.noSelectClamp}
                                    value={
                                      perfil.estabelecimento?.nome_fantasia
                                    }
                                    options={[
                                      perfil.estabelecimento?.nome_fantasia,
                                    ]}
                                    renderInput={(params) => (
                                      <StyledAsteriskTextField
                                        {...params}
                                        required={getValues().status[indexer]}
                                        fullWidth
                                        size="small"
                                        label="Estabelecimento de Saúde"
                                        variant="outlined"
                                        className={styles.textField}
                                      />
                                    )}
                                  />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                  <Controller
                                    name={`perfil.${indexer}`}
                                    control={control}
                                    render={({
                                      field: { onChange, value },
                                    }) => {
                                      return (
                                        <Autocomplete
                                          placeholder="Perfil"
                                          disabled={
                                            !getValues().status[indexer] ||
                                            getUserToken().permissao_atual
                                              .id === 6 ||
                                            userMunicipal ||
                                            userEstadual
                                          }
                                          value={value ? value : null}
                                          onChange={(_, newValue) => {
                                            newValue &&
                                              setValue(
                                                `cargo.${indexer}`,
                                                newValue.descricao
                                              );
                                            onChange(
                                              newValue ? newValue : undefined //todo mudar quando for editar
                                            );
                                            setFocus(`cargo.${indexer}`, {
                                              shouldSelect: true,
                                            });
                                          }}
                                          options={
                                            permissoesSelect
                                              ? permissoesSelect
                                              : [
                                                  {
                                                    id: 0,
                                                    descricao: '',
                                                    contexto: '',
                                                    label: 'Não há opções',
                                                  },
                                                ]
                                          }
                                          getOptionLabel={(option) =>
                                            `${option.contexto} - ${option.nome}`
                                          }
                                          renderInput={(params) => (
                                            <StyledAsteriskTextField
                                              {...params}
                                              required={
                                                getValues().status[indexer]
                                              }
                                              fullWidth
                                              size="small"
                                              label="Perfil"
                                              variant="outlined"
                                              className={styles.textField}
                                            />
                                          )}
                                        />
                                      );
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                  <Controller
                                    name={`cargo.${indexer}`}
                                    control={control}
                                    render={({
                                      field: { onChange, value },
                                    }) => (
                                      <StyledAsteriskTextField
                                        disabled={
                                          !getValues().perfil[indexer]?.id ||
                                          !getValues().status[indexer] ||
                                          getUserToken().permissao_atual.id ===
                                            6 ||
                                          userMunicipal ||
                                          userEstadual
                                        }
                                        fullWidth
                                        id={`cargo.${indexer}`}
                                        label="Cargo"
                                        size="small"
                                        variant="outlined"
                                        onChange={onChange}
                                        value={value ? value : ''}
                                      />
                                    )}
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                          </Fragment>
                        );
                      })
                    ) : isLoadingPerfis ? (
                      <Typography style={{ marginLeft: '2rem' }}>
                        Carregando...
                      </Typography>
                    ) : (
                      <Typography style={{ marginLeft: '2rem' }}>
                        Usuário não possuí perfis/estabelecimento vinculado
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Box>
            </Paper>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                borderRadius: 1,
              }}
              mb={3}
              mt={1}
            >
              <Button
                sx={{
                  marginTop: '10px',
                  backgroundColor: '#238884',
                }}
                variant="contained"
                type="submit"
                startIcon={<CheckIcon />}
                disabled={isLoading}
              >
                Confirmar
              </Button>

              <Button
                sx={{ marginTop: '10px' }}
                variant="outlined"
                color="error"
                onClick={handleOpenDialogCancel}
                startIcon={<ClearIcon />}
              >
                Cancelar
              </Button>
            </Box>
          </form>
        </Box>
      </Container>
    </SideMenu>
  );
}
