import moment from 'moment';

const CardPendingResults = ({ subtitle, total, date }: any) => {
  const dataInfo = date ? moment(date).format('DD/MM/YYYY [às] HH:mm') : '';
  return (
    <div
      style={{
        position: 'relative',
        width: '100%',
        borderRadius: '10px',
        border: '0.8px solid rgba(0, 0, 0, 0.23)',
        paddingLeft: '17px',
        paddingTop: '21px',
        height: '50%',
      }}
    >
      <p style={{ margin: 0, fontSize: 20, fontWeight: 700 }}>
        Teste de HPV
        <br />
        {subtitle}
      </p>
      <p style={{ margin: 0, fontSize: 12, color: '#9e9e9e' }}>
        Data da Atualização: {dataInfo}
      </p>

      <p style={{ margin: 0, fontSize: 64, fontWeight: 700 }}>{total}</p>
      <div
        style={{
          position: 'absolute',
          width: '105px',
          display: 'flex',
          justifyContent: 'flex-end',
          fontSize: 10,
          marginBottom: '16px',
          marginRight: '16px',
          bottom: '0',
          right: '0',
          color: 'rgba(0, 0, 0, 0.6)',
          // marginBottom: '8px',
        }}
      >
        Fonte dos Dados: GAL
      </div>
    </div>
  );
};

export default CardPendingResults;
