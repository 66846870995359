/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Container,
  Typography,
  Divider,
  Grid,
  TextField,
  Button,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { SideMenu } from '../../components/SideMenu';
import TableSamples from '../../components/TableSamples/TableSamples';
import { useCallback, useContext, useEffect, useState } from 'react';
import { SampleContext } from '../../context/SampleContext';

export default function Samples() {
  const { getSample, setFilters } = useContext(SampleContext);
  const [idadeInicial, setIdadeInicial] = useState(25);
  const [idadeFinal, setIdadeFinal] = useState(35);
  const [quantidade, setQuantidade] = useState(1500);

  const handleSubmit = useCallback(() => {
    getSample();
  }, []);

  useEffect(() => {
    setFilters((filter) => ({
      ...filter,
      idadeInicial,
      idadeFinal,
      quantidade,
    }));
  }, [idadeInicial, idadeFinal, quantidade, setFilters]);

  return (
    <SideMenu>
      <Container>
        <Box mt={12}>
          <Typography
            variant="h4"
            fontWeight="bold"
            style={{ marginBottom: '30px' }}
          >
            Listagem de Amostras
          </Typography>

          <Divider />
          <Box mt={4} mb={4}>
            <Grid container spacing={2}>
              <Grid item xs={6} sm={4} md={3} lg={2}>
                <TextField
                  value={idadeInicial}
                  label="Idade Inicial"
                  size="small"
                  variant="outlined"
                  id="idadeInicial"
                  onChange={(e) => setIdadeInicial(Number(e.target.value))}
                />
              </Grid>
              <Grid item xs={6} sm={4} md={3} lg={2}>
                <TextField
                  value={idadeFinal}
                  label="Idade Final"
                  size="small"
                  variant="outlined"
                  id="idadeFinal"
                  onChange={(e) => setIdadeFinal(Number(e.target.value))}
                />
              </Grid>
              <Grid item xs={6} sm={4} md={3} lg={2}>
                <TextField
                  value={quantidade}
                  label="Quantidade"
                  size="small"
                  variant="outlined"
                  id="quantidade"
                  onChange={(e) => setQuantidade(Number(e.target.value))}
                />
              </Grid>
              <Grid item xs={6} sm={4} md={3} lg={2}>
                <Button
                  variant="contained"
                  startIcon={<SearchIcon />}
                  style={{
                    backgroundColor: '#C51162',
                    color: '#FFFFFF',
                    height: '100%',
                  }}
                  onClick={handleSubmit}
                >
                  Pesquisar
                </Button>
              </Grid>
            </Grid>
            <TableSamples />
          </Box>
        </Box>
      </Container>
    </SideMenu>
  );
}
