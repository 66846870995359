export const optionsRaçaCor = [
  'Branca',
  'Preta',
  'Parda',
  'Amarela',
  'Indígena',
];
export const optionsEscolaridade = [
  'Analfabeta',
  'Ensino Fundamental Incompleto',
  'Ensino Fundamental Completo',
  'Ensino Médio Incompleto',
  'Ensino Médio Completo',
  'Ensino Superior Incompleto',
  'Ensino Superior Completo',
];
