import moment from 'moment';

export const textCapitalize = (str: string): string => {
  const finalStr = str
    .toLowerCase()
    .replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());
  return finalStr;
};

export const collectionMethod = (method: string): string => {
  switch (method) {
    case 'MEIO_LIQUIDO':
      return 'Meio Líquido';
    default:
      return textCapitalize(method);
  }
};

export const formatDate = (date, format = 'DD/MM/YYYY') => {
  return moment.utc(date).format(format);
};

export const formatTestId = (prefix: string, text: string) => {
  return prefix + 
    (text ? text.toLocaleLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/[^a-z0-9 ]/g, '')
    .replace(/ /g, '_')
    : 'NotAvailible');
};


export const formatCpf = (cpf: string) =>
  cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
export const formateDataWithHours = (date) => {
  const dataOriginal = new Date(date);
  const dia = String(dataOriginal.getDate()).padStart(2, '0');
  const mes = String(dataOriginal.getMonth() + 1).padStart(2, '0');
  const ano = dataOriginal.getFullYear();

  const dataFormatada = `${dia}/${mes}/${ano}`;

  return dataFormatada;
};
