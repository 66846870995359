export const scrollToErrors = (errors) => {
  const hasErrors = Object.keys(errors).length > 0;

  if (hasErrors) {
    const firstComponentWithError =
      document.getElementsByClassName('Mui-error');

    if (firstComponentWithError.length > 0) {
      const headerHeight = 300;
      const top =
        firstComponentWithError[0].getBoundingClientRect().top +
        window.scrollY -
        headerHeight;

      window.scrollTo({
        top: top,
        behavior: 'smooth',
      });
    }
  }
};
