import { TableCell, Typography } from '@mui/material';
import moment from 'moment';
import { valuesResultado } from '../../../variablesRowsValues';

const RowsResultadoData = ({ row }) => {
  return (
    <>
      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {row?.resultado?.data_recebimento
            ? moment(row.resultado.data_recebimento).format('DD/MM/YYYY')
            : ''}
        </Typography>
      </TableCell>
      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {row?.resultado?.estabelecimentoSaude?.nome_fantasia}
        </Typography>
      </TableCell>
      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {row.resultado ? valuesResultado[row?.resultado?.resultado] : ''}
        </Typography>
      </TableCell>
      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {row.resultado?.resultado === 'POSITIVO'
            ? row?.resultado?.hpv_16
              ? 'Sim'
              : 'Não'
            : ''}
        </Typography>
      </TableCell>
      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {row.resultado?.resultado === 'POSITIVO'
            ? row?.resultado?.hpv_18
              ? 'Sim'
              : 'Não'
            : ''}
        </Typography>
      </TableCell>
      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {row.resultado?.resultado === 'POSITIVO'
            ? row?.resultado?.hpv_outros
              ? 'Sim'
              : 'Não'
            : ''}
        </Typography>
      </TableCell>
    </>
  );
};

export default RowsResultadoData;
