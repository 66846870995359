import {
  Autocomplete,
  Box,
  Divider,
  Grid,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import { Controller } from 'react-hook-form';
import { useStyles } from '../../RegisterPatient.style';
import { optionsEscolaridade } from '../../../../utils/optionsArrays';
import { IdentidadeGenero, OrientacaoSexual } from '../../types';
import { AlertInfo } from '../../../../components/AlertInfo';

const AdditionalInformation = ({ errors, control }) => {
  const styles = useStyles();
  return (
    <Box sx={{ width: '100%', marginTop: '24px' }}>
      <Paper
        elevation={0}
        sx={{
          width: '100%',
          border: `1px solid RGBA(0, 0, 0, 0.23)`,
          borderRadius: '10px',
        }}
      >
        <Typography
          variant="h6"
          fontWeight="bold"
          style={{ margin: '25px', paddingTop: '10px' }}
        >
          Informações Complementares
        </Typography>
        <Divider variant="middle" sx={{ width: '96%', marginLeft: '2%' }} />
        <Box m={3} pb={3} sx={{ flexDirection: 'column' }}>
          <Grid
            container
            rowSpacing={3}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            alignItems={'center'}
          >
            <Grid item xs={12}>
              <Controller
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    options={optionsEscolaridade}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Escolaridade"
                        fullWidth
                        size="small"
                        variant="outlined"
                        className={styles.textField}
                        helperText={errors.escolaridade?.message}
                        error={!!errors.escolaridade}
                      />
                    )}
                  />
                )}
                name="escolaridade"
                control={control}
              />
            </Grid>
            <Grid item xs={12} md={3.5}>
              <Controller
                render={({ field: { onChange, value } }) => (
                  <TextField
                    label="Orientação Sexual"
                    select
                    fullWidth
                    size="small"
                    variant="outlined"
                    className={styles.textField}
                    onChange={onChange}
                    value={value ? value : ''}
                    error={!!errors.orientacao}
                    helperText={errors.orientacao?.message}
                    disabled
                  >
                    <MenuItem value={OrientacaoSexual.Heterossexual}>
                      Heterossexual
                    </MenuItem>
                    <MenuItem value={OrientacaoSexual.Homossexual}>
                      Homossexual (gay / lésbica)
                    </MenuItem>
                    <MenuItem value={OrientacaoSexual.Bissexual}>
                      Bissexual
                    </MenuItem>
                    <MenuItem value={OrientacaoSexual.Outro}>Outro</MenuItem>
                  </TextField>
                )}
                name="orientacao"
                control={control}
              />
            </Grid>
            <Grid item xs={12} md={3.5}>
              <Controller
                render={({ field: { onChange, value } }) => (
                  <TextField
                    label="Identidade de Gênero"
                    select
                    fullWidth
                    size="small"
                    variant="outlined"
                    className={styles.textField}
                    onChange={onChange}
                    value={value ? value : ''}
                    error={!!errors.identidade_genero}
                    helperText={errors.identidade_genero?.message}
                    disabled
                  >
                    <MenuItem value={IdentidadeGenero.HomemTrans}>
                      Homem transsexual
                    </MenuItem>
                    <MenuItem value={IdentidadeGenero.MulherTrans}>
                      Mulher transsexual
                    </MenuItem>
                    <MenuItem value={IdentidadeGenero.Travesti}>
                      Travesti
                    </MenuItem>
                    <MenuItem value={IdentidadeGenero.Outro}>Outro</MenuItem>
                  </TextField>
                )}
                name="identidade_genero"
                control={control}
              />
            </Grid>
            <Grid item xs={12} md={5}>
              {/* <AlertInfo
                text="Alterações nas informações complementares deverão ser
                          realizadas no e-SUS APS"
              /> */}
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Box>
  );
};

export default AdditionalInformation;
