import styled from '@emotion/styled';
import { Box } from '@mui/material';
import { responsiveBreakpoints } from '../../styles/breakpoinst';

export const ContainerButtonsActions = styled(Box)`
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  @media (min-width: ${responsiveBreakpoints.small}) {
    flex-direction: row;
  }
`;
