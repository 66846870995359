import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
  textField: {
    width: '100%',
    '& label.Mui-focused': {
      color: '#238884',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#238884',
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: '#238884',
      },
    },
  },
  paper: {
    padding: '16px 8px 16px 16px',

    borderRadius: '8px',
  },
  image: {
    width: '100%',
    height: '180px',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
  },
  linkText: {
    height: '65.412px',
    padding: '16px 8px 16px 16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'black',
    fontWeight: 'bold',
    marginTop: '.5rem',
  },
  customTypography: {
    marginBottom: '8px',
    color: 'var(--text-primary, rgba(0, 0, 0, 0.87))',
    fontSize: '34px',
    fontstyle: 'normal',
    fontweight: '700',
  },
  customBreadcrumbs: {
    color: '#000000',
    display: 'flex',
    padding: '24px 0px',
    gap: '24px',
  },
  customSpan: {
    textDecoration: 'none',
    color: '#646464',
    display: 'flex',
    padding: '8px 0px',
    gap: '8px',
  },
  link: {
    textDecoration: 'none',
  },
}));
