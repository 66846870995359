import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles({
  alertInfo: {
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box',
    backgroundColor: '#E5F6FD',
    borderRadius: '4px',
    padding: '0px 16px',
    gap: '12px',
    fontSize: '0.875rem;',
    flex: 1,

    '& > p': {
      color: 'rgb(1, 67, 97)',
      lineHeight: '1.43',
    },
  },
});
