import styled from '@emotion/styled';
import { Box, Button, Paper, Table } from '@mui/material';
import { responsiveBreakpoints } from '../../styles/breakpoinst';

export const ContainerScreen = styled(Box)`
  width: 100%;
  margin-top: 15px;
  margin-bottom: 50px;
`;

export const PaperContainer = styled(Paper)`
  width: 100%;
  margin-bottom: 2;
  padding-top: 2;
`;

export const BoxButtonsActionsHeaderTable = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (min-width: ${responsiveBreakpoints.small}) {
    flex-direction: row;
  }
`;

export const ContainerButtonsTableHeader = styled(Box)`
  display: flex;
  justify-content: space-between;
  border-radius: 1;
  flex-direction: column;
  @media (min-width: ${responsiveBreakpoints.small}) {
    flex-direction: row;
  }
`;

export const ButtonGenerate = styled(Button)`
  height: 36px;
  margin: 10px;
`;

export const StyledTable = styled(Table)`
  min-width: 750;
  border: none;
  td {
    border-bottom: none;
  }
`;
