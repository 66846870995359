import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const FiltersWrapper = styled(Box)`
  display: flex;
  align-items: center;
  gap: 24px;

  
  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
`;

export const InputWrapper = styled(Box)`
  margin-top: 32px;
  min-height: 56px;
  padding: 12px 16px;
  border-radius: 4px;
  background-color: #EEEEEE;
  color: rgba(0, 0, 0, 0.54);
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 12px;
`;

export const FiltersSelectedWrapper = styled(Box)`
  flex: 1;
  display: flex;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;
`;
