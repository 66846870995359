import { getReportsConvocatoria } from '../../../../../lib/Reports/getReportsConvocatoria';

const useRequestFilter = ({ setTableData }) => {
  const loadReportsConvocatoria = async (payload) => {
    const response = await getReportsConvocatoria({
      payload,
    });

    setTableData(response);
  };

  return {
    loadReportsConvocatoria,
  };
};

export default useRequestFilter;
