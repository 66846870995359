import { TableCell, TableHead, TableRow, Typography } from '@mui/material';

const TableHeaders = () => {
  return (
    <TableHead>
      <TableRow>
        <TableCell sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Nome Completo <br /> da Mulher
          </Typography>
        </TableCell>
        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            {' '}
            Nº do Cartão SUS
          </Typography>
        </TableCell>
        <TableCell align="left" sx={{ borderBottom: 'none', width: '160px' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Nome Completo <br />
            da Mãe
          </Typography>
        </TableCell>
        <TableCell align="left" sx={{ borderBottom: 'none', width: '160px' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            CPF
          </Typography>
        </TableCell>
        <TableCell align="left" sx={{ borderBottom: 'none', width: '160px' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Data de <br /> Nascimento
          </Typography>
        </TableCell>
        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Idade
          </Typography>
        </TableCell>
        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Nacionalidade
          </Typography>
        </TableCell>
        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Raça / Cor
          </Typography>
        </TableCell>
        {/* <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Equipe de Saúde
          </Typography>
        </TableCell> */}
        {/* <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Estabelecimento
          </Typography>
        </TableCell> */}

        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            CEP
          </Typography>
        </TableCell>
        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Logradouro
          </Typography>
        </TableCell>
        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Número
          </Typography>
        </TableCell>
        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Complemento
          </Typography>
        </TableCell>
        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Bairro
          </Typography>
        </TableCell>
        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Ponto de <br />
            Referência
          </Typography>
        </TableCell>
        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            UF
          </Typography>
        </TableCell>
        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Município
          </Typography>
        </TableCell>
        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Estabelecimento Vinculado
          </Typography>
        </TableCell>
        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Rede de <br /> Atendimento
          </Typography>
        </TableCell>
        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            CNES do <br /> Laboratório
          </Typography>
        </TableCell>
        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Laboratório <br /> Responsável pelo <br />
            Laudo
          </Typography>
        </TableCell>
        {/* <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Número do <br /> Protocolo do <br /> Exame no SISCAN
          </Typography>
        </TableCell> */}
        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Data do Resultado
          </Typography>
        </TableCell>
        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Resultado
          </Typography>
        </TableCell>
        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Células Atípicas
            <br />
            de Significado
            <br />
            Indeterminado
            <br />
            Escamosas
          </Typography>
        </TableCell>
        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Células Atípicas
            <br />
            de Significado
            <br />
            Indeterminado
            <br />
            Glandulares
          </Typography>
        </TableCell>
        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Células Atípicas
            <br />
            de Significado
            <br />
            Indeterminado de
            <br />
            Origem Indefinida
          </Typography>
        </TableCell>
        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Atípias em Células <br />
            Glandulares
          </Typography>
        </TableCell>
        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Atípias em Células <br />
            Escamosas
          </Typography>
        </TableCell>
        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Outras neoplasias
            <br /> Malignas
          </Typography>
        </TableCell>

        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Profissional <br /> Responsável Pelo
            <br />
            Resultado
          </Typography>
        </TableCell>

        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            CBO do <br />
            Profissional
          </Typography>
        </TableCell>

        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            CNS do <br />
            Profissional
          </Typography>
        </TableCell>
        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            INE do
            <br /> Profissional
          </Typography>
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

export default TableHeaders;
