import { Button, Paper } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';

const TotalCardsPanels = ({ title, value, color, graphicComponent }) => {
  return (
    <Paper
      style={{
        borderBottom: `8px solid ${color}`,
        padding: '8px',
        borderRadius: '10px',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <h1 style={{ fontSize: '20px', margin: 0 }}>{title}</h1>
        <Button
          sx={{ width: '100px', fontSize: '11px' }}
          variant="outlined"
          onClick={() => {
            return;
          }}
          startIcon={<DownloadIcon />}
        >
          DOWNLOAD
        </Button>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: '20px',
        }}
      >
        <p style={{ fontSize: '60px', margin: 0, lineHeight: '1' }}>
          {value ? value.total : 0}
        </p>
        {graphicComponent}
      </div>
    </Paper>
  );
};

export default TotalCardsPanels;
