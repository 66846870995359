import { styled } from '@mui/material/styles';
import { Paper } from '@mui/material';

export const StyledContainerCookies = styled(Paper)`
  display: flex;
  padding: 15px 22px;
  justify-content: center;
  align-items: center;
  gap: 32px;
  flex-wrap: wrap;
  margin-top: 40px;
  height: auto !important;
  text-align: center;
  margin-bottom: 20px;

  .links_pdf {
    text-decoration: underline;
    border: none;
    font-size: 16px;
    color: #2196f3;
    background: none;
    :hover {
      cursor: pointer;
    }
  }

  @media (min-width: 886px) {
    text-align: start;
    margin-bottom: 0;
  }

  span {
    line-height: 1;
  }

  a {
    color: #2196f3;
  }
`;
