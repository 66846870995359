/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect } from 'react';

import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import './styles.css';

const GraphicTotalCards = ({ color, index, valueGraphic }) => {
  const percentage = String(valueGraphic).slice(0, 4);

  useEffect(() => {
    const elements = document.getElementsByClassName(
      'CircularProgressbar-path'
    );

    if (index >= 0 && index < elements.length) {
      const element = elements[index] as HTMLElement;

      element.style.stroke = color;
    }
  }, [color, index]);

  return (
    <CircularProgressbar
      value={valueGraphic}
      text={`${percentage}%`}
      strokeWidth={13}
      styles={buildStyles({
        textColor: 'black',
        textSize: '30px',
        pathColor: 'turquoise',
        trailColor: '#rgb(157, 83, 242)',
      })}
    />
  );
};

export default GraphicTotalCards;

// render(<App />, document.getElementById('root'));
