import React from 'react';
import ReactDOM from 'react-dom';
import App from './Application';
import reportWebVitals from './reportWebVitals';
import { CssBaseline } from '@mui/material';
import { PeopleStorage } from './context/PeopleContext';
import { HelperSendingProvider } from './context/HelperSendingContext';
import EstablishmentProvider from './context/EstablishmentContext';
import Theme from './styles/theme';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.min.css';
import { BackgroundProvider } from './context/BackgroundContext';
import './styles.css';
import { InformationPaperStateContextProvider } from './context/InformationPaperStateContext';
import { EnviromentPopup } from './components/EnviromentPopup';
import PerfilProvider from './context/PerfilContext';
import { AlertListProvider } from './context/AlertListContext';
import { LoadingProvider } from './context/LoadingContext';
import Loading from './components/Loading';
import { FiltersCentralNotificationProvider } from './context/FiltersCentralNotificationContext';

ReactDOM.render(
  <>
    <CssBaseline />
    <Theme>
      <HelperSendingProvider>
        <FiltersCentralNotificationProvider>
          <AlertListProvider>
            <PerfilProvider>
              <EstablishmentProvider>
                <BackgroundProvider>
                  <PeopleStorage>
                    <LoadingProvider>
                      <InformationPaperStateContextProvider>
                        <>
                          <App />
                          <Loading />
                        </>
                      </InformationPaperStateContextProvider>
                    </LoadingProvider>
                  </PeopleStorage>
                </BackgroundProvider>
              </EstablishmentProvider>
            </PerfilProvider>
            <ToastContainer />
            <EnviromentPopup />
          </AlertListProvider>
        </FiltersCentralNotificationProvider>
      </HelperSendingProvider>
    </Theme>
  </>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals)
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
