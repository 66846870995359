import { ArrowBack } from '@mui/icons-material';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const ReturnButton = ({ backPage }) => {
  const navigate = useNavigate();
  const handleReturn = () => {
    navigate(backPage);
  };
  return (
    <Button
      onClick={handleReturn}
      sx={{
        textTransform: 'none',
        fontWeight: 'bold',
        color: 'rgba(0, 0, 0, 0.6)',
        '&:hover': {
          background: 'transparent',
        },
        padding: '0px',
        marginBottom: '16px',
        fontSize: '20px',
        display: 'flex',
        gap: '8px',
      }}
      variant="text"
      data-testid={"returnButton"+backPage}
    >
      <ArrowBack />
      Voltar
    </Button>
  );
};

export default ReturnButton;
