/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect } from 'react';
import * as echarts from 'echarts';

const GraphicReasonNaoElegivel = ({ data, total }) => {
  const allZeros = data.every((item) => item.value === 0);
  useEffect(() => {
    const chart = echarts.init(
      document.getElementById('echarts-chart-reason-nao-elegivel')
    );

    let option;

    if (allZeros) {
      option = {
        color: ['#ccc'],
        series: [
          {
            type: 'pie',
            radius: ['40%', '70%'],
            avoidLabelOverlap: true,
            startAngle: 90,
            itemStyle: {
              borderColor: '#fff',
              borderWidth: 2,
            },
            label: {
              show: false,
              position: 'center',
            },
            data: [{ value: 1, name: 'No Data' }],
          },
        ],
      };
    } else {
      option = {
        tooltip: {
          trigger: 'item',
        },
        legend: {
          top: '5%',
          left: 'center',
          show: false,
        },
        series: [
          {
            name: '',
            type: 'pie',
            radius: ['40%', '70%'],
            avoidLabelOverlap: true,
            startAngle: 90,
            itemStyle: {
              borderColor: '#fff',
              borderWidth: 2,
            },
            label: {
              show: false,
              position: 'center',
            },
            emphasis: {
              label: {
                show: false,
                fontSize: 40,
                fontWeight: 'bold',
              },
            },
            labelLine: {
              show: false,
            },
            data: data,
          },
        ],
      };
    }

    chart.setOption(option);

    return () => {
      chart.dispose();
    };
  }, [allZeros]);

  return (
    <div style={{ position: 'relative' }}>
      <p
        style={{
          position: 'absolute',
          top: '20%',
          fontSize: '34px',
          fontWeight: '700',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {total}
      </p>
      <div
        id="echarts-chart-reason-nao-elegivel"
        style={{
          width: '200px',
          height: '200px',
        }}
      />
    </div>
  );
};

export default GraphicReasonNaoElegivel;
