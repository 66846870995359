import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/CloseRounded';
import EventAvailableOutlinedIcon from '@mui/icons-material/EventAvailableOutlined';

import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  InputLabel,
  Modal,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect, useState, useRef, useContext } from 'react';
import { Label } from '../../../../../components/Typo';
import { useStyles } from '../styles';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import ptBR from 'date-fns/locale/pt-BR';
import Select, { SelectChangeEvent } from '@mui/material/Select';
// import { times as timesArray } from '../modalScheduling';
import { toast } from 'react-toastify';
import { AlertInfo } from '../../../../../components/AlertInfo';
import { EstablishmentContext } from '../../../../../context/EstablishmentContext';
import api from '../../../../../api';

export const CancelScheduleModal = ({
  open,
  handleClose,
  appointmentDate,
  patientName,
  profissionalName,
  optionsDays,
  scheduleId,
  appointmentTime,
  scheduleData,
  datesWithSchedule,
  examName,
}) => {
  const [, setPatient] = useState(patientName);
  const [time, setTime] = useState(appointmentTime);
  const [reasonForCancellation, setReasonForCancellation] = useState('');
  const [cancelAppointment, setCancelAppointment] = useState(false);
  const [reschedule, setReschedule] = useState(false);
  const [, setHasError] = useState(false);
  const inputRef = useRef<HTMLDivElement>(null);
  const [newScheduleDate, setNewScheduleDate] = useState<Date | null>(null);
  const [newScheduleTime, setNewScheduleTime] = useState('');
  const [showInputReason, setShowInputReason] = useState(false);
  const [reasonCancel, setReasonCancel] = useState('');
  const [errorReasonInput, setErrorReasonInput] = useState('');
  const [selectedDay, setSelectedDay] = useState('');
  const [payloadDate, setPayloadDate] = useState<any>('');
  const [disponiveisHours, setDisponiveisHours] = useState([]);

  const styles = useStyles();

  const handleTypeReason = (newValue) => {
    setReasonCancel(newValue);
  };

  const handleReschedule = async () => {
    const deleteSchedule = await api.delete(`/cidadao_agenda/${scheduleId}`);

    if (deleteSchedule.status === 200) {
      const responseNewSchedule = await api.post(
        '/cidadao_agenda/agendamento',
        {
          id_paciente: scheduleData.pessoaId,
          nome: scheduleData.nome,
          cns: scheduleData.nSus,
          cpf: scheduleData.cpf,
          dt_nascimento: scheduleData.dtNascimento,
          nacionalidade: scheduleData.nacionalidade ?? '',
          estabelecimentoId: currentEstablishment.id,
          nome_fantasia: currentEstablishment.nome_fantasia,
          endereco_local: enderecoCompleto,
          procedimento: 'Teste de HPV',
          isagendamento: true,
          nomeProfissional: profissionalName,
          dt_agendamento: payloadDate,
          idProfissional: scheduleData.profissionalId,
          horario_atendimento: time,
          status: 'AGENDADO',
          motivo_cancelamento: '',
          motivo_outro: '',
        }
      );

      if (responseNewSchedule.status === 201) {
        handleClose();
        setTimeout(() => {
          window.location.reload();
        }, 2000);
        toast.success('Reagendamento realizado com sucesso!', {
          position: 'bottom-right',
        });
      }
    }
  };

  const handleCancelSchedule = async () => {
    if (reasonCancel) {
      const response = await api.put(
        `/cidadao_agenda/agendamento/${scheduleId}/atualizar-status`,
        {
          status: 'Agendamento Cancelado',
          isAgendamento: true,
          motivo_depois_agendado: true,
        }
      );

      if (response.status === 200) {
        handleClose();
        setTimeout(() => {
          window.location.reload();
        }, 2000);
        toast.success('Agendamento Cancelado', { position: 'bottom-right' });
      }
    } else {
      setErrorReasonInput('Motivo do cancelamento é obrigatório');
    }
  };
  const handleSetTime = (time: any) => {
    setTime(time);
  };

  const handleSetSelectedDay = (day: any) => {
    const schedulesOfSelectedDays = datesWithSchedule.find(
      (item) => item.date === day
    );

    setDisponiveisHours(
      schedulesOfSelectedDays?.schedule
        ?.filter((item) => item.disponivel === true)
        .map((item) => item.hour)
    );

    const splited = day.split('-');

    const dateFormated = `${splited[2]}/${splited[1]}/${splited[0]}`;
    setSelectedDay(day ? dateFormated : '');
  };
  const handleChange = (event: SelectChangeEvent) =>
    setNewScheduleTime(event.target.value as string);

  const { currentEstablishment } = useContext(EstablishmentContext);
  const numeroFormatado =
    currentEstablishment.endereco.numero === 'S/N'
      ? 'S/N'
      : currentEstablishment.endereco.numero.padStart(2, '0');
  const cepFormatado =
    currentEstablishment.endereco.cep.slice(0, 5) +
    '-' +
    currentEstablishment.endereco.cep.slice(5);
  const enderecoCompleto =
    currentEstablishment.endereco.logradouro +
    ', ' +
    numeroFormatado +
    ' - ' +
    currentEstablishment.endereco.bairro +
    ', ' +
    currentEstablishment.endereco.municipio.nome +
    ' - ' +
    currentEstablishment.endereco.municipio.estado.sigla +
    ', ' +
    cepFormatado;

  const handleCancelAppointment = () => {
    if (!reasonForCancellation) {
      setHasError(true);
    } else {
      handleClose();
      toast.success('Agendamento cancelado com Sucesso.', {
        position: 'bottom-right',
      });
    }

    if (inputRef) {
      inputRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  };

  const CancelOrRescheduleFooter = () => {
    return (
      <Box flex={1} px={1} gap={4} display="flex" flexDirection="column">
        <AlertInfo text="Ao cancelar a agenda o será informado via celular" />

        <Box flex={1} display="flex" justifyContent="center">
          <Button
            variant="contained"
            onClick={handleCancelAppointment}
            endIcon={<CheckIcon />}
          >
            Cancelar Agenda
          </Button>
        </Box>
      </Box>
    );
  };

  const CancelAppointmentFooter = () => {
    return (
      <Box flex={1} display="flex" px={1} justifyContent="space-between">
        <Button
          variant="outlined"
          color="error"
          onClick={() => setCancelAppointment(true)}
        >
          Cancelar Agenda
        </Button>
        <Button variant="outlined" onClick={() => setReschedule(true)}>
          Reagendar
        </Button>
      </Box>
    );
  };

  const RescheduleFooter = () => {
    return (
      <Box flex={1} px={1} gap={4} display="flex" flexDirection="column">
        <AlertInfo
          text="Ao reagendar, o paciente será informado via celular sobre a nova
            data e horário"
        />

        <Box flex={1} display="flex" justifyContent="center">
          <Button
            variant="contained"
            onClick={() => {
              handleClose();
              toast.success('Agendamento reagendado com Sucesso.', {
                position: 'bottom-right',
              });
            }}
            endIcon={<CheckIcon />}
          >
            Reagendar
          </Button>
        </Box>
      </Box>
    );
  };

  const NewScheduleForm = () => {
    return (
      <Box display="flex" justifyContent="space-between" gap={2} mt={2}>
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
          <DatePicker
            onChange={(e) => setNewScheduleDate(e)}
            maxDate={new Date()}
            value={newScheduleDate}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Data"
                size="small"
                variant="outlined"
                fullWidth
              />
            )}
          />
        </LocalizationProvider>

        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Horário</InputLabel>
          {!reschedule && (
            <Select
              value={newScheduleTime}
              label="Horário"
              onChange={handleChange}
              size="small"
            >
              {/* {timesArray.map((time) => (
              <MenuItem value={time}>{time}</MenuItem>
            ))} */}
            </Select>
          )}
        </FormControl>
      </Box>
    );
  };

  // const Footer = () => {
  //   return (
  //     <div className={styles.footer}>
  //       {cancelAppointment && <CancelOrRescheduleFooter />}
  //       {reschedule && <RescheduleFooter />}
  //       {!cancelAppointment && !reschedule && <CancelAppointmentFooter />}
  //     </div>
  //   );
  // };

  useEffect(() => {
    if (patientName) {
      setPatient(patientName);
    } else {
      setPatient('');
    }
  }, [patientName]);

  useEffect(() => {
    if (appointmentTime) {
      setTime(appointmentTime);
    } else {
      setTime('');
    }
  }, [appointmentTime]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.header}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              className={styles.h6}
              fontWeight="700"
            >
              Agendamento Realizado
            </Typography>

            <button className={styles.closeIcon} onClick={handleClose}>
              <CloseIcon fontSize="small" />
            </button>
          </div>

          <div className={styles.body}>
            <Typography
              variant="h6"
              component="h2"
              className={styles.h6}
              fontWeight="700"
            >
              Dados da convocação
            </Typography>

            {reschedule ? (
              <Autocomplete
                options={optionsDays}
                onChange={(_, value) => {
                  handleSetSelectedDay(value ?? '');
                  setPayloadDate(value);
                }}
                value={selectedDay}
                renderOption={(props, option) => {
                  const splited = option.split('-');

                  const dateFormated = `${splited[2]}/${splited[1]}/${splited[0]}`;

                  return <li {...props}>{dateFormated}</li>;
                }}
                sx={{ mt: 2, mb: 0.5 }}
                isOptionEqualToValue={(option, value) => option === value}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Selecione um dia"
                    size="small"
                  />
                )}
              />
            ) : (
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '8px',
                }}
                sx={{ mt: 2 }}
              >
                <EventAvailableOutlinedIcon sx={{ color: 'rgba(0,0,0,0.8)' }} />
                <Typography variant="body1">{appointmentDate || ''}</Typography>
              </Box>
            )}

            <Label labelcolor="rgba(0,0,0,0.87)" sx={{ mt: 2, mb: 0.5 }}>
              Local do Procedimento
            </Label>
            <Typography variant="body1">
              {currentEstablishment?.nome_fantasia || ''}
            </Typography>

            <Label labelcolor="rgba(0,0,0,0.87)" sx={{ mt: 2, mb: 0.5 }}>
              Endereço
            </Label>
            <Typography variant="body1">{enderecoCompleto || ''}</Typography>

            <Label labelcolor="rgba(0,0,0,0.87)" sx={{ mt: 2, mb: 0.5 }}>
              Profissional
            </Label>
            <Typography variant="body1">{profissionalName || ''}</Typography>

            <Label labelcolor="rgba(0,0,0,0.87)" sx={{ mt: 2, mb: 0.5 }}>
              Procedimento
            </Label>
            <Typography variant="body1">{examName || ''}</Typography>

            <Label labelcolor="rgba(0,0,0,0.87)" sx={{ mt: 2, mb: 0.5 }}>
              Paciente
            </Label>
            <Typography variant="body1">{patientName || ''}</Typography>

            {reschedule ? (
              <Autocomplete
                options={disponiveisHours}
                onChange={(_, value) => handleSetTime(value ?? '')}
                defaultValue=""
                // value={time}
                renderOption={(props, option) => <li {...props}>{option}</li>}
                sx={{ mt: 2, mb: 0.5 }}
                isOptionEqualToValue={(option, value) => option === value}
                renderInput={(params) => (
                  <TextField {...params} label="Horário" size="small" />
                )}
              />
            ) : (
              <>
                <Label labelcolor="rgba(0,0,0,0.87)" sx={{ mt: 2, mb: 0.5 }}>
                  Horário
                </Label>
                <Typography variant="body1">{appointmentTime || ''}</Typography>
                {showInputReason && (
                  <TextField
                    label="Motivo do Cancelamento"
                    error={!!errorReasonInput}
                    size="small"
                    sx={{ width: '100%', marginTop: '15px' }}
                    placeholder="Informe o motivo do cancelamento"
                    onChange={(e) => handleTypeReason(e.target.value)}
                  />
                )}
              </>
            )}
          </div>

          <div
            className={styles.footer}
            style={{
              display: 'flex',
              justifyContent:
                showInputReason || reschedule ? 'center' : 'space-between',
              alignItems: 'center',
            }}
          >
            {reschedule && (
              <Button
                variant="contained"
                endIcon={<CheckIcon />}
                sx={{ marginRight: '10px' }}
                onClick={() => {
                  handleReschedule();
                }}
              >
                Confirmar Reagendamento
              </Button>
            )}
            {!showInputReason && !reschedule && (
              <>
                <Button
                  variant="outlined"
                  sx={{ marginLeft: '10px' }}
                  color="error"
                  endIcon={<CheckIcon />}
                  onClick={() => {
                    setShowInputReason(true);
                  }}
                >
                  Cancelar Agenda
                </Button>
                <Button
                  variant="contained"
                  endIcon={<CheckIcon />}
                  sx={{ marginRight: '10px' }}
                  onClick={() => {
                    setReschedule(true);
                  }}
                >
                  Reagendar
                </Button>
              </>
            )}
            {showInputReason && !reschedule && (
              <Button
                variant="contained"
                endIcon={<CheckIcon />}
                sx={{ marginRight: '10px' }}
                onClick={() => {
                  handleCancelSchedule();
                }}
              >
                Cancelar Agenda
              </Button>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};
