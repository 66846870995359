import CancelIcon from '@mui/icons-material/Cancel';
import ErrorIcon from '@mui/icons-material/Error';
import React, { useEffect, useRef, useState } from 'react';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HelpIcon from '@mui/icons-material/Help';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardBackspace from '@mui/icons-material/KeyboardBackspace';
import CameraIcon from '@mui/icons-material/CameraAlt';
import {
  CharCount,
  Container,
  ContainerButtons,
  ContainerHeader,
  ScreenshotContainer,
  SubTitle,
} from './styles';
import { Controller, useForm } from 'react-hook-form';
import { Button, IconButton, TextField } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import html2canvas from 'html2canvas';
import { ButtonIcon } from '../../ModalStatus/ModalStatus.style';
import api from '../../../api';
import { toast } from 'react-toastify';

type HelperProps = {
  enabled;
  setEnabled;
  type: 'TIP' | 'SUPPORT';
};

const schema = Yup.object({
  descricao: Yup.string()
    .required('Campo Obrigatório')
    .max(410, 'Máximo De Caracteres Atingido'),
});

export const HelperSendingBox = ({
  enabled,
  setEnabled,
  type,
}: HelperProps) => {
  const [isSending, setIsSending] = useState(false);
  const [isScreenshoting, setIsScreenshoting] = useState(false);

  const [screenshots, setScreenshots] = useState<any[]>([]);
  const [screenshotError, setScreenshotError] = useState(false);
  const {
    handleSubmit,
    setValue,
    formState: { errors },
    control,
    watch,
    clearErrors,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const rhf = watch();

  const ScreenshotBox = ({ screenshot, idx }) => {
    return (
      <ScreenshotContainer>
        <img src={screenshot} alt="Captura de tela" />
        <p>Captura de Tela {idx + 1}</p>
        <CloseIcon
          style={{ color: '#f00' }}
          onClick={() =>
            setScreenshots((prev) => prev.filter((v, i) => i !== idx))
          }
        />
      </ScreenshotContainer>
    );
  };

  const onSubmit = async (data) => {
    setIsSending(true);

    const formData = new FormData();
    for (let i = 0; i < screenshots.length; i++) {
      const blob = await fetch(screenshots[i]).then((res) => res.blob());
      const file = new File([blob], `screenshot${i + 1}.png`, {
        type: 'image/png',
      });
      formData.append('images', file);
    }
    formData.append('type', type);
    formData.append('description', data.descricao);
    formData.append('current_url', window.location.href);

    try {
      const response = await api.post('/suporte/send', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      if (!response?.data?.created) {
        throw new Error(response?.data || response);
      }
      setValue('descricao', '');
      setScreenshots([]);
      toast.success(
        `Enviado com Sucesso!\n Nossa equipe irá analisar sua ${
          type === 'SUPPORT' ? 'solicitação' : 'sugestão'
        } em breve!`
      );
      setEnabled(false);
    } catch (error) {
      toast.error(
        "Houve um erro no envio.\nProcure o suporte via E-mail 'suporte.jordana@digitalcare2u.com'"
      );
    } finally {
      setIsScreenshoting(false);
      setScreenshotError(false);
      setIsSending(false);
    }
  };

  const takeScreenShot = async () => {
    if (screenshots.length >= 3) {
      setScreenshotError(true);
      return;
    }
    setIsScreenshoting(true);
    setTimeout(async () => {
      const mainElement = document.querySelector('html') as HTMLElement;
      const canvas = await html2canvas(mainElement, {
        width: window.innerWidth,
        height: window.innerHeight,
        x: window.scrollX,
        y: window.scrollY,
        scale: 2, // aumenta a resolução (se ocorrer alguma inconsistencia na foto pode ser isso)
      });
      const imgData = canvas.toDataURL('image/png');
      setScreenshots((prev) => [...prev, imgData]);
    }, 10);
    setTimeout(() => {
      setIsScreenshoting(false);
    }, 80);
  };

  useEffect(() => {
    setIsSending(false);
  }, []);
  useEffect(() => {
    if (screenshots.length < 3) setScreenshotError(false);
  }, [screenshots]);
  useEffect(() => {
    setScreenshotError(false);
    setScreenshots([]);
    setValue('descricao', '');
    setIsSending(false);
    setIsScreenshoting(false);
  }, [type]);

  if (!enabled) return <span></span>;
  return (
    <>
      <Container style={{ display: isScreenshoting ? 'none' : 'inherit' }}>
        <ContainerHeader>
          <KeyboardBackspace onClick={() => setEnabled((prev) => !prev)} />
          <p>{type === 'SUPPORT' ? 'Solicitar Suporte' : 'Deixar Sugestão'}</p>

          <CloseIcon onClick={() => setEnabled((prev) => !prev)} />
        </ContainerHeader>

        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Controller
            name="descricao"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                multiline
                disabled={isSending}
                error={!!errors.descricao}
                rows={3}
                sx={{ width: '100%' }}
                label={
                  type === 'SUPPORT'
                    ? 'Digite o motivo pelo qual precisa de suporte'
                    : 'Digite sua sugestão'
                }
              />
            )}
          />
          <CharCount
            style={{
              color:
                Number(rhf?.descricao?.length || '0') > 410 ? 'red' : 'black',
            }}
          >
            {rhf?.descricao?.length || '0'}/410 Caracteres
          </CharCount>

          <SubTitle>Capturas de Tela:</SubTitle>

          {!screenshots?.length && (
            <p style={{ fontSize: 12 }}>
              Aperte o ícone da câmera abaixo para registrar a tela da qual você
              precisa de suporte.
            </p>
          )}
          {screenshots.map((screenshot, idx) => {
            return (
              <ScreenshotBox screenshot={screenshot} key={idx} idx={idx} />
            );
          })}
          {screenshotError && (
            <p style={{ color: '#f00', fontStyle: 'italic', fontSize: 14 }}>
              Número máximo de capturas de tela.
            </p>
          )}
          <ContainerButtons>
            <IconButton
              sx={{
                marginTop: '10px',
                backgroundColor:
                  screenshotError && screenshots.length === 3
                    ? '#ff000042'
                    : '#00000042',
                borderRadius: '4px',
              }}
              disabled={isScreenshoting || isSending}
              onClick={() => takeScreenShot()}
              aria-label="Tirar ScreenShot"
            >
              <CameraIcon />
            </IconButton>
            <Button
              sx={{
                marginTop: '10px',
                width: '100%',
                backgroundColor: '#17bebb',
              }}
              disabled={isSending || isScreenshoting}
              variant="contained"
              type="submit"
              //startIcon={<CheckIcon />}
            >
              {isSending
                ? 'ENVIANDO...'
                : type === 'SUPPORT'
                ? 'ENVIAR SOLICITAÇÃO'
                : 'ENVIAR SUGESTÃO'}
            </Button>
          </ContainerButtons>
        </form>
      </Container>
    </>
  );
};
