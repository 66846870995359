import { yupResolver } from '@hookform/resolvers/yup';
import { Box, FormControl, Typography } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import logoJordana from '../../../../assets/imgs/logo.png';
import { ArrowForward } from '@mui/icons-material';
import {
  FormContainer,
  SendEmailButton,
  CancelButton,
  BoxInputButton,
} from './styles';

import { schemaChangePassword } from './schema';
import api from '../../../../api';
import { toast } from 'react-toastify';
import { InputPasswordRegistred } from '../../../../components/InputPassword/PasswordRegister';

export default function TypeNewPassword({ setPageCurrent, token }) {
  const {
    handleSubmit,
    formState: { errors },
    register,
    control,
  } = useForm<any>({
    resolver: yupResolver(schemaChangePassword),
  });

  let navigate = useNavigate();

  const onSubmit = async (data: any) => {
    await api
      .post(`/usuarios/changePassword/${token}`, {
        novaSenha: window.btoa(data.nova_senha),
      })
      .then((res) => {
        setPageCurrent('success');
      })
      .catch((err) => {
        if (err?.request?.status === 400 && err?.response?.data?.message)
          return toast.error(`Erro: ${err.response.data.message}`);
        if (err?.error?.message) {
          toast.error('Erro: ' + err.error.message);
          return;
        }
        toast.error('Erro no servidor, contacte o suporte!');
      });
  };

  const returnToSigninPage = () => {
    navigate('/');
  };

  return (
    <FormContainer variant="outlined">
      <Box py={3} px={3} textAlign="center">
        <img src={logoJordana} alt="logo jornada" width="181" />

        <Box my={4}>
          <Typography variant="h5" fontWeight="bold">
            Redefinição de senha
          </Typography>
          <Typography variant="body2" sx={{ color: '#00000099' }}>
            {'Insira uma nova senha para acessar o sistema'}
          </Typography>
        </Box>

        <Box>
          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <BoxInputButton>
              <FormControl sx={{ width: '100%' }}>
                <Controller
                  control={control}
                  name="nova_senha"
                  render={() => (
                    <InputPasswordRegistred
                      registerName="nova_senha"
                      sx={{ marginBottom: '24px' }}
                      register={register}
                      label="Nova senha"
                      size="small"
                      error={!!errors.nova_senha || errors.confirme_nova_senha}
                    />
                  )}
                />
              </FormControl>
              <FormControl sx={{ width: '100%' }}>
                <Controller
                  control={control}
                  name="confirme_nova_senha"
                  render={() => (
                    <InputPasswordRegistred
                      registerName="confirme_nova_senha"
                      register={register}
                      label="Confirme a Nova Senha"
                      sx={{ marginBottom: '24px' }}
                      size="small"
                      error={!!errors.confirme_nova_senha}
                      helperText={errors.confirme_nova_senha?.message}
                    />
                  )}
                />
                <p
                  style={{
                    fontSize: '14px',
                    color: !!errors.nova_senha ? '#d32f2f' : '#00000099',
                  }}
                >
                  Sua senha deve ter no mínimo 8 caracteres, incluindo uma letra
                  maiúscula, uma minúscula, um número e um caractere especial
                </p>
              </FormControl>
              <SendEmailButton
                type="submit"
                variant="contained"
                endIcon={<ArrowForward />}
              >
                Alterar Senha
              </SendEmailButton>
              <CancelButton onClick={returnToSigninPage}>CANCELAR</CancelButton>
            </BoxInputButton>
          </form>
        </Box>
      </Box>
    </FormContainer>
  );
}
