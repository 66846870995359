import axios from 'axios';
import { useEffect, useState } from 'react';
import { SideMenu } from '../../components/SideMenu';
import { Container } from '@mui/material';
import ReturnButton from '../../components/ReturnButton';

const GetIp = () => {
  const [localIPAddress, setLocalIPAddress] = useState('Aguardando...');

  //   function getIPs(callback) {
  //     var ip_dups = {};

  //     // compatibility for firefox and chrome
  //     var RTCPeerConnection =
  //       window.RTCPeerConnection ||
  //       window.mozRTCPeerConnection ||
  //       window.webkitRTCPeerConnection;
  //     var useWebKit = !!window.webkitRTCPeerConnection;
  //     //bypass naive webrtc blocking using an iframe
  //     if (!RTCPeerConnection) {
  //       //NOTE: you need to have an iframe in the page right above the script tag // //
  //       <iframe
  //         id="iframe"
  //         sandbox="allow-same-origin"
  //         style={{ display: 'none' }}
  //       ></iframe>;
  //       //<script>...getIPs called in here... //
  //       var win = iframe.contentWindow;
  //       RTCPeerConnection =
  //         win.RTCPeerConnection ||
  //         win.mozRTCPeerConnection ||
  //         win.webkitRTCPeerConnection;
  //       useWebKit = !!win.webkitRTCPeerConnection;
  //     }
  //     //minimal requirements for data connection
  //     var mediaConstraints = { optional: [{ RtpDataChannels: true }] };
  //     var servers = { iceServers: [{ urls: 'stun:stun.services.mozilla.com' }] };
  //     //construct a new RTCPeerConnection
  //     var pc = new RTCPeerConnection(servers, mediaConstraints);
  //     function handleCandidate(candidate) {
  //       //match just the IP address
  //       var ip_regex =
  //         /([0-9]{1,3}(\.[0-9]{1,3}){3}|[a-f0-9]{1,4}(:[a-f0-9]{1,4}){7})/;
  //       var ip_addr = ip_regex?.exec(candidate)?.[1];
  //       //remove duplicates
  //       if (ip_dups[ip_addr] === undefined) callback(ip_addr);
  //       ip_dups[ip_addr] = true;
  //     } //listen for candidate events
  //     pc.onicecandidate = function (ice) {
  //       //skip non-candidate events
  //       if (ice.candidate) handleCandidate(ice.candidate.candidate);
  //     };
  //     //create a bogus data channel
  //     pc.createDataChannel('');
  //     //create an offer sdp
  //     pc.createOffer(
  //       function (result) {
  //         //trigger the stun server request
  //         pc.setLocalDescription(
  //           result,
  //           function () {},
  //           function () {}
  //         );
  //       },
  //       function () {}
  //     );
  //     //wait for a while to let everything done
  //     setTimeout(function () {
  //       //read candidate info from local description
  //       var lines = pc?.localDescription?.sdp.split('\n');
  //       lines?.forEach(function (line) {
  //         if (line.indexOf('a=candidate:') === 0) handleCandidate(line);
  //       });
  //     }, 1000);
  //   }
  //   //Test: Print the IP addresses into the console
  // function getIPs(callback: (ip: string) => void): void {
  //   const ip_dups: Record<string, boolean> = {};

  //   let RTCPeerConnection: any;
  //   const win = window as any;

  //   RTCPeerConnection = win.RTCPeerConnection;
  //   win.mozRTCPeerConnection;
  //   win.webkitRTCPeerConnection;

  //   let useWebKit = !!win.webkitRTCPeerConnection;

  //   if (!RTCPeerConnection) {
  //     const iframe = document.createElement('iframe');
  //     document.body.appendChild(iframe);
  //     const iframeWin: any = iframe.contentWindow;
  //     RTCPeerConnection = iframeWin.RTCPeerConnection;
  //     iframeWin.mozRTCPeerConnection;
  //     iframeWin.webkitRTCPeerConnection;
  //     useWebKit = !!iframeWin.webkitRTCPeerConnection;
  //   }

  //   const mediaConstraints = {
  //     optional: [{ RtpDataChannels: true }],
  //   };

  //   const servers = {
  //     iceServers: [{ urls: 'stun:stun.services.mozilla.com' }],
  //   };

  //   const pc = new RTCPeerConnection(servers, mediaConstraints);

  //   function handleCandidate(candidate: string): void {
  //     const ip_regex =
  //       /([0-9]{1,3}(\.[0-9]{1,3}){3}|[a-f0-9]{1,4}(:[a-f0-9]{1,4}){7})/;
  //     const ip_addr = ip_regex.exec(candidate)?.[1];

  //     if (ip_addr && ip_dups[ip_addr] === undefined) {
  //       callback(ip_addr);
  //     }

  //     ip_dups[String(ip_addr)] = true;
  //   }

  //   pc.onicecandidate = function (ice: { candidate: RTCIceCandidate }): void {
  //     if (ice.candidate) {
  //       handleCandidate(ice.candidate.candidate);
  //     }
  //   };

  //   pc.createDataChannel('');

  //   pc.createOffer(
  //     function (result: RTCSessionDescriptionInit) {
  //       pc.setLocalDescription(
  //         result,
  //         function () {},
  //         function () {}
  //       );
  //     },
  //     function () {}
  //   );

  //   setTimeout(function () {
  //     const lines = pc.localDescription?.sdp.split('\n') || [];

  //     lines.forEach(function (line) {
  //       if (line.indexOf('a=candidate:') === 0) {
  //         handleCandidate(line);
  //       }
  //     });
  //   }, 1000);
  // }

  // // Executar
  // function click(sender: any, e: any, eOpts: any): void {
  //   getIPs(function (ip: string): void {
  //     alert(ip);
  //   });
  // }

  //   useEffect(() => {
  //     getIPs(function (ip) {
  //     });
  //   }, []);

  return (
    <div>
      <h2>Seu endereço IP local é:</h2>
      <p>{localIPAddress}</p>
    </div>
  );
};

export default GetIp;
