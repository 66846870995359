import { TableCell, Typography } from '@mui/material';
import { formatCpf } from '../../../../../../utils/formatters';
import moment from 'moment';
import calculaIdade from '../../../../../../utils/calculaIdade';

const RowsPersonalData = ({ row }) => {
  return (
    <>
      <TableCell sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body1" fontSize="14px">
          {String(row?.realizou_histopatologia_pos_tratamento).toUpperCase()}
        </Typography>
      </TableCell>
      <TableCell sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body1" fontSize="14px">
          {String(row?.realizou_histopatologia_pre_tratamento).toUpperCase()}
        </Typography>
      </TableCell>
      <TableCell sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body1" fontSize="14px">
          {String(row?.paciente_nome).toUpperCase()}
        </Typography>
      </TableCell>
      <TableCell sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body1" fontSize="14px">
          {row?.paciente_cns}
        </Typography>
      </TableCell>
      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {row?.paciente_nome_mae}
        </Typography>
      </TableCell>
      <TableCell sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body1" fontSize="14px">
          {row?.paciente_cpf ? formatCpf(row?.paciente_cpf) : ''}
        </Typography>
      </TableCell>
      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {row?.paciente_data_nascimento}
        </Typography>
      </TableCell>
      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {row?.paciente_data_nascimento
            ? `${calculaIdade(
                moment(row.paciente_data_nascimento, 'DD/MM/YYYY').format(
                  'DD/MM/YYYY'
                )
              )} anos`
            : ''}
        </Typography>
      </TableCell>
      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {row?.paciente_raca}
        </Typography>
      </TableCell>
    </>
  );
};

export default RowsPersonalData;
