import { Button, Checkbox, Menu, MenuItem, TextField } from '@mui/material';
import { useRef, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import useStatesFields from '../../hooks/useStatesFields';
import useHandleSelect from '../../hooks/useHandleSelect';

const ModalAcs = ({
  acsSelected,
  arrayAcs,
  searchAcs,
  allAcsOptions,
  anchorElAcs,
  disableAcsInput,
  setAnchorElAcs,
  setArrayAcs,
  setSearchAcs,
  setAcsSelected,
  loadingAcs,
}) => {
  const { handleSelectMultiplesFields } = useHandleSelect();

  return (
    <>
      <TextField
        data-testid="modalAcsInput"
        size="small"
        disabled={disableAcsInput}
        label="Agente Comunitário de Saúde"
        value={
          acsSelected.length > 1
            ? 'Vários'
            : String(
                arrayAcs.find((item: any) => item?.id === acsSelected[0])
                  ?.pessoa?.nome ?? ''
              ).toUpperCase()
        }
        sx={{
          padding: 0,
          width: '100%',
          '.MuiOutlinedInput-root': {
            paddingRight: '9px',
          },
        }}
        InputProps={{
          endAdornment: (
            <Button
              data-testid="modalAcsDropdownButton"
              sx={{
                padding: 0,
                minWidth: '24px !important',
              }}
              disabled={disableAcsInput}
              onClick={(event) => {
                setAnchorElAcs(event.currentTarget);
              }}
            >
              <ArrowDropDownIcon
                sx={{
                  cursor: 'pointer',
                  color: disableAcsInput ? '#BDBDBD' : 'gray',
                }}
              />
            </Button>
          ),
        }}
      />

      <Menu
        anchorEl={anchorElAcs}
        open={!!anchorElAcs}
        onClose={() => setAnchorElAcs(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          position: 'absolute',
          width: '100%',
          marginTop: '10px',
          left: '10px',

          '.MuiList-root': {
            paddingTop: 0,
            paddingBottom: 0,
          },
        }}
      >
        <div
          style={{
            width: '100%',

            padding: '24px 32px',
            flexDirection: 'column',
          }}
        >
          <TextField
            size="small"
            placeholder="Pesquise por Nome da Equipe"
            onChange={(e) => setSearchAcs(e.target.value)}
            value={searchAcs}
            sx={{
              width: '289px',
              '.MuiOutlinedInput-root': {
                paddingRight: '9px',
              },
            }}
            onClick={() => {
              if (!searchAcs) {
                setArrayAcs(allAcsOptions);
              } else {
                const newArrEquipes = allAcsOptions.filter((equipe) =>
                  equipe.nome.toLowerCase().includes(searchAcs.toLowerCase())
                );

                setArrayAcs(newArrEquipes);
              }
            }}
            InputProps={{
              endAdornment: (
                <Button
                  sx={{
                    padding: 0,
                    minWidth: '24px !important',
                  }}
                >
                  <SearchIcon
                    sx={{
                      cursor: 'pointer',
                      color: 'gray',
                    }}
                  />
                </Button>
              ),
            }}
          />
          <div>
            <Button
              variant="text"
              sx={{ padding: '0' }}
              onClick={() => {
                const allItemsArray = allAcsOptions.map((acs: any) => {
                  return acs.pessoa.nome;
                });

                setAcsSelected(allItemsArray);
              }}
            >
              SELECIONAR TODOS -{' '}
            </Button>
            <Button
              sx={{ padding: '0' }}
              variant="text"
              onClick={() => setAcsSelected([])}
            >
              LIMPAR
            </Button>
          </div>
          {arrayAcs?.map((acs: any) => {
            const objectValue = {
              nome: acs?.pessoa?.nome,
              id: acs?.id,
            };

            return (
              <div style={{ display: 'flex' }}>
                <Checkbox
                  onChange={() => {
                    handleSelectMultiplesFields({
                      paramToSave: 'id',
                      setState: setAcsSelected,
                      state: acsSelected,
                      value: objectValue,
                    });
                  }}
                  checked={
                    acsSelected.find((item) => item === acs?.id) ? true : false
                  }
                />
                <MenuItem
                  sx={{ padding: 0 }}
                  onClick={() => {
                    handleSelectMultiplesFields({
                      paramToSave: 'id',
                      setState: setAcsSelected,
                      state: acsSelected,
                      value: objectValue,
                    });
                  }}
                >
                  {String(acs?.pessoa?.nome).toUpperCase()}
                </MenuItem>
              </div>
            );
          })}
        </div>
      </Menu>
    </>
  );
};

export default ModalAcs;
