import { Option } from '../types/Option';

export const origemExame = [
  {
    label: 'Biópsia',
    value: 1,
  },
  {
    label: 'Peça Cirúrgica',
    value: 2,
  },
];
