import { Visibility, VisibilityOff } from '@mui/icons-material';
import { TextField } from '@mui/material';
import { useState } from 'react';

export const InputPasswordRegistred = (props: any) => {
  const [inputTypePassword, setInputTypePassword] = useState('password');

  const handleVisibilityPassword = () => {
    if (inputTypePassword === 'text') {
      setInputTypePassword('password');
    } else {
      setInputTypePassword('text');
    }
  };

  const iconProps = {
    onClick: handleVisibilityPassword,
    sx: {
      color: '#50596F',
      padding: 0,
      border: 'none',
      background: 'none',
      cursor: 'pointer',
    },
  };

  return (
    <TextField
      {...props}
      type={inputTypePassword}
      fullWidth
      label={props.label}
      size="medium"
      variant="outlined"
      {...props.register(props.registerName, { required: true })}
      InputProps={{
        endAdornment:
          inputTypePassword === 'password' ? (
            <VisibilityOff {...iconProps} />
          ) : (
            <Visibility {...iconProps} />
          ),
      }}
    />
  );
};
