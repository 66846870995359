import { Box, Grid, Typography } from '@mui/material';

const AddresInformation = ({ addressData }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
      data-testid='address-box'
    >
      <Grid container mb={3}>
        <Grid item xs={12} md={2.4}>
          <Typography sx={{ fontSize: '10px', color: '#555555' }}>
            CEP
          </Typography>
          <Typography sx={{ fontSize: '14px', fontWeight: 600 }} data-testid='address-cep'>
            {addressData.data?.endereco?.cep}
          </Typography>
        </Grid>
        <Grid item xs={12} md={3}>
          <Typography sx={{ fontSize: '10px', color: '#555555' }}>
            Logradouro
          </Typography>
          <Typography sx={{ fontSize: '14px', fontWeight: 600 }} data-testid='address-logradouro'>
            {addressData.data?.endereco?.logradouro}
          </Typography>
        </Grid>
        <Grid item xs={12} md={1.8}>
          <Typography sx={{ fontSize: '10px', color: '#555555' }}>
            Número
          </Typography>
          <Typography sx={{ fontSize: '14px', fontWeight: 600 }} data-testid='address-numero'>
            {addressData.data?.endereco?.numero}
          </Typography>
        </Grid>
        <Grid item xs={12} md={2.4}>
          <Typography sx={{ fontSize: '10px', color: '#555555' }}>
            Complemento
          </Typography>
          <Typography sx={{ fontSize: '14px', fontWeight: 600 }} data-testid='address-complemento'>
            {addressData.data?.endereco?.complemento}
          </Typography>
        </Grid>
        <Grid item xs={12} md={2.4}>
          <Typography sx={{ fontSize: '10px', color: '#555555' }}>
            Bairro
          </Typography>
          <Typography sx={{ fontSize: '14px', fontWeight: 600 }} data-testid='address-bairro'>
            {addressData.data?.endereco?.bairro}
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} md={5.4}>
          <Typography sx={{ fontSize: '10px', color: '#555555' }}>
            Ponto de Referência
          </Typography>
          <Typography sx={{ fontSize: '14px', fontWeight: 600 }} data-testid='address-ref'>
            {addressData.data?.endereco?.referencia}
          </Typography>
        </Grid>

        <Grid item xs={12} md={1.8}>
          <Typography sx={{ fontSize: '10px', color: '#555555' }}>
            UF
          </Typography>
          <Typography sx={{ fontSize: '14px', fontWeight: 600 }} data-testid='address-uf'>
            {addressData.data?.endereco?.municipio?.estado?.sigla}
          </Typography>
        </Grid>

        <Grid item xs={12} md={2.4}>
          <Typography sx={{ fontSize: '10px', color: '#555555' }}>
            Município
          </Typography>
          <Typography sx={{ fontSize: '14px', fontWeight: 600 }} data-testid='address-municipio'>
            {addressData.data?.endereco?.municipio?.nome}
          </Typography>
        </Grid>
        <Grid item xs={12} md={2.4}>
          <Typography sx={{ fontSize: '10px', color: '#555555' }}>
            Zona
          </Typography>
          <Typography sx={{ fontSize: '14px', fontWeight: 600 }} data-testid='address-zona'>
            {addressData.data?.endereco?.zona}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AddresInformation;
