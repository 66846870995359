import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import { Controller } from 'react-hook-form';

import Add from '@mui/icons-material/Add';
import StarIcon from '@mui/icons-material/Star';
import ClearIcon from '@mui/icons-material/Clear';

import MaskedInput, { MaskedInputProps } from 'react-text-mask';
import { StyledAsteriskTextField } from '../../../../components/StyledAsteriskTextField/styles';
import { useStyles } from '../../RegisterPatient.style';
import { phoneMask } from '../../../../utils/phoneMask';
import { ContatoPreferencial } from '../../types';
import { useHandlers } from '../../hooks/useHandlers';

const ContactInformation = ({
  addContato,
  contatos,
  watch,
  setValue,
  control,
  errors,
  setIndexRemoveContato,
  setOpenDialogRemoveContato,
}) => {
  const styles = useStyles();

  const { handleClickOpenDialogRemoveContato, handleClickOpenDialogCancel } =
    useHandlers();

  return (
    <Box sx={{ width: '100%', marginTop: '24px' }}>
      <Paper
        elevation={0}
        sx={{
          width: '100%',
          border: `1px solid RGBA(0, 0, 0, 0.23)`,
          borderRadius: '10px',
        }}
      >
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          style={{ padding: '24px 0 24px 24px', gap: 4 }}
        >
          <Grid item>
            <Typography variant="h6" fontWeight="bold">
              Dados de Contato
            </Typography>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              startIcon={<Add />}
              onClick={addContato}
              style={{
                backgroundColor: '#238884',
                color: '#FFFFFF',
                marginRight: '25px',
              }}
            >
              Adicionar forma de contato
            </Button>
          </Grid>
        </Grid>
        <Divider variant="middle" sx={{ width: '96%', margin: '0 auto' }} />
        <Box m={3} pb={3} sx={{ flexDirection: 'column' }}>
          <Grid container>
            {contatos.length > 0 ? (
              contatos?.map((contato: any, i) => {
                const formaContato = watch(
                  `contatos.${i}.forma_contato` as 'contatos.0.forma_contato'
                );
                const contatoFavorito = watch(
                  `contatos.${i}.is_favorito` as 'contatos.0.is_favorito'
                );
                return (
                  <Grid container key={contato.id} spacing={2} my={1}>
                    <Grid item xs={12} md={0.5}>
                      <Controller
                        render={({ field: { onChange, value } }) => (
                          <Checkbox
                            sx={{
                              backgroundColor: `${
                                contatoFavorito ? '#dbefef' : 'transparent'
                              }}`,
                            }}
                            checkedIcon={
                              contatoFavorito ? (
                                <StarIcon sx={{ color: '#238884' }} />
                              ) : (
                                <StarIcon sx={{ color: '#bdbdbd' }} />
                              )
                            }
                            indeterminateIcon={
                              contatoFavorito ? (
                                <StarIcon sx={{ color: '#238884' }} />
                              ) : (
                                <StarIcon sx={{ color: '#bdbdbd' }} />
                              )
                            }
                            icon={<StarIcon sx={{ color: '#bdbdbd' }} />}
                            checked={contatoFavorito}
                            value={value ? value : ''}
                            onChange={(e) => {
                              contatos.forEach((contato, index) => {
                                setValue(
                                  `contatos.${index}.is_favorito` as 'contatos.0.is_favorito',
                                  false,
                                  {}
                                );
                              });
                              onChange(e.target.checked);
                            }}
                          />
                        )}
                        name={
                          `contatos.${i}.is_favorito` as 'contatos.0.is_favorito'
                        }
                        control={control}
                      />
                    </Grid>
                    <Grid item xs={12} md={2}>
                      <Controller
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            defaultValue="EMAIL"
                            label="Forma de Contato"
                            select
                            fullWidth
                            size="small"
                            variant="outlined"
                            className={styles.textField}
                            value={value}
                            onChange={(e) => {
                              onChange(e);
                              setValue(
                                `contatos.${i}.descricao` as 'contatos.0.descricao',
                                '',
                                {}
                              );
                            }}
                          >
                            <MenuItem value="TELEFONE">Telefone</MenuItem>
                            <MenuItem value="EMAIL">E-mail</MenuItem>
                          </TextField>
                        )}
                        name={
                          `contatos.${i}.forma_contato` as 'contatos.0.forma_contato'
                        }
                        control={control}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      {formaContato === 'TELEFONE' ? (
                        <>
                          <Controller
                            render={({
                              field: { onChange, value: valueInput },
                            }) => {
                              return (
                                <TextField
                                  label={
                                    formaContato.charAt(0) +
                                    formaContato.slice(1).toLowerCase()
                                  }
                                  onChange={(e) => {
                                    const phone = e.target.value.replace(
                                      /[^0-9]/g,
                                      ''
                                    );

                                    onChange(
                                      phone.replace(
                                        /^(\d{2})(\d{5})(\d{4})$/,
                                        '($1) $2-$3'
                                      )
                                    );
                                    const fone = phoneMask(e.target.value);

                                    setValue(
                                      `contatos.${i}.foneZap` as 'contatos.0.foneZap',
                                      contato.isWhatsapp ? fone : null
                                    );
                                  }}
                                  defaultValue={valueInput}
                                  value={valueInput}
                                  inputProps={{ maxLength: 15 }}
                                  fullWidth
                                  size="small"
                                  variant="outlined"
                                  className={styles.textField}
                                  type="text"
                                  error={!!errors?.contatos?.[i]?.descricao}
                                  helperText={
                                    errors?.contatos?.[i]?.descricao?.message
                                  }
                                />
                              );
                            }}
                            name={
                              `contatos.${i}.descricao` as 'contatos.0.descricao'
                            }
                            control={control}
                          />
                          <span
                            style={{
                              color: 'rgba(0,0,0,0.6)',
                              fontSize: '0.8rem',
                            }}
                          >
                            <Controller
                              control={control}
                              name={
                                `contatos.${i}.isWhatsapp` as 'contatos.0.isWhatsapp'
                              }
                              render={({ field: { onChange, value } }) => (
                                <Checkbox
                                  defaultChecked
                                  sx={{
                                    color: '#238884',
                                    '&.Mui-checked': {
                                      color: '#238884',
                                    },
                                  }}
                                  value={value ? value : ''}
                                  onChange={(e, v) => {
                                    onChange(v);
                                    setValue(
                                      `contatos.${i}.foneZap` as 'contatos.0.foneZap',
                                      v ? contato.descricao : null
                                    );
                                  }}
                                />
                              )}
                            />
                            Esse número é Whatsapp?
                          </span>
                        </>
                      ) : (
                        <Controller
                          render={({ field: { onChange, value } }) => (
                            <TextField
                              onChange={onChange}
                              value={value ? value : ''}
                              label={
                                formaContato.charAt(0) +
                                formaContato.slice(1).toLowerCase()
                              }
                              fullWidth
                              size="small"
                              variant="outlined"
                              className={styles.textField}
                              type="email"
                              error={!!errors?.contatos?.[i]?.descricao}
                              helperText={
                                errors?.contatos?.[i]?.descricao?.message
                              }
                            />
                          )}
                          name={
                            `contatos.${i}.descricao` as 'contatos.0.descricao'
                          }
                          control={control}
                        />
                      )}
                    </Grid>
                    <Grid item xs={12} md={5}>
                      <Controller
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            label="Observações Adicionais"
                            fullWidth
                            size="small"
                            variant="outlined"
                            className={styles.textField}
                            value={value ? value : ''}
                            onChange={onChange}
                          />
                        )}
                        name={
                          `contatos.${i}.observacao` as 'contatos.0.observacao'
                        }
                        control={control}
                      />
                      <i
                        style={{
                          color: 'rgba(0,0,0,0.6)',
                          fontSize: '0.8rem',
                          textDecoration: 'italic',
                        }}
                      >
                        Ex: Nome do dono do contato caso não seja próprio ou
                        melhor horário de contato
                      </i>
                    </Grid>
                    <Grid item xs={6} md={1.5}>
                      <Button
                        fullWidth
                        startIcon={<ClearIcon />}
                        onClick={() => {
                          setIndexRemoveContato(i);
                          handleClickOpenDialogRemoveContato(
                            setOpenDialogRemoveContato
                          );
                        }}
                        style={{
                          color: '#e31b0c',
                          backgroundColor: 'white',
                          fontWeight: 'bold',
                        }}
                      >
                        Remover
                      </Button>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                  </Grid>
                );
              })
            ) : (
              <Grid item xs={12}>
                <Alert severity="info">
                  Nenhuma forma de contato cadastrada{' '}
                </Alert>
              </Grid>
            )}
            <Grid item xs={12} my={2}>
              <Grid mt={2}>
                <FormControl error={!!errors.contato_preferencial}>
                  <FormLabel sx={{ '&.Mui-focused': { color: '#238884' } }}>
                    Forma de contato preferencial
                  </FormLabel>
                  <Controller
                    render={({ field: { onChange, value } }) => (
                      <RadioGroup
                        onChange={onChange}
                        value={value ? value : ''}
                        row
                      >
                        <FormControlLabel
                          value={ContatoPreferencial.Whatsapp}
                          control={<Radio className={styles.radioButton} />}
                          label="WhatsApp"
                        />
                        <FormControlLabel
                          value={ContatoPreferencial.Ligacao}
                          control={<Radio className={styles.radioButton} />}
                          label="Ligação"
                        />
                        <FormControlLabel
                          value={ContatoPreferencial.SMS}
                          control={<Radio className={styles.radioButton} />}
                          label="SMS"
                        />
                        <FormControlLabel
                          value={ContatoPreferencial.Email}
                          control={<Radio className={styles.radioButton} />}
                          label="E-mail"
                        />
                        <FormControlLabel
                          value={ContatoPreferencial.Presencial}
                          control={<Radio className={styles.radioButton} />}
                          label="Contato presencial com profissional"
                        />
                        <FormControlLabel
                          value={ContatoPreferencial.Carta}
                          control={<Radio className={styles.radioButton} />}
                          label="Carta"
                        />
                      </RadioGroup>
                    )}
                    name="contato_preferencial"
                    control={control}
                  />
                  <FormHelperText>
                    {errors.contato_preferencial?.message}
                  </FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Box>
  );
};

export default ContactInformation;
