import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CustomTablePagination from '../../CustomTablePagination';
import { useNavigate } from 'react-router-dom';
import { PerfilContext } from '../../../context/PerfilContext';

interface Column {
  id: 'municipio' | 'coloposcopia_encaminhamento_biopsia' | 'histopatologia_realizadas' | 'histopatologia_a_realizar';
  label: string;
  minWidth?: number;
  align?: 'right';
  format?: (value: number) => string;
}

const columns: readonly Column[] = [
  { id: 'municipio', label: 'Município', minWidth: 170 },
  { id: 'coloposcopia_encaminhamento_biopsia', label: 'Coloscopia com Encaminhamento para Biopsia', minWidth: 170 },
  { id: 'histopatologia_realizadas', label: 'Histopatologia Realizadas', minWidth: 170 },
  { id: 'histopatologia_a_realizar', label: 'Histopatologia a Realizar', minWidth: 170 },
];

export interface ResponseData {
  count: number;
  data: [];
  page: number;
  totalItems: number;
  totalPages: number;
}

export default function TableExamesHistopatologia(props) {
  const navigate = useNavigate();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(8);
  const { hasPermission } = React.useContext(PerfilContext);

  const handleChangePage = (event: unknown, newPage: number) => {
    console.log('Changing page to:', newPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    console.log('Changing rows per page to:', event.target.value);
    setRowsPerPage(Number(event.target.value));
    setPage(0);
  };

  const calcularTotal = (campo) => {
    return props.data?.reduce((acc, row) => acc + (row[campo] || 0), 0);
  };

  // Calcular os índices de início e fim
  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const currentData = props.data.slice(startIndex, endIndex);

  return (
    <Box className="table-container">
      <Paper sx={{ width: '100%', mb: 2, pt: 2 }}>
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={'medium'}
            className="table"
          >
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth, fontWeight: 'bold' }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {currentData.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={4}>Sem Resultados</TableCell>
                </TableRow>
              ) : (
                <>
                  {currentData.map((row: any, index: number) => {
                    return (
                      <TableRow hover tabIndex={-1} key={index}>
                        <TableCell component="th" scope="row">
                          {row.municipio}
                        </TableCell>
                        <TableCell>
                          {row.coloposcopia_encaminhamento_biopsia}
                        </TableCell>
                        <TableCell>
                          {row.histopatologia_realizadas}
                        </TableCell>
                        <TableCell>
                          {row.histopatologia_a_realizar}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  <TableRow>
                    <TableCell align="right" style={{ fontWeight: 'bold' }}>Total</TableCell>
                    <TableCell style={{ fontWeight: 'bold' }}>{calcularTotal('coloposcopia_encaminhamento_biopsia')}</TableCell>
                    <TableCell style={{ fontWeight: 'bold' }}>{calcularTotal('histopatologia_realizadas')}</TableCell>
                    <TableCell style={{ fontWeight: 'bold' }}>{calcularTotal('histopatologia_a_realizar')}</TableCell>
                  </TableRow>
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <CustomTablePagination
          rowsPerPageOptions={[8, 16, 24]}
          component="div"
          count={props.data.length}
          page={page}
          rowsPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={'Itens por página'}
          labelDisplayedRows={({ from, to, count }) => {
            return `${from}–${to} de ${
              count !== -1 ? count : `mais do que ${to}`
            }`;
          }}
        />
      </Paper>
    </Box>
  );
}