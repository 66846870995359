import * as Yup from 'yup';
export const schema = Yup.object({
  dt_realizado: Yup.date().required('Campo Obrigatório'),
  estabelecimentoSaudeId: Yup.number().required('Campo Obrigatório'),
  indicacao_ezt: Yup.number().required('Campo Obrigatório'),
  realizado_in: Yup.number().required('Campo Obrigatório'),
  tipo_exerese_ezt: Yup.number().required('Campo Obrigatório'),
  //   anestesia_local: Yup.boolean().required('Campo Obrigatório'),
  //   anestesia_sedacao: Yup.boolean().required('Campo Obrigatório'),
  //   anestesia_raqui: Yup.boolean().required('Campo Obrigatório'),
  //   anestesia_outras: Yup.boolean().required('Campo Obrigatório'),
  anestesia_outras_desc: Yup.string(),
  tipo_procedimento: Yup.number().required('Campo Obrigatório'),
  profissionalId: Yup.object({
    idprofissional: Yup.number().required('Campo Obrigatório'),
  }),
});
