import { TableCell, Typography } from '@mui/material';
import { formatCpf } from '../../../../../../utils/formatters';
import moment from 'moment';
import calculaIdade from '../../../../../../utils/calculaIdade';

const RowsPersonalData = ({ row }) => {
  return (
    <>
      <TableCell sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body1" fontSize="14px">
          {String(row?.procedimento?.pessoa?.nome)?.toUpperCase()}
        </Typography>
      </TableCell>
      <TableCell sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body1" fontSize="14px">
          {row?.procedimento?.pessoa?.cns}
        </Typography>
      </TableCell>
      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {row?.procedimento?.pessoa?.nome_mae}
        </Typography>
      </TableCell>
      <TableCell sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body1" fontSize="14px">
          {row?.procedimento?.pessoa?.cpf
            ? formatCpf(row?.procedimento?.pessoa?.cpf)
            : ''}
        </Typography>
      </TableCell>
      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {moment(row?.procedimento?.pessoa?.dt_nascimento).format(
            'DD/MM/YYYY'
          )}
        </Typography>
      </TableCell>
      <TableCell sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body1" fontSize="14px">
          {row?.procedimento?.pessoa?.dt_nascimento
            ? `${calculaIdade(
                moment(row.procedimento.pessoa.dt_nascimento).format(
                  'DD/MM/YYYY'
                )
              )} anos`
            : ''}
        </Typography>
      </TableCell>
      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {row?.procedimento?.pessoa?.nacionalidade || ''}
        </Typography>
      </TableCell>
      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {row?.procedimento?.pessoa?.raca || ''}
        </Typography>
      </TableCell>
    </>
  );
};

export default RowsPersonalData;
