import { useContext } from 'react';
import { getReportsHPV } from '../../../../../lib/Reports/getReportsHPV';
import { LoadingContext } from '../../../../../context/LoadingContext';

const useRequestFilter = ({ setTableData }) => {
  const { setLoading } = useContext(LoadingContext);
  const loadReportsHpv = async (payload) => {
    const response = await getReportsHPV({
      payload,
    });

    setTableData(response);
    setLoading(false);
  };

  return {
    loadReportsHpv,
  };
};

export default useRequestFilter;
