
import styled from '@emotion/styled';
import { Box } from '@mui/material';

export const Container = styled(Box)`
  width: 375px;
  height: 205px;
  background-color: #016460;
  color: #fff;
  border-radius: 8px;
  padding: 24px;
  box-shadow: 0px 1px 18px 0px #0000001F;
  box-shadow: 0px 6px 10px 0px #00000024;
  box-shadow: 0px 3px 5px -1px #00000033;

  /* border-radius: 10px; */
  /* margin: 0.5rem; */
  /* box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);  */
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  right: 0;
  gap: 8px;
  margin-top: 75px;
  margin-right: 48px;

  @media (max-width: 700px) {
    margin-right: 0;
    width: 80vw;
    margin: 75px 10vw;
    padding: 16px;
  }
`

export const ContainerHeader = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  p {
    width: 100%;
    text-align: center;
    margin: 0;
    font-weight: bold;
    font-size: 1rem;
  }
  svg {
    width: 32px;
    height: 32px;
    cursor: pointer;
  }

`

export const CardContainer = styled(Box)`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
`

interface CardHelperProps {
  disabled?: boolean;
}
export const CardHelper = styled.div<CardHelperProps>`
  width: 90px;
  height: 105px;
  background-color: #fff;
  cursor: pointer;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  border-radius: 4px;
  gap: 4px;

  filter: brightness(${({disabled}) =>  disabled ? .7 : 1});

  >svg {
    min-width: 32px;
    min-height: 32px;
  }

  &:hover {
    filter: brightness(${({disabled}) =>  disabled ? .7 : .9});
  }
`