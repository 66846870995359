import { useContext } from 'react';
import { EstablishmentContext } from '../../../../../context/EstablishmentContext';

const useClearValues = ({
  permissao,
  setValue,
  handleSetFilter,
  watch,
  loadReportsConvocatoria,
}) => {
  const fieldsToClearWhenIsPadrao: any[] = [
    'motivo',
    'idade_ini',
    'idade_fin',
    'realizou_atendimento',
    'atendimento_status',
    'inspecao_colo',
    'responsavel_coleta',
    'dt_coleta',
    'resultado',
    'responsavel_laudo',
    'dt_resultado',
    'search',
  ];

  const fieldsToClearWhenIsMunicipal = [
    'estabelecimento_saude',
    ...fieldsToClearWhenIsPadrao,
  ];

  const fieldsToClearWhenEstadual = [
    'municipio',
    ...fieldsToClearWhenIsMunicipal,
  ];

  const fieldsToClearWhenSuperAdmin = ['estado', ...fieldsToClearWhenEstadual];

  const { currentEstablishment } = useContext(EstablishmentContext);

  const clearValues = () => {
    if (permissao === 'isPadrao') {
      fieldsToClearWhenIsPadrao.forEach((field) => {
        setValue(field, '');
        handleSetFilter('', field);
      });

      const payload = {
        estado: currentEstablishment.endereco.municipio.estado.nome,
        municipio: currentEstablishment.endereco.municipio.nome,
        estabelecimentoId: currentEstablishment.id,
        size: watch().size,
        page: watch().page,
      };

      loadReportsConvocatoria(payload);
    }

    if (permissao === 'isMunicipial') {
      fieldsToClearWhenIsMunicipal.forEach((field) => {
        setValue(field, '');
        handleSetFilter('', field);
      });

      const payload = {
        estado: currentEstablishment.endereco.municipio.estado.nome,
        municipio: currentEstablishment.endereco.municipio.nome,
        size: watch().size,
        page: watch().page,
      };

      loadReportsConvocatoria(payload);
    }

    if (permissao === 'isEstadual') {
      fieldsToClearWhenEstadual.forEach((field) => {
        setValue(field, '');
        handleSetFilter('', field);
      });

      const payload = {
        estado: currentEstablishment.endereco.municipio.estado.nome,
        size: watch().size,
        page: watch().page,
      };

      loadReportsConvocatoria(payload);
    }

    if (permissao === 'isSuperAdmin') {
      fieldsToClearWhenSuperAdmin.forEach((field) => {
        setValue(field, '');
        handleSetFilter('', field);
      });

      const payload = {
        size: watch().size,
        page: watch().page,
      };

      loadReportsConvocatoria(payload);
    }
  };

  return { clearValues };
};

export default useClearValues;
