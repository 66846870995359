import { Box, Grid, Typography } from '@mui/material';
import { Label } from '../../Typo';

const BindingData = ({ bindingData }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
      data-testid='linking-box'
    >
      <Grid container mb={3}>
        <Grid item xs={12} md={2.4}>
          <Label>CNS do Profissional</Label>
          <Typography variant="body1" sx={{ color: '#000', marginTop: '4px' }} data-testid='linking-cns'>
            {bindingData.cns || 'S/N'}
          </Typography>
        </Grid>
        <Grid item xs={12} md={2.4}>
          <Label>CBO do Profissional</Label>
          <Typography variant="body1" sx={{ color: '#000', marginTop: '4px' }}  data-testid='linking-cbo'>
            {bindingData.cbo || 'S/N'}
          </Typography>
        </Grid>
        <Grid item xs={12} md={2.4}>
          <Label>INE da Equipe</Label>
          <Typography variant="body1" sx={{ color: '#000', marginTop: '4px' }}  data-testid='linking-ine'>
            {bindingData.ine || 'S/N'}
          </Typography>
        </Grid>
        <Grid item xs={12} md={2.4}>
          <Label>Área</Label>
          <Typography variant="body1" sx={{ color: '#000', marginTop: '4px' }}  data-testid='linking-area'>
            {bindingData.area || 'S/N'}
          </Typography>
        </Grid>
        <Grid item xs={12} md={2.4}>
          <Label>CNES do Estabelecimento</Label>
          <Typography variant="body1" sx={{ color: '#000', marginTop: '4px' }}  data-testid='linking-cnes'>
            {bindingData.cnes || 'S/N'}
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} md={2.4}>
          <Label>Nome do Profissional</Label>
          <Typography variant="body1" sx={{ color: '#000', marginTop: '4px' }}  data-testid='linking-nome'>
            {bindingData.nomeProfissional || 'S/N'}
          </Typography>
        </Grid>
        <Grid item xs={12} md={2.4}>
          <Label>Tipo de Profissional</Label>
          <Typography variant="body1" sx={{ color: '#000', marginTop: '4px' }}  data-testid='linking-tipo'>
            {bindingData.cargoProfissional || 'Desconhecido'}
          </Typography>
        </Grid>
        <Grid item xs={12} md={2.4}>
          <Label>Nome da Equipe</Label>
          <Typography variant="body1" sx={{ color: '#000', marginTop: '4px' }} data-testid='linking-equipe'>
            {bindingData.nomeEquipe || 'Desconhecido'}
          </Typography>
        </Grid>
        <Grid item xs={12} md={2.4}>
          <Label>Microárea</Label>
          <Typography variant="body1" sx={{ color: '#000', marginTop: '4px' }}  data-testid='linking-micro_area'>
            {bindingData.microarea || 'S/N'}
          </Typography>
        </Grid>
        <Grid item xs={12} md={2.4}>
          <Label>Nome do Estabelecimento</Label>
          <Typography variant="body1" sx={{ color: '#000', marginTop: '4px' }}  data-testid='linking-estabelecimento'>
            {bindingData.nomeEstabelecimento || 'Desconhecido'}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default BindingData;
