import { useContext, useEffect, useState } from 'react';
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Modal,
  Paper,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import RestoreIcon from '@mui/icons-material/Restore';
import ClearIcon from '@mui/icons-material/Clear';

import { Controller, useForm } from 'react-hook-form';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { ptBR } from 'date-fns/locale';
import { StyledAsteriskTextField } from '../StyledAsteriskTextField/styles';
import CloseIcon from '@mui/icons-material/Close';
import DocSvg from '../../assets/imgs/doc.svg';
import DocSvgDisabled from '../../assets/imgs/DocSvgDisabled.svg';

import './style.css';
import { ExamePendente } from '../NotificationModal';
import { Label } from '../Typo';
import React from 'react';
import api from '../../api';
import moment from 'moment';
import { Link } from 'react-router-dom';

import alert from '../../assets/imgs/alert.png';
import { LoadingContext } from '../../context/LoadingContext';

interface Props {
  examePendente: any;
  selectedGalDate: boolean;
  setSelectedGalDate: (value: boolean) => void;
  selectedJordDate: boolean;
  setSelectedJordDate: (value: boolean) => void;
  selectedDate: string | null | undefined;
  setSelectedDate: (value: string | null) => void;
  finalDate: string | null | undefined;
  setFinalDate: (value: string | null) => void;
  closeModal: (modalIsOpen: boolean) => void;
}

export const formatDate = (date?: string) => {
  if (!date) return '';
  return date.replace(/(\d{4})-(\d{2})-(\d{2})T.*/, '$3-$2-$1');
};

const NotificationModalHpvResult = (props: Props) => {
  const [opne, setOpen] = useState(false);
  const [optionsHpv, setOptionsHpv] = useState<any>([]);
  const [selectedHpv, setSelectedHpv] = useState<any>();
  const [dataColetaGal, setDataColetaGal] = useState<any>();
  const [dateJordana, setDateJordana] = useState('');
  const [selectedOriginDate, setSelectedOriginDate] = useState<any>(null);
  const [objHpv, setObjHpv] = useState<any>();
  const [listHpvs, setListHpvs] = useState<any>([]);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [disableButton, setDisableButton] = useState(true);
  const [modalToShow, setModalToShow] = useState('');
  const result = 'POSITIVO';
  // let control;
  const { control } = useForm();

  const resultado = (() => {
    if (props.examePendente.resultado === 'POSITIVO') {
      return 'Detectável';
    }
    if (props.examePendente.resultado === 'NEGATIVO_INDETECTADO') {
      return 'Não Detectável';
    }
    return 'Inválido';
  })();

  const loadOptionsHpv = async () => {
    try {
      const response = await api.get(
        `/notificacao/gal/details/mismatch-hpv/${props.examePendente.user_notify_id}`
      );

      setOptionsHpv(response.data.options);

      setListHpvs(response.data.hpvs);

      setDataColetaGal(
        moment(response.data?.data_coleta_gal).format('DD/MM/YYYY')
      );
    } catch (error) {}
  };

  useEffect(() => {
    loadOptionsHpv();
  }, []);

  useEffect(() => {
    setObjHpv(listHpvs?.find((hpv) => hpv?.id === selectedHpv?.value));
  }, [selectedHpv]);

  const { setLoading } = useContext(LoadingContext);

  const loadDataHpv = async () => {
    return new Promise<void>(async (resolve, reject) => {
      try {
        const response = await api.get(`/hpv/${selectedHpv?.value}`);
        resolve(response.data);
      } catch (error) {}
    });
  };

  const handleRedirectToHpv = async () => {
    if (selectedHpv?.value > 0) {
      const procedimentoId = listHpvs.find(
        (hpv) => hpv.id === selectedHpv?.value
      )?.procedimento?.id;

      await loadDataHpv().then((hpvObj: any) => {
        const target = `/pacientes/${props.examePendente.notify_data.pessoa.id}/exame/${procedimentoId}/visualizar`;
        const objFormatedToLocalStorage = {
          examData: { hpv: hpvObj, categoria: { id: 1 } },
          mismatchNotification: true,
          idHpv: selectedHpv?.value,
          id: selectedHpv?.value,
        };
        localStorage.setItem(
          'state',
          JSON.stringify(objFormatedToLocalStorage)
        );
        window.open(target, '_blank', 'noreferrer');
      });
    }
  };

  const confirmChangeDataHpv = async () => {
    setLoading(true);
    try {
      const response = await api.post(
        `/notificacao/gal/resolve/mismatch-hpv/${props.examePendente.user_notify_id}`,
        {
          hpv_id: selectedHpv?.value,
          use_dt_coleta_gal: selectedOriginDate,
        }
      );

      if (response.data.success) {
        props.closeModal(false);
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedHpv?.value > 0 && selectedOriginDate === null) {
      setDisableButton(true);
    }

    if (selectedHpv?.value > 0 && selectedOriginDate !== null) {
      setDisableButton(false);
    }

    if (selectedHpv?.value === 0) {
      setModalToShow('no_test');
      setDisableButton(false);
    }
  }, [selectedHpv, selectedOriginDate]);

  useEffect(() => {
    if (selectedHpv?.value === 0) {
      setSelectedOriginDate(null);
    }
  }, [selectedHpv]);

  return (
    <>
      <Modal
        onClose={() => setOpenConfirmationModal(false)}
        open={openConfirmationModal}
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Paper
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            textAlign: 'center',
            padding: '48px 24px',
            maxWidth: '464px',
          }}
        >
          {modalToShow === 'no_test' ? (
            <>
              <p style={{ fontSize: '24px', fontWeight: 700 }}>
                Confirmar a ausência de um Teste ?
              </p>

              <img src={alert} width={104} />

              <p
                style={{
                  fontWeight: 400,
                  fontSize: '14px',
                  marginTop: '32px',
                  marginBottom: '0',
                }}
              >
                Deseja <b>confirmar</b> a ausência de um <b>Teste de HPV</b>{' '}
                referente a <b>Requisição do GAL?</b>
              </p>

              <Box
                width="100%"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                mt="32px"
              >
                <Button
                  variant="contained"
                  disabled={disableButton}
                  onClick={() => {
                    confirmChangeDataHpv();
                  }}
                  startIcon={<CheckIcon />}
                >
                  SIM, CONFIRMAR
                </Button>

                <Button
                  variant="outlined"
                  color="error"
                  onClick={() => {
                    setOpenConfirmationModal(false);
                  }}
                  startIcon={<ClearIcon />}
                >
                  CANCELAR
                </Button>
              </Box>
            </>
          ) : (
            <>
              {selectedOriginDate === false && (
                <p style={{ fontSize: '24px', fontWeight: 700 }}>
                  Alterar a Data de Coleta?
                </p>
              )}

              {selectedOriginDate === true && (
                <p style={{ fontSize: '24px', fontWeight: 700 }}>
                  Confirmar a Data de Coleta?
                </p>
              )}

              <img src={alert} width={104} />

              <p
                style={{
                  fontWeight: 400,
                  fontSize: '14px',
                  marginTop: '32px',
                  marginBottom: '0',
                }}
              >
                {selectedOriginDate === false && (
                  <>
                    Deseja <b> alterar a Data de Coleta do Teste de HPV</b> da
                    paciente{' '}
                    <b>{props.examePendente.notify_data.pessoa?.nome}</b> para o
                    dia{' '}
                    <b>{selectedOriginDate ? dataColetaGal : dateJordana},</b>{' '}
                    apresentado na Ficha do Jordana?
                  </>
                )}

                {selectedOriginDate === true && (
                  <>
                    Deseja <b> confirmar a Data de Coleta do Teste de HPV</b> da
                    paciente{' '}
                    <b>{props.examePendente.notify_data.pessoa?.nome}</b> para o
                    dia <b>{dataColetaGal},</b> apresentado na Requisição do
                    GAL?
                  </>
                )}
              </p>
              <Box
                width="100%"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                mt="32px"
              >
                <Button
                  variant="contained"
                  disabled={disableButton}
                  onClick={() => {
                    confirmChangeDataHpv();
                  }}
                  startIcon={<CheckIcon />}
                >
                  SIM, {selectedOriginDate === true ? 'CONFIRMAR' : 'ALTERAR'}
                </Button>

                <Button
                  variant="outlined"
                  color="error"
                  onClick={() => {
                    setOpenConfirmationModal(false);
                  }}
                  startIcon={<ClearIcon />}
                >
                  CANCELAR
                </Button>
              </Box>
            </>
          )}
        </Paper>
      </Modal>

      <div>
        <p style={{ marginTop: '24px' }}>
          Um resultado do Teste de HPV para a paciente{' '}
          <b>{props.examePendente?.notify_data?.pessoa?.nome}</b> foi
          identificado no GAL, porém o mesmo apresenta o dado de coleta
          divergente em relação ao cadastrado no Jordana.{' '}
        </p>
        <Box className="subModalTextBody">
          <span>
            <b>Requisição do GAL: </b> {props.examePendente.notify_data.gal_id}
          </span>
        </Box>
        {props.finalDate ? (
          <p>
            Deseja <span style={{ color: '#2196F3' }}>vincular</span> essa Data
            de Coleta ?
          </p>
        ) : (
          <p>
            Escolha a Data de Coleta para vincular o resultado corretamente:{' '}
          </p>
        )}
        <Box className="subModalTextBody">
          <div
            style={{
              display: 'flex',
              width: '100%',
              flexDirection: 'column',
              gap: '10px',
            }}
          >
            <p style={{ fontSize: '12px' }}>Testes de HPV sem Resultado</p>

            <Autocomplete
              sx={{ width: '100%' }}
              options={optionsHpv}
              onChange={(e, newValue) => {
                setSelectedHpv(newValue);
                const date = newValue?.label?.split(' ')[6];
                if (date) {
                  setDateJordana(date);
                } else {
                  setDateJordana('');
                }
              }}
              value={selectedHpv ? selectedHpv : null}
              getOptionLabel={(option: any) => option.label}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  fullWidth
                  label="Testes de HPV disponíveis"
                />
              )}
            />

            <div
              style={{
                display: 'flex',
                cursor: selectedHpv?.value > 0 ? 'pointer' : 'default',
              }}
              // onClick={() => {
              //   if (selectedHpv) {
              //     setOpen(true);
              //   }
              // }}
            >
              <img
                src={
                  selectedHpv && selectedHpv.value !== 0
                    ? DocSvg
                    : DocSvgDisabled
                }
                alt=""
                height="16px"
                width="16px"
                style={{
                  marginTop: '3px',
                  marginRight: '6px',
                  color: selectedHpv ? '#0B79D0' : 'rgba(0, 0, 0, 0.38)',
                }}
              />
              <p
                onClick={() => {
                  handleRedirectToHpv();
                }}
                // to={`/pacientes/${props.examePendente.notify_data.pessoa.id}/exame/${selectedHpv?.value}/visualizar`}
                // target="_blank"
                style={{
                  fontSize: '16px',
                  textDecoration: 'underline',
                  margin: '0',
                  fontWeight: 700,
                  color:
                    selectedHpv && selectedHpv.value !== 0
                      ? '#0B79D0'
                      : 'rgba(0, 0, 0, 0.38)',
                }}
              >
                Visualizar Teste de HPV
              </p>
            </div>

            <p style={{ fontSize: '12px', marginBottom: '0' }}>Data Correta</p>

            <Box sx={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
              <Checkbox
                disabled={!selectedHpv || selectedHpv.value === 0}
                checked={selectedOriginDate === true}
                onChange={() => {
                  setSelectedOriginDate(true);
                }}
              />
              <p
                style={{
                  fontWeight: 'bold',
                  margin: 0,
                  color:
                    selectedHpv && selectedHpv.value !== 0
                      ? 'black'
                      : 'rgba(0, 0, 0, 0.38)',
                }}
              >
                Data de Coleta no GAL:{' '}
                <span style={{ fontWeight: 'normal' }}>{dataColetaGal}</span>
              </p>
            </Box>

            <Box sx={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
              <Checkbox
                disabled={!selectedHpv || selectedHpv.value === 0}
                checked={selectedOriginDate === false}
                onChange={() => {
                  setSelectedOriginDate(false);
                }}
              />
              <p
                style={{
                  fontWeight: 'bold',
                  margin: 0,
                  color:
                    selectedHpv && selectedHpv.value !== 0
                      ? 'black'
                      : 'rgba(0, 0, 0, 0.38)',
                }}
              >
                Data do Jordana:{' '}
                <span style={{ fontWeight: 'normal' }}>{dateJordana}</span>
              </p>
            </Box>
          </div>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          gap="24px"
          mt="24px"
        >
          <Button
            variant="contained"
            disabled={disableButton}
            onClick={() => {
              setOpenConfirmationModal(true);
            }}
            endIcon={<CheckIcon />}
          >
            CONFIRMAR
          </Button>

          <Button
            variant="outlined"
            color="error"
            onClick={() => {
              props.closeModal(false);
            }}
            endIcon={<RestoreIcon />}
          >
            CONFIRMAR DEPOIS
          </Button>
        </Box>
      </div>
    </>
  );
};

export default NotificationModalHpvResult;
