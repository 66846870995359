import { ApexOptions } from 'apexcharts';
import { useEffect, useState } from 'react';
import { PieChart } from '../pieChart';

interface DetectableAndVirusTypeProps {
  data: number[];
  categories?: String[];
  height?: number;
  payload: any;
}

export function DetectableAndVirusType({
  data,
  payload,
}: DetectableAndVirusTypeProps) {
  const [chartData, setChartData] = useState<ApexOptions>();

  useEffect(() => {
    setChartData({
      series: data,
      chart: {
        width: 284,
        type: 'pie',
        events: {
          dataPointSelection: function (event, chartContext, config) {
            if (config.dataPointIndex > -1) {
              localStorage.removeItem('reasonNotProcedure');
              localStorage.removeItem('result');
              localStorage.removeItem('collectionOrigin');
              const values = {
                0: 'hpv_16',
                1: 'hpv_18',
                2: 'hpv_outros',
              };
              localStorage.setItem('typeVirus', values[config.dataPointIndex]);
              localStorage.setItem('requestFromTable', 'true');
              localStorage.setItem('payload', JSON.stringify(payload));
              window.location.href = '/relatorios/hpv';
            }
          },
        },
      },
      labels: [
        `HPV 16 (${data[0]})`,
        `HPV 18 (${data[1]})`,
        `Outros (${data[2]})`,
      ],
      responsive: [
        {
          breakpoint: 768,
          options: {
            legend: {
              position: 'bottom',
              offsetY: 0,
              horizontalAlign: 'left',
              width: 180,
            },
          },
        },
      ],
      colors: ['#AB2F26', '#F44336', '#ACB7B8'],
      yaxis: {
        min: 0,
      },
      xaxis: {
        min: 0,
      },
      legend: {
        position: 'right',
        offsetY: 80,
      },
      dataLabels: {
        enabled: true,
        style: {
          fontSize: '12px',
          colors: ['rgba(0,0,0,0.7)'],
        },
        background: {
          enabled: true,
          foreColor: '#ffffff',
          borderRadius: 6,
          borderColor: 'transparent',
        },
      },
      plotOptions: {
        pie: {
          startAngle: -60,
          endAngle: 300,
        },
      },
    });
  }, [data]);

  return (
    <>
      {chartData ? (
        <>
          <div style={{ display: 'none' }}>
            <ul>
              {data.map((value, index) => {
                const total = data[0] + data[1] + data[2];
                const percentHpv16 = `${Number((data[0] / total) * 100).toFixed(
                  1
                )}%`;
                const percentHpv18 = `${Number((data[1] / total) * 100).toFixed(
                  1
                )}%`;
                const percentOthers = `${Number(
                  (data[2] / total) * 100
                ).toFixed(1)}%`;
                return (
                  <>
                    {index === 0 && (
                      <li>
                        <p data-testid="labelAndValueHpv16">HPV 16 ({value})</p>
                        <p data-testid="percentHpv16">
                          percentHpv16: {percentHpv16}
                        </p>
                      </li>
                    )}
                    {index === 1 && (
                      <li>
                        <p data-testid="labelAndValueHpv18">HPV 18 ({value})</p>
                        <p data-testid="percentHpv18">
                          percentHpv18: {percentHpv18}
                        </p>
                      </li>
                    )}
                    {index === 2 && (
                      <li>
                        <p data-testid="labelAndValueOthers">
                          Outros ({value})
                        </p>
                        <p data-testid="percentOthers">
                          percentOthers: {percentOthers}
                        </p>
                      </li>
                    )}
                  </>
                );
              })}
            </ul>
          </div>

          <PieChart chartData={chartData} height={284} />
        </>
      ) : undefined}
    </>
  );
}
