import axios from 'axios';
import {
  checkAccessToken,
  getAccessToken,
  logout,
  refreshAccessToken,
  verifyWebVersion,
} from './lib/auth';
import moment from 'moment';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'web-sync': 'valid',
  },
});

api.interceptors.request.use(async (config) => {
  const lastRequest = localStorage.getItem('lastRequest');

  if (lastRequest) {
    const diffMinutesLastRequest = moment().diff(lastRequest, 'minutes');

    if (diffMinutesLastRequest >= 30 && window.location.href !== '/') {
      window.location.href = '/';

      localStorage.setItem('lastRequest', String(new Date()));
    } else {
      localStorage.setItem('lastRequest', String(new Date()));
      if (config?.url?.includes('/usuarios/forgotPass')) return config;
      if (config?.url.includes('/usuarios/changePassword/')) return config;
      if (checkAccessToken()) {
        await refreshAccessToken();
      } else {
        logout();
      }
      const token = getAccessToken();

      if (token) config.headers.Authorization = `Bearer ${token}`;
      return config;
    }
  } else {
    localStorage.setItem('lastRequest', String(new Date()));
  }
});

api.interceptors.response.use(
  (config) => {
    const webVersion = config.headers['x-login-validator'];
    verifyWebVersion(webVersion);
    return config;
  },
  async (err) => {
    const config = err?.config;
    if (
      config?.url !== '/oauth/token' &&
      config?.url !== '/usuarios/forgotPass' &&
      err?.response
    ) {
      if (err?.response?.status === 401 && !config?._retry) {
        config._retry = true;
        try {
          await refreshAccessToken();
          return api(config);
        } catch (e) {
          Promise.reject(e);
        }
      }
      const message = err.response?.data ? err.response?.data : err?.response;

      // if(message?.error?.response?.message) {
      //   toast.error(message.error.response.message, {
      //     position: 'bottom-right',
      //   });
      // }
      return Promise.reject(message);
    }
    return Promise.reject(err);
  }
);

export default api;
