/* eslint-disable react-hooks/exhaustive-deps */

import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControl,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import React, { useContext, useEffect } from 'react';
import { Label } from './styles';
import { PatientScheduleProps } from './types';

import { ModalSchedulingPatient } from '../modals/patientschedule';
import api from '../../../../api';
import { EstablishmentContext } from '../../../../context/EstablishmentContext';
import { useNavigate } from 'react-router-dom';

export const PatientSchedule = ({}) => {
  const navigate = useNavigate();

  const [showCancel, setShowCancel] = React.useState('');
  const [hasError, setHasError] = React.useState(false);
  const [cpf, setCpf] = React.useState<string>('');
  const [data, setData] = React.useState<PatientScheduleProps[]>([]);
  const [patientName, setPatientName] = React.useState<string>('');
  const [professionalId, setProfessionalId] = React.useState<string>('');
  const [, setFilteredData] = React.useState(data);
  const { currentEstablishment } = useContext(EstablishmentContext);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [tableData, setTableData] = React.useState<any>();

  const backgroundStatus = (status: string) => {
    status = status.toLowerCase();
    switch (status) {
      case 'realizado':
        return '#4caf50';
      case 'agendamento cancelado':
        return '#f44336';
      case 'agendado':
        return '#2196f3';
      default:
        return '';
    }
  };

  const setListData = async (props: any = {}) => {
    const params: any = {
      estabelecimentoId: currentEstablishment?.id,
      cpf: cpf || 'none',
      // page: currentPage,
      // size: rowsPerPage,
      ...props,
    };
    const res: any = await api.get('/profissional_paciente', { params });

    if (res.data.items?.length) {
      setPatientName(res.data.items[0].pessoa?.nome);
      const values: PatientScheduleProps[] = res.data.items?.map(
        (item: any) => ({
          id: item.id,
          id_profissional: item.profissional.id,
          nome_profissional: item.profissional.pessoa.nome,
          data: item.dtAgendamento,
          hora: item.horarioAtendimento,
          cbo: item.profissional.cargo,
          status: item.status,
          procedimento: item.procedimento,
        })
      );
      // setTableData({...re});
      setTableData({ ...res.data, rows: values });

      setData(values);
      setFilteredData(values);
      setHasError(false);
    } else {
      setHasError(true);
    }
  };

  useEffect(() => {
    setListData({ anteriores: showCancel });
  }, [showCancel]);

  useEffect(() => {
    if (!cpf) {
      setHasError(false);
    }
  }, [cpf]);

  //format date
  const formatDate = (date: string) => {
    const dateSplit = date?.split('-');
    return `${dateSplit[2]}/${dateSplit[1]}/${dateSplit[0]}`;
  };

  //modal
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  // const handleChangeRowsPerPage = async (
  //   event: React.ChangeEvent<HTMLInputElement>
  // ) => {
  //   setRowsPerPage(Number(event.target.value));

  //   const params: any = {
  //     estabelecimentoId: currentEstablishment?.id,
  //     cpf: cpf || 'none',
  //     anteriores: showCancel,
  //     page: currentPage,
  //     pageSize: Number(event.target.value),
  //   };

  //   const res: any = await api.get('/profissional_paciente', { params });

  //   if (res.data.items?.length) {
  //     setPatientName(res.data.items[0].pessoa?.nome);
  //     const values: PatientScheduleProps[] = res.data.items?.map(
  //       (item: any) => ({
  //         id: item.id,
  //         id_profissional: item.profissional.id,
  //         nome_profissional: item.profissional.pessoa.nome,
  //         data: item.dtAgendamento,
  //         hora: item.horarioAtendimento,
  //         cbo: item.profissional.cargo,
  //         status: item.status,
  //         procedimento: item.procedimento,
  //       })
  //     );
  //     // setTableData({...re});
  //     setTableData({ ...res, rows: values });

  //     setData(values);
  //     setFilteredData(values);
  //     setHasError(false);
  //   } else {
  //     setHasError(true);
  //   }

  //   // const response = await getConvocations({
  //   //   getParams: {
  //   //     ...stateToSaveFilter,
  //   //     size: event.target.value,
  //   //     page: String(currentPage),
  //   //   },
  //   // });
  //   // setTableData({ ...response, rows: response.data });
  // };

  // const handleChangePage = async (newPage) => {
  //   setCurrentPage(newPage + 1);

  //   const params: any = {
  //     estabelecimentoId: currentEstablishment?.id,
  //     cpf: cpf || 'none',
  //     anteriores: showCancel,
  //     page: newPage,
  //     pageSize: Number(rowsPerPage),
  //   };

  //   const res: any = await api.get('/profissional_paciente', { params });

  //   if (res.data.items?.length) {
  //     setPatientName(res.data.items[0].pessoa?.nome);
  //     const values: PatientScheduleProps[] = res.data.items?.map(
  //       (item: any) => ({
  //         id: item.id,
  //         id_profissional: item.profissional.id,
  //         nome_profissional: item.profissional.pessoa.nome,
  //         data: item.dtAgendamento,
  //         hora: item.horarioAtendimento,
  //         cbo: item.profissional.cargo,
  //         status: item.status,
  //         procedimento: item.procedimento,
  //       })
  //     );
  //     // setTableData({...re});
  //     setTableData({ ...res, rows: values });

  //     setData(values);
  //     setFilteredData(values);
  //     setHasError(false);
  //   } else {
  //     setHasError(true);
  //   }

  //   // const response = await getConvocations({
  //   //   getParams: {
  //   //     ...stateToSaveFilter,
  //   //     page: newPage + 1,
  //   //     size: String(rowsPerPage),
  //   //   },
  //   // });
  //   // setTableData({ ...response, rows: response.data });
  // };

  return (
    <>
      <Grid container spacing={2} sx={{ marginTop: '26px' }}>
        <Grid item xs={12} md={7}>
          <TextField
            label="CPF do paciente"
            placeholder="CPF DO PACIENTE"
            size="small"
            onChange={(e) => setCpf(e.target.value)}
            value={cpf}
            error={hasError}
            sx={{
              height: '40px',
              width: '100%',
              '.MuiOutlinedInput-root': {
                paddingRight: '0',
                marginBottom: '5px',
              },
            }}
          />
        </Grid>
        <Grid item xs={12} md={1}>
          <Button
            sx={{ minWidth: '100%', height: '40px' }}
            variant="contained"
            onClick={() => setListData({ anteriores: showCancel })}
          >
            BUSCAR
          </Button>
        </Grid>
        <Grid item xs={12} md={1} style={{ textAlign: 'center' }}>
          <Label
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              marginTop: '10px',
            }}
          >
            ou
          </Label>
        </Grid>
        <Grid item xs={12} md={3} style={{ paddingLeft: '0px' }}>
          <Button
            sx={{ width: '100%', paddingLeft: '0px', height: '40px' }}
            variant="outlined"
            onClick={() => {
              navigate('/cadastrar-paciente');
            }}
          >
            CADASTRAR PACIENTE
          </Button>
        </Grid>
      </Grid>

      <Box mt={5}>
        <Paper variant="outlined" sx={{ borderRadius: '10px', padding: 4 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={5} style={{ alignItems: 'center' }}>
              <Checkbox
                checked={!!showCancel}
                onChange={(e) => {
                  setShowCancel((e.target.checked || '') + '');
                }}
                sx={{ color: '#238884' }}
              />
              <Label style={{ color: 'rgba(0, 0, 0, 0.87)' }}>
                Exibir agendamentos anteriores
              </Label>
            </Grid>
            <Grid item xs={12} md={5}>
              <FormControl sx={{ width: '100%', height: '40px' }}>
                <Autocomplete
                  options={data}
                  getOptionLabel={(option) => option.nome_profissional}
                  onChange={(e, v) => {
                    setProfessionalId(v?.id_profissional || '');
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      key={params.id}
                      size="small"
                      label="Selecione o nome do Profissional"
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={2}>
              <Button
                sx={{ minWidth: '100%', height: '40px' }}
                variant="contained"
                onClick={() => {
                  if (!professionalId) {
                    setFilteredData(data);
                    return;
                  }
                  const filtered = data.filter(
                    (item) => item.id_profissional === professionalId
                  );
                  setFilteredData(filtered);
                }}
              >
                Filtrar
              </Button>
            </Grid>
          </Grid>
          <Typography
            sx={{
              fontSize: '25px',
              fontWeight: '600',
              margin: '10px',
            }}
          >
            {patientName}
          </Typography>
          <TableContainer style={{ marginTop: '16px' }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ borderBottom: 'none' }}>
                    <Typography
                      sx={{
                        fontSize: '16px',
                        width: '160px',
                        fontWeight: '400',
                        color: '#238884',
                      }}
                    >
                      Data e Hora
                    </Typography>
                  </TableCell>
                  <TableCell align="left" sx={{ borderBottom: 'none' }}>
                    <Typography
                      sx={{
                        fontSize: '16px',
                        width: '160px',
                        fontWeight: '400',
                        color: '#238884',
                      }}
                    >
                      Profissional
                    </Typography>
                  </TableCell>
                  <TableCell align="left" sx={{ borderBottom: 'none' }}>
                    <Typography
                      sx={{
                        fontSize: '16px',
                        width: '160px',
                        fontWeight: '400',
                        color: '#238884',
                      }}
                    >
                      CBO
                    </Typography>
                  </TableCell>
                  <TableCell align="left" sx={{ borderBottom: 'none' }}>
                    <Typography
                      sx={{
                        fontSize: '16px',
                        width: '160px',
                        fontWeight: '400',
                        color: '#238884',
                      }}
                    >
                      Procedimento
                    </Typography>
                  </TableCell>
                  <TableCell align="left" sx={{ borderBottom: 'none' }}>
                    <Typography
                      sx={{
                        fontSize: '16px',
                        width: '160px',
                        fontWeight: '400',
                        color: '#238884',
                      }}
                    >
                      STATUS
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>

              {tableData?.rows.map((item: PatientScheduleProps) => {
                return (
                  <React.Fragment key={item.id}>
                    <TableBody>
                      <TableRow
                        // onClick={handleOpen}
                        style={
                          item.status === 'Agendamento Cancelado'
                            ? { backgroundColor: 'rgba(0, 0, 0, 0.04)' }
                            : {}
                        }
                        // sx={{ cursor: 'pointer' }}
                      >
                        <TableCell sx={{ width: '162px' }}>
                          <Typography
                            color="#000001de"
                            variant="body1"
                            fontSize="14px"
                          >
                            {formatDate(item.data)} - {item.hora}
                          </Typography>
                        </TableCell>
                        <TableCell align="left" sx={{ width: '162px' }}>
                          <Typography
                            color="#000001de"
                            variant="body2"
                            fontSize="14px"
                          >
                            {item.nome_profissional}
                          </Typography>
                        </TableCell>
                        <TableCell sx={{ width: '162px' }}>
                          <Typography
                            color="#000001de"
                            variant="body1"
                            fontSize="14px"
                          >
                            {item.cbo}
                          </Typography>
                        </TableCell>
                        <TableCell align="left" sx={{ width: '162px' }}>
                          <Typography
                            color="#000001de"
                            variant="body2"
                            fontSize="14px"
                          >
                            {item.procedimento}
                          </Typography>
                        </TableCell>
                        <TableCell sx={{ width: '162px' }}>
                          <Typography
                            color="#000001de"
                            variant="body1"
                            fontSize="14px"
                            style={{
                              backgroundColor: backgroundStatus(item.status),
                              borderRadius: '50px',
                              padding: '5px',
                              color: '#fff',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              // cursor: 'pointer',
                            }}
                          >
                            {item.status === 'Agendamento Cancelado'
                              ? 'Cancelado'
                              : item.status}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableBody>

                    <ModalSchedulingPatient
                      open={open}
                      handleClose={handleClose}
                      patientSchedule={item}
                    />
                  </React.Fragment>
                );
              })}
            </Table>
            {/* <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={tableData?.totalItems}
              rowsPerPage={rowsPerPage}
              page={currentPage}
              onPageChange={(e, newPage) => handleChangePage(newPage)}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage={'Itens por página'}
              labelDisplayedRows={({ from, to, count }) => {
                return `${from}–${to} de ${
                  count !== -1 ? count : `mais do que ${to}`
                }`;
              }}
            /> */}

            {/* <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={filteredData.length}
              rowsPerPage={5}
              page={0}
              onPageChange={(e, newPage) => {}}
              onRowsPerPageChange={() => {}}
              labelRowsPerPage={'Itens por página'}
              labelDisplayedRows={({ from, to, count }) => {
                return `${from}–${to} de ${
                  count !== -1 ? count : `mais do que ${to}`
                }`;
              }}
            /> */}
          </TableContainer>
        </Paper>
      </Box>
    </>
  );
};
