import styled from '@emotion/styled';
import { Button, Paper } from '@mui/material';

export const FormContainer = styled(Paper)`
  width: 464px;
  border: 12px;

  @media (max-width: 768px) {
    max-width: 464px;
  }
`;

export const ButtonSelectUnit = styled(Button)({
  height: '62px',
  width: '100%',
  margin: '16px 24px',
  border: '1px solid #238884',
});
