import { Button, Collapse, Typography } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const CollapseColumn = ({
  arrayItens,
  objItens,
  valueToOpen,
  stateOpenRow,
  setStateOpenedRow,
  classes,
}) => {
  let arrLabels: any;
  let arrItens: any = [];
  for (const key in objItens) {
    const itemWithValueTrue =
      objItens[key]?.value === true ? objItens[key] : undefined;

    arrItens.push(itemWithValueTrue);
  }

  arrLabels = arrItens.filter((item) => {
    return item?.value === true;
  });

  return (
    <>
      <Typography color="#000001de" variant="body2" fontSize="14px">
        {arrLabels.length > 1 ? (
          <div>
            <p style={{}}>{objItens[arrayItens[0]].label}</p>

            {arrayItens
              .map((itemInsideArray) => {
                return objItens[itemInsideArray].value === true
                  ? objItens[itemInsideArray].label
                  : null;
              })
              .filter((_, index) => index > 0)
              .map((itemWithValue) => {
                return (
                  <Collapse in={stateOpenRow === valueToOpen}>
                    <p>{itemWithValue}</p>
                  </Collapse>
                );
              })}

            {arrayItens.length > 1 && (
              <>
                {stateOpenRow === valueToOpen ? (
                  <Button
                    onClick={() => setStateOpenedRow('')}
                    sx={{ textTransform: 'none' }}
                    endIcon={<KeyboardArrowUpIcon className={classes.icon} />}
                  >
                    Ver menos
                  </Button>
                ) : (
                  <Button
                    onClick={() => setStateOpenedRow(valueToOpen)}
                    sx={{ textTransform: 'none' }}
                    endIcon={<KeyboardArrowDownIcon className={classes.icon} />}
                  >
                    Ver mais
                  </Button>
                )}
              </>
            )}
          </div>
        ) : (
          <p>{arrLabels?.[0]?.label}</p>
        )}
      </Typography>
    </>
  );
};

export default CollapseColumn;
