import { ChartProps } from '../../../types/Chart';
import { PieChart } from '../pieChart';
import { PieChart2 } from '../pieChart2';

export function SampleStatus({ data }: ChartProps) {
  return (
    <PieChart2
      data={data}
      height={284}
      labels={[
        `Aguardando Triagem (${data[0]})`,
        `Em Análise (${data[1]})`,
        `Resultados Liberados (${data[2]})`,
      ]}
      colors={['#ACB7B8', '#0079AD', '#01AEFA']}
    />
  );
}
