const useSetPayload = ({
  watch,
  period,

  estadosSelected,
  municipiosSelected,
  estabelecimentosSelected,
  acsSelected,
}) => {
  const setPayloadFromChartToPaginate = ({ payloadFromCharts, page, size }) => {
    let payload: any;
    return new Promise<void>((resolve, reject) => {
      payload = {
        ...payloadFromCharts,
        page: page,
        size: size,
      };

      resolve(payload);
    });
  };

  const setPayload = () => {
    let payload: any;
    return new Promise((resolve, reject) => {
      payload = {
        estados:
          estadosSelected.length > 0 ? estadosSelected.join(', ') : undefined,
        municipioId:
          municipiosSelected.length > 0
            ? municipiosSelected.join(', ')
            : undefined,
        estabelecimentoSaudeId:
          estabelecimentosSelected.length > 0
            ? estabelecimentosSelected.join(', ')
            : undefined,
        estado: watch('estado'),
        profissionalAcsId:
          acsSelected.length > 0 ? acsSelected.join(', ') : undefined,
        idade_inicio: watch().idade_inicio,
        idade_final: watch().idade_final,
        rastreio: watch().rastreio,
        prioridade: watch().prioridade,
        has_procedimento: watch().has_procedimento,
        periodo_inicio: period?.periodo_inicio,
        periodo_fim: period?.periodo_fim,

        search: watch().search,
        size: watch().size,
        page: watch().page,
      };

      resolve(payload);
    });
  };

  return { setPayload, setPayloadFromChartToPaginate };
};

export default useSetPayload;
