import { yupResolver } from '@hookform/resolvers/yup';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import {
  Autocomplete,
  Box,
  Breadcrumbs,
  Button,
  Checkbox,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';

import { ptBR } from 'date-fns/locale';
import { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import api from '../../../api';
import BoxInfoPatient from '../../../components/BoxInfoPatient/BoxInfoPatient';
import ReturnButton from '../../../components/ReturnButton';
import { SideMenu } from '../../../components/SideMenu';
import { StyledAsteriskTextField } from '../../../components/StyledAsteriskTextField/styles';
import { PerfilContext } from '../../../context/PerfilContext';
import { ArrowBack } from '@mui/icons-material';
import moment from 'moment';
import { toast } from 'react-toastify';
import { LoadingContext } from '../../../context/LoadingContext';

const schema = Yup.object({
  status: Yup.string(),
});

// interface Inputs {
//   status: string;
// }

export const Asterisk = () => {
  return <span style={{ color: '#d32f2f' }}>*</span>;
};

export function ViewInjuryTreatment() {
  const [patientData, setPatientData] = useState<any>([]);
  const location = useLocation();
  const state = location.state;
  const [responsaveis, setResponsaveis] = useState<any>([]);

  const [valueDtRealizado, setValueDtRealizado] = useState<any>();
  const [valueIndicacaoEzt, setValueIndicacaoEzt] = useState(0);
  const [valueRealizado_in, setValueRealizado_in] = useState(0);
  const [valueTipo_exerese_ezt, setValueTipo_exerese_ezt] = useState(0);
  const [valueAnestesia_local, setValueAnestesia_local] = useState(false);
  const [valueAnestesia_sedacao, setValueAnestesia_sedacao] = useState(false);
  const [valueAnestesia_raqui, setValueAnestesia_raqui] = useState(false);
  const [valueAnestesia_outras, setValueAnestesia_outras] = useState(false);
  const [valueAnestesia_outras_desc, setValueAnestesia_outras_desc] =
    useState('');
  const [valueTipo_procedimento, setValueTipo_procedimento] = useState(0);
  const [valueProfissionalId, setValueProfissionalId] = useState(0);
  const [valueCbo, setValueCbo] = useState('');
  const [valueEstabelecimentoId, setValueEstabelecimento] = useState('');
  const [optionsEstabelecimentos, setOptionsEstabelecimentos] = useState<any>(
    []
  );

  const { hasPermission } = useContext(PerfilContext);

  const {
    formState: { errors },

    control,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  let navigate = useNavigate();

  const { id } = useParams();

  const loadEstabelecimentos = async () => {
    try {
      const response = await api.get('/intraepitelial/estabelecimentos');

      if (response.status === 200) {
        setOptionsEstabelecimentos(response.data);
      } else {
        toast.error('Recarregue a página', { position: 'bottom-right' });
      }
    } catch (error) {}
  };

  const editPaciente = () => {
    navigate(`/pacientes/${patientData.id}/edit`, {
      state: { ...patientData, url: window.location.pathname },
    });
  };

  const loadExam = async () => {
    try {
      const response = await api.get(
        `/intraepitelial/${state?.examData?.intraepitelial?.id}`
      );

      const { data } = response;

      let dataInicial = new Date(data.dt_realizado);

      // Adicionando 1 dia
      dataInicial.setDate(dataInicial.getDate() + 1);

      setValueDtRealizado(dataInicial);
      setValueIndicacaoEzt(data.indicacao_ezt);
      setValueRealizado_in(data.realizado_in);
      setValueTipo_exerese_ezt(data.tipo_exerese_ezt);
      setValueAnestesia_local(data.anestesia_local);
      setValueAnestesia_sedacao(data.anestesia_sedacao);
      setValueAnestesia_raqui(data.anestesia_raqui);
      setValueAnestesia_outras(data.anestesia_outras);
      setValueAnestesia_outras_desc(data.anestesia_outras_desc);
      setValueTipo_procedimento(data.tipo_procedimento);
      setValueEstabelecimento(data?.procedimento?.estabelecimento_saude?.id);
      setValueProfissionalId(data?.procedimento?.profissional?.id);
      setValueCbo(data?.procedimento?.profissional?.cbo);
    } catch (error) {}
  };
  async function loadResponsaveis() {
    setValue('pessoaId', state.id);
    setValue('estabelecimentoSaudeId', Number(valueEstabelecimentoId));
    const response = await api.get('/profissionais/cbo/list', {
      params: {
        cboTypes: 'MÉDICO',
        // estabelecimentoId: valueEstabelecimentoId,
      },
    });

    const profissionais = response.data.map((profissional) => {
      return {
        idprofissional: profissional.id,
        nomeprofissional: `${profissional.pessoa.nome}`,
        label: `${profissional.pessoa.nome}`,
        cbo: profissional.cbo,
      };
    });

    setResponsaveis(profissionais);
    setLoading(false);
  }

  const generatePDF = async () => {
    try {
      const response = await api.get(
        `/reports/intraepitelial/exame/${state.examData.id}`,
        {
          responseType: 'arraybuffer',
          headers: {
            Accept: 'application/pdf',
          },
        }
      );

      const blob = new Blob([response.data], { type: 'application/pdf' });
      const link = document.createElement('a');

      link.href = window.URL.createObjectURL(blob);
      link.target = '_blank';

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Erro ao gerar PDF:', error);
    }
  };

  useEffect(() => {
    if (valueEstabelecimentoId) {
      loadResponsaveis();
    }
  }, [valueEstabelecimentoId]);

  useEffect(() => {
    loadEstabelecimentos();
  }, []);
  const { setLoading } = useContext(LoadingContext);

  useEffect(() => {
    setLoading(true);
    loadExam();
  }, []);

  useEffect(() => {
    setValue('anestesia_local', true);
  }, [setValue]);

  useEffect(() => {
    async function loadData(id: any) {
      await api
        .get('/pessoas/' + id)
        .then((response) => {
          setPatientData(response.data);
        })
        .catch((err) => {
          console.error('ops! ocorreu um erro' + err);
        });

      // hasPermission('VIEW_EXAME') &&
      //   (await api
      //     .get('/pacientes/' + id + '/exames')
      //     .then((response) => {
      //       setExamData(response.data.exames);
      //     })
      //     .catch((err) => {
      //       console.error('ops! ocorreu um erro' + err);
      //     }));
    }
    loadData(id);
  }, [id]);

  return (
    <SideMenu>
      <form noValidate>
        <Container>
          <Box
            mt={12}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-end',
              alignItems: 'flex-end',
            }}
          >
            <ReturnButton backPage="/pacientes" />
            <Typography
              variant="h4"
              fontWeight="bold"
              style={{ marginBottom: '5px' }}
            >
              Tratamento das Lesões Intraepiteliais
            </Typography>
            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
              <Link
                style={{
                  textDecoration: 'none',
                  color: '#646464',
                }}
                to="/pacientes"
              >
                Listagem de Pacientes
              </Link>
              <Link
                style={{
                  textDecoration: 'none',
                  color: '#646464',
                }}
                to={`/pacientes/${id}`}
              >
                Ficha da Paciente
              </Link>
              <Typography color="text.primary">
                Tratamento das Lesões Intraepiteliais
              </Typography>
            </Breadcrumbs>
            <BoxInfoPatient
              data={patientData}
              buttonEditar={editPaciente}
              viewButton={true}
            ></BoxInfoPatient>
            <Button
              variant="outlined"
              sx={{ width: '134px', marginTop: '24px' }}
              onClick={() => generatePDF()}
              startIcon={<DownloadIcon />}
            >
              EXPORTAR
            </Button>
          </Box>

          <p
            style={{
              fontSize: '12px',
              textAlign: 'right',
              color: '#d32f2f',
              margin: '24px 0',
            }}
          >
            <i>* Resposta obrigatória</i>
          </p>

          <Paper variant="outlined" sx={{ borderRadius: '10px', padding: 4 }}>
            <Typography
              variant="h4"
              fontWeight="bold"
              style={{ marginBottom: '30px', fontSize: '24px' }}
            >
              Exérese da Zona de Transformação (EZT)
            </Typography>
            <Divider />

            <Grid container spacing={3}>
              <Grid item xs={12}>
                <FormControl sx={{ mt: 3, width: 220 }} size="small">
                  <Controller
                    name="dt_realizado"
                    control={control}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <LocalizationProvider
                          dateAdapter={AdapterDateFns}
                          locale={ptBR}
                        >
                          <DatePicker
                            onChange={(value) => onChange(value)}
                            mask="__/__/____"
                            disabled={true}
                            value={valueDtRealizado ? valueDtRealizado : null}
                            renderInput={(params) => (
                              <StyledAsteriskTextField
                                {...params}
                                label="Data da Realização"
                                size="small"
                                variant="outlined"
                                fullWidth
                                required
                              />
                            )}
                          />
                        </LocalizationProvider>
                      );
                    }}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <FormControl fullWidth size="small">
                  <Controller
                    control={control}
                    name="estabelecimentoSaudeId"
                    render={({ field: { onChange, value } }) => {
                      return (
                        <StyledAsteriskTextField
                          required
                          error={!!errors?.estabelecimentoSaudeId}
                          helperText={errors.estabelecimentoSaudeId?.message}
                          label="Serviço de Saúde da realização da EZT"
                          fullWidth
                          size="small"
                          select
                          disabled
                          value={value ? value : ''}
                          onChange={onChange}
                        >
                          {optionsEstabelecimentos.map((estab) => {
                            return (
                              <MenuItem value={estab?.id}>
                                {estab?.label}
                              </MenuItem>
                            );
                          })}
                        </StyledAsteriskTextField>
                      );
                    }}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <Typography color="gray" mb={1}>
                  Indicação da EZT? <Asterisk />
                </Typography>

                <Box ml={3}>
                  <FormControl>
                    <FormLabel>Terapêutica:</FormLabel>
                    <Controller
                      name="indicacao_ezt"
                      control={control}
                      render={({ field: { onChange, value } }) => {
                        return (
                          <>
                            <RadioGroup
                              value={valueIndicacaoEzt}
                              onChange={onChange}
                            >
                              <FormControlLabel
                                value={1}
                                control={<Radio />}
                                disabled={true}
                                label=" Biópsia prévia com lesão de alto grau"
                              />
                              <FormControlLabel
                                value={2}
                                disabled={true}
                                control={<Radio />}
                                label="Critérios ver e tratar"
                              />
                              <Box mt={1} display="flex" flexDirection="column">
                                <FormLabel>Diagnóstica:</FormLabel>
                                <FormControlLabel
                                  value={3}
                                  disabled={true}
                                  control={<Radio />}
                                  label=" Investigação do canal"
                                />
                              </Box>
                            </RadioGroup>
                          </>
                        );
                      }}
                    />
                  </FormControl>
                </Box>
              </Grid>

              <Grid item xs={12}>
                <FormControl
                  required
                  sx={{
                    '& .MuiFormLabel-asterisk': {
                      color: '#d32f2f',
                    },
                  }}
                >
                  <FormLabel>Local de Realização do Procedimento</FormLabel>
                  <Controller
                    name="realizado_in"
                    control={control}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <RadioGroup
                          value={valueRealizado_in}
                          onChange={onChange}
                          row
                        >
                          <FormControlLabel
                            value={1}
                            disabled={true}
                            control={<Radio />}
                            label=" Ambulatorial"
                          />
                          <FormControlLabel
                            value={2}
                            disabled={true}
                            control={<Radio />}
                            label="Sala de procedimentos/pequenas cirurgias"
                          />
                          <FormControlLabel
                            value={3}
                            disabled={true}
                            control={<Radio />}
                            label="Bloco cirúrgico"
                          />
                        </RadioGroup>
                      );
                    }}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <FormControl
                  required
                  sx={{
                    '& .MuiFormLabel-asterisk': {
                      color: '#d32f2f',
                    },
                  }}
                >
                  <FormLabel>
                    Tipo de Exérese da Zona de Transformação (EZT)
                  </FormLabel>
                  <Controller
                    name="tipo_exerese_ezt"
                    control={control}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <RadioGroup
                          value={valueTipo_exerese_ezt}
                          onChange={onChange}
                          row
                        >
                          <FormControlLabel
                            value={1}
                            disabled={true}
                            control={<Radio />}
                            label=" Tipo 1"
                          />
                          <FormControlLabel
                            value={2}
                            disabled={true}
                            control={<Radio />}
                            label="Tipo 2"
                          />
                          <FormControlLabel
                            value={3}
                            disabled={true}
                            control={<Radio />}
                            label="Tipo 3"
                          />
                        </RadioGroup>
                      );
                    }}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <FormControl
                  component="fieldset"
                  required
                  sx={{
                    '& .MuiFormLabel-asterisk': {
                      color: '#d32f2f',
                    },
                  }}
                >
                  <FormLabel component="legend">Anestesia Utilizada</FormLabel>

                  <FormGroup aria-label="position">
                    <Controller
                      name="anestesia_local"
                      control={control}
                      render={({ field: { onChange, value } }) => {
                        return (
                          <FormControlLabel
                            value="local"
                            disabled={true}
                            control={
                              <Checkbox
                                onChange={onChange}
                                checked={valueAnestesia_local}
                              />
                            }
                            label="Local"
                          />
                        );
                      }}
                    />
                    <Controller
                      name="anestesia_sedacao"
                      control={control}
                      render={({ field: { onChange, value } }) => {
                        return (
                          <FormControlLabel
                            value="sedacao"
                            disabled={true}
                            control={
                              <Checkbox
                                onChange={onChange}
                                checked={valueAnestesia_sedacao}
                              />
                            }
                            label="Sedação"
                          />
                        );
                      }}
                    />
                    <Controller
                      name="anestesia_raqui"
                      control={control}
                      render={({ field: { onChange, value } }) => {
                        return (
                          <FormControlLabel
                            value="raqui"
                            disabled={true}
                            control={
                              <Checkbox
                                onChange={onChange}
                                checked={valueAnestesia_raqui}
                              />
                            }
                            label="Raqui"
                          />
                        );
                      }}
                    />
                    <Controller
                      name="anestesia_outras"
                      control={control}
                      render={({ field: { onChange, value } }) => {
                        return (
                          <FormControlLabel
                            value="outras"
                            disabled={true}
                            control={
                              <Checkbox
                                onChange={(_, checked) => {
                                  if (!checked) {
                                    setValue('anestesia_outras_desc', null);
                                  }
                                  onChange(checked);
                                }}
                                checked={valueAnestesia_outras}
                              />
                            }
                            label="Outras"
                          />
                        );
                      }}
                    />
                  </FormGroup>
                </FormControl>
              </Grid>

              {valueAnestesia_outras === true && (
                <Grid item xs={12}>
                  <Controller
                    name="anestesia_outras_desc"
                    control={control}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <TextField
                          label="Outras - Descrição"
                          disabled={true}
                          multiline
                          fullWidth
                          onChange={onChange}
                          value={valueAnestesia_outras_desc}
                          InputLabelProps={{ shrink: true }}
                          rows={4}
                        />
                      );
                    }}
                  />
                </Grid>
              )}

              <Grid item xs={12}>
                <Typography color="gray" mb={1}>
                  Tipo de Procedimento Realizado? <Asterisk />
                </Typography>

                <Box ml={3}>
                  <FormControl>
                    <FormLabel>Por alça diatérmica:</FormLabel>
                    <Controller
                      name="tipo_procedimento"
                      control={control}
                      render={({ field: { onChange, value } }) => {
                        return (
                          <RadioGroup value={valueTipo_procedimento}>
                            <FormControlLabel
                              disabled={true}
                              value={1}
                              control={<Radio />}
                              label=" Excisão com uma passagem da alça diatérmica"
                            />
                            <FormControlLabel
                              disabled={true}
                              value={2}
                              control={<Radio />}
                              label="Excisão com múltiplas passagens da alça diatérmica com ampliação de margens laterais"
                            />
                            <FormControlLabel
                              disabled={true}
                              value={3}
                              control={<Radio />}
                              label="Excisão com múltiplas passagens da alça diatérmica com ampliação de canal endocervical (margem endocervical)"
                            />

                            <Box mt={4} display="flex" flexDirection="column">
                              <FormLabel>Por bisturi a fio:</FormLabel>
                              <FormControlLabel
                                disabled={true}
                                value={4}
                                control={<Radio />}
                                label=" Cone clássico"
                              />
                            </Box>
                          </RadioGroup>
                        );
                      }}
                    />
                  </FormControl>
                </Box>
              </Grid>

              <Grid item xs={12} md={8}>
                <Controller
                  name="profissionalId"
                  control={control}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <StyledAsteriskTextField
                        required
                        error={!!errors?.estabelecimentoSaudeId}
                        helperText={errors.estabelecimentoSaudeId?.message}
                        label="Profissional Responsável"
                        fullWidth
                        size="small"
                        select
                        disabled
                        value={valueProfissionalId}
                        onChange={onChange}
                      >
                        {responsaveis.map((profissional) => {
                          return (
                            <MenuItem value={profissional?.idprofissional}>
                              {profissional?.label}
                            </MenuItem>
                          );
                        })}
                      </StyledAsteriskTextField>
                    );
                  }}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <Controller
                  name="cbo"
                  control={control}
                  render={({ field: { value } }) => {
                    return (
                      <StyledAsteriskTextField
                        required
                        disabled
                        value={valueCbo}
                        label="CBO"
                        fullWidth
                        size="small"
                        InputLabelProps={{
                          shrink: valueCbo ? true : false,
                        }}
                      />
                    );
                  }}
                />
              </Grid>
            </Grid>
          </Paper>

          <Box mt={3}>
            {state.examData && (
              <Button
                sx={{
                  backgroundColor: '#17BEBB',
                }}
                variant="contained"
                type="submit"
                startIcon={<ArrowBack />}
                onClick={() => navigate(`/pacientes/${id}`)}
              >
                Voltar
              </Button>
            )}
          </Box>

          {!state.examData && (
            <Box mt={3} display="flex" justifyContent="space-between">
              <Button
                sx={{
                  backgroundColor: '#17BEBB',
                }}
                variant="contained"
                type="submit"
                startIcon={<CheckIcon />}
              >
                Cadastrar
              </Button>

              <Button
                variant="outlined"
                color="error"
                onClick={() => navigate(`/pacientes/${location.state.id}`)}
                startIcon={<ClearIcon />}
              >
                Cancelar
              </Button>
            </Box>
          )}
        </Container>
      </form>
    </SideMenu>
  );
}
